<template>
  <v-scroll-y-transition tag="div" class="transition-wrapper" :class="{'success-banner': interactiveMessage}" group>
    <div
      v-for="(message, key) in messages"
      :key="key"
      class="notification-success-message"
    >
      <div
        class="success-message" :class="getClass(message.type)"
        v-if="message.type !== 'confirmation'"
      >
        <img
          :src="getImage(message.type)"
          loading="lazy"
          alt=""
          width="40"
          height="40"
          class="image-4 noselect"
        />
        <div class="noselect">{{ message.text }}</div>
      </div>
    </div>
    <div
      class="notification-success-message"
      key="1"
      style="padding-right: 20px"
      v-if="interactiveMessage"
    >
      <div class="success-message confirmation-background">
        <div class="noselect ml-3 mr-5">{{ interactiveMessage.text }}</div>
        <div class="d-flex flex-row align-items-center">
          <button
            class="confirmation-button mr-2"
            @click="callbackPass = true"
          >
            OK
          </button>
          <img
            src="@/images/x.png" alt=""
            width="13" height="13"
            class="pointer"
            @click="callbackPass = false"
          >
        </div>
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SuccessMessageHandler",
  computed: {
    ...mapGetters({
      successMessageGetter: "appState/getSuccessMessage",
    }),
  },
  watch: {
    successMessageGetter (successMessage) {
      if (!successMessage) return;
      if (['error', 'success'].includes(successMessage.type)) this.nonInteractiveMessageHandler(successMessage);
      else if (successMessage.type === 'confirmation') this.interactiveMessageHandler(successMessage);
      this.$store.commit("appState/setSuccessMessage", null);
    },
  },
  methods: {
    interactiveMessageHandler (successMessage) {
      if (this.interactiveMessage) return;
      this.interactiveMessage = successMessage;
      const int = setInterval(() => {
        if (this.callbackPass === true) {
          this.$store.commit("appState/setUserConfirmation", true);
          this.interactiveMessage = null;
          this.callbackPass = null;
          clearInterval(int);
        } else if (this.callbackPass === false) {
          this.$store.commit("appState/setUserConfirmation", false);
          this.interactiveMessage = null;
          this.callbackPass = null;
          clearInterval(int);
        }
      }, 50);
    },
    nonInteractiveMessageHandler (message) {
      this.messages.push(message);
      setTimeout(() => {
        this.messages.shift();
      }, 2000);
    },
    getClass (type) {
      if (type === "success") return "success-message-background";
      else if (type === "error") return "error-message-background";
      else if (type === "confirmation") return "confirmation-message-background";
    },
    getImage (type) {
      if (type === "success") return require("@/images/success-icon.png");
      else if (type === "error") return require("@/images/error-exclamation.png");
    },
  },
  data: () => ({
    messages: [],
    interactiveMessage: null,
    message: null,
    showPage: false,
    type: null,
    callbackPass: null,
  }),
};
</script>

<style lang="scss" scoped>
.transition-wrapper {
  position: fixed;
  width: 100%;
  //left: 0;
  bottom: 0;
  //right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 99999 !important;
}

.notification-success-message {
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.success-message-background {
  background: linear-gradient(90.06deg, rgba(42, 139, 242, 0.9) 0.04%, rgba(0, 25, 217, 0.9) 99.96%);
}

.error-message-background {
  background: linear-gradient(90.06deg, rgba(255, 51, 102, 0.9) 0.04%, rgba(228, 21, 9, 0.9) 99.96%);
}

.confirmation-background {
  background: linear-gradient(90.06deg, rgba(32, 161, 52, 0.9) 0.04%, rgba(37, 139, 102, 0.9) 99.96%);
}

.confirmation-message {
  max-width: 45%;
}

.confirmation-button {
  background: #0E6A49;
  border-radius: 5px;
  width: 60px;
}

.success-banner {
  position: fixed;
  bottom: 0;
  height: 100%;
  justify-content: end;
}
</style>
