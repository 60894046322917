import store from "../store/index";

export default {
  methods: {
    getMortgagePurpose () {
      let maxAmount = Number.MIN_SAFE_INTEGER;
      let purpose = null;
      for (const [key, amount] of Object.entries(store.getters["mortgageDetailState/getAmounts"])) {
        if (amount < maxAmount) continue;
        maxAmount = amount;
        purpose = key;
      }
      if (maxAmount === 0) {
        purpose = store.getters["mortgageDetailState/getMortgage"].ciel_prva_analyza;
      }
      switch (purpose) {
        case "suma_bezucel":
        case "americka_hypo":
          purpose = "bezucel";
          break;
        case "suma_refinancovanie":
        case "refinancovanie":
        case "konsolidacia":
          purpose = "refinancovanie";
          break;
        case "suma_nadobudnutie":
        case "nadobudnutie":
        case "uverovy_certifikat":
          purpose = "nadobudnutie";
          break;
        default:
          purpose = "nadobudnutie";
          break;
      }
      return purpose;
    },
    getMortgageAmount() {
      const amounts = store.getters["mortgageDetailState/getAmounts"];
      const mortgage = store.getters["mortgageDetailState/getMortgage"];
      let totalMortgageAmount = Number.MIN_SAFE_INTEGER;
      let totalAmount = 0;
      for (const amount of Object.values()) {
        totalAmount += amount;
      }
      return totalAmount;
    }
  }
}