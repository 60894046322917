<template>
  <div
    class="div-block-102"
  >
    <div class="div-block-106">
      <range-slider
        :id-not-selected="'not-selected-ucelova-case'"
        :id-selected="'selected-ucelova-case'"
        :min="0"
        :max="limit"
        :default-value="amounts.suma_bezucel"
        :twoSideValues="false"
        :description="'Výška bez účelovej časti'"
        :unit="'EUR'"
        :value-correction="valueCorrection"
        @value-corrected="valueCorrection = null"
        @change-event="setUcelAmount($event)"
      />
    </div>
  </div>
</template>

<script>
import RangeSlider from "../../../../shared/RangeSlider";
import { mapGetters } from "vuex";

export default {
  name: "UcelovaCastWrapper",
  components: {
    RangeSlider
  },
  props: {
    mortgage: Object,
    totalLimit: Number,
    loanPurpose: [String, null],
  },
  computed: {
    ...mapGetters({
      amountsGetter: "mortgageDetailState/getAmounts",
    }),
  },
  data: () => ({
    amounts: null,
    limit: 0,
    valueCorrection: null,
  }),
  created () {
    this.amounts = this.$store.getters["mortgageDetailState/getAmounts"];
    if (!this.totalLimit) return;
    this.setMaximumAmount();
  },
  watch: {
    // limit (newLimit) {
    //   this.$store.commit("mortgageDetailState/setAmountsLimits", {type: "suma_bezucel", value: newLimit});
    // },
    amountsGetter (newAmounts) {
      this.amounts = newAmounts;
      this.setMaximumAmount();
    },
    totalLimit () {
      this.setMaximumAmount();
    },
  },
  methods: {
    showConfirmationDialog () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Touto zmenou zmeníte účel hypotéky",
        type: "confirmation"
      });
    },
    checkForPurposeChange (value) {
      const currentMaxKey = Object.keys(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const currentMaxValue = Object.values(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const newAmounts = {...this.amounts, suma_bezucel: value};
      const newMaxKey = Object.keys(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      const newMaxValues = Object.values(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      if (currentMaxValue === 0 || newMaxValues === 0) return false;
      return currentMaxKey !== newMaxKey
    },
    askForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    setMaximumAmount () {
      let limit = this.totalLimit;
      for (const [key, amount] of Object.entries(this.amounts)) {
        if (key === "suma_bezucel") continue;
        limit = limit - amount;
      }
      if (limit < 0) {
        this.limit = this.totalLimit;
        if (this.loanPurpose === "bezucel") {
          this.$store.commit("mortgageDetailState/setAmounts", {
            suma_nadobudnutie: 0,
            suma_refinancovanie: 0,
            suma_bezucel: this.limit,
          });
        }
      } else {
        this.limit = limit;
      }
    },
    async setUcelAmount (value) {
      const latestValue = this.amounts.suma_bezucel;
      const willChangePurpose = this.checkForPurposeChange(value);
      let userPermission = null;
      if (willChangePurpose) {
        this.showConfirmationDialog();
        userPermission = await this.askForUserPermission();
      }
      if (userPermission === false) {
        this.valueCorrection = latestValue;
        return;
      }
      if (latestValue === value) return;
      this.$store.commit("mortgageDetailState/setAmount", {
        type: 'suma_bezucel',
        value: value,
      });
      const mortgageObject = this.createObjectToHistory();
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
    },
    createObjectToHistory () {
      const currentHistory = this.mortgage.offers_filter_history;
      const applicationData = this.$store.getters["mortgageDetailState/getOffersData"];
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let newHistoryObject = {
        date: new Date().toISOString(),
        fix: applicationData.fix ? applicationData.fix : 3,
        suma_bezucel: amounts.suma_bezucel ? amounts.suma_bezucel : 0,
        suma_nadobudnutie: amounts.suma_nadobudnutie ? amounts.suma_nadobudnutie : 0,
        suma_refinancovanie: amounts.suma_refinancovanie ? amounts.suma_refinancovanie : 0
      };
      if (!currentHistory) {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              broker: {
                lastOffersSetup: null,
                history: [newHistoryObject],
              },
              sent: [],
              client: [],
            }
          },
        }
      } else {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              sent: currentHistory.sent,
              client: currentHistory.client,
              broker: {
                lastOffersSetup: currentHistory.broker?.lastOffersSetup ? currentHistory.broker.lastOffersSetup : null,
                history:
                  currentHistory.broker.history ? [newHistoryObject, ...currentHistory.broker.history] : Array.isArray(currentHistory.broker) && currentHistory.broker?.length !== 0 ? [newHistoryObject, ...currentHistory.broker] : [newHistoryObject],
              }
            }
          }
        }
      }
    },
  }
};
</script>

<style scoped></style>
