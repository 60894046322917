<template>
  <div
    class="noselect"
    :class="classCst"
    @click="openApplicationSignDatePopUp()"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "ClientEventHandler",
  props: {
    rules: Array,
    type: [String, null],
    title: String,
    eventType: String,
    component: String,
    classCst: [String, null],
    client: [Object, null],
  },
  methods: {
    openApplicationSignDatePopUp () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {type: this.type, component: this.component},
        payload: this.client,
        component: "ClientEventPopUp",
        rules: this.rules,
      });
    }
  }
}
</script>

<style scoped>

</style>
