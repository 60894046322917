<template>
  <div class="zaznam-banner">
    <div class="d-flex" style="align-items: center;">
      <img class="image-10" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f38_zok%20ikon.png" alt="">
      <div class="blc-color pop-800 ml-3">
        Záznam o potrebách klienta
      </div>
    </div>
    <div class="div-block-144 fullwidth">
      <client-needs-handler/>
      <document-cloud-handler :leadingText="true" :type="6" :cloud-meta="mortgageCloud" :rules="['priority']" force-file-replace icon-button-sign/>
      <!-- <div class="klienticta w-inline-block pointer" @click="openHelperPopup">
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4d_icons8-ereader.svg" alt="">
        ako na záznam?
      </div> -->
    </div>
    <div class="uploaded-files-wrapper">
      <div
        class="link-block-7 w-inline-block justify-content-between"
        v-for="(file, index) in clientNeedsFiles"
        :key="index"
      >
        <div class="d-flex flex-row mr-3">
          <div class="small">{{ file.name }}</div>
        </div>
        <a
          :href="file.file"
          target="_blank"
          class="text-cta pointer"
          style="text-decoration: none"
        >
          STIAHNUŤ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationMissingInfoHandler
  from "../../../../shared/PopupHandler/Popups/ApplicationMissingInfo/ApplicationMissingInfoHandler";
import ClientNeedsHandler from "@/components/shared/PopupHandler/Popups/ClientNeeds/ClientNeedsHandler.vue";
import {mapGetters} from "vuex";
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";

export default {
  name: "ClientNeedsRecord",
  components: {
    DocumentCloudHandler,
    ClientNeedsHandler,
    ApplicationMissingInfoHandler
  },
  computed: {
    ...mapGetters({
      cloudGetter: "mortgageDetailCloud/getClouds",
    })
  },
  watch: {
    cloudGetter() {
      this.clientNeedsFiles = this.$store.getters["mortgageDetailCloud/getAllFilesWithSpecificType"]("zaznam_o_potrebach_klienta");
    },
  },
  created() {
    const mortgageCloudId = (this.$store.getters["mortgageDetailState/getMortgage"]).cloud;
    this.mortgageCloud = (this.$store.getters["mortgageDetailCloud/getCloudByCloudId"](mortgageCloudId)).cloudMeta;
    this.clientNeedsFiles = this.$store.getters["mortgageDetailCloud/getAllFilesWithSpecificType"]("zaznam_o_potrebach_klienta");
  },
  methods: {
    openHelperPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ApplicationHelpPopUp",
        rules: ['single'],
      })
    },
  },
  data: () => ({
    mortgageCloud: null,
    clientNeedsFiles: [],
  }),
}
</script>

<style lang="scss" scoped>
</style>
