<template>
  <div
    class="klienticta pointer"
    style="margin-left: 6px"
    @click="openShareChatPopUp()"
  >
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f23_icons8-invite.svg">
  </div>
</template>

<script>
export default {
  name: "ClientChatShareHandler",
  props: {
    clientObject: Object,
  },
  methods: {
    openShareChatPopUp() {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.clientObject,
        component: "ClientChatSharePopUp",
        rules: ['single'],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>