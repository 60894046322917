<template>
  <div class="pointer archive-button button-item-handler white--text no-decoration"
       @click="openMortgageApplicationsRemovePopup">
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1b_icons8-trash.svg" alt="">
  </div>
</template>

<script>
export default {
  name: "ObjectsRemoveHandler",
  methods: {
    openMortgageApplicationsRemovePopup () {
      this.$store.commit("popupManagerState/toggleMortgageApplicationsRemovePopup", true);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
