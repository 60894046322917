<template>
  <div
    class="pointer button-item-handler white--text no-decoration" @click="showCalculationsHistory"
  >
    <img
      class="nav-link-icon filter-primary"
      src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4a_icons8-clock.svg"
      alt="" 
    >
  </div>
</template>

<script>
export default {
  name: "CalculationsHistoryHandler",
  props: {
    rules: [Array, null],
    history: Object,
  },
  methods: {
    showCalculationsHistory () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.history,
        component: "CalculationsHistoryPopUp",
        rules: this.rules,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
