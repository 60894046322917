<template>
  <div>
    <div class="cloud-holder-wrapper">
      <client-summary :summary-data="clientData.clientObject" :role="role" :opened-default="opened"/>
    </div>
    <div
      class="cloud-holder-wrapper"
      v-for="(income, index) in clientData.incomes"
      :key="index + 'income'"
      v-if="income.is_active && transformTypeToSummary(income.druh)"
    >
      <component
        :is="transformTypeToSummary(income.druh)"
        :opened-default="opened"
        :summary-data="income"
      />
    </div>
    <div
      class="cloud-holder-wrapper"
      v-for="(debt, index) in clientData.debts"
      :key="index + 'debt'"
      v-if="shouldDisplayDebt(debt)"
    >
      <debt-summary :summary-data="debt" :opened-default="opened"/>
    </div>
  </div>
</template>

<script>
import ClientSummary from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/ClientSummary.vue";
import EmploymentSummary from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/EmploymentSummary.vue";
import SroSummary from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/SroSummary.vue";
import TradesmanSummary from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/TradesmanSummary.vue";
import DebtSummary from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/DebtSummary.vue";
import translateFromDBValue from "@/mixins/translateFromDBValue";

export default {
  name: "ClientSection",
  props: {
    opened: Boolean,
    role: {
      type: String,
      required: true,
    },
    clientData: {
      type: Object,
      required: true,
    },
  },
  components: {
    DebtSummary,
    TradesmanSummary,
    SroSummary,
    EmploymentSummary,
    ClientSummary
  },
  mixins: [
    translateFromDBValue
  ],
  methods: {
    shouldDisplayDebt(debt) {
      if (debt.is_visible) return true;
      const mortgageId = this.$store.getters["mortgageDetailState/getMortgage"].id
      return debt.refinancovany_hypotekou === mortgageId;
    },
    transformTypeToSummary(type) {
      const translatedType = this.translateIncomeType(type);
      switch (translatedType) {
        case "Zamestnanie": return "EmploymentSummary";
        case "Živnosť": return "TradesmanSummary";
        case "Sro": return "SroSummary";
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>