<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Prerušenie schvaľovania</div>
        <button class="w-button saveformBtn" @click="saveStatus">Aktualizovať</button>
      </div>
      <!-- <div class="input-group">
        <label class="field-label">Kedy ste žiadosť odovzdali</label>
        <input
          name="dateOfUpload"
          type="date"
          :max="todayLong"
          class="text-input w-input date-input"
          maxlength="256"
        />
      </div> -->
      <!-- <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="infoMissing = $event"
          :id="'doplnit'"
          :init-value="missingInfo[0].value"
          :options="missingInfo"
          :name="'Čo treba doplniť'"
          normal
        />
      </div> -->
      <div class="input-group">
        <label class="field-label">Dôvod prerušenia</label>
        <textarea type="text" class="text-input w-input date-input" v-model="formData.dovod_prerusenia"/>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "MortgageApplicationStoppedPopup",
  components: {DropdownSelect},
  // props: {
  //   payload: Object,
  // },
  methods: {
    saveStatus () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: true, form: this.formData, stage: "prerusena"});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    closePopup () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  beforeDestroy () {
    if (this.preverDestroy) return;
    this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
  },
  data: () => ({
    preverDestroy: false,
    todayLong: new Date().toISOString().substr(0, 10),
    infoMissing: null,
    formData: {
      dovod_prerusenia: null,
    },
    missingInfo: [
      {
        display_name: "výpis z účtu",
        value: "vypis_z_uctu",
      },
    ]
  }),
}
</script>

<style scoped>

</style>
