<template>
  <div class="popup-form">

   
    <div
      class="content-form-popup"
    > 
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
          />
        </div>
        <div class="blackcolor pop-800">Doplnenie žiadosti</div>
      </div>
      <div class="w-form">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationMissingInfoPopUp",
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style scoped>

</style>
