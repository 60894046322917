<template>
  <div class="popup-form">
      <div class="content-form-popup">
        <div class="header-text">
            <div class="back-button-wrapper pointer" @click="closePopup">
              <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt="">
            </div>
            <div class="blackcolor pop-800">
              {{ payload ? "Úprava" : "Pridanie" }} {{ getPropertyDataBySubType(type.child_type).label }}
            </div>
            <button form="property-submit" class="w-button saveformBtn">Uložiť</button>
        </div>
        <property-form
          :druh="type.druh"
          :type="getPropertyDataBySubType(type.child_type)"
          @property-saved="saveProperty($event)"
          :property="payload"
          :banks="banks"
          :form-template="form"
        />
        <div v-if="cloud && cloud.missingFiles.length > 0">
          <div class="splitter">Podklady</div>
          <document-cloud-handler
            v-for="(missingFile, index) in cloud.missingFiles"
            :key="index"
            :title="missingFile.name"
            :type="missingFile.id"
            :uploaded="missingFile.uploaded"
            :class-cst="'upload-item-broker-custom'"
            :cloud-meta="cloud.cloudMeta"
            :rules="['single']"
            text-button
          />
        </div>
      </div>
  </div>
</template>

<script>
import PropertyForm from "./PropertyForm";
import propertyFilters from "../../../../../mixins/propertyFilters";
import {postChildTypeToPropertyById} from "../../../../../services/brokerService";
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";
import {mapGetters} from "vuex";

export default {
  name: "ClientPropertyPopUp",
  components: {
    DocumentCloudHandler,
    PropertyForm
  },
  props: {
    payload: [Object, null],
    type: Object,
  },
  mixins: [
    propertyFilters,
  ],
  computed: {
    ...mapGetters({
      cloudsGetter: "mortgageDetailCloud/getClouds",
    }),
  },
  watch: {
    cloudsGetter(clouds) {
      if (!this.payload.cloud) return;
      this.cloud = clouds.find((cloud) => cloud.cloudId === this.payload.cloud);
    }
  },
  created () {
    if (this.payload?.cloud) {
      const clouds = this.$store.getters["mortgageDetailCloud/getClouds"];
      this.cloud = clouds.find((cloud) => cloud.cloudId === this.payload.cloud);
    }
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
    this.form = this.$store.getters["formStore/getFormByType"]("propertyForm");
    const pBanks = this.$store.getters["formStore/getBanks"];
    for (const bank of pBanks) {
      this.banks.push({display_name: bank.nazov, value: bank.id});
    }
  },
  methods: {
    async saveProperty (propertySaved) {
      if (this.payload) {
        const data = {
          propertyLabel: this.getLabelByType(this.type.druh),
          id: propertySaved.id,
          object: propertySaved.data,
        }
        if (!propertySaved.meta.childTypeAlreadySpecified) {
          const newProperty = await postChildTypeToPropertyById({type: propertySaved.meta.childType}, propertySaved.id);
          data.object = {...newProperty, ...data.object};
        }
        this.$store.dispatch("mortgageDetailState/patchProperty", data);
      } else {
        const data = {
          propertyLabel: this.getLabelByType(this.type.druh),
          propertyType: this.type,
          mortgageId: this.mortgage.id,
          object: propertySaved.data,
        }
        this.$store.dispatch("mortgageDetailState/createProperty", data);
      }
      this.closePopup();
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    cloud: null,
    banks: [],
    form: null,
    mortgage: null,
  }),
};
</script>

<style scoped lang="scss">
.sub-popup-wrapper {
  text-align: center;
  color: #0019d9;
}

.popup {
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.pop-up-active {
  transform: translateX(0);
}

.header-form-popup {
  border: none;
}
</style>
