<template>
  <div class="bcg-loading" v-if="loading">
    <v-progress-circular
      class="loading-spinner"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadingPage",
  computed: {
    ...mapGetters({
      loginPageLoadingGetter: "appState/getLoadingPage",
    }),
  },
  watch: {
    loginPageLoadingGetter (loading) {
      this.loading = loading;
    },
  },
  created () {
    this.loading = this.$store.getters["appState/getLoadingPage"];
  },
  data: () => ({
    loading: null,
  }),
};
</script>

<style scoped>
.bcg-loading {
  position: fixed;
  background-color: rgba(0, 0, 0, 25%);
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
