<template>
  <div class="master-filter drop hideSmartphone">
    <div class="dropdown-title">{{ label }}</div>
    <div data-hover="false" data-delay="0" class="dropdown w-dropdown">
      <b-dropdown
        ref="dropdown-filters"
        size="sm"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="w-dropdown-toggle">
            <div class="icon w-icon-dropdown-toggle"></div>
            <div class="dropdown-placeholder">
              {{ inspectionsFor.active.name }}
            </div>
          </div>
        </template>
        <b-dropdown-item
          class="drop"
          :class="[
              inspectionsFor.active === item ? 'active-filter' : '',
            ]"
          href="#"
          v-for="(item, key) in inspectionsFor.inDropdown"
          :key="key"
          @click="filterPeriod(item)"
        >
          <div class="text-block-4 fnt-13" id="inspectionsFor">
            {{ item.name }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterByPeriod",
  props: {
    label: String,
  },
  methods: {
    getFirstDayOfTheMonth () {
      const date = new Date();
      const firstDayCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      );
      return firstDayCurrentMonth.toLocaleDateString();
    },
    getFirstDayOfTheSecondLastMonth () {
      const date = new Date();
      const firstDayCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      return firstDayCurrentMonth.toLocaleDateString();
    },
    getFirstDayOfTheLastMonth () {
      const date = new Date();
      const firstDayCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      );
      return firstDayCurrentMonth.toLocaleDateString();
    },
    getLastDayOfTheLastMonth () {
      const date = new Date();
      const firstDayCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        0
      );
      return firstDayCurrentMonth.toLocaleDateString();
    },
    getMondayOfWeek (type) {
      const today = new Date();
      let first = null;
      if (type === "current") {
        first = today.getDate() - today.getDay() + 1;
      } else {
        first = today.getDate() - today.getDay() + 1 - 7;
      }

      const monday = new Date(today.setDate(first));
      return monday.toLocaleDateString();
    },
    getDateOfDatePriorToToday (days) {
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - days));
      return priorDate.toLocaleDateString();
    },
    filterPeriod (item) {
      this.inspectionsFor.active = item;
      let gte = null;
      let stgte = null;
      let lt = null;
      let stlt = null;
      switch (item.value) {
        case "thisWeek":
          gte = this.getMondayOfWeek("current");
          stgte = this.getMondayOfWeek("previous");
          stlt = this.getMondayOfWeek("current");
          break;
        case "lastSevenDays":
          gte = this.getDateOfDatePriorToToday(7);
          stgte = this.getDateOfDatePriorToToday(14);
          stlt = this.getDateOfDatePriorToToday(7);
          break;
        case "lastFifteenDays":
          gte = this.getDateOfDatePriorToToday(15);
          stgte = this.getDateOfDatePriorToToday(30);
          stlt = this.getDateOfDatePriorToToday(15);
          break;
        case "thisMonth":
          gte = this.getFirstDayOfTheMonth();
          stgte = this.getFirstDayOfTheLastMonth();
          stlt = this.getLastDayOfTheLastMonth();
          break;
        case "lastThirtyDays":
          gte = this.getDateOfDatePriorToToday(30);
          stgte = this.getDateOfDatePriorToToday(60);
          stlt = this.getDateOfDatePriorToToday(30);
          break;
        case "lastMonth":
          gte = this.getFirstDayOfTheLastMonth();
          stlt = this.getFirstDayOfTheLastMonth();
          lt = this.getLastDayOfTheLastMonth();
          stgte = this.getFirstDayOfTheSecondLastMonth();
          break;
        case "all":
          gte = null;
          stlt = null;
          lt = null;
          stgte = null;
          break;
        default:
          gte = null;
          stlt = null;
          lt = null;
          stgte = null;
          break;
      }
      this.$emit("period-filter-change", {gte: gte, lt: lt});
      this.$emit("period-statistic-change", {gte: stgte, lt: stlt});
    },
  },
  data: () => ({
    inspectionsFor: {
      active: {name: "Všetko", value: "all"},
      inDropdown: [
        {name: "Všetko", value: "all"},
        {name: "Tento týždeň", value: "thisWeek"},
        {name: "Posledných 7 dní", value: "lastSevenDays"},
        {name: "Posledných 15 dní", value: "lastFifteenDays"},
        {name: "Tento mesiac", value: "thisMonth"},
        {name: "Posledných 30 dní", value: "lastThirtyDays"},
        {name: "Predchádzajúci mesiac", value: "lastMonth"},
      ],
    },
  }),
}
</script>

<style lang="scss" scoped>
.active-filter {
  background-color: rgba(232, 232, 232, 0.8);
}
</style>

