<template>
  <div class="popup-form popup" @dragover="dragover" @dragleave="dragleave">
    <div class="pdf-section">
      <iframe
        v-if="!loadingPdf && sourcePdf"
        id="iframepdf"
        :src="sourcePdf"
      />
      <div class="no-pdf-link" v-else-if="!loadingPdf && !sourcePdf">
        <img src="@/images/no-generated-pdf-image.svg" alt="">
        <div>
          v prípade tejto banky je potrebné žiadosť <br>
          zadať <a :href="getLinkToApplication(payload.application?.bank?.code_name)" target="_blank" class="bold">online TU</a>,
          vygenerovanú žiadosť <br>online nzabudnite nahrať hore v pravo
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center h-100 w-100" v-else>
        <v-progress-circular
          class="loading-spinner"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div class="infomation-section">
      <div class="content-form-popup">
        <div class="header-text">
          <div class="back-button-wrapper pointer" @click="closePopup">
            <img
              class="nav-link-icon filter-primary"
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
              alt=""
            />
          </div>
          <div class="blackcolor pop-800">Vygenerovanie žiadosti</div>
          <button class="w-button" @click="savePdfToCloud">Uložiť</button>
        </div>
        <div class="information-wrapper">
          <div class="header-wrapper-custom">
            <img :src="payload.application?.bank?.logo_square" alt="" width="50px" height="auto">
            <div class="p-12 bg-gray-100 border border-gray-300" @drop="drop">
              <input
                type="file"
                name="vygenerovana-ziadost"
                id="vygenerovana-ziadost"
                @change="onChange"
                ref="file" accept=".pdf"
              />
              <label
                for="vygenerovana-ziadost"
                class="sub-ctass image-upload-btn add-file-button pointer blc-color pop-800 mb-0 drag-drop-label"
              >
                {{ filelist.length === 0 ? "+vyplnená žiadosť" : filelist[0].name }}
              </label>
            </div>
          </div>
        </div>
        <div class="cloud-holder-wrapper ">
          <mortgage-summary :summary-data="payload.application"/>
        </div>
        <client-section :client-data="applicantData" :role="'Žiadateľ'" :opened-default="opened"/>
        <client-section v-if="coApplicantData.clientObject" :client-data="coApplicantData" :role="'Spolužiadateľ'" :opened-default="opened"/>
        <div
          class="cloud-holder-wrapper"
          v-for="(property, index) in foundedPropertiesData"
          :key="index + 'founded'"
        >
          <property-summary :summary-data="property" :label="`Zabezpečenie úveru ${index+1}`" :opened-default="opened"/>
        </div>
        <div
          class="cloud-holder-wrapper"
          v-for="(property, index) in financedPropertiesData"
          :key="index + 'financed'"
        >
          <property-summary :summary-data="property" :label="`Predmet nadobúdania ${index+1}`" :opened-default="opened"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertySummary from "./PropertySummary";
import MortgageSummary from "./MortgageSummary";
import EmploymentSummary from "./EmploymentSummary";
import TradesmanSummary from "./TradesmanSummary";
import DebtSummary from "./DebtSummary";
import SroSummary from "./SroSummary";
import ClientSummary from "./ClientSummary";
import ClientSection from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/ClientSection.vue";
import propertyFilters from "@/mixins/propertyFilters";
import {postFileToCloud, removeCloudFileByID} from "@/services/brokerService";

export default {
  name: "ApplicationGeneratorPopUp",
  props: {
    payload: Object,
  },
  components: {
    ClientSection,
    ClientSummary,
    SroSummary,
    DebtSummary,
    TradesmanSummary,
    EmploymentSummary,
    MortgageSummary,
    PropertySummary
  },
  mixins: [
    propertyFilters,
  ],
  async created() {
    this.applicantData = this.$store.getters["mortgageDetailState/getFullApplicant"];
    this.coApplicantData = this.$store.getters["mortgageDetailState/getFullCoApplicant"];
    const propertyObjects = this.$store.getters["mortgageDetailState/getProperties"];
    this.financedPropertiesData = this.filterFinancedPropertiesById(propertyObjects);
    this.foundedPropertiesData = this.filterFoundedPropertiesById(propertyObjects);
    const application = this.payload.application;
    if (!application.bank.ziadost_o_uver) {
      this.loadingPdf = false;
      return;
    }
    const applicationPdf = this.$store.getters["mortgageDetailCloud/getCloudFileFromCloudByType"](application.cloud, "vygenerovana_ziadost");
    if (applicationPdf) {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Súbor bol identifikovaný",
        type: "success"
      });
      this.sourcePdf = applicationPdf.file;
      this.oldFile = applicationPdf;
    }
    else {
      this.sourcePdf = application.bank.ziadost_o_uver;
    }
    this.loadingPdf = false;
  },
  methods: {
    async createFileBlob(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (event) => {
          const data = event.target.result;
          const blob = new Blob([data], { type: file.type });
          resolve(blob);
        }
        reader.onerror = reject;
      });
    },
    async savePdfToCloud() {
      const file = this.filelist[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("cloud", this.payload.application.cloud);
      formData.append("type", "5");
      const newPdfFile = await postFileToCloud(formData);
      newPdfFile.type = this.$store.getters["mortgageDetailCloud/getCloudFileTypeById"](newPdfFile.type);
      this.$store.commit("appState/setSuccessMessage", {
        text: "Žiadosť bola úspešne uložená co cloudu.",
        type: "success"
      })
      this.closePopup();
      this.$store.commit("mortgageDetailCloud/registerNewFiles", [newPdfFile]);
      if (!this.oldFile) {
        this.oldFile = newPdfFile;
        return;
      }
      this.$store.commit("mortgageDetailCloud/removeCloudFile", {cloudId: this.payload.application.cloud, file: this.oldFile})
      await removeCloudFileByID(this.oldFile.id);
      this.oldFile = newPdfFile;
    },
    onChange() {
      this.filelist = this.$refs.file.files;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    getLinkToApplication(bankKey) {
      switch(bankKey) {
        case "mbank": return "https://www.mbankbrokers.cz/login/index";
        case "vub": return "https://ib.vub.sk/eap/login";
        case "slsp": return "https://partnerportal.slsp.sk/partnerportal/sk";
        default: return "";
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!this.sourcePdf || this.classAssigned) return;
      const label = event.currentTarget.getElementsByClassName("drag-drop-label")[0];
      label.textContent = "Žiadosť ako PDF pretiahnite sem";
      this.classAssigned = true;
      label.classList.add("drag-active");
    },
    dragleave(event) {
      if (!this.sourcePdf) return;
      const label = event.currentTarget.getElementsByClassName("drag-drop-label")[0];
      label.textContent = "+vyplnená žiadosť"
      this.classAssigned = false;
      label.classList.remove("drag-active")
    },
    drop(event) {
      event.preventDefault();
      if (!this.sourcePdf) return;
      this.$refs.file.files = event.dataTransfer.files;
      const label = event.currentTarget.getElementsByClassName("drag-drop-label")[0];
      label.textContent = event.dataTransfer.files[0].name;
      label.classList.remove("drag-active")
      this.onChange();
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    classAssigned: false,
    oldFile: null,
    filelist: [], // Store our uploaded files
    sourcePdf: null,
    loadingPdf: true,
    opened: false,
    applicantData: null,
    coApplicantData: null,
    foundedPropertiesData: null,
    financedPropertiesData: null,
  }),
}
</script>

<style lang="scss" scoped>

input[type="file"] {
  display: none;
}

.infomation-section {
  overflow-y: auto;
}

.popup {
  display: flex;
  flex-direction: row;
}

.infomation-section {
  width: 35%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.popup {
  width: 90% !important;
  @media screen and (max-width: 767px) {
    width: 90% !important;
  }
}

</style>
