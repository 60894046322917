<template>
  <div>
    <div
      class="popup-block pointer noselect"
      @click="selectRest"
    >
      <div class="vertical-align-divs flex-row">
        <div class="user-relation">
          <div v-if="item.value">
            <img
              class="nav-link-icon filter-primary"
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
              alt=""
            >
          </div>
          <div class="text-block">
            <div class="text--black">
              {{ item.display_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="dropdown-input-custom mt-2"
      v-if="item.hasOwnProperty('dropdownValue') && item.value"
    >
      <input
        type="text"
        v-model="item.dropdownValue.value"
        style="opacity: 0; position: absolute"
        required
      />
      <dropdown-select
        @select="item.dropdownValue.value = $event"
        :id="item.dropdownValue.id"
        :init-value="item.dropdownValue.value ? item.dropdownValue.value : item.dropdownValue.default"
        :options="item.dropdownValue.choices"
        normal
        :name="item.dropdownValue.title"
      />
    </div>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "RestItemHolder",
  components: {DropdownSelect},
  props: {
    item: Object,
    index: Number,
  },
  methods: {
    selectRest () {
      this.item.value = !this.item.value;
      this.$emit("rest-toggle", {item: this.item, index: this.index})
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-input-custom {
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
}
</style>
