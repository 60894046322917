<template>
  <div>
    <div v-if="mortgageLayout">
      <div
        class="hypotekacta hypoteka pointer"
        v-if="brokerId"
      >
        <div v-if="imageLoaded">
          <span class="blc-color">
            {{ broker.first_name }} {{ broker.last_name }}
          </span>
        </div>
      </div>
      <div
        class="hypotekacta w-inline-block pointer"
        style="margin-left: 6px"
        @click="openBrokerAssignPicker()"
        v-else
      >
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1d_icons8-add.svg">
        <span class="bl-color noselect pop-600" style="padding-left: 25px">pridať brokera</span>
      </div>
    </div>
    <div v-if="klient">
      <div
        class="klienticta pointer w-inline-block"
        v-if="brokerId"
      >
        <div v-if="imageLoaded">
          <img
            :src="image"
            loading="lazy"
            height="20"
            width="20"
            alt=""
            class="broker-image"
          />
        </div>
      </div>
      <div
        class="klienticta w-inline-block pointer"
        @click="openBrokerAssignPicker()"
        v-else
      >
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1d_icons8-add.svg">
      </div>
    </div>
  </div>
</template>

<script>
import { getUserProfileByID } from "../../../../../services/brokerService";
import imagesConvert from "../../../../../mixins/imagesConvert";

export default {
  name: "BrokerPickerHandler",
  props: {
    brokerId: [Number, null],
    clientInfo: [Object, null],
    rules: [Array, null],
    clients: Boolean,
    mortgageId: [Number, null],
    klient: Boolean,
    detail: Boolean,
    hypoteka: Boolean,
    mortgageStage: [String, null],
    mortgageLayout: Boolean
  },
  data: () => ({
    broker: null,
    isBroker: false,
    imageLoaded: false,
    image: null,
  }),
  mixins: [
    imagesConvert
  ],
  watch: {
    brokerId () {
      this.handleBrokerImage();
    }
  },
  created () {
    this.handleBrokerImage();
  },
  methods: {
    async handleBrokerImage () {
      if (!this.brokerId) return;
      const broker = this.$store.getters["clientState/getEmployeeById"](this.brokerId);
      if (broker) this.setBrokerImage(broker);
      else await this.fetchBrokerImage()
      this.imageLoaded = true;
    },
    async fetchBrokerImage () {
      const broker = await getUserProfileByID(this.brokerId);
      this.setBrokerImage(broker)
    },
    setBrokerImage (broker) {
      if (broker.profile_image) {
        this.image = broker.profile_image;
      } else {
        this.image = require("@/images/Michal-Voronič.jpg");
      }
      this.broker = broker;
    },
    openBrokerAssignPicker () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {
          detail: this.detail,
          client: this.clients,
          mortgage: this.hypoteka,
          mortgageId: this.mortgageId,
          mortgageStage: this.mortgageStage
        },
        payload: this.clientInfo,
        component: "BrokerPickerPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
