<template>
  <div>
    <v-scroll-x-reverse-transition>
      <div class="popup popup-form white" v-if="displayed">

        <div
          class="content-form-popup"
        >
          <div class="header-text">
            <div class="back-button-wrapper pointer">
              <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt="" @click="closecard"/>
            </div>
          </div>
          <calendar-custom v-if="!calendarLoading" :calendar-events="events" :event-users="eventUsers" @event-deleted="deleteEvent($event)"/>
          <div class="bcg-loading" v-else>
            <v-progress-circular
              class="loading-spinner"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import CalendarCustom from "../../../components/shared/CalendarCustom";
import { mapGetters } from "vuex";
import {getCalendarEvents, getUserProfileByID} from "../../../services/brokerService";

export default {
  name: "KartaKalendar",
  components: {
    CalendarCustom
  },
  created() {
    this.eventsRefreshInterval = setInterval(async () => {
      await this.$store.dispatch("clientsEvents/updateUserEvents");
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.eventsRefreshInterval);
  },
  computed: {
    calendarLoading() {
      return this.$store.getters["clientsEvents/getCalendarLoading"];
    },
    ...mapGetters({
      visibilityGetter: "appState/getCalendarVisible"
    }),
  },
  watch: {
    visibilityGetter (newVisibility) {
      this.displayed = newVisibility;
      if (!newVisibility) return;
      this.refreshEvents();
    },
  },
  methods: {
    deleteEvent(id) {
      const indexOfRemoved = this.events.map(e => e.id).indexOf(id);
      this.events.splice(indexOfRemoved, 1);
    },
    closecard () {
      this.$store.commit("appState/setCalendarVisible", false);
    },
    async refreshEvents() {
      this.$store.commit("clientsEvents/setCalendarLoading", true);
      await this.$store.dispatch("clientsEvents/updateUserEvents");
      this.$store.commit("clientsEvents/setCalendarLoading", false);
    },
  },
  data: () => ({
    eventsRefreshInterval: null,
    loading: false,
    notClientIds: [],
    displayed: false,
    events: [],
    eventUsers: [],
  })
}
</script>

<style lang="scss" scoped>
.bcg-loading {
  z-index: 9999;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 90%;
  z-index: 100;
  overflow-y: auto;
}
</style>
