<template>
  <v-slide-y-reverse-transition>
    <div
      class="objects-popup-wrapper"
      v-if="show"
    >
      <div
        class="objects-wrapper"
      >
      <div
        class="closeBtn pointer noselect"
        @click="hideApplication"
      >
        zatvoriť
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary MGleft10px"
          alt=""
        />
      </div>
        <component
          v-for="(object, index) in objects"
          :key="index"
          class="w-inline-block no-decoration position-relative"
          :is="objectsComponent"
          :object="object"
          @restore="$emit('restore', $event)"
        />
      </div>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
import imagesConvert from "@/mixins/imagesConvert";
import MortgageApplicationsObjects
  from "@/components/shared/PopupHandler/Popups/ObjectsRemove/MortgageApplicationsObjects.vue";
import MortgageObjects from "@/components/shared/PopupHandler/Popups/ObjectsRemove/MortgageObjects.vue";

export default {
  name: "ObjectsRemovePopUp",
  components: {MortgageObjects, MortgageApplicationsObjects},
  props: {
    show: Boolean,
    objects: Array,
    objectsComponent: String,
  },
  mixins: [
    imagesConvert,
  ],
  methods: {
    hideApplication () {
      this.$store.commit("popupManagerState/toggleMortgageApplicationsRemovePopup", false);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
