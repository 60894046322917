<template>
  <div class="content-panel">
    <!-- <div class="statistics">
      <div class="stat-bar-filter">
        <testimonials-filter-bar/>
      </div>
    </div>
    <testimonials-items-wrapper/> -->
    <iframe
        class="embedded-iframe"
        src="https://bankari-5047.slite.page/p/rLwu9me26ZvZFR/Public-hypoteky"
        frameborder="0"
        allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import UserProfile from "../../../components/shared/UserProfile";
import TestimonialsFilterBar from "../../../components/expert/KartaPosudky/TestimonialsFilterBar";
import TestimonialsItemsWrapper from "../../../components/expert/KartaPosudky/TestimonialsItemsWrapper";

export default {
  name: "KartaPosudky",
  components: {
    TestimonialsItemsWrapper,
    TestimonialsFilterBar,
    UserProfile,
  }
}
</script>

<style scoped>

  .content-panel{
    padding: 40px;
  }

  .embedded-iframe {
    width: auto;
    height: 100vh;
    border-radius: 10px;
    overflow: hidden;
    margin: -40px;
  }

  @media screen and (max-width: 767px) {
    .content-panel{
      padding: 20px;
    }
    .embedded-iframe {
      margin: -20px;
    }
  }

</style>
