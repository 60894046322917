<template>
  <div class="clipboard-button">
    <div class="success-copy" :class="{'active': success}">
      skopírované
    </div>
    <div class="image-wrapper pointer" @click="copyTextToClipboard()">
      <img src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f58_icons8-copy.svg" class="nav-link-icon extrasmall filter-primary" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "ClipboardButton",
  props: {
    value: [String, Number],
  },
  methods: {
    async copyTextToClipboard () {
      await navigator.clipboard.writeText(this.value);
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 1000);
    }
  },
  data: () => ({
    success: false,
  }),
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.clipboard-button {
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

.active {
  opacity: 1;
}
</style>
