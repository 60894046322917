<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closeDialog">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="_900 big">
          Pridanie {{ type === "refinancovane" ? "refinancovnaého" : "" }} výdavku
        </div>
      </div>
      <div class="header-block inline" v-if="clientInfo">
        <div class="bold">{{ clientInfo.type }}</div>
        <img :src="getIcon(clientInfo.type)" loading="lazy" alt="" class="image-10"/>
      </div>
      <div class="w-form">
        <div id="email-form" class="form-2">
          <div class="splitter">
            1. Vyberte typ <strong>výdavku</strong>, ktorú chcete pridať
          </div>
          <div
            data-w-id="8466810e-7557-a963-e1a4-1ff5d954c81e"
            class="option-block"
            @click="debtTypePicker('hypoteka')"
          >
            <div class="bold">hypotéka</div>
          </div>
          <div
            data-w-id="815ce0e9-0210-f8e5-3b0d-1a7cbddd7f06"
            class="option-block"
            @click="debtTypePicker('povolene_precerpanie')"
          >
            <div class="bold">povolené prečerpanie</div>
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="debtTypePicker('leasing')"
          >
            <div class="bold">leasing</div>
          </div>
          <div
            data-w-id="0802c97d-bcad-27ac-d75b-f75f48e0a1e5"
            class="option-block"
            @click="debtTypePicker('kreditna_karta')"
          >
            <div class="bold">kreditná karta</div>
          </div>
          <div
            data-w-id="0802c97d-bcad-27ac-d75b-f75f48e0a1e5"
            class="option-block"
            @click="debtTypePicker('spotrebny_uver')"
          >
            <div class="bold">spotrebný úver</div>
          </div>
          <div class="splitter">2. alebo vyberte z už pridaných</div>
          <div
            class="input-block p-relative"
            v-for="(debt, key) in debtsGetter"
            :key="key"
          >
            <div class="div-block-101">
              <div>
                {{ translateDebtType(debt.typ) }} <strong>{{ translateBank(debt.banka) }}</strong> {{ debt.cislo_uveru ? "č." + debt.cislo_uveru : "" }}
              </div>
              <v-progress-circular
                v-if="debt.loading"
                class="loading-spinner extrasmall"
                indeterminate
              ></v-progress-circular>
              <div class="bold" v-else>{{ debt.dti.value | dotTheAmount }} EUR</div>
            </div>
            <div class="div-block-112">
              <div class="text-cta" @click="edit(debt)">
                {{ debt.error ? 'DOKONČIŤ' : 'EDIT' }}
              </div>
              <div class="text-cta" @click="pick(debt)"
                   v-if="type === 'classic' && !debt.is_visible">
                PICK
              </div>
              <div class="text-cta" @click="pick(debt)"
                   v-if="type === 'refinancovane' && !debt.refinancovany_hypotekou">
                PICK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import clientFilters from "../../../../../mixins/clientFilters";

export default {
  name: "ClientDebtPicker",
  props: {
    isClicked: Boolean,
    rules: [Array, null],
    type: [String, null],
  },
  mixins: [
    translateFromDBValue,
    clientFilters
  ],
  data: () => ({
    debts: null,
    clientInfo: null,
  }),
  computed: {
    debtsGetter () {
      if (this.type !== "refinancovane") {
        return this.$store.getters["mortgageDetailState/getClientDebts"](this.clientInfo.type_code);
      } else {
        return this.$store.getters["mortgageDetailState/getAllDebts"];
      }
    }
  },
  created () {
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
  },
  methods: {
    getIcon (type) {
      if (type === "Žiadateľ") {
        return require("@/images/avatar_applicant.png");
      } else if (type === "Spolužiadateľ") {
        return require("@/images/avatar_coapplicant.png");
      }
    },
    edit (pDebt) {
      if (this.type === "refinancovane") {
        this.$store.commit("popupManagerState/setClientInfo", pDebt.clientInfo);
      }
      this.debtTypePicker(pDebt.typ, pDebt);
    },
    getTotalAmount (amounts) {
      let totalAmount = 0;
      for (const amount of Object.values(amounts)) {
        totalAmount += amount;
      }
      return totalAmount;
    },
    checkIfHasBudget (leftover, amounts) {
      const limit = this.$store.getters["mortgageDetailState/getLoanLimit"];
      const amount = this.getTotalAmount(amounts);
      const amountLeft = limit - amount;
      return {
        amountOverflow: Math.round(leftover-amountLeft),
        isBudgetEnough: leftover <= amountLeft
      };
    },
    showDialogToUser (label) {
      this.$store.commit("appState/setSuccessMessage", {
        text: label,
        type: "confirmation"
      });
    },
    waitForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    lowerAmountsByOverflow(amountOverflow) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      if (amounts.suma_nadobudnutie >= amountOverflow) {
        const value = Math.round(Math.abs(amounts.suma_nadobudnutie - amountOverflow));
        return { amounts: {...amounts, suma_nadobudnutie: value},  label: `nadobudnutie na ${value}€` };
      }
      if ((amounts.suma_nadobudnutie + amounts.suma_bezucel) >= amountOverflow) {
        const value = Math.round(Math.abs(amounts.suma_bezucel + amounts.suma_nadobudnutie - amountOverflow));
        return {
          amounts: {
            ...amounts,
            suma_nadobudnutie: 0,
            suma_bezucel: value
          },
          label: `nadobudnutie na 0€ a bezúčel na ${value}€`
        }
      }
      if (this.getTotalAmount(amounts) >= amountOverflow) {
        const value = Math.round(Math.abs((amounts.suma_nadobudnutie + amounts.suma_bezucel + amounts.suma_refinancovanie) - amountOverflow));
        return {
          amounts: {
            suma_nadobudnutie: 0,
            suma_bezucel: 0,
            suma_refinancovanie: value
          },
          label: `nadobudnutie na 0€ bezúčel na 0€ refinancovanie na ${value}€`
        };
      }
      return false;
    },
    constructMessageLabel(restOfLabel) {
      return "Pre refinancovanie dlhu bude potrebné nastaviť " + restOfLabel;
    },
    async pickRefinanced(debt) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      const budgetData = this.checkIfHasBudget(debt.zostatok, amounts);
      if (!budgetData.isBudgetEnough) {
        const loweredAmountsObject = this.lowerAmountsByOverflow(budgetData.amountOverflow);
        this.showDialogToUser(this.constructMessageLabel(loweredAmountsObject.label));
        const userPermission = await this.waitForUserPermission();
        if (!userPermission) return;
        this.$store.commit("mortgageDetailState/setAmounts", loweredAmountsObject.amounts);
      }
      const clientObject = this.getClientType(debt.klient);
      const debtObject = {
        id: debt.id,
        object: {
          is_visible: false,
          refinancovany_hypotekou: parseInt(this.$route.params.hypoteka),
        },
        clientRole: clientObject.type_code,
        clientLabel: clientObject.type,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientRefinanced", debtObject)
    },
    pick (debt) {
      if (this.type === "refinancovane") {
        this.pickRefinanced(debt);
      } else if (this.type === "classic") {
        const data = {
          id: debt.id,
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          isActive: true,
        };
        this.$store.dispatch("mortgageDetailState/toggleClientDebt", data);
      }
    },
    closeDialog () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    debtTypePicker (debtType, debtData = null) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {debtType, debtPurpose: this.type},
        component: "ClientDebtPopUp",
        payload: debtData,
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped lang="scss">
//@media screen and (max-width: 991px) {
//  .popup {
//    width: 35%;
//  }
//}
.popup {
  width: 35%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.header-form-popup {
  border: none;
}
</style>
