<template>
    <img @click="openGeneratorPopup()" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2d_icons8-print%20(1).svg" alt="">
</template>

<script>
import ApplicationGeneratorPopUp from "./ApplicationGeneratorPopUp";

export default {
  name: "ApplicationGeneratorHandler",
  components: {ApplicationGeneratorPopUp},
  props: {
    rules: Array,
    image: String,
    application: Object,
  },
  methods: {
    openGeneratorPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: {image: this.image, application: this.application},
        component: "ApplicationGeneratorPopUp",
        rules: this.rules,
      });
    },
  }
}
</script>

<style scoped>

</style>
