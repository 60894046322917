<template>
   <div class="nav-menu">
      
      <div class="left-top-bar">
        <div class="nav-link-block">
          <user-profile/>
        </div>

        <div class="nav-link-block">
          <div
            class="nav-link noselect"
            @click="showCalendar()"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0e_icons8-calendar.svg"></img>
          </div>
        </div>
  
        <div class="nav-link-block">
          <div
            class="nav-link noselect"
            :class="[this.current === 'posudky' ? 'bl-color' : '']"
            @click="navigateTo('posudky')"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f59_icons8-home-address.svg"></img>
          </div>
        </div>
      </div>
      
      <div class="right-top-bar">
        <!-- <users-tasks-handler/> -->
        <notification-handler :rules="['single']"/>
      </div>

      <audio id="new-notification" style="display: none" muted="muted">
        <source :src="require('@/sounds/new_message.mp3')" type="audio/mpeg"/>
      </audio>
    
    </div>
</template>

<script>
import router from "@/router";
import UserProfile from "../shared/UserProfile";

export default {
  name: "NavBarExpert",
  components: {
    UserProfile
  },
  watch: {
    $route (to) {
      this.current = to.path.split("/")[1].split("-")[1];
    },
  },
  mounted () {
    this.current = this.$route.fullPath.split("/")[1].split("-")[1];
  },
  created () {
    this.me = this.$store.getters["userState/getMe"];
    this.role = this.$store.getters["userState/getRole"];
  },
  beforeDestroy () {
    clearInterval(this.interval);
    clearInterval(this.mortgagesInterval);
  },
  methods: {
    navigateTo (clicked) {
      this.mobileDropdownMenu = false;
      let finalLocation = "/karta-" + clicked;
      router.push(finalLocation);
    },
  },
  data: () => ({
    me: null,
    mobileDropdownMenu: false,
    messageCounter: 0,
    interval: 0,
    mortgagesInterval: 0,
    isOpen: false,
    isClicked: false,
    notificationOpen: false,
    allUnseenMessages: 0,
    current: "",
    brokerId: null,
    clientId: null,
  }),
};
</script>

<style scoped lang="scss">

</style>
