<template>
  <div class="summary-item-background noselect" @mouseenter="copyVisible = true" @mouseleave="copyVisible = false">
    <span>{{ label }}</span>
    <span class="bl-color">{{ value }}</span>
    <clipboard-button v-if="value && copyVisible" :value="value"/>
  </div>
</template>

<script>
import ClipboardButton from "./ClipboardButton";

export default {
  name: "TextValueHolder",
  components: {ClipboardButton},
  props: {
    label: String,
    value: [String, Number, null],
  },
  data: () => ({
    copyVisible: false,
  }),
}
</script>

<style lang="scss" scoped>
.summary-item-background {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  background: #F8F9FA;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

</style>
