export default {
  namespaced: true,
  state: {
    mortgageApplicationsRemovePopup: false,
    pdfGenerationPopup: false,
    clientNeedsPopup: false,
    define: false,
    popupOrder: [],
    popupOrderLength: 0,
    clientInfo: null,
    pickerRefresh: false,
    userManagerRefresh: false,
    popupSuccessResult: null,
  },
  getters: {
    getClientNeeds: (state) => {
      return state.clientNeedsPopup;
    },
    getMortgageApplicationsRemovePopup: (state) => {
      return state.mortgageApplicationsRemovePopup;
    },
    getClientFinancingOffersPopup: (state) => {
      return state.pdfGenerationPopup;
    },
    getPopupSuccessResult: (state) => {
      return state.popupSuccessResult;
    },
    getPickerRefresh: (state) => {
      return state.pickerRefresh;
    },
    getLengthOfPopupOrder: (state) => {
      return state.popupOrderLength;
    },
    getPopupOrder: (state) => {
      return state.popupOrder;
    },
    getClientInfo: (state) => {
      return state.clientInfo;
    },
    getUserManagerRefresh: (state) => {
      return state.userManagerRefresh;
    },
  },
  mutations: {
    toggleMortgageApplicationsRemovePopup: (state, value) => {
      state.mortgageApplicationsRemovePopup = value;
    },
    toggleClientNeeds: (state, value) => {
      state.clientNeedsPopup = value;
    },
    toggleClientFinancingOffer: (state, value) => {
      state.pdfGenerationPopup = value;
    },
    setPopupSuccessResult: (state, value) => {
      state.popupSuccessResult = value;
    },
    setUserManagerRefresh: (state, value) => {
      state.userManagerRefresh = value;
    },
    setPickerLoad: (state, value) => {
      if (state.popupOrder.length !== 0) {
        state.pickerRefresh = value;
      }
    },
    resetPopupOrder: (state) => {
      state.popupOrder = [];
      state.popupOrderLength = state.popupOrder.length;
    },
    pushToPopupOrder: (state, newPopup) => {
      const rules = newPopup.rules;
      if (rules && rules.length > 0) {
        if (rules.includes("priority")) {
          state.popupOrder = [];
          state.popupOrderLength = state.popupOrder.length;
          setTimeout(() => {
            state.popupOrder.push(newPopup);
            state.popupOrderLength = state.popupOrder.length;
          }, 100);
        } else if (rules.includes("single")) {
          if (!isPopupOpened(state.popupOrder, newPopup.component)) {
            state.popupOrder.push(newPopup);
            state.popupOrderLength = state.popupOrder.length;
          }
        } else if (rules.includes("force-one")) {
          if (!isPopupOpened(state.popupOrder, newPopup.component)) {
            state.popupOrder = [];
            state.popupOrderLength = state.popupOrder.length;
            setTimeout(() => {
              state.popupOrder.push(newPopup);
              state.popupOrderLength = state.popupOrder.length;
            }, 1);
          }
        }
      } else {
        state.popupOrder.push(newPopup);
        state.popupOrderLength = state.popupOrder.length;
      }
    },
    popItemFromPopupOrder: (state) => {
      state.popupOrder.pop();
      state.popupOrderLength = state.popupOrder.length;
      if (state.popupOrderLength === 0) state.clientInfo = null;
    },
    setClientInfo: (state, clientInfo) => {
      state.clientInfo = clientInfo;
    },
  },
  actions: {},
};

function isPopupOpened (popupsOpened, popupType) {
  for (const popupOpened of popupsOpened) {
    if (popupOpened.component !== popupType) continue;
    return true;
  }
  return false;
}
