<template>
  <div class="popup-form">
  
    <div class="content-form-popup">

      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closeDialog">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"/>
        </div>
        <div class="_900 big">Pridanie príjmu</div>
      </div>

      <div class="header-block inline">
        <div class="bold">{{ clientInfo.type }}</div>
        <img
          :src="getIcon(clientInfo.type)"
          loading="lazy"
          alt=""
          class="image-10"
        />
      </div>
      
      <div class="w-form">
        <div id="email-form" class="form-2">
          <div class="splitter">
            1. Vyberte typ <strong>hlavného príjmu</strong>, ktorý chcete pridať
          </div>
          <div
            data-w-id="8466810e-7557-a963-e1a4-1ff5d954c81e"
            class="option-block"
            @click="incomeTypePicker('trvaly_pracovny_pomer')"
          >
            <div class="bold">zo zamestnania</div>
          </div>
          <div
            data-w-id="815ce0e9-0210-f8e5-3b0d-1a7cbddd7f06"
            class="option-block"
            @click="incomeTypePicker('prijem_zivnostnika')"
          >
            <div class="bold">zo živnosti</div>
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="incomeTypePicker('prijem_z_sro')"
          >
            <div class="bold">z podnikania SRO</div>
          </div>
          <div class="splitter">
            2. Vyberte typ <strong>vedľajšieho príjmu</strong>, ktorý chcete
            pridať
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="incomeTypePicker('prijem_z_prenajmu_nehnutelnosti')"
          >
            <div class="bold">príjem z prenájmu</div>
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="incomeTypePicker('vysluhovy_dochodca')"
          >
            <div class="bold">z dôchodku</div>
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="incomeTypePicker('materska_rodicovsky_prispevok')"
          >
            <div class="bold">rodičovský príspevok</div>
          </div>
          <div class="splitter">Už registrované</div>
          <div
            class="input-block p-relative"
            v-for="(income, key) in incomesGetter"
            :key="key"
          >
            <div class="div-block-101">
              <div>{{ translateIncomeType(income.druh) }}</div>
              <v-progress-circular
                v-if="income.loading"
                class="loading-spinner extrasmall"
                indeterminate
              ></v-progress-circular>
              <div class="bold" v-else>+{{ income.dti.value | dotTheAmount }} EUR</div>
            </div>
            <div
              class="div-block-112"
              :class="{'mr-3': income.error}"
            >
              <div
                class="text-cta"
                :class="{'mr-2': income.error}"
                @click="edit(income)"
              >
                {{ income.error ? 'DOKONČIŤ' : 'EDIT' }}
              </div>
              <div
                class="text-cta"
                @click="pick(income.id)"
                v-if="!income.is_active"
              >
                PICK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";

export default {
  name: "ClientIncomePicker",
  computed: {
    incomesGetter () {
      return this.$store.getters["mortgageDetailState/getClientIncomes"](this.clientInfo.type_code);
    },
  },
  mixins: [
    translateFromDBValue
  ],
  data: () => ({
    clientInfo: null,
    client: null,
  }),
  created () {
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
  },
  methods: {
    getIcon (type) {
      if (type === "Žiadateľ") {
        return require("@/images/avatar_applicant.png");
      } else if (type === "Spolužiadateľ") {
        return require("@/images/avatar_coapplicant.png");
      }
    },
    pick (incomeId) {
      const data = {
        id: incomeId,
        clientLabel: this.clientInfo.type,
        clientRole: this.clientInfo.type_code,
        isActive: true,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientIncome", data);
    },
    edit (pIncome) {
      this.incomeTypePicker(pIncome.druh, pIncome);
    },
    incomeTypePicker (incomeType, incomeData = null) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: incomeType,
        payload: incomeData,
        component: "ClientIncomePopUp",
        rules: null,
      });
    },
    closeDialog () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  width: 35%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.pop-up-active {
  transform: translateX(0);
}

.header-form-popup {
  border: none;
}
</style>
