<template>
  <div class="main-view-stage-2">
    <client-needs-pop-up :show="showClientNeeds"/>
    <document-cloud-handler
      :cloud-meta="getCloudMeta()"
      :rules="['single']"
      :class-cst="'cloud-button add-obhliadka white--text no-decoration'"
      icon-button
    />
    <client-sign-event-holder
      v-for="(event, index) in mortgageEvents"
      :key="index"
      :event="event"
    />
    <!-- <div class="div-block-30 vertical">
      <sign-user-detail-item :client="applicant" :client-info="getApplicantInfo()"/>
      <sign-user-detail-item v-if="coApplicant" :client="coApplicant" :client-info="getCoApplicantInfo()"/>
    </div> -->
    <mortgage-applications-sign-wrapper :applications="mortgage.applications"/>
  </div>
</template>

<script>
// import SignUserDetailItem
//   from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicatioinSign/SignUserDetailItem";
import MortgageApplicationsSignWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicatioinSign/MortgageApplicationsSignWrapper";
import DocumentCloudHandler from "../../../../components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler";
import ClientSignEventHolder
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicatioinSign/ClientSignEventHolder.vue";
import {mapGetters} from "vuex";
import ClientNeedsPopUp from "@/components/shared/PopupHandler/Popups/ClientNeeds/ClientNeedsPopUp.vue";

export default {
  name: "ApplicationSign",
  components: {
    ClientNeedsPopUp,
    ClientSignEventHolder,
    DocumentCloudHandler,
    MortgageApplicationsSignWrapper,
    // SignUserDetailItem
  },
  props: {
    mortgage: [Object, null],
    applicant: [Object, null],
    coApplicant: [Object, null],
  },
  computed: {
    ...mapGetters({
      eventsGetter: "clientsEvents/getEvents",
      clientNeedsVisibilityGetter: "popupManagerState/getClientNeeds",
      cloudsGetter: "mortgageDetailCloud/getClouds",
    }),
  },
  watch: {
    clientNeedsVisibilityGetter(value) {
      this.showClientNeeds = value;
    },
    eventsGetter(events) {
      events = events.filter(e => e.type === "podpis_ziadosti");
      const applicationEvents = events.filter(e => e.users.includes(this.applicant.id));
      let coApplicationEvents = [];
      if (this.coApplicant) {
        coApplicationEvents = events.filter(e => e.users.includes(this.coApplicant.id));
      }
      this.mortgageEvents = [...applicationEvents, ...coApplicationEvents];
      if (this.mortgageEvents.length > 0) return;
      this.mortgageEvents.push({
        type: "notCreated",
        applicantId: this.applicant.id,
      });
    },
  },
  created() {
    const applicationEvents = this.$store.getters["clientsEvents/getEventsByUserIdAndOrType"](this.applicant.id, "podpis_ziadosti");
    let coApplicationEvents = [];
    if (this.coApplicant) {
      coApplicationEvents = this.$store.getters["clientsEvents/getEventsByUserIdAndOrType"](this.coApplicant.id, "podpis_ziadosti");
    }
    this.mortgageEvents = [...applicationEvents, ...coApplicationEvents];
    if (this.mortgageEvents.length > 0) return;
    this.mortgageEvents.push({
      type: "notCreated",
      applicantId: this.applicant.id,
    });
  },
  beforeDestroy() {
    this.$store.commit("popupManagerState/toggleClientNeeds", false);
  },
  methods: {
    getCloudMeta () {
      return this.$store.getters["mortgageDetailCloud/getFullClouds"];
    },
    getApplicantInfo () {
      return {
        type: "Žiadateľ",
        id: this.applicant.id,
        email: this.applicant.email,
        firstName: this.applicant.first_name,
        lastName: this.applicant.last_name
      };
    },
    getCoApplicantInfo () {
      if (!this.coApplicant) return {type: "Spolužiadateľ", id: null, firstName: null, lastName: null};
      return {
        type: "Spolužiadateľ",
        id: this.coApplicant.id,
        email: this.coApplicant.email,
        firstName: this.coApplicant.first_name,
        lastName: this.coApplicant.last_name
      };
    },
  },
  data: () => ({
    showClientNeeds: false,
    mortgageEvents: [],
  }),
}
</script>

<style scoped>

</style>
