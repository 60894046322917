import "@babel/polyfill";

import Vuelidate from "vuelidate";

import "mutationobserver-shim";
import Vue from "vue";

import App from "./App.vue";

import vClickOutside from "v-click-outside";
import router from "./router";

import store from "./store/index";

import "./plugins/bootstrap-vue";
import "@/style/global.scss";

import "./utils/filters";
import "./utils/transformators";


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"

import vueKanban from "vue-kanban";
import vSelect from "vue-select";

Vue.component("vue-select", vSelect);
Vue.config.productionTip = false;
Vue.use(Vuelidate)
Vue.use(vueKanban);
Vue.use(Skeleton);

const vue = new Vue({
  vClickOutside,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.addEventListener('beforeunload', () => {
  vue.$destroy();
});
