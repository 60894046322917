<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Vyberte hypotéku</div>
      </div>
      <div class="w-form">
        <div class="input-block p-relative" v-for="(mortgage, key) in mortgages" :key="key">
          <div class="div-block-101">
            <div class="wrap">
              <div class="primary">
                {{ getChoicesFromFormByKey("hlavny_ucel", mortgage.hlavny_ucel) }}
              </div>
              <div class="light-text">
                {{ getChoicesFromFormByKey("stage", mortgage.stage) }} od {{ getDate(mortgage.modified) }}
              </div>
            </div>
            <div class="black">{{ getMortgageAmount(mortgage) }} EUR</div>
          </div>
          <div class="div-block-112">
            <div class="text-cta" @click="openMortgage(mortgage.id)">{{ payload.title }}</div>
          </div>  
        </div>
        <div class="sub-popup-wrapper" v-if="payload.title === 'EDIT'">
          <div class="text-small bl-color pointer" @click="createMortgage()">+ založiť ďalšiu HYPOTÉKU</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClientById } from "@/services/brokerService";

export default {
  name: "MortgagePickerPopUp",
  props: {
    payload: [Object, null],
  },
  async created () {
    this.form = this.$store.getters["formStore/getFormByType"]("mortgageForm");
    this.mortgages = this.payload.mortgages;
    // if (this.payload)
    // console.log(this.mortgages);
  },
  methods: {
    getMortgageAmount(mortgage) {
      const historyAmount = this.getMortgageAmountFromHistory(mortgage.offers_filter_history);
      if (historyAmount !== 0) return historyAmount;
      return mortgage.suma_nadobudnutie + mortgage.suma_refinancovanie + mortgage.suma_bezucel;
    },
    getMortgageAmountFromHistory(historyObject) {
      if (!historyObject || Array.isArray(historyObject.broker) || historyObject.broker.history.length === 0) return 0;
      const lastHistoryObject = historyObject.broker.history[0];
      return lastHistoryObject.suma_bezucel + lastHistoryObject.suma_nadobudnutie + lastHistoryObject.suma_refinancovanie;
    },
    async createMortgage () {
      const role = this.$store.getters["userState/getRole"];
      let broker = null;
      if (role === "financnik") {
        broker = this.$store.getters["userState/getId"];
      } else if (role === "alex_admin") {
        broker = this.payload.financnik;
      }
      const mortgageInfo = {
        applicant: this.payload.id,
        stage: "vyber_riesenia",
        financnik: broker,
      };
      await this.$store.dispatch("mortgagesState/postMortgage", mortgageInfo);
      const client = await getClientById(this.payload.id);
      this.mortgages = client.mortgages;
    },
    openMortgage (id) {
      this.$router.push({
        name: "KartaHypotekyDetail",
        params: {hypoteka: id},
      });
      this.$store.commit("popupManagerState/resetPopupOrder");
    },
    getDate (pDate) {
      const date = new Date(pDate);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    getChoicesFromFormByKey (key, value) {
      for (const choice of this.form[key].choices) {
        if (choice.value === value) {
          return choice.display_name;
        }
      }
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    form: null,
    mortgages: [],
  }),
};
</script>

<style lang="scss" scoped>
.sub-popup-wrapper {
  margin-top: 15px;
}

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
