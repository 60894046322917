<template>
  <div
    class="klienticta w-inline-block flex-column d-flex align-items-center pointer"
    @click="openTestimonialInvoicePopup"
  >
    <img class="nav-link-icon filter-primary" src="@/images/invoice-icon.svg" width="22px">
    <span class="bl-color">faktúra</span>
  </div>
</template>

<script>
export default {
  name: "TestimonialInvoiceHandler",
  methods: {
    openTestimonialInvoicePopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "TestimonialInvoicePopUp",
        rules: ['single'],
      });
    }
  },
}
</script>

<style scoped>

</style>
