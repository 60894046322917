<template>
  <div class="master-filter hideLandscape">
    <div class="dropdown-title">{{ label }}</div>
    <div data-hover="false" data-delay="0" class="dropdown w-dropdown">
      <b-dropdown
        ref="dropdown-filters"
        size="sm"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="w-dropdown-toggle">
            <div class="icon w-icon-dropdown-toggle"></div>
            <div class="dropdown-placeholder">
              {{ forEmployee.active.name }}
            </div>
          </div>
        </template>
        <b-dropdown-item
          class="drop"
          :class="[
              forEmployee.active === item ? 'active-filter' : '',
            ]"
          href="#"
          v-for="(item, key) in forEmployee.inDropdown"
          :key="key"
          @click="filterBroker(item)"
        >
          <div class="text-block-4 fnt-13" id="forEmployee">
            {{ item.name }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterByEmployee",
  props: {
    employees: Array,
    label: String,
  },
  created () {
    this.initializeEmployeeFilter();
  },
  methods: {
    filterBroker (item) {
      this.forEmployee.active = item;
      this.$emit("employee-filter-change", item.id);
    },
    initializeEmployeeFilter () {
      for (let i = 0; i < this.employees.length; i++) {
        const name = this.employees[i].first_name + " " + this.employees[i].last_name;
        const id = this.employees[i].id;
        this.forEmployee.inDropdown.push({name: name, id: id});
      }
    },
  },
  data: () => ({
    forEmployee: {
      active: {name: "Všetko", id: null},
      inDropdown: [{name: "Všetko", id: null}],
    },
  }),
}
</script>

<style lang="scss" scoped>
.active-filter {
  background-color: rgba(232, 232, 232, 0.8);
}
</style>

