<template>
  <div class="summary-item-background noselect">
    <span>{{ label }}</span>
    <a
      class="bl-color pointer"
      style="text-decoration: none"
      target="_blank"
      :href="cloudFile.file"
      :download="cloudFile.name"
    >
      STIAHNUŤ
    </a>
  </div>
</template>

<script>
export default {
  name: "FileDownloadHolder",
  props: {
    cloudFile: Object,
    label: String,
  }
}
</script>

<style lang="scss" scoped>
.summary-item-background {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #F8F9FA;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}
</style>