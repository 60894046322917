<template>
  <div class="popup-form">
    <div class="content-form-popup">
      
       <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div id="Client-name" class="bold blc-color">
            Posudok {{ type.label }}
        </div>
      </div>

      <div class="splitter">Objednávateľ</div>

      <div class="header-block inline">
        <div class="bold">Dominik Petrovič</div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
      </div>

      <div class="email-cta">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f21_icons8-mail.svg" loading="lazy" alt=""/>
        <div class="cta-email">
          <div class="light-text lightgrey">Email</div>
            <a
              class="blc-color pointer"
              :href="`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=$`"
              target="_blank"
            >
              petrovic@osobnimakleri.sk
            </a>
        </div>
      </div>

      <div class="email-cta">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f20_icons8-shake-phone.svg" loading="lazy" alt=""/>
        <div class="cta-email">
          <div class="light-text lightgrey">Telefón</div>
          <div class="blc-color">
            0950 880 132
          </div>
        </div>
      </div>

      <div class="popup-block">
        <div class="icons-wrapper">
          <testimonial-deadline-handler/>
          <document-cloud-handler
            :cloud-meta="null"
            :rules="['single']"
            text-icon-button
          />
          <!-- <testimonial-invoice-handler/> -->
          <div
            class="center"
          >
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1b_icons8-trash.svg">
          </div>
          <testimonial-overview-handler/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentCloudHandler from "../DocumentCloud/DocumentCloudHandler";
import TestimonialDeadlineHandler from "../TestimonialDeadline/TestimonialDeadlineHandler";
import TestimonialOverviewHandler from "../TestimonialOverview/TestimonialOverviewHandler";
import TestimonialInvoiceHandler from "../TestimonialInvoice/TestimonialInvoiceHandler";

export default {
  name: "TestimonialManagerPopUp",
  components: {TestimonialInvoiceHandler, TestimonialOverviewHandler, TestimonialDeadlineHandler, DocumentCloudHandler},
  props: {
    type: Object,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    getCloudMeta () {
    }
  },
}
</script>

<style lang="scss" scoped>
.popup-block {
  display: inline-block;
  width: 100%;
  font-weight: 500;
}

</style>
