<template>
  <div class="div-block-25">
    <div
      data-w-id="239b29db-b0c5-1e0a-548a-17a023868560"
      class="add-prijem-btn"
    >
      <div class="text-small bl-color pointer" @click="openIncomeTypePicker()">
        upraviť PRÍJEM
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientIncomeHandler",
  props: {
    clientInfo: Object,
    rules: [Array, null],
  },
  methods: {
    openIncomeTypePicker () {
      this.$store.commit("popupManagerState/setClientInfo", this.clientInfo);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ClientIncomePicker",
        rules: this.rules,
      });
    },

  },
};
</script>

<style scoped></style>
