<template>
  <div>
    <div
      class="popup-block pointer"
      @click="selectFixation"
    >
      <div class="vertical-align-divs flex-row">
        <div class="user-relation">
          <div v-if="selected">
            <img
              class="nav-link-icon filter-primary"
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
              alt=""
            >
          </div>
          <div class="text-block">
            <div class="text--black">
              {{ year }} {{ title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixationItemsHolder",
  props: {
    year: Number,
    title: String,
    preselected: Number,
  },
  watch: {
    preselected (newPreselected) {
      this.selected = newPreselected === this.year;
    },
  },
  created () {
    this.selected = this.preselected === this.year;
  },
  methods: {
    selectFixation () {
      this.$emit("fixation-toggle", this.year)
      this.selected = !this.selected;
    }
  },
  data: () => ({
    selected: false,
  })
}
</script>

<style scoped>

</style>
