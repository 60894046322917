<template>
  <div class="popup-form">

    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">
          História výpočtov
        </div> 
        <button class="w-button" @click="loadCalculation()">Načítať</button>
      </div>

      <div
        class="div-block-141 pointer noselect"
        v-for="(calculation, key) in history"
        :key="key"
        style="margin-bottom: 5px; position: relative"
        @click.self="pickCalculation(calculation, key)"
      >
        <div>
          <strong>
            [ {{
            (calculation.suma_bezucel + calculation.suma_nadobudnutie + calculation.suma_refinancovanie) |
            dotTheAmount
            }} EUR
          </strong>, FIX {{ calculation.fix }}
          <strong>]</strong> zo dňa
                   {{ getDate(calculation.date) }}.{{ getMonth(calculation.date) }}.{{ getYear(calculation.date) }} o
                   {{ getHours(calculation.date) }}:{{ getMinutes(calculation.date) }}
          <div v-if="calculation.client" class="text-color-disabled pop-500" style="font-size: 12px;">
            KLIENTOV VÝPOČET
          </div>
          <div
            v-else
            class="bl-color pop-500"
            style="font-size: 12px;"
            @click.self="pickCalculation(calculation, key)"
          >
            <span @click="sendOffer(calculation, key)" v-if="!calculation.sent">
              ODOSLAŤ NÁVRH
            </span>
            <span style="cursor: default" v-else>
              UŽ ODOSLANÉ
            </span>
          </div>
        </div>
        <img
          v-if="pickedKey === key" style="position: absolute; right: 20px;"
          class="nav-link-icon filter-primary"
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CalculationsHistoryPopUp",
  props: {
    payload: [Object, null],
  },
  computed: {
    ...mapGetters({
      mortgageGetter: "mortgageDetailState/getMortgage",
    }),
  },
  watch: {
    mortgageGetter (newMortgage) {
      this.sortHistory(newMortgage.offers_filter_history);
    },
  },
  created () {
    this.sortHistory(this.mortgageGetter.offers_filter_history);
  },
  methods: {
    async sendOffer (offer) {
      const deepEqual = require("deep-equal");
      const mortgage = this.mortgageGetter;
      for (const historyItem of mortgage.offers_filter_history.broker.history) {
        if (deepEqual(historyItem, offer, true)) {
          historyItem.sent = true;
        }
      }
      const mortgageObject = {
        id: mortgage.id,
        object: {
          offers_filter_history: {
            broker: mortgage.offers_filter_history.broker,
            client: mortgage.offers_filter_history.client,
            sent: []
          }
        }
      }
      if (!this.mortgageGetter.offers_filter_history.hasOwnProperty("sent")) {
        mortgageObject.object.offers_filter_history.sent = [offer];
      } else {
        mortgageObject.object.offers_filter_history.sent = [offer, ...this.mortgageGetter.offers_filter_history.sent];
      }
      this.$store.commit("appState/setSuccessMessage", {
        text: "Návrh hypotéky bol odoslaný klientovi",
        type: "success"
      });
      await this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
    },
    sortHistory (historyPayload) {
      const mortgageCopy = JSON.parse(JSON.stringify(historyPayload));
      let history = [];
      if (mortgageCopy?.client?.history) {
        for (const client of mortgageCopy.client.history) {
          client.client = true;
        }
        history = [...history, ...mortgageCopy.client.history]
      }
      if (mortgageCopy?.broker?.history) {
        history = [...history, ...mortgageCopy.broker.history];
      }
      this.history = history.sort((a, b) => {
        return (
          new Date(b.date) - new Date(a.date)
        );
      });
    },
    loadCalculation () {
      const applicationsData = this.$store.getters["mortgageDetailState/getOffersData"];
      this.$store.commit("mortgageDetailState/setAmounts", {
        suma_bezucel: this.pickedCalculation.suma_bezucel,
        suma_refinancovanie: this.pickedCalculation.suma_refinancovanie,
        suma_nadobudnutie: this.pickedCalculation.suma_nadobudnutie,
      });
      const newAppData = {
        maximalna_splatnost: applicationsData.maximalna_splatnost,
        splatnost_v_rokoch: this.pickedCalculation.splatnost_v_rokoch,
        fix: this.pickedCalculation.fix,
      }
      const deepEqual = require("deep-equal");
      if (!deepEqual(applicationsData, newAppData, true)) {
        this.$store.commit("mortgageDetailState/setOffersData", newAppData);
      } else {
        this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgageGetter.id);
      }
      this.closePopup();
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    pickCalculation (calculation, key) {
      if (this.pickedKey === key) {
        this.pickedKey = null;
        this.pickedCalculation = null;
        return;
      }
      this.pickedKey = key;
      this.pickedCalculation = calculation;
    },
    getYear (created) {
      const date = new Date(created);
      return date.getFullYear();
    },
    getDate (created) {
      const date = new Date(created);
      return date.getDate();
    },
    getMonth (created) {
      const date = new Date(created);
      return date.getMonth() + 1;
    },
    getHours (created) {
      const date = new Date(created);
      return date.getHours();
    },
    getMinutes (created) {
      const date = new Date(created);
      if (date.getMinutes() < 10) {
        return "0" + date.getMinutes();
      }
      return date.getMinutes();
    },
  },
  data: () => ({
    history: null,
    pickedKey: null,
    brokerHistory: [],
    clientHistory: [],
    pickedCalculation: null,
  }),
}
</script>

<style scoped>

</style>
