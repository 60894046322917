<template>
  <div class="bl-color noselect pointer" style="font-size: 12px; margin-top: 8px" @click="openDietyPopup">
    + pridať
  </div>
</template>

<script>
export default {
  name: "ClientEmploymentDietyHandler",
  props: {
    rules: [Array, null],
    diety: Array,
  },
  methods: {
    openDietyPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.diety,
        component: "ClientEmploymentDietyPopUp",
        rules: this.rules,
      });
    },
  }
}
</script>

<style scoped>

</style>
