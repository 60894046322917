<template>
  <form @submit.prevent="saveBonita" id="bonita-submit">
    <div class="form-2">
      <div class="inlineInputs">
        <div class="input-group">
          <label for="vek" class="field-label">Vek alebo</label
          ><input
          v-model.number="form.vek_v_case_vyplnania"
          :disabled="ageCalculated"
          type="number"
          class="text-input w-input leftItem"
          maxlength="256"
          min="1"
          name="vek"
          id="vek"
        />
        </div>
        <div class="input-group">
          <label class="field-label"
          >dátum narodenia</label>
          <input
            name="dateOfBirth"
            v-model="form.datum_narodenia"
            type="date"
            :max="todayLong"
            class="text-input w-input date-input rightItem"
            maxlength="256"
          />
        </div>
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'rodinny_stav')"
          :id="'rodinny_stav'"
          :init-value="form.rodinny_stav"
          :options="formTemplate.rodinny_stav.choices"
          :value-correction="rodinnyStavCorrection"
          @value-corrected="rodinnyStavCorrection = null"
          normal
          :name="'Rodinný stav'"
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'vzdelanie')"
          :id="'vzdelanie'"
          :init-value="form.vzdelanie"
          :options="formTemplate.vzdelanie.choices"
          normal
          :name="'Vzdelanie'"
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'aktualne_byvate')"
          :id="'aktualne_byvate'"
          :init-value="form.aktualne_byvate"
          :options="formTemplate.aktualne_byvate.choices"
          normal
          :name="'V súčastnosti bývam v nehnuteľnosti'"
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'zuzene_bsm')"
          :id="'zuzene_bsm'"
          :init-value="form.zuzene_bsm"
          :options="booleanForm"
          normal
          :name="'Zúžené BSM'"
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'osobitny_vztah_k_banke')"
          :id="'osobitny_vztah_k_banke'"
          :init-value="form.osobitny_vztah_k_banke"
          :options="booleanForm"
          normal
          :name="'Osobitý vzťah ku banke'"
        />
      </div>
    </div>
  </form>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "ClientBonitaForm",
  components: {DropdownSelect},
  props: {
    bonita: [Object, null],
    formTemplate: Object,
    metaData: [Object, null],
  },
  watch: {
    metaData(data) {
      if (data.type === "coApplicantAdd") {
        if (data.status === "aborted") {
          this.rodinnyStavCorrection = this.form.rodinny_stav;
        } else if (data.stats === "success") {
          this.form[data.key] = data.value;
        }
      }
    }
  },
  created () {
    if (this.bonita) {
      this.form.vek_v_case_vyplnania = this.bonita.client_profile.vek_v_case_vyplnania;
      this.form.datum_narodenia = this.bonita.client_profile.datum_narodenia;
      this.form.rodinny_stav = this.bonita.client_profile.rodinny_stav;
      this.form.vzdelanie = this.bonita.client_profile.vzdelanie;
      this.form.aktualne_byvate = this.bonita.client_profile.aktualne_byvate;
      this.form.zuzene_bsm = this.bonita.client_profile.zuzene_bsm;
      this.form.osobitny_vztah_k_banke =
        this.bonita.client_profile.osobitny_vztah_k_banke;
      if (this.bonita.client_profile.datum_narodenia) {
        const dateOfBirth = new Date(this.bonita.client_profile.datum_narodenia);
        const monthDifference = Date.now() - dateOfBirth.getTime();
        const year = new Date(monthDifference).getUTCFullYear();
        this.form.vek_v_case_vyplnania = Math.abs(year - 1970);
        this.ageCalculated = true;
      }
    }
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-');
  },
  data: () => ({
    rodinnyStavCorrection: null,
    ageCalculated: false,
    todayLong: null,
    form: {
      vek_v_case_vyplnania: null,
      rodinny_stav: null,
      vzdelanie: null,
      aktualne_byvate: null,
      zuzene_bsm: null,
      osobitny_vztah_k_banke: null,
      datum_narodenia: null,
    },
    booleanForm: [
      {
        display_name: "áno",
        value: true,
      },
      {
        display_name: "nie",
        value: false,
      },
    ],
  }),
  methods: {
    addZero(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
    saveBonita () {
      const bonitaObject = {
        datum_narodenia: this.form.datum_narodenia,
        vek_v_case_vyplnania: this.form.vek_v_case_vyplnania ? this.form.vek_v_case_vyplnania : 0,
        rodinny_stav: this.form.rodinny_stav,
        vzdelanie: this.form.vzdelanie,
        aktualne_byvate: this.form.aktualne_byvate,
        zuzene_bsm: this.form.zuzene_bsm,
        osobitny_vztah_k_banke: this.form.osobitny_vztah_k_banke,
      };
      bonitaObject.id = this.bonita.client_profile.id;
      bonitaObject.user = this.bonita.client_profile.user;
      this.$emit("bonita-saved", bonitaObject);
    },
    askForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    showConfirmationDialog () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Pre nastavenie rodinneho stavu ženatý/vydatá je potrebné pridať spolužiadateľa",
        type: "confirmation"
      });
    },
    async setDropdownValue (value, key) {
      if (key === "rodinny_stav" && value === "zenaty") {
        const coApplicant = this.$store.getters["mortgageDetailState/getCoApplicant"];
        if (!coApplicant) {
          this.showConfirmationDialog();
          const userOption = await this.askForUserPermission();
          if (!userOption) {
            this.rodinnyStavCorrection = this.form.rodinny_stav;
            return;
          }
          this.$store.commit("popupManagerState/setClientInfo", {type: "Spolužiadateľ", id: null});
          this.$store.commit("popupManagerState/pushToPopupOrder", {
            type: null,
            payload: {key, value},
            component: "ClientAddPopUp",
            rules: ['single'],
          });
          return;
        }
      }
      this.form[key] = value;
    },
  },
};
</script>

<style scoped></style>
