<template>
  <div class="content-panel">
    <div class="bcg-loading" v-if="loading">
      <v-progress-circular
        class="loading-spinner"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <progress-bar-hypoteky @switch-to="switchComponent($event)"/>
      <component
        :is="component"
        :mortgage="mortgage"
        :applicant="applicant"
        :co-applicant="coApplicant"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApplicationChoice from "./KartyHypotekyDetailStages/ApplicationChoice";
import ApplicationSign from "./KartyHypotekyDetailStages/ApplicationSign";
import ApplicationApproval from "./KartyHypotekyDetailStages/ApplicationApproval";
import ApplicationSummary from "./KartyHypotekyDetailStages/ApplicationSummary";
import DocumentCompletion from "./KartyHypotekyDetailStages/DocumentCompletion";
import ProgressBarHypoteky from "../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ProgressBarHypoteky";

export default {
  name: "KartaHypoteky",
  components: {
    ApplicationChoice,
    ApplicationSign,
    DocumentCompletion,
    ApplicationApproval,
    ApplicationSummary,
    ProgressBarHypoteky
  },
  async created () {
    this.loading = true;
    this.idOfMortgage = parseInt(this.$route.params.hypoteka);
    await this.$store.dispatch(
      "mortgageDetailState/initializeMortgageDetailInfo",
      this.idOfMortgage
    );
    this.mortgage = this.mortgageGetter;
    this.applicant = this.applicantGetter;
    this.coApplicant = this.coApplicantGetter;
    this.mortgageFetch();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      mortgageGetter: "mortgageDetailState/getMortgage",
      applicantGetter: "mortgageDetailState/getApplicant",
      coApplicantGetter: "mortgageDetailState/getCoApplicant",
    }),
  },
  watch: {
    mortgageGetter (newMortgage) {
      this.mortgage = newMortgage;
    },
    applicantGetter (newApplicant) {
      this.applicant = newApplicant;
    },
    coApplicantGetter (newCoApplicant) {
      this.coApplicant = newCoApplicant;
    },
  },
  beforeDestroy () {
    clearInterval(this.mortgageUpdate);
    clearInterval(this.propertiesUpdate);
    this.$store.commit("mortgageDetailState/nullState");
    this.$store.commit("mortgageDetailCloud/initCloud");
  },
  methods: {
    switchComponent(target) {
      this.component = target;
    },
    mortgageFetch () {
      this.mortgageUpdate = setInterval(() => {
        this.$store.dispatch("mortgageDetailState/fetchMortgage");
      }, 30000);
      this.propertiesUpdate = setInterval(() => {
        this.$store.dispatch("mortgageDetailState/fetchProperties");
      }, 30000)
    },
  },
  data: () => ({
    idOfMortgage: null,
    loading: true,
    component: null,
    mortgage: null,
    applicant: null,
    coApplicant: null,
  }),
};
</script>

<style scoped lang="scss">
.bcg-loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: relative;
}
</style>
