<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Primerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <div class="dropdown-input-custom rest-input" style="margin-top: 20px">
        <dropdown-select
          @select="setDropdownValue($event, 'druh')"
          :id="'retirement_druh'"
          :init-value="form.druh"
          :options="druhChoices"
          normal
          :name="'Typ dôchodku'"
        />
      </div>
      <div class="input-group">
        <label for="vyska-dochodok" class="field-label"
        >Výška dôchodku<strong></strong
        ></label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.suma"
          type="number"
          step="0.01"
          min="0"
          class="text-input w-input required"
          name="vyska-dochodok"
          data-name="vyska-dochodok"
          required
          id="vyska-dochodok"
        />
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import { getRestIncomeDTI } from "@/services/brokerService";

export default {
  name: "RetirementPension",
  components: {DropdownSelect},
  props: {
    income: [Object, null],
    formTemplate: Object,
  },
  created () {
    if (this.income) {
      this.form.suma = this.income.suma;
      this.form.druh = this.income.druh;
    }
  },
  data: () => ({
    dtiLoading: false,
    form: {
      druh: null,
      suma: 0,
    },
    estimatedDti: null,
    druhChoices: [
      {
        display_name: "výsluhový",
        value: "vysluhovy_dochodca",
      },
      {
        display_name: "starobný",
        value: "starobny_dochodca",
      },
      {
        display_name: "vdovský",
        value: "vdovsky_dochodca",
      },
      {
        display_name: "invalidný",
        value: "invalidny_dochodca_v_sr",
      },
    ],
  }),
  methods: {
    async showEstimatedDtiToUser () {
      this.dtiLoading = true;
      const incomeData = {
        suma: this.form.suma,
      };
      this.estimatedDti = null;
      if (this.checkValue(incomeData.suma)) {
        const dtiData = await getRestIncomeDTI(incomeData);
        this.pickHighestDti(dtiData);
      }
      this.dtiLoading = false;
    },
    checkValue(value) {
      return !(value === undefined || value === null || value === "");
    },
    pickHighestDti (dtiInfo) {
      let dtiValue = 0;
      for (const value of Object.entries(dtiInfo)) {
        for (const valueElement of value) {
          if (this.estimatedDti < valueElement.value) {
            dtiValue = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
    },
    saveIncome () {
      const incomeObject = {
        typ: "vedlajsi",
        druh: this.form.druh,
        suma: this.form.suma,
      };
      if (this.income) {
        incomeObject.id = this.income.id;
      }
      this.$emit("income-saved", {incomeInfo: incomeObject});
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
  },
};
</script>

<style scoped lang="scss">
.error-text {
  font-size: 11px;
}
</style>
