<template>
  <div class="div-block-102" :class="{'not-active-cloud': !object.cloudMeta._.clouds[0].isActive}">
    <div class="div-block-104">
      <img
        :src="object.image"
        loading="lazy"
        class="image-10"
      >
      <div class="bold">{{ object.label }}</div>
    </div>
    <div class="input-block contrast" v-if="object.subField">
      <div class="div-block-101">
        <div>
          {{ object.subLabel }}
        </div>
        <div class="bold">{{ object.subValue | dotTheAmount }} EUR</div>
<!--        <order-property-report-handler-->
<!--          :rules="['priority']"-->
<!--          :contact-person="getContactPerson()"-->
<!--          :property-id="property.id"-->
<!--        />-->
      </div>
    </div>
    <div class="horizontal-diveder"/>
    <document-cloud-handler
      v-for="(missingFile, index) in object.missingFiles"
      :key="index + 'cldmis'"
      :title="missingFile.name"
      :type="missingFile.id"
      :uploaded="missingFile.uploaded"
      :class-cst="'upload-item-broker-custom'"
      :cloud-meta="object.cloudMeta"
      :rules="['priority']"
      text-button
    />
    <div class="horizontal-diveder"/>
  </div>
</template>

<script>
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";

export default {
  name: "MortgageItemCloud",
  components: {DocumentCloudHandler},
  props: {
    object: Object,
  },
}
</script>

<style scoped>

</style>