<template>
  <div class="div-block-25">
    <div class="add-prijem-btn">
      <div class="text-small bl-color pointer" @click="openPropertyTypePicker()">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientPropertyHandler",
  props: {
    title: String,
    type: String,
    clientType: [Object, null],
    rules: [Array, null],
    propertiesSelected: [Array, null],
  },
  methods: {
    openPropertyTypePicker () {
      this.$store.commit("popupManagerState/setClientInfo", this.clientType);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: this.type,
        payload: this.propertiesSelected,
        component: "ClientPropertyPicker",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped></style>
