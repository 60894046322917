<template>
    <div @click="openClientNeedsPopup" class="pointer">
      <div class="link-icon-text">
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2d_icons8-print%20(1).svg" alt="">
      generovať</div>
    </div>
</template>

<script>
export default {
  name: "ClientNeedsHandler",
  methods: {
    openClientNeedsPopup() {
      this.$store.commit("popupManagerState/toggleClientNeeds", true);
    }
  }
}
</script>

<style scoped>

</style>