<template>
  <div>
    <div
      class="d-flex flex-column align-items-center bl-color w-inline-block no-decoration pointer"
      @click="openClientPersonalInfoPopup()"
      v-if="iconButton"
    >
      <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1f_icons8-edit.svg"/>
      upraviť
    </div>
    <div class="div-block-25" v-else>
      <div
        data-w-id="239b29db-b0c5-1e0a-548a-17a023868560"
        class="add-prijem-btn"
      >
        <div class="text-small bl-color pointer" @click="openClientPersonalInfoPopup()">
          upraviť ÚDAJE
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientPersonalInfoHandler",
  props: {
    clientInfo: [Object, null],
    clientProfile: [Object, null],
    rules: Array,
    iconButton: Boolean,
  },
  methods: {
    openClientPersonalInfoPopup () {
      this.$store.commit("popupManagerState/setClientInfo", {type: this.clientInfo?.type, id: this.clientInfo?.id});
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: {clientInfo: this.clientInfo, clientProfile: this.clientProfile},
        component: "ClientPersonalInfoPopUp",
        rules: this.rules,
      });
    }
  }
}
</script>

<style scoped>

</style>
