<template>
  <div>
    <div
      class="div-block-102"
      v-if="clientInfo.id"
    >
      <div class="div-block-104">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
        <div class="div-block-103 aside">
          <div class="bold pointer" v-if="clientInfo.id">
            <user-manager-handler :class-cst="'blc-color'" :client-info="client" :rules="['priority']" dti client detail/>
          </div>
        </div>
      </div>
      <div class="DTIitems">
        <div id="income-dti" class="input-block contrast p-relative" v-for="income in incomesGetter" :key="income.id" v-if="income.is_active">
          <div class="closebtn-broker-app pointer" @click="deactivateIncome(income.id)">
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
          </div>
          <div class="closebtn-broker-app pointer edit" @click="finishIncome(income)">
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/64cb50251cbbf5e263940bb6_edit%20icon%20simple.svg"/>
          </div>
          <div class="div-block-101">
            <div>{{ translateIncomeType(income.druh) }}</div>
            <v-progress-circular
              v-if="income.loading"
              class="loading-spinner extrasmall"
              indeterminate
            ></v-progress-circular>
            <div class="bold" v-else>+{{ income.dti?.value | dotTheAmount }} EUR</div>
          </div>
          <div class="div-block-112" v-if="income.error">
            <div class="text-cta pointer" @click="finishIncome(income)">
              DOKONČIŤ
            </div>
          </div>
        </div>
        <div class="input-block contrast p-relative" v-for="(debt, index) in debtsGetter" :key="debt.id" v-if="debt.is_visible">
          <div class="closebtn-broker-app pointer" @click="deactivateDebt(debt.id)">
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
          </div>
          <div class="closebtn-broker-app pointer edit" @click="finishDebt(debt)">
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/64cb50251cbbf5e263940bb6_edit%20icon%20simple.svg"/>
          </div>
          <div id="debt-dti" class="div-block-101">
            <div>
              {{ translateDebtType(debt.typ) }} <strong>{{ translateBank(debt.banka) }}</strong> {{ debt.cislo_uveru ? "č." + debt.cislo_uveru : "" }}
            </div>
            <v-progress-circular
              v-if="debt.loading"
              class="loading-spinner extrasmall"
              indeterminate
            ></v-progress-circular>
            <div class="bold" v-else>
              <div v-if="debt.dti">{{ debt.dti?.value | dotTheAmount }} EUR</div>
              <div v-else>{{ debt.zostatok?.value | dotTheAmount }} EUR</div>
            </div>
            <div v-if="debt.error" class="text-cta pointer">
              DOPLNIŤ
            </div>
          </div>
          <div class="debt-activation-picker">
            <dropdown-select
              @select-to-from="changeActivationOfDebt($event, debt, index)"
              :first-time-prop="true"
              :id="'activation-picker' + debt.id"
              :init-value="debt.is_active"
              :options="activationPicker"
              :custom-css="'debt-activation-picker'"
              :value-correction="debtDtiActivationCorrection[index]"
              @value-corrected="debtDtiActivationCorrection.splice(index, 1, null)"
              dual-input
              normal
            />
          </div>
        </div>
      </div>
      <div
        class="DTIsummary"
        v-if="(debtsGetter.filter((d) => d.is_visible).length + incomesGetter.filter((d) => d.is_active).length) > 1"
      >
        celkom
        <span class="black">
          {{ totalDti | dotTheAmount }} EUR
        </span>
      </div>
      <div>
        <client-verification-handler
          :rules="['priority']"
          :client-info="clientInfo"
          :verified="!!client.user_review"
        />
        <client-income-handler :rules="['priority']" :client-info="clientInfo"/>
        <client-debt-handler
          :type="'classic'"
          :rules="['priority']"
          :title="'upraviť VÝDAVKY'"
          :client-info="clientInfo"
        />
        <client-bonita-handler :rules="['priority']"/>
        <div class="div-block-25" v-if="clientInfo.type === 'Spolužiadateľ'">
          <div
            class="add-prijem-btn"
            @click="removeCoApplicant()"
          >
            <div class="text-small bl-color pointer">
              odobrať SPOLUŽIADATEĽA
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-4" v-else>
      <client-add-handler
        :rules="['priority']"
        :client-info="clientInfo"
      />
    </div>
  </div>
</template>

<script>
import ClientDebtHandler from "../../../../shared/PopupHandler/Popups/ClientDebt/ClientDebtHandler";
import ClientIncomeHandler from "../../../../shared/PopupHandler/Popups/ClientIncomes/ClientIncomeHandler";
import ClientAddHandler from "../../../../shared/PopupHandler/Popups/ClientAdd/ClientAddHandler";
import UserManagerHandler from "../../../../shared/PopupHandler/Popups/UserManager/UserManagerHandler";
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import ClientBonitaHandler from "../../../../shared/PopupHandler/Popups/ClientBonita/ClientBonitaHandler";
import DropdownSelect from "../../../../shared/DropdownSelect.vue";
import clientFilters from "../../../../../mixins/clientFilters";
import ClientVerificationHandler
  from "@/components/shared/PopupHandler/Popups/ClientVerification/ClientVerificationHandler.vue";

export default {
  name: "ChoiceUserDetailItem",
  components: {
    ClientVerificationHandler,
    DropdownSelect,
    ClientBonitaHandler, UserManagerHandler, ClientAddHandler, ClientIncomeHandler, ClientDebtHandler
  },
  mixins: [
    translateFromDBValue,
    clientFilters
  ],
  props: {
    clientInfo: [Object, null],
    mortgageId: Number,
    client: [Object, null],
    coApplicant: Boolean,
    missingInformation: Boolean,
  },
  computed: {
    incomesGetter () {
      const incomes = this.$store.getters["mortgageDetailState/getClientIncomes"](this.clientInfo.type_code);
      this.incomeDtiInfo = incomes;
      return incomes;
    },
    debtsGetter () {
      const debts = this.$store.getters["mortgageDetailState/getClientDebts"](this.clientInfo.type_code);
      this.debtDtiInfo = debts;
      return debts;
    }
  },
  data: () => ({
    debtDtiActivationCorrection: [],
    totalDti: 0,
    debtDtiInfo: [],
    incomeDtiInfo: [],
    activationPicker: [
      {
        display_name: "Splatím zo svojho",
        value: false,
      },
      {
        display_name: "Ponechávam si",
        value: true,
      },
      {
        display_name: "Refinacujem",
        value: "refinancovat",
      }
    ]
  }),
  async created () {
    this.calculateDti();
    this.debtDtiActivationCorrection = [];
    const debts = this.$store.getters["mortgageDetailState/getClientDebts"](this.clientInfo.type_code);
    for (const debt of debts) {
      this.debtDtiActivationCorrection.push(null);
    }
  },
  watch: {
    incomeDtiInfo () {
      this.calculateDti();
    },
    debtDtiInfo () {
      this.calculateDti();
    },
  },
  methods: {
    getTotalAmount (amounts) {
      let totalAmount = 0;
      for (const amount of Object.values(amounts)) {
        totalAmount += amount;
      }
      return totalAmount;
    },
    lowerAmountsByOverflow(amountOverflow) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      if (amounts.suma_nadobudnutie >= amountOverflow) {
        const value = Math.round(Math.abs(amounts.suma_nadobudnutie - amountOverflow));
        return { amounts: {...amounts, suma_nadobudnutie: value},  label: `nadobudnutie na ${value}€` };
      }
      if ((amounts.suma_nadobudnutie + amounts.suma_bezucel) >= amountOverflow) {
        const value = Math.round(Math.abs(amounts.suma_bezucel + amounts.suma_nadobudnutie - amountOverflow));
        return {
          amounts: {
            ...amounts,
            suma_nadobudnutie: 0,
            suma_bezucel: value
          },
          label: `nadobudnutie na 0€ a bezúčel na ${value}€`
        }
      }
      if (this.getTotalAmount(amounts) >= amountOverflow) {
        const value = Math.round(Math.abs((amounts.suma_nadobudnutie + amounts.suma_bezucel + amounts.suma_refinancovanie) - amountOverflow));
        return {
          amounts: {
            suma_nadobudnutie: 0,
            suma_bezucel: 0,
            suma_refinancovanie: value
          },
          label: `nadobudnutie na 0€ bezúčel na 0€ refinancovanie na ${value}€`
        };
      }
      return false;
    },
    checkIfHasBudget (leftover, amounts) {
      const limit = this.$store.getters["mortgageDetailState/getLoanLimit"];
      const amount = this.getTotalAmount(amounts);
      const amountLeft = limit - amount;
      return {
        amountOverflow: Math.round(leftover-amountLeft),
        isBudgetEnough: leftover <= amountLeft
      };
    },
    showDialogToUser (label) {
      this.$store.commit("appState/setSuccessMessage", {
        text: label,
        type: "confirmation"
      });
    },
    constructMessageLabel(restOfLabel) {
      return "Pre refinancovanie dlhu bude potrebné nastaviť " + restOfLabel;
    },
    waitForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    async setAsRefinanced(debt, index, from) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      const budgetData = this.checkIfHasBudget(debt.zostatok, amounts);
      if (!budgetData.isBudgetEnough) {
        if (from) {
          this.$store.commit("mortgageDetailState/setLimitCorrection", debt.dti?.value * -1);
        } else {
          const loweredAmountsObject = this.lowerAmountsByOverflow(budgetData.amountOverflow);
          this.showDialogToUser(this.constructMessageLabel(loweredAmountsObject.label));
          const userPermission = await this.waitForUserPermission();
          if (!userPermission) {
            this.debtDtiActivationCorrection.splice(index, 1, from);
            return;
          }
          this.$store.commit("mortgageDetailState/setAmounts", loweredAmountsObject.amounts);
        }
      }
      const clientObject = this.getClientType(debt.klient);
      const debtObject = {
        id: debt.id,
        object: {
          is_visible: false,
          refinancovany_hypotekou: parseInt(this.$route.params.hypoteka),
        },
        clientRole: clientObject.type_code,
        clientLabel: clientObject.type,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientRefinanced", debtObject)
    },
    changeActivationOfDebt(active, debt, index) {
      if (active.to === "refinancovat") {
        this.setAsRefinanced(debt, index, active.from);
      } else {
        const data = {
          id: debt.id,
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          object: {
            is_active: active.to,
          },
        };
        this.$store.dispatch("mortgageDetailState/patchClientDebt", data);
      }
    },
    finishIncome (income) {
      this.$store.commit("popupManagerState/setClientInfo", this.clientInfo);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: income.druh,
        payload: income,
        component: "ClientIncomePopUp",
        rules: null,
      });
    },
    finishDebt (debt) {
      this.$store.commit("popupManagerState/setClientInfo", this.clientInfo);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {debtType: debt.typ, debtPurpose: "classic"},
        payload: debt,
        component: "ClientDebtPopUp",
        rules: null,
      });
    },
    async removeCoApplicant () {
      const mortgage = {
        id: parseInt(this.$route.params.hypoteka),
        object: {
          coapplicant: null,
          coapplicant_detail: null,
          pocet_spolocnych_nezaopatrenych_deti: 0,
        }
      };
      await this.$store.dispatch("mortgageDetailState/removeCoApplicant", mortgage);
    },
    navigateToKartaKlienti () {
      this.$router.push({name: "KartaKlienti"});
    },
    getIcon (type) {
      if (type === "Žiadateľ") {
        return require("@/images/avatar_applicant.png");
      } else if (type === "Spolužiadateľ") {
        return require("@/images/avatar_coapplicant.png");
      }
    },
    calculateDti () {
      let incomeDti = 0;
      let debtDti = 0;
      this.incomeDtiInfo.forEach((income) => {
        if (income.is_active) {
          incomeDti += income.dti?.value;
        }
      });
      this.debtDtiInfo.forEach((debt) => {
        if (debt.is_active) {
          debtDti += debt.dti?.value;
        }
      });
      this.totalDti = incomeDti + debtDti;
    },
    deactivateIncome (incomeId) {
      const data = {
        id: incomeId,
        clientLabel: this.clientInfo.type,
        clientRole: this.clientInfo.type_code,
        isActive: false,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientIncome", data);
    },
    deactivateDebt (debtId) {
      const data = {
        id: debtId,
        clientLabel: this.clientInfo.type,
        clientRole: this.clientInfo.type_code,
        isActive: false,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientDebt", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.closebtn-broker-app.pointer.edit{
  right:20px;
}

.wrapper{
  min-height: 0 !important;
}

</style>
