<template>    
  <div class="klienticta w-inline-block pointer" @click="openMortgagePicker" :class="classCst">
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0c_icons8-business.svg">hypotéka
  </div>
</template>

<script>
export default {
  name: "MortgagePickerHandler",
  props: {
    rules: [Array, null],
    client: Object,
    classCst: [String, null],
  },
  methods: {
    openMortgagePicker () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: {mortgages: this.client.mortgages, id: this.client.id, title: 'EDIT'},
        component: "MortgagePickerPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
