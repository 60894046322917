import store from "../store/index";

export default {
  methods: {
    translateDayToSlovak(day) {
      switch (day) {
        case "Monday": return "Pondelok";
        case "Tuesday": return "Utorok";
        case "Wednesday": return "Streda";
        case "Thursday": return "Štvrtok";
        case "Friday": return "Piatok";
        case "Saturday": return "Sobota";
        case "Sunday": return "Nedeľa";
        default: return "";
      }
    },
    translateEventType(type, addition="") {
      switch (type) {
        case "podpis_ziadosti": return `Podpis${addition} žiadosti`;
        case "podpis_uverovej_zmluvy": return `Podpis${addition} úverovej zmluvy`;
        case "online_konzultacia": return "Online konzultácia";
        case "obhliadka": return "Obhliadka";
        case "nabor": return "Nábor";
        case "podpis_rezervacnej_zmluvy": return "Podpis rezervačnej zmluvy";
        case "podpis_kupnej_zmluvy": return "Podpis kúpnej zmluvy";
        case "odovzdanie_nehnutelnosti": return "Odovzdanie nehnuteľnosti";
        case "obhliadka_znalec": return "Obhliadka znalca";
        default: return "Iné";
      }
    },
    translateDebtType (type) {
      switch (type) {
        case "hypoteka":
          return "Hypotéka";
        case "povolene_precerpanie":
          return "Povolené prečerpanie";
        case "kreditna_karta":
          return "Kreditná karta";
        case "spotrebny_uver":
          return "Spotrebný úver";
        case "leasing":
          return "Leasing";
      }
    },
    translateBank (id) {
      if (!id) id = "other";
      return store.getters["formStore/getBankById"](id).nazov;
    },
    translateBankFromCodeNameToId(key) {
      return store.getters["formStore/getBankByKey"](key).id;
    },
    getBankLogoImage (id) {
      return store.getters["formStore/getBankById"](id).logo;
    },
    getBankSquareImage (id) {
      return store.getters["formStore/getBankById"](id).logo_square;
    },
    translateIncomeType (type) {
      if (["trvaly_pracovny_pomer",
        "pracovny_pomer_na_cas_urcity",
        "pracovny_pomer_na_neurcito",
        "dohoda_o_pracovnej_cinnosti",
        "pracovny_pomer_cez_agenturu",
        "pracovny_pomer_vo_vlastnej_firme"].includes(type)) return "Zamestnanie";
      else if (["prijem_zivnostnika"].includes(type)) return "Živnosť";
      else if (["prijem_z_sro"].includes(type)) return "Sro";
      else if (["prijem_z_prenajmu_nehnutelnosti"].includes(type)) return "Prenájom"
      else if
      (["vysluhovy_dochodca",
          "starobny_dochodca",
          "vdovsky_dochodca",
          "invalidny_dochodca_v_sr"].includes(type)) return "Dôchodok"
      else if (["materska_rodicovsky_prispevok"].includes(type)) return "Rodičovský príspevok"
    },
  }
}
