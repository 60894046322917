import store from "../store/index";

export default {
  methods: {
    convertToAbsolute (url) {
      if (url.includes("https://api.bankari.sk")) return url
      return "https://api.bankari.sk" + url;
    },
    getBankImage (bankKey) {
      return (store.getters["formStore/getBankByKey"](bankKey)).logo_square;
    }
  }
}
