<template>
  <div
    class="wrapper"
    :class="{ 'p-0 freq': noInput }"
    @click="$refs['dropdown-' + id].open = true"
    :id="id"
  >
    <div class="field-label dropdown" :class="{ 'd-none': nameShort }" v-if="name">
      {{ name }}
    </div>
    <div class="select-wrapper" :class="{ 'select-wrapper-normal': normal }">
      <vue-select
        :name="name"
        :ref="'dropdown-' + id"
        :taggable="taggable"
        class="select"
        label="display_name"
        v-model="selected"
        :options="options"
        :multiple="multiple"
        @option:selected="removeLabel"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownSelect",
  props: {
    customCss: [String, null],
    normal: Boolean,
    multiple: Boolean,
    taggable: Boolean,
    options: Array,
    name: [String, null],
    dataRef: String,
    initValue: [String, Number, Array, Boolean, null],
    valueRemoval: Boolean,
    valueCorrection: [String, Number, Array, Boolean, null],
    nameShort: String,
    noInput: Boolean,
    id: [String, null],
    dualInput: Boolean,
  },
  data: () => ({
    selected: null,
    span: null,
    firstTime: true,
    emitEvent: true,
  }),
  created () {
    if (this.valueRemoval) {
      this.$emit("value-removed", false);
    }
    if (Array.isArray(this.initValue)) {
      this.selected = this.options.filter((option) =>
        this.initValue.includes(option.value)
      );
    } else {
      this.selected = this.options.filter(
        (option) => option.value === this.initValue
      )[0];
    }
  },
  mounted () {
    //Nahradí ikonu caretu
    (() => {
      const el = document.getElementById(this.id);

      el.getElementsByClassName("vs__open-indicator")[0].innerHTML =
        '<path d="M1 1L5.5 5.5L10 1"/>';

      if (!this.normal) {
        const nodeMap = el.attributes;
        const node = nodeMap.getNamedItem("type");
        node.value = "number";
        nodeMap.setNamedItem(node);
      }
    })();

    // if (this.nameShort && !this.selected) {
    //   this.span = document.createElement("span");
    //   this.span.classList.add("vs__selected");
    //   this.span.innerHTML = this.nameShort;
    //
    //   document
    //     .getElementsByClassName("vs__selected-options")[0]
    //     .appendChild(this.span);
    // }
  },
  methods: {
    addEntry (options, search) {
      options.push({text: `${search} EUR`, value: search});

      return options;
    },
    removeLabel () {
      if (this.span) {
        this.span.remove();
        this.span = null;
      }
    },
  },
  watch: {
    valueCorrection (value) {
      if (value === null) return;
      this.emitEvent = false;
      this.selected = this.options.filter(
        (option) => option.value === value
      )[0];
      this.$emit("value-corrected");
    },
    valueRemoval (value) {
      if (!value) return;
      this.selected = null;
      this.$emit("value-removed", false);
    },
    selected (to, from) {
      if (!this.emitEvent) {
        this.emitEvent = true;
        return;
      }
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }
      if (Array.isArray(to)) {
        let values = [];
        for (const toItem of to) {
          values.push(toItem.value);
        }
        this.$emit("select", values);
      } else {
        if (to) {
          if (typeof to.value !== "undefined") {
            if (this.dualInput) {
              this.$emit("select-to-from", {to: to.value, from: from.value});
              return;
            }
            this.$emit("select", to.value);
          }
          else this.$emit("select", parseInt(to.display_name));
        } else this.$emit("select", null);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
