<template>
  <form id="event-form" @submit.prevent="saveEvent">
    <div
      class="input-group"
    >
      <label for="podnikamOd" class="field-label"
      >Termín podpisu</label
      ><input
      type="datetime-local"
      v-model="meetingDate"
      :min="todayLong"
      class="text-input w-input date-input"
      maxlength="256"
      name="podnikamOd"
      data-name="podnikamOd"
      id="podnikamOd"
      required
    />
    </div>
    <div class="pb-2">
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event)"
          :id="'podpis'"
          :init-value="signLocal"
          :options="booleanForm"
          :name="'Podpis u nás'"
          normal
        />
      </div>
    </div>
    <div v-if="!signLocal">
      <sidlo-input
        @address-change="setAddress($event)"
        :not-required="{orientacne: true}"
        :not-included="{supisne: true}"
        :type="'house'"
      />
    </div>
    <div
      class="input-group"
    >
      <label for="sprava" class="field-label"
      >Správa pre klienta</label
      >
      <textarea
        v-model="invitationForm.description"
        class="text-input w-input"
        id="sprava"
      />
    </div>
  </form>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import SidloInput from "../../../SidloInput";

export default {
  name: "ApplicationSignEvent",
  components: {SidloInput, DropdownSelect},
  props: {
    type: String,
  },
  methods: {
    setStartEndDate() {
      this.invitationForm.start = `${this.meetingDate}:00`
      const date = new Date(this.invitationForm.start);
      date.setMinutes(date.getMinutes() + 30);
      date.setHours(date.getHours() + 2);
      this.invitationForm.end = date.toISOString().slice(0, 19);
    },
    saveEvent() {
      this.setStartEndDate();
      this.$emit("event-saved", this.invitationForm);
    },
    setAddress(event) {
      const eventCopy = JSON.parse(JSON.stringify(event));
      this.invitationForm.meta.placeId = eventCopy.google_place_id;
      this.invitationForm.meta.addressMeta = eventCopy;
      delete this.invitationForm.meta.addressMeta.cislo_parcely;
      delete this.invitationForm.meta.addressMeta.cislo_lv;
      delete this.invitationForm.meta.addressMeta.google_place_id;
    },
    setDropdownValue (value) {
      this.signLocal = value;
      if (!value) return;
      this.invitationForm.meta = {...this.invitationForm.meta, addressMeta: null};
    },
    addZero (value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    }
  },
  created () {
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-') + "T" + [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(':');
  },
  data: () => ({
    signLocal: true,
    todayLong: null,
    meetingDate: null,
    invitationForm: {
      type: "podpis_ziadosti",
      start: null,
      end: null,
      users: null,
      description: null,
      meta: {
        placeId: null,
        addressMeta: null,
      }
    },
    booleanForm: [
      {
        display_name: "áno",
        value: true,
      },
      {
        display_name: "nie",
        value: false,
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
</style>
