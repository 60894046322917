<template>
  <kanban-board :blocks="testimonials" :stages="stages" :config="config">
    <div v-for="(stage, index) in stages" :slot="stage" :key="stage" class="light-text small">
      <h2 class="pop-800 blc-color text-uppercase header-custom" style="">
        <h2 class="div-block-110 bordered">
          {{ index + 1 }}
        </h2>
        {{ getLabel(stage) }}
      </h2>
    </div>
    <div
      class="ziadost-item-custom pointer"
      @click="openTestimonialManager"
      v-for="(testimonial, index) in testimonials"
      :key="index"
      :slot="testimonial.id"
    >
      <div class="ziadost-top">
        <div class="ziadost-icon justify-content-start">
          <div class="text-part">
            <div>{{ testimonial.property.address }}</div>
            <div class="bold bl-color">{{ testimonial.property.type }}</div>
            <div class="bold">{{ testimonial.property.suma }} EUR</div>
          </div>
        </div>
      </div>

      <div class="ziadost-bottom" @click="openTestimonialManager">
        
      </div>
      <!-- <div v-if="testimonial.status === 'nove'">
        <expert-picker-handler v-if="role === 'alex_admin'"/>
        <div
          v-else
          class="bl-color pointer"
        >
          zmeniť znalca
        </div>
      </div> -->
    </div>
  </kanban-board>
</template>

<script>
import ExpertPickerHandler from "../../shared/PopupHandler/Popups/ExpertPicker/ExpertPickerHandler";

export default {
  name: "TestimonialsItemsWrapper",
  components: {
    ExpertPickerHandler
  },
  created () {
    this.role = this.$store.getters["userState/getRole"];
  },
  methods: {
    testimonialMoved () {
    },
    getLabel (stage) {
      return this.stageLabel[stage];
    },
    openTestimonialManager () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {label: "Azalková 1, Bratislava"},
        payload: null,
        component: "TestimonialManagerPopUp",
        rules: ['priority'],
      });
    },
  },
  data: () => ({
    role: null,
    stageLabel: {
      nove: "Nové",
      prevzate: "Prevzaté",
      obhliadka_vykonana: "Obhliadka vykonaná",
      doruceny: "Doručený"
    },
    stages: ["nove", "prevzate", "obhliadka_vykonana", "doruceny"],
    config: {
      moves () {
        return false
      }
    },
    testimonials: [
      {
        id: 1,
        status: "nove",
        property: {
          suma: 123456,
          address: "Azalková 1, Bernolákovo",
          type: "dom",
        },
      },
      {
        id: 2,
        status: "prevzate",
        property: {
          suma: 123456,
          address: "Azalková 1, Bernolákovo",
          type: "dom",
        },
      }
    ],
  }),
}
</script>

<style lang="scss" scoped>
.header-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editing {
  cursor: not-allowed !important;
}

.text-part {
  margin: 0;
}

</style>

<style lang="scss">
@import "node_modules/vue-kanban/src/assets/kanban.scss";

.drag-item {
  display: flex;
  justify-content: center;
  height: auto;
  background: none;
  margin: 10px 0;
  padding: 0;
}

.drag-container {
  max-width: none;
  margin: 0 30px;
}

.drag-column-header {
  display: block;
  padding: 0;
  text-align: center;
}

.drag-column {
  & h2 {
    text-transform: none;
  }

  background: none;
}
</style>