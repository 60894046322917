<template>
  <div class="popup-form">
    <div
      class="content-form-popup noselect"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Posudok</div>
        <button class="w-button">Uložiť</button>
      </div>
      <div class="input-group">
        <label for="vseob.hodnota" class="field-label">Všeobecná hodnota:</label>
        <input
          type="number"
          class="text-input w-input"
          maxlength="256"
          name="vseob.hodnota"
          data-name="vseob.hodnota"
          id="vseob.hodnota"
          required=""
        />
      </div>
      <div class="input-group">
        <label for="bud.hodnota" class="field-label">Budúca hodnota:</label>
        <input
          type="number"
          class="text-input w-input"
          maxlength="256"
          name="bud.hodnota"
          data-name="bud.hodnota"
          id="bud.hodnota"
          required=""
        />
      </div>
      <div class="add-file-wrapper">
        <label
          :for="'file-upload'"
          class="sub-ctass image-upload-btn add-file-button pointer blc-color pop-500 ">
          + nahrajte znalecký posudok
          <input :id="'file-upload'" type="file" accept=".zip,.rar,.7zip"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialOverviewPopUp",
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style lang="scss" scoped>
.image-upload-btn > input {
  display: none;
}

.add-file-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.add-file-button {
  width: 80%;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-style: dashed;
  border-width: 2px;
  border-color: #0a1953;
  border-radius: 10px;
}
</style>
