<template>
  <div class="badge-icon pointer" @click="showNotificationPanel()">
    <img
      src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1a_icons8-chat-room.svg"
      loading="lazy"
      alt=""
      class="nav-link-icon filter-primary desktop"
    />
    <div class="div-block-85" v-if="allUnseenMessages !== 0">
      <div>{{ allUnseenMessages }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getUserProfileByID } from "../../../../../services/brokerService";

export default {
  name: "NotificationHandler",
  props: {
    rules: [Array, null],
  },
  data: () => ({
    allUnseenMessages: 0,
    interval: null,
    brokerId: null,
    messages: null,
    clients: [],
    clientIds: [],
  }),
  computed: ({
    ...mapGetters({
      messagesGetter: "clientState/getUnseenMessages",
    }),
  }),
  watch: {
    messagesGetter (newMessages) {
      this.messages = newMessages;
    },
  },
  async created () {
    this.brokerId = this.$store.getters["userState/getId"];
    await this.setUnreadMessages(false);
    this.messages = this.messagesGetter;
    await this.retrieveClients();
    this.interval = setInterval(async () => {
      await this.setUnreadMessages(true);
    }, 2000);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    async setUnreadMessages (checkNew) {
      await this.$store.dispatch("clientState/updateUnseenMessages", this.brokerId);
      if (checkNew) {
        const newUnreadMessagesAmount =
          this.$store.getters["clientState/totalAmountOfUnseenMessages"];
        if (
          newUnreadMessagesAmount > this.allUnseenMessages
        ) {
          const audio = document.getElementById("new-notification");
          audio.play();
        }
      }
      this.allUnseenMessages =
        this.$store.getters["clientState/totalAmountOfUnseenMessages"];
    },
    async retrieveClients () {
      for (const message of this.messages) {
        const clientId = message.sender;
        if (!this.clientIds.includes(clientId)) {
          const client = await getUserProfileByID(clientId);
          this.clients.push(client);
          this.clientIds.push(clientId);
        }
      }
    },
    showNotificationPanel () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: {messages: this.messages, clients: this.clients, clientIds: this.clientIds},
        component: "NotificationPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-800 {
  color: #000 !important;
}
</style>
