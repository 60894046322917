<template>
  <router-view style="height: 100%"/>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  created () {
    this.me = this.$store.getters["userState/getMe"];
    let html = document.getElementsByTagName("html")[0];
    html.style.overflowX = "hidden";
    html.style.overflowY = "auto";
  },
  mounted () {
    AOS.init();
  },
  data: () => ({
    me: null
  }),
};
</script>

<style>
.v-main__wrap {
  background-color: #f8f9fa !important;
}
</style>
