import Vue from "vue";
import VueRouter from "vue-router";
import { getAccess, userMe } from "../services/brokerService";
import store from "../store/index";
import LandingPage from "../containers/login/LandingPage.vue";

import adminRoutes from "./adminRoutes";
import brokerRoutes from "./brokerRoutes";
import expertRoutes from "./expertRoutes";

Vue.use(VueRouter);

const routes = [
  {
    name: "Landing",
    component: LandingPage,
    path: "/login",
  },
]

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  const logged = store.getters["userState/getLogged"];
  if (to.name === "Landing" && !logged) {
    next();
    return;
  }
  if (!logged) {
    store.commit("appState/setLoadingMainData", true);
    store.commit("appState/setEntryUrl", to);
    try {
      await getAccess();
      const user = await userMe();
      if (
        !["alex_admin", "financnik", "znalec"]
          .includes(user.role)
      ) throw {status: 999}
      store.commit("userState/setMe", user);
      store.commit("userState/setLogged", {
        logged: true,
      });
      store.commit("appState/setLoadingMainData", false);
      if (["alex_admin"].includes(user?.role)) {
        router.addRoute(adminRoutes);
      } else if (["financnik"].includes(user?.role)) {
        router.addRoute(brokerRoutes);
      } else if (["znalec"].includes(user?.role)) {
        router.addRoute(expertRoutes);
      }
      next(to);
    } catch (e) {
      store.commit("userState/setMe", null);
      store.commit("userState/setLogged", {logged: false});
      next({name: "Landing"});
      return;
    }
  }
  if (logged) {
    next();
  }
});

export default router;
