<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Ostatné</div>
        <button class="w-button saveformBtn" @click="saveRestValues">Uložiť</button>
      </div>
      <rest-item-holder
        v-for="(item, key) in itemsData" :key="key"
        :index="key"
        :item="item"
        @rest-toggle="itemToggled($event)"
      />
    </div>
  </div>
</template>

<script>
import RestItemHolder from "./RestItemHolder";

export default {
  name: "ClientEmploymentRestPopUp",
  components: {RestItemHolder},
  props: {
    payload: Array,
  },
  created () {
    this.itemsData = JSON.parse(JSON.stringify(this.payload))
  },
  methods: {
    saveRestValues () {
      this.$emit("set-meta", {rest: this.itemsData});
      this.closePopup();
    },
    itemToggled (itemInfo) {
      this.itemsData[itemInfo.index] = itemInfo.item;
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    itemsData: null,
  }),
}
</script>

<style lang="scss" scoped>
.closebtn-broker-app {
  right: 15px;
}

.field-label {
  margin-top: 0;
}

.text-input {
  margin-top: 0;
}

.input-wrapper {
  position: relative;
}

.sub-popup-wrapper {
  text-align: center;
  color: #0019d9;
  font-size: 12px;
}

</style>
