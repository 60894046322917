import {
  getAllBanks,
  getBankVariables,
  getClientProfileForm,
  getDebtForm,
  getIncomeForm,
  getMortgageForm, getProfessions,
  getPropertyForm,
} from "../services/brokerService";

export default {
  namespaced: true,
  state: {
    debtForm: null,
    bonitaForm: null,
    incomeForm: null,
    propertyForm: null,
    mortgageForm: null,
    clientProfileForm: null,
    professionForm: null,
    banks: null,
    bankVars: null,
  },
  getters: {
    getProfessionLabelById: (state) => (id) => {
      if (!id) return "";
      const index = state.professionForm.map(p => p.id).indexOf(id);
      return state.professionForm[index].label;
    },
    getFormByType: (state) => (type) => {
      return state[type];
    },
    translateBEValue: (state) => (type, key, value) => {
      const choices = state[type][key].choices;
      for (const choice of choices) {
        if (choice.value !== value) continue;
        return choice.display_name;
      }
    },
    // getMortgageTemplate: (state) => {
    //   return state.mortgageTemplate;
    // },
    getBanks: (state) => {
      return state.banks;
    },
    getBankVars: (state) => {
      return state.bankVars;
    },
    getBankById: (state) => (id) => {
      let bankFound = null
      state.banks.forEach((bank) => {
        if (bank.id === id) {
          bankFound = bank
        }
      });
      return bankFound;
    },
    getBankByKey: (state) => (key) => {
      let bankFound = null
      state.banks.forEach((bank) => {
        if (bank.code_name === key) {
          bankFound = bank
        }
      });
      return bankFound;
    }
  },
  mutations: {
    setFormByType (state, data) {
      state[data.type] = data.form;
    },
    setBankVars (state, vars) {
      state.bankVars = vars;
    },
    setMortgageTemplate (state, data) {
      state.mortgageTemplate = data;
    },
    setBanks (state, banks) {
      state.banks = [...banks, {id: "other", nazov: "Iné"}];
    },
  },
  actions: {
    initializeForms: async ({commit}) => {
      const banks = (await getAllBanks("notes")).results;
      commit("setBanks", banks);

      const bankVars = await getBankVariables();
      commit("setBankVars", bankVars);

      const professions = await getProfessions();
      commit("setFormByType", {type: "professionForm", form: professions});

      const incomeForm = (await getIncomeForm()).actions.POST;
      commit("setFormByType", {type: "incomeForm", form: incomeForm});

      const propertyForm = (await getPropertyForm()).actions.POST;
      commit("setFormByType", {type: "propertyForm", form: propertyForm});

      const debtForm = (await getDebtForm()).actions.POST
      commit("setFormByType", {type: "debtForm", form: debtForm});

      const mortgageForm = (await getMortgageForm()).actions.POST
      commit("setFormByType", {type: "mortgageForm", form: mortgageForm});

      const clientProfileForm = (await getClientProfileForm()).actions.POST
      commit("setFormByType", {type: "clientProfileForm", form: clientProfileForm});

    },
  },
};
