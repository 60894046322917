<template>
  <div class="pointer archive-button button-item-handler white--text no-decoration hideSmartphone"
       @click="openClientFinancingOffer">
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2d_icons8-print%20(1).svg" alt="">
  </div>
</template>

<script>

export default {
  name: "ClientFinancingOfferHandler",
  methods: {
    openClientFinancingOffer () {
      this.$store.commit("popupManagerState/toggleClientFinancingOffer", true);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>