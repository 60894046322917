<template>
  <form id="event-form" @submit.prevent="saveEvent">
    <div
      class="input-group"
      v-for="(month, index) in signEventDates"
      :key="index"
    >
      <label for="podnikamOd" class="field-label"
      >Termín podpisu</label
      ><input
      type="datetime-local"
      v-model="month.date"
      :min="todayLong"
      class="text-input w-input date-input"
      maxlength="256"
      name="podnikamOd"
      data-name="podnikamOd"
      id="podnikamOd"
      required
    />
<!--      <div class="closebtn-broker-app pointer">-->
<!--        <img src="@/images/refresh-icon.svg" loading="lazy" width="10px" alt=""/>-->
<!--      </div>-->
    </div>
<!--    <div class="section-title" v-if="signEventDates.length < 3">-->
<!--      <div class="text-small bl-color pointer" @click="addMonth">-->
<!--        + pridať ďalší možný termín-->
<!--      </div>-->
<!--    </div>-->
    <sidlo-input
      @address-change="setAddress($event)"
      :type="'house'"
      :not-required="{supisne: true}"
      :not-included="{orientacne: true}"
    />
    <div
      class="input-group"
    >
      <label for="sprava" class="field-label"
      >Správa pre klienta</label
      >
      <textarea
        v-model="invitationForm.description"
        class="text-input w-input date-input"
        id="sprava"
      />
    </div>
  </form>
</template>

<script>
import SidloInput from "../../../SidloInput.vue";

export default {
  name: "ContractSignEvent",
  components: {
    SidloInput
  },
  created () {
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-') + "T" + [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(':');
  },
  methods: {
    setAddress(event) {
      const eventCopy = JSON.parse(JSON.stringify(event));
      this.invitationForm.meta.placeId = eventCopy.google_place_id;
      this.invitationForm.meta.addressMeta = eventCopy;
      delete this.invitationForm.meta.addressMeta.cislo_parcely;
      delete this.invitationForm.meta.addressMeta.cislo_lv;
      delete this.invitationForm.meta.addressMeta.google_place_id;
    },
    saveEvent() {
      this.setStartEndDate();
      this.$emit("event-saved", this.invitationForm);
    },
    setStartEndDate() {
      this.invitationForm.start = `${this.signEventDates[0].date}:00`
      const date = new Date(this.invitationForm.start);
      date.setMinutes(date.getMinutes() + 30);
      date.setHours(date.getHours() + 2);
      this.invitationForm.end = date.toISOString().slice(0, 19);
    },
    addMonth() {
      this.signEventDates.push({
        date: null,
      });
    },
    addZero(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    }
  },
  data: () => ({
    todayLong: null,
    signEventDates: [
      {
        date: null,
      }
    ],
    invitationForm: {
      type: "podpis_uverovej_zmluvy",
      start: null,
      end: null,
      users: null,
      description: null,
      meta: {
        placeId: null,
        addressMeta: null,
      }
    },
  }),
}
</script>

<style scoped>

</style>
