<template>
  <div
    class="hypotekacta MGtop20px"
    @click="openExpertAssignPicker()"
  >
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1d_icons8-add.svg">
  </div>
</template>

<script>
export default {
  name: "ExpertPickerHandler",
  methods: {
    openExpertAssignPicker () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ExpertPickerPopUp",
        rules: ['priority'],
      });
    },
  },
}
</script>

<style scoped>

</style>
