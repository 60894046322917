<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Priemerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
        <div class="w-form">
          <div class="section-title">
            <div class="text-small light blc-color">Zadajte príjem klienta za posledných 3 až 6 mesiacov, alebo použite zadanie priemerného príjmu za 6 / 12 mesiacov nižšie</div>
          </div>
          <monthly-amount-input :averages="monthlyAmountAveragesFromInput" :init-months="form.monthlyIncomes" @refresh-dti="showEstimatedDtiToUser"/>
          <div
            class="input-group"
          >
            <label for="priemer-inc-6" class="field-label">
              Priemer za posledných 6 mesiacov
            </label>
            <input
              type="number"
              @change="calculateAverage($event, 6)"
              class="text-input w-input date-input"
              maxlength="256"
              name="priemer-inc-6"
              data-name="priemer-inc-6"
              id="priemer-inc-6"
            />
          </div>
          <div
            class="input-group"
          >
            <label for="priemer-inc-12" class="field-label">
              Priemer za posledných 12 mesiacov
            </label>
            <input
              type="number"
              @change="calculateAverage($event, 12)"
              class="text-input w-input date-input"
              maxlength="256"
              name="priemer-inc-12"
              data-name="priemer-inc-12"
              id="priemer-inc-12"
            />
          </div>

<!--          <monthly-amount-input :update-value="'diety'" :init-months="diety" @refresh-dti="showEstimatedDtiToUser"/>-->
          <!-- <div
            class="input-group"
          >
            <label for="priemer-die-6" class="field-label">
              Priemer diét za posledných 6 mesiacov
            </label>
            <input
              type="number"
              class="text-input w-input date-input"
              maxlength="256"
              name="priemer-die-6"
              data-name="priemer-die-6"
              id="priemer-die-6"
            />
          </div>
          <div
            class="input-group"
          >
            <label for="priemer-die-12" class="field-label">
              Priemer diét za posledných 12 mesiacov
            </label>
            <input
              type="number"
              class="text-input w-input date-input"
              maxlength="256"
              name="priemer-die-12"
              data-name="priemer-die-12"
              id="priemer-die-12"
            />
          </div> -->
          <div class="section-title">
            <div class="text-small light blc-color">O pracovnom pomere</div>
          </div>
          <div class="dropdown-input-custom required">
            <input
              type="text"
              v-model="form.druh"
              style="opacity: 0; position: absolute"
              :required="required"
            />
            <dropdown-select
              @select="setDropdownValue($event, 'druh')"
              :id="'employment_druh'"
              :init-value="form.druh"
              :options="typeOfEmploymentSelect"
              normal
              :name="'Typ pracovného pomeru'"
            />
          </div>
          <div
            class="date-picker-wrapper"
            v-if="form.druh === 'pracovny_pomer_na_neurcito'"
          >
            <div class="input-group">
              <label for="Zamestnan-od" class="field-label"
              >Zamestnaný <strong>od</strong></label
              ><input
              v-model="form.zamestnanyOd"
              type="date"
              :max="getTodayDate()"
              class="text-input w-input date-input required"
              maxlength="256"
              name="zamestnanyOd"
              data-name="Zamestnaný od"
              id="Zamestnan-od"
              :required="required"
            />
            </div>
          </div>
          <div
            class="date-picker-wrapper"
            v-if="form.druh === 'pracovny_pomer_na_cas_urcity'"
          >
            <div
              class="input-group"
            >
              <label for="Zamestnan-od" class="field-label"
              >Zamestnaný <strong>od</strong></label
              ><input
              v-model="form.zamestnanyOd"
              type="date"
              :max="getTodayDate()"
              class="text-input w-input date-input required"
              maxlength="256"
              name="zamestnanyOd"
              data-name="Zamestnaný od"
              id="Zamestnan-od"
              :required="required"
            />
            </div>
            <div class="input-group">
              <label for="Zamestnan-do" class="field-label"
              >Zamestnaný <strong>do</strong></label
              ><input
              v-model="form.zamestnanyDo"
              type="date"
              :min="getTodayDate()"
              class="text-input w-input date-input required"
              maxlength="256"
              name="zamestnanyDo"
              data-name="Zamestnaný do"
              id="Zamestnan-do"
              :required="required"
            />
            </div>
          </div>
          <div
            class="dropdown-input-custom rest-input"
            v-if="shortIncome"
          >
            <dropdown-select
              @select="setDropdownValue($event, 'dovodKratsejDobyPrijmu')"
              :id="'dovod_kratsej_doby_prijmu'"
              :init-value="form.dovodKratsejDobyPrijmu"
              :options="formTemplate.dovod_kratsej_doby_prijmu.choices"
              normal
              :name="'Dôvod kratšej doby príjmu'"
            />
          </div>
          <div class="text-input w-input date-input popup-input-wrapper">
            <div class="blc-color">Iné špecifiká príjmu</div>
            <client-employment-rest-handler :rules="['single']" :ostatne="restMultiselect"/>
          </div>
          <!--          <div-->
          <!--            class="dropdown-input-custom rest-input"-->
          <!--            style="margin-top: 17px"-->
          <!--          >-->
          <!--            <dropdown-select-->
          <!--              @select="setDropdownValue($event, 'ostatne')"-->
          <!--              :id="'employment_ostatne'"-->
          <!--              :init-value="form.ostatne"-->
          <!--              :options="restMultiselect"-->
          <!--              normal-->
          <!--              multiple-->
          <!--              :name="'Ostatné'"-->
          <!--            />-->
          <!--          </div>-->
          <div class="section-title">
            <div class="text-small light blc-color">O profesii</div>
          </div>
          <div class="dropdown-input-custom">
            <input
              type="text"
              v-model="form.profesia"
              style="opacity: 0; position: absolute"
            />
            <dropdown-select
              @select="setDropdownValue($event, 'profesia')"
              :id="'employment_profesia'"
              :init-value="form.profesia"
              :options="formTemplate.profesia.choices"
              normal
              :name="'Profesia'"
            />
          </div>
          <div class="dropdown-input-custom">
            <input
              type="text"
              v-model="form.pracovneZaradenie"
              style="opacity: 0; position: absolute"
            />
            <dropdown-select
              @select="setDropdownValue($event, 'pracovneZaradenie')"
              :id="'employment_prac_zaradenie'"
              :init-value="form.pracovneZaradenie"
              :options="formTemplate.pracovne_zaradenie.choices"
              normal
              :name="'Pracovné zaradenie'"
            />
          </div>
          <div class="dropdown-input-custom rest-input">
            <input
              type="text"
              v-model="form.odvetvie"
              style="opacity: 0; position: absolute"
            />
            <dropdown-select
              @select="setDropdownValue($event, 'odvetvie')"
              :id="'employment_odvetvie'"
              :init-value="form.odvetvie"
              :options="
                formTemplate.zamestnavatel_odvetvie.choices
              "
              normal
              :name="'Odvetvie'"
            />
          </div>
          <!-- doplniť profesiu sem -->
          <div class="section-title">
            <div class="text-small light blc-color">O zamestnávateľovi</div>
            <div class="text-small light blc-color"> údaje overíte na
              <a class="link" href="https://www.finstat.sk/" target="_blank">finstat.sk</a>
            </div>
          </div>
          <div
            class="input-group"
          >
            <label for="Typ-pr-jmu-2" class="field-label" v-if="!restMultiselect[4].value">
              IČO <span class="pop-300">(údaje sa doplnia automaticky)</span>
            </label>
            <label for="Typ-pr-jmu-2" class="field-label" v-else>
              ID spoločnosti
            </label>
            <input
              @change="initializeEmployer"
              v-model="ico"
              :readonly="!sidloInputNeed"
              type="text"
              class="text-input w-inpu"
              maxlength="8"
              minlength="8"
              name="icoZamestnavatel"
              data-name="Typ Pr Jmu 2"
              placeholder=""
              id="Typ-pr-jmu-2"
            />
          </div>
          <div class="input-group">
            <label for="obchodny-nazov" class="field-label"
            >Obchodný názov</label
            ><input
            v-model="employerDetail.name"
            :readonly="!restMultiselect[4].value || !sidloInputNeed"
            type="text"
            class="text-input w-input"
            :class="{'input-color-disabled': !restMultiselect[4].value}"
            maxlength="256"
            name="obchodny-nazov"
            data-name="obchodny-nazov"
            placeholder=""
            id="obchodny-nazov"
          />
          </div>
          <div v-if="restMultiselect[4].value">
            <sidlo-input
              v-if="sidloInputNeed"
              @address-change="setAddress($event)"
              :type="'house'"
            />
            <div class="input-group" v-else>
              <label class="field-label">Sídlo</label
              ><input
              v-model="sidloInputFormattedValue"
              readonly
              type="text"
              class="text-input w-input"
              maxlength="256"
              name="sidlo"
              data-name="sidlo"
              placeholder=""
              id="sidlo"
            />
            </div>
          </div>
          <div class="input-group" v-else>
            <label class="field-label">Sídlo</label
            ><input
            v-model="
                employerDetail.slovensko_digital_response.formatted_address
              "
            :readonly="!restMultiselect[4].value"
            type="text"
            class="text-input w-input"
            :class="{'input-color-disabled': !restMultiselect[4].value}"
            maxlength="256"
            name="sidlo"
            data-name="sidlo"
            placeholder=""
            id="sidlo"
          />
          </div>
          <div
            class="input-group"
          >
            <label for="emailZamestnavatela" class="field-label"
            >Email na zamestnávateľa<strong></strong></label
            ><input
            v-model="form.emailZamestnavatel"
            type="email"
            class="text-input w-input"
            maxlength="256"
            name="emailZamestnavatela"
            data-name="emailZamestnavatela"
            placeholder=""
            id="emailZamestnavatela"
          />
          </div>
          <div class="input-group">
            <label for="telefonZamestnavatela" class="field-label"
            >Telefón na zamestnávateľa<strong></strong></label
            ><input
            v-model="form.telefonZamestnavatel"
            type="tel"
            class="text-input w-input"
            maxlength="256"
            name="telefonZamestnavatela"
            data-name="telefonZamestnavatela"
            placeholder=""
            id="telefonZamestnavatela"
          />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import {
  createEmployer,
  deleteMonthlyIncome,
  getEmployerByIco,
  getEmploymentDTI,
  patchMonthlyIncome,
  postMonthlyIncome,
} from "@/services/brokerService";
import ClientEmploymentDietyHandler from "../ClientEmploymentDiety/ClientEmploymentDietyHandler";
import ClientEmploymentRestHandler from "../ClientEmploymentRest/ClientEmploymentRestHandler";
import SidloInput from "../../../SidloInput";
import MonthlyAmountInput from "@/components/shared/PopupHandler/Popups/ClientIncomes/MonthlyAmountInput.vue";

export default {
  name: "EmploymentForm",
  components: {
    MonthlyAmountInput,
    SidloInput,
    ClientEmploymentRestHandler,
    ClientEmploymentDietyHandler,
    DropdownSelect
  },
  props: {
    employmentDefined: Boolean,
    income: [Object, null],
    formTemplate: Object,
    metaData: [Object, null],
  },
  watch: {
    income(updatedIncome) {
      if (typeof updatedIncome.zamestnavatel === "object") {
        this.employerDetail = updatedIncome.zamestnavatel;
        this.ico = updatedIncome.zamestnavatel.ico;
      }
    },
    'form.zamestnanyOd'(newFrom) {
      if (!["pracovny_pomer_na_neurcito", "pracovny_pomer_na_neurcito"].includes(this.form.druh)) return;
      if (this.form.druh === "pracovny_pomer_na_neurcito") {
        const toDate = new Date();
        const fromDate = new Date(newFrom);
        const differenceMonths = this.monthDiff(fromDate, toDate);
        this.shortIncome = differenceMonths < 6;
      } else {
        const fromDate = new Date(newFrom);
        const toDate = new Date(this.form.zamestnanyDo);
        const differenceMonths = this.monthDiff(fromDate, toDate);
        this.shortIncome = differenceMonths < 3;
      }
    },
    'form.zamestnanyDo'(newFrom) {
      if (!["pracovny_pomer_na_neurcito", "pracovny_pomer_na_neurcito"].includes(this.form.druh)) return;
      const fromDate = new Date(this.form.zamestnanyOd);
      const toDate = new Date(newFrom);
      const differenceMonths = this.monthDiff(fromDate, toDate);
      this.shortIncome = differenceMonths < 3;
    },
    metaData(meta) {
      if (meta.hasOwnProperty("diety")) {
        this.diety = meta.diety;
        this.mergeDietsToMonthlyIncomes();
      } else if (meta.hasOwnProperty("rest")) {
        this.restMultiselect = meta.rest;
      }
    }
  },
  created() {
    this.restMultiselect[4].dropdownValue.choices = this.formTemplate.mena.choices;
    if (this.income) {
      // console.log(this.income);
      this.form.druh = this.income.druh;
      this.form.zamestnanyOd = this.income.zamestnanie_od;
      this.form.zamestnanyDo = this.income.zamestnanie_do;
      this.form.emailZamestnavatel = this.income.zamestnavatel_email;
      this.form.telefonZamestnavatel = this.income.zamestnavatel_telefon;
      this.form.pracovneZaradenie = this.income.pracovne_zaradenie;
      this.form.profesia = this.income.profesia;
      this.form.monthlyIncomes = [...this.income.mesacne_prijmy];
      this.form.monthlyIncomes = this.sortIncomes(this.form.monthlyIncomes);
      // this.splitMonthlyIncomes(this.income.mesacne_prijmy);
      this.fillEmptyDateBlocks(this.form.monthlyIncomes);
      // this.fillEmptyDateBlocks(this.diety);
      this.form.mena = this.income.mena;
      this.setOstatne();
      if (this.income.zamestnavatel && typeof this.income.zamestnavatel === "object") {
        const empl = this.income.zamestnavatel;
        this.employerDetail.name = empl.name;
        this.employerDetail.id = empl.id;
        this.ico = empl.ico;
        if (empl.slovensko_digital_response) {
          this.employerDetail.slovensko_digital_response.formatted_address =
            empl.slovensko_digital_response.formatted_address;
        } else {
          this.sidloInputNeed = false;
          this.sidloInputFormattedValue = `${empl.address_street} ${empl.address_popisne}, ${empl.address_city} ${empl.address_zip}`;
        }
      }
      this.form.odvetvie = this.income.zamestnavatel_odvetvie;
      if (!["pracovny_pomer_na_neurcito", "pracovny_pomer_na_neurcito"].includes(this.form.druh)) {
        this.form.dovodKratsejDobyPrijmu = null;
      } else {
        this.form.dovodKratsejDobyPrijmu = this.income.dovod_kratsej_doby_prijmu;
      }
      this.showEstimatedDtiToUser();
      let toDate = null;
      let fromDate = null;
      if (this.form.druh === "pracovny_pomer_na_urcito") {
        fromDate = new Date(this.form.zamestnanyOd);
        toDate = new Date(this.form.zamestnanyDo);
      }
      if (toDate && fromDate) {
        const differenceMonths = this.monthDiff(fromDate, toDate);
        this.shortIncome = differenceMonths < 3;
      }
    } else {
      this.fillEmptyDateBlocks(this.form.monthlyIncomes);
      // this.fillEmptyDateBlocks(this.diety);
    }
  },
  data: () => ({
    monthlyAmountAveragesFromInput: null,
    required: true,
    dtiLoading: false,
    sidloInputNeed: true,
    sidloInputFormattedValue: null,
    employerAddress: {
      address_country: null,
      address_county: null,
      address_district: null,
      address_city: null,
      address_street: null,
      address_number: null,
      address_popisne: null,
      address_zip: null,
    },
    form: {
      druh: null,
      zamestnanyOd: null,
      zamestnanyDo: null,
      emailZamestnavatel: null,
      telefonZamestnavatel: null,
      pracovneZaradenie: null,
      ostatne: [],
      odvetvie: null,
      profesia: null,
      monthlyIncomes: [],
      mena: null,
      dovodKratsejDobyPrijmu: null,
    },
    employerDetail: {
      name: null,
      id: null,
      slovensko_digital_response: {
        formatted_address: null,
      },
    },
    shortIncome: false,
    // diety: [],
    // monthlyIncomesMerged: [],
    ico: null,
    estimatedDti: null,
    typeOfEmploymentSelect: [
      {
        display_name: "pracovný pomer na určito",
        value: "pracovny_pomer_na_cas_urcity",
      },
      {
        display_name: "pracovný pomer na neurčito",
        value: "pracovny_pomer_na_neurcito",
      },
      {
        display_name: "dohoda o pracovnej činnosti",
        value: "dohoda_o_pracovnej_cinnosti",
      },
      {
        display_name: "pracovný pomer vo vlastnej firme",
        value: "pracovny_pomer_vo_vlastnej_firme",
      },
      {
        display_name: "pracovný pomer cez agentúru",
        value: "pracovny_pomer_cez_agenturu",
      },
    ],
    restMultiselect: [
      {
        display_name: "prechod zo živnosti na zamestnanie",
        value: false,
        dbProperty: "prechod_zivnost_tpp_poslednych_6m",
      },
      {
        display_name: "výpovedná skúšobná doba",
        value: false,
        dbProperty: "vypovedna_skusobna_doba",
      },
      {
        display_name: "zastupovanie na materskej",
        value: false,
        dbProperty: "zastupovanie_na_materskej",
      },
      {
        display_name: "obnovená pracovná zmluva",
        value: false,
        dbProperty: "zmluva_obnovena",
      },
      {
        display_name: "zahraničný príjem",
        value: false,
        dbProperty: "mena",
        dropdownValue: {
          id: 'mena',
          title: 'Mena',
          choices: null,
          default: "EUR",
          value: null,
        }
      },
    ],
    firstTime: true,
    date: new Date().toISOString().substr(0, 10),
    max: new Date().toISOString().substr(0, 10),
  }),
  methods: {
    calculateAverage(event, months) {
      this.monthlyAmountAveragesFromInput = {
        amount: event.target.valueAsNumber,
        months,
      };
    },
    setAddress(addressObject) {
      this.employerAddress.address_country = addressObject.address_text_country;
      this.employerAddress.address_county = addressObject.address_text_county;
      this.employerAddress.address_district = addressObject.address_text_district;
      this.employerAddress.address_city = addressObject.address_text_city;
      this.employerAddress.address_street = addressObject.address_text_street;
      this.employerAddress.address_number = addressObject.address_text_number;
      this.employerAddress.address_popisne = addressObject.address_text_popisne;
      this.employerAddress.address_zip = addressObject.address_text_zip;
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    // checkForShortIncomeDuration(employeeFrom) {
    //   console.log(employeeFrom);
    //   if (!employeeFrom) return;
    //   const currentMonth = new Date().getMonth() + 1;
    //   const selectedMonth = parseInt(employeeFrom.substr(5, 6));
    //   this.shortIncome = monthDiff(currentMonth,) < 3;
    // },
    sortIncomes(objects) {
      return objects.sort(function(a, b) {
        const yearA = a.rok;
        const monthA = a.mesiac;
        const yearB = b.rok;
        const monthB = b.mesiac;
        const dateA = new Date(yearA, monthA);
        const dateB = new Date(yearB, monthB);
        return dateB - dateA;
      });
    },
    fillEmptyDateBlocks(pDateBlocks) {
      const currentDate = new Date();
      let dateBlocks = pDateBlocks;
      if (dateBlocks.length === 0) {
        // If there were empty array first will be initialized so then the rest would be filled by call of checkForMissingBlocks
        const dateObject = {
          income: null,
          mesiac: currentDate.getMonth(),
          rok: currentDate.getFullYear(),
          diety: null,
          suma: null,
        }
        dateBlocks.push(dateObject)
      }
      // This call ensure that when new month comes, missing month would be fulfilled
      this.fillLastMissingMonth(dateBlocks, currentDate);
      if (dateBlocks.length < 3) {
        // If there were less than 3 blocks then the rest will be filled by this call
        this.fillMissingToLimit(dateBlocks, 3 - dateBlocks.length);
        return;
      }
      // In case there would be object which would have missing months in between e.g. month:8 year:2022 next closest month:3 year:2022
      this.checkForMissingBlocks(dateBlocks);
    },
    fillMissingToLimit(dateBlocks, missing) {
      for (let i = 0; i < missing; i++) {
        const latest = dateBlocks[dateBlocks.length - 1];
        dateBlocks.push({
          income: latest.income,
          mesiac: latest.mesiac - 1 === 0 ? 12 : latest.mesiac - 1,
          rok: latest.mesiac - 1 === 0 ? latest.rok - 1 : latest.rok,
          diety: null,
          suma: null,
        });
      }
    },
    fillLastMissingMonth(dateBlocks, currentDate) {
      if (currentDate.getMonth() > dateBlocks[0].mesiac || currentDate.getFullYear() > dateBlocks[0].rok) {
        dateBlocks.unshift({
          income: dateBlocks[0].income,
          mesiac: currentDate.getMonth(),
          rok: currentDate.getFullYear(),
          diety: null,
          suma: null,
        })
      }
    },
    checkForMissingBlocks(dateBlocks) {
      for (const [index, dateBlock] of dateBlocks.entries()) {
        const current = dateBlock;
        if (dateBlocks.length - 1 === index) break;
        const next = dateBlocks[index + 1];
        if (current.rok === next.rok && (current.mesiac - next.mesiac) === 1) continue;
        if (current.rok !== next.rok && current.mesiac === 1 && next.mesiac === 12) continue;
        dateBlocks.splice(index + 1, 0, {
          income: current.income,
          mesiac: current.mesiac - 1 === 0 ? 12 : current.mesiac - 1,
          rok: current.mesiac - 1 === 0 ? current.rok - 1 : current.rok,
          diety: null,
          suma: null,
        });
      }
    },
    splitMonthlyIncomes(incomes) {
      const copyOfIncomes = JSON.parse(
        JSON.stringify(incomes)
      ).reverse();
      for (const copyOfIncome of copyOfIncomes) {
        if (copyOfIncome.suma !== 0) {
          const suma = {...copyOfIncome};
          suma.diety = 0;
          suma.automated = false;
          this.form.monthlyIncomes.push(suma);
        }
        if (copyOfIncome.diety !== 0) {
          const dieta = {...copyOfIncome};
          dieta.suma = 0;
          this.diety.push(dieta);
        }
      }
    },
    mergeDietsToMonthlyIncomes() {
      const copyOfMonthlyIncomes = JSON.parse(
        JSON.stringify(this.form.monthlyIncomes)
      );
      for (const copyOfMonthlyIncome of copyOfMonthlyIncomes) {
        copyOfMonthlyIncome.diety = 0;
      }
      for (const dieta of this.diety) {
        for (const [index, monthlyIncome] of copyOfMonthlyIncomes.entries()) {
          if (dieta.mesiac === monthlyIncome.mesiac && dieta.rok === monthlyIncome.rok) {
            dieta.dietyOnly = false;
            monthlyIncome.diety = dieta.diety;
            break;
          }
          if (index === copyOfMonthlyIncomes.length - 1) {
            dieta.dietyOnly = true;
            copyOfMonthlyIncomes.push(dieta);
            break;
          }
        }
      }
      this.monthlyIncomesMerged = copyOfMonthlyIncomes;
    },
    setOstatne() {
      if (this.income.prechod_zivnost_tpp_poslednych_6m) {
        this.restMultiselect[0].value = true;
      }
      if (this.income.vypovedna_skusobna_doba) {
        this.restMultiselect[1].value = true;
      }
      if (this.income.zastupovanie_na_materskej) {
        this.restMultiselect[2].value = true;
      }
      if (this.income.zmluva_obnovena) {
        this.restMultiselect[3].value = true;
      }
      if (this.income.mena !== "EUR") {
        this.restMultiselect[4].value = true;
        this.restMultiselect[4].dropdownValue.value = this.income.mena;
      }
    },
    async initializeEmployer() {
      if (this.restMultiselect[4].value) {
        return;
      }
      if (this.ico === "") {
        this.employerDetail = {
          slovensko_digital_response: {
            formatted_address: null,
          },
        };
      } else {
        try {
          await getEmployerByIco({ico: this.ico}).then((employer) => {
            this.employerDetail = employer;
          });
        } catch (e) {
          this.employerDetail = {
            slovensko_digital_response: {
              formatted_address: null,
            },
          };
          this.$store.commit("appState/setSuccessMessage", {
            text: "Zamestnávateľ podľa iča nebol nájdený",
            type: "error"
          });
        }
      }
    },
    async showEstimatedDtiToUser() {
      this.dtiLoading = true;
      const arrayOfMonthlyIncomeAmount = this.getArrayOfMonthlyIncomesAmount();
      this.estimatedDti = null;
      const dtiInfo = await getEmploymentDTI(arrayOfMonthlyIncomeAmount);
      let dtiValue = 0;
      for (const value of Object.entries(dtiInfo)) {
        for (const valueElement of value) {
          if (this.estimatedDti < valueElement.value) {
            dtiValue = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
      this.dtiLoading = false;
    },
    getArrayOfMonthlyIncomesAmount() {
      let arrayOfAmounts = [];
      for (const income of this.form.monthlyIncomes) {
        arrayOfAmounts.push({suma: income.suma ? income.suma : 0});
      }
      return arrayOfAmounts;
    },
    setDropdownValue(value, dropdownName) {
      if (dropdownName === "druh") {
        if (!this.firstTime) {
          this.form.zamestnanyOd = null;
          this.form.zamestnanyDo = null;
        }
        this.firstTime = false;
      }
      this.form[dropdownName] = value;
    },
    translateDateFromDbValue(dateToTransform) {
      if (!dateToTransform) return null;
      let dateArray = dateToTransform.split("-");
      dateArray.pop();
      return dateArray.join("-");
    },
    mergeRestToIncome(incomeObject) {
      for (const restValue of this.restMultiselect) {
        if (restValue.hasOwnProperty("dropdownValue")) {
          incomeObject[restValue.dbProperty] = restValue.value ? restValue.dropdownValue.value : restValue.dropdownValue.default;
        } else {
          incomeObject[restValue.dbProperty] = restValue.value;
        }
      }
    },
    async getEmployerId() {
      if (this.restMultiselect[4].value) {
        const employerObject = {
          ico: this.ico,
          name: this.employerDetail.name,
          ...this.employerAddress
        }
        const employer = await createEmployer(employerObject);
        // console.log(employer);
        return employer.id;
      }
      return this.employerDetail.id;
    },
    async saveIncome() {
      let incomeObject = {
        typ: "hlavny",
        druh: this.form.druh,
        zamestnanie_od: this.form.zamestnanyOd,
        zamestnanie_do: this.form.zamestnanyDo,
        profesia: this.form.profesia,
        pracovne_zaradenie: this.form.pracovneZaradenie,
        zamestnavatel_email: this.form.emailZamestnavatel,
        zamestnavatel_telefon: this.form.telefonZamestnavatel,
        zamestnavatel_odvetvie: this.form.odvetvie,
        zamestnavatel: await this.getEmployerId(),
        dovod_kratsej_doby_prijmu: this.shortIncome ? this.form.dovodKratsejDobyPrijmu : null,
      };
      this.mergeRestToIncome(incomeObject);
      // this.mergeDietsToMonthlyIncomes();
      if (this.income) {
        incomeObject.id = this.income.id;
        incomeObject.mesacne_prijmy = await this.handleMonthIncomesChange();
      }
      this.$emit("income-saved", {
        incomeInfo: incomeObject,
        monthlyIncomes: this.form.monthlyIncomes,
      });
    },
    async handleMonthIncomesChange() {
      let oldMonthIncomes = JSON.parse(
        JSON.stringify(this.income.mesacne_prijmy)
      );
      for (const monthIncomeOld of this.form.monthlyIncomes) {
        for (const [index, monthIncomeNew] of oldMonthIncomes.entries()) {
          if (monthIncomeOld.id === monthIncomeNew.id) {
            oldMonthIncomes.splice(index, 1);
          }
        }
      }
      for (const oldMonthIncome of oldMonthIncomes) {
        await deleteMonthlyIncome(oldMonthIncome.id);
      }
      const monthlyIncomes = [];
      for (const monthIncome of this.form.monthlyIncomes) {
        if (!monthIncome.id) {
          monthIncome.income = this.income.id;
          const monthIncomeObject = await postMonthlyIncome(monthIncome);
          monthlyIncomes.push(monthIncomeObject);
        } else if (monthIncome.id && !monthIncome.automated) {
          const monthIncomeObject = await patchMonthlyIncome(monthIncome, monthIncome.id);
          monthlyIncomes.push(monthIncomeObject);
        }
      }
      return monthlyIncomes;
    },
    addMonth () {
      const incomeDate = this.getNextIncomeDate();
      const incomeMonth = {
        automated: false,
        income: null,
        id: null,
        mesiac: incomeDate.mesiac,
        rok: incomeDate.rok,
        suma: null,
        diety: 0,
      };
      this.form.monthlyIncomes.push(incomeMonth);
    },
    getMonth() {
      const incomeDate = this.getNextIncomeDate();
      return {
        automated: false,
        income: null,
        id: null,
        mesiac: incomeDate.mesiac,
        rok: incomeDate.rok,
        suma: 0,
        diety: 0,
      };
    },
    getNextIncomeDate() {
      const lastMonthInArray =
        this.form.monthlyIncomes[this.form.monthlyIncomes.length - 1].mesiac;
      const lastYearInArray =
        this.form.monthlyIncomes[this.form.monthlyIncomes.length - 1].rok;
      if (lastMonthInArray === 1) {
        return {
          mesiac: 12,
          rok: lastYearInArray - 1,
        };
      } else {
        return {
          mesiac: lastMonthInArray - 1,
          rok: lastYearInArray,
        };
      }
    },
    getTodayDate() {
      const today = new Date();
      const dd = today.getDate();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + (dd < 10 ? `0${dd}` : dd);
    },
    getJobClass(e) {
      switch (e) {
        case "top_management":
          return "Top manažment";
        case "stredny_management":
          return "Stredný manažment";
        case "ostatni_zamestnanci":
          return "Ostatní zamestnanci";
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
input[type=month]::-webkit-input-placeholder {
  display: none;
}

.error-text {
  font-size: 11px;
}

.error-custom {
  font-size: 12px;
  padding-left: 15px;
  color: red;
}

.invalid-input {
  border: 1px solid red;
}

.date-picker-wrapper {
  margin-top: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.dropdown-margin {
  margin-bottom: 20px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rest-input {
  margin-top: 10px;
}

.datepicker-wrapper {
  border-radius: 5px;
  position: absolute;
  width: 75%;
  z-index: 2;
  left: 60px;
  border: 1px solid black;
}

.datepicker-item {
  margin: 0 !important;
  z-index: 1;
}

.date-input:hover {
  cursor: pointer;
}

.popup-input-wrapper {
  padding: 15px 20px 15px 20px;
}

//.checkbox-employment-type >>> label {
//  color: black;
//  line-height: 1.5;
//  font-size: 13px;
//}
</style>
