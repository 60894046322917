<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Priemerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <div class="input-group">
        <label for="najomne" class="field-label"
        >Výška mesačného nájomného<strong></strong></label
        ><input
        @change="showEstimatedDtiToUser"
        v-model.number="form.suma"
        type="number"
        step="0.01"
        class="text-input w-input required"
        maxlength="256"
        name="najomne"
        data-name="najomne"
        placeholder=""
        id="najomne"
        required=""
      />
      </div>
      <div
        class="input-group"
      >
        <label for="prijem-od" class="field-label"
        >Neprerušený príjem nájmu od</label
        ><input
        @keydown.tab.prevent
        v-model="form.prijem_od"
        type="month"
        :max="getTodayDate()"
        class="text-input w-input date-input required"
        maxlength="256"
        name="prijem-od"
        data-name="prijem-od"
        id="prijem-od"
        required
      />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'prijem_zdaneny')"
          :id="'rental_prijem_zdaneny'"
          :init-value="form.prijem_zdaneny"
          :options="taxedChoices"
          normal
          :name="'Bol príjem už zdanený?'"
        />
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import { getRentDTI } from "@/services/brokerService";

export default {
  name: "RentalIncome",
  components: {DropdownSelect},
  props: {
    income: [Object, null],
    formTemplate: Object,
  },
  created () {
    if (this.income) {
      this.form.suma = this.income.suma;
      this.form.prijem_od = this.translateDateFromDbValue(this.income.zamestnanie_od);
      this.form.prijem_zdaneny = this.income.zdanenie_minuly_rok;
      this.showEstimatedDtiToUser();
    }
  },
  methods: {
    saveIncome () {
      const incomeObject = {
        typ: "vedlajsi",
        druh: "prijem_z_prenajmu_nehnutelnosti",
        suma: this.form.suma,
        zamestnanie_od: this.translateDateToDbValue(this.form.prijem_od),
        zdanenie_minuly_rok: this.form.prijem_zdaneny,
      };
      if (this.income) {
        incomeObject.id = this.income.id;
      }
      // console.log("A", incomeObject);
      this.$emit("income-saved", {incomeInfo: incomeObject});
    },
    async showEstimatedDtiToUser () {
      this.dtiLoading = true;
      const incomeData = {
        suma: this.form.suma,
      };
      this.estimatedDti = null;
      if (this.checkValue(incomeData.suma)) {
        const dtiData = await getRentDTI(incomeData);
        this.pickHighestDti(dtiData);
      }
      this.dtiLoading = false;
    },
    checkValue(value) {
      return !(value === undefined || value === null || value === "");
    },
    pickHighestDti (dtiData) {
      let valueDti = 0;
      for (const value of Object.entries(dtiData)) {
        for (const valueElement of value) {
          if (valueDti < valueElement.value) {
            valueDti = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
    },
    translateDateFromDbValue (dateToTransform) {
      if (!dateToTransform) return null;
      let dateArray = dateToTransform.split("-");
      dateArray.pop();
      return dateArray.join("-");
    },
    translateDateToDbValue (dateToTransform) {
      if (!dateToTransform) return null;
      return dateToTransform + "-01";
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
    getTodayDate () {
      const today = new Date();
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm;
    },
  },
  data () {
    return {
      dtiLoading: false,
      estimatedDti: null,
      form: {
        suma: null,
        typ: "vedlajsi",
        druh: "prijem_z_prenajmu_nehnutelnosti",
        prijem_od: null,
        prijem_zdaneny: null,
      },
      taxedChoices: [
        {
          display_name: "áno",
          value: true,
        },
        {
          display_name: "nie",
          value: false,
        },
      ],
      max: new Date().toISOString().substr(0, 7),
    };
  },
};
</script>

<style scoped lang="scss">
.error-text {
  font-size: 11px;
}

.v-text-field--outlined label {
  left: 7px !important;
  color: rgba(10, 25, 83, 0.5) !important;
}

.v-text-field--outlined fieldset {
  border-radius: 10px !important;
  border-width: 1px !important;
  border-color: hsla(0, 0%, 80.8%, 0.4) !important;
}

.datepicker-wrapper {
  border-radius: 5px;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
}

.datepicker-item {
  margin: 0 !important;
  z-index: 1;
}

.date-input[readonly] {
  background: #fff;
}

.date-input:hover {
  cursor: pointer;
}

.icon-add-date {
  top: 28px;
  right: 15px;
  position: absolute;
  width: 27px;
  opacity: 0.6;
}
</style>
