<template>
  <div class="content-panel">
    <div class="bcg-loading" v-if="loading">
      <v-progress-circular
        class="loading-spinner"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <handlers-wrapper-button :handlers="getHandlers()"/>
      <div class="statistics">
        <div class="stat-bar-filter">
          <hypoteky-filters-bar
            @period-filter-change="filterForPeriod($event)"
            @broker-filter-change="filterForBroker($event)"
            @filter-client="filterMortgages($event)"/>
          <hypoteky-stats/>
        </div>
      </div>
      <hypoteky-items-wrapper/>
    </div>
  </div>
</template>

<script>
import HypotekyStats from "../../../components/broker/KartaHypoteky/KartaHypotekyOverview/HypotekyStats";
import HypotekyItemsWrapper from "../../../components/broker/KartaHypoteky/KartaHypotekyOverview/HypotekyItemsWrapper";
import MortgageAddHandler from "../../../components/shared/PopupHandler/Popups/MortgageAdd/MortgageAddHandler";
import HypotekyFiltersBar from "../../../components/broker/KartaHypoteky/KartaHypotekyOverview/HypotekyFiltersBar";
import HandlersWrapperButton from "@/components/shared/PopupHandler/HandlerWrapper/HandlersWrapperButton.vue";

export default {
  name: "KartaHypoteky",
  components: {
    HandlersWrapperButton,
    HypotekyFiltersBar,
    HypotekyItemsWrapper,
    HypotekyStats,
  },
  methods: {
    getHandlers() {
      return [
        {
          component: "ObjectsRemoveHandler"
        },
        {
          component: "MortgageAddHandler",
          classCst: "button-item-handler white&#45;&#45;text no-decoration",
          button: true,
          rules: ['priority']
        },
      ]
    },
    filterForPeriod(period) {
      this.$store.commit("mortgagesState/setMortgagesFilter", {
        key: "created__lt",
        value: period.lt?.split(" ").join(""),
      });
      this.$store.commit("mortgagesState/setMortgagesFilter", {
        key: "created__gte",
        value: period.gte?.split(" ").join(""),
      });
      this.$store.dispatch("mortgagesState/fetchMortgages");
    },
    filterForBroker(broker) {
      this.$store.commit("mortgagesState/setMortgagesFilter", {
        key: "financnik",
        value: broker,
      });
      this.$store.commit("appState/setLoadingPage", true);
      this.$store.dispatch("mortgagesState/fetchMortgages");
    },
    filterMortgages(key) {
      this.$store.commit("mortgagesState/setMortgagesFilter", {
        key: "search",
        value: key,
      });
      this.$store.commit("appState/setLoadingPage", true);
      this.$store.dispatch("mortgagesState/fetchMortgages");
    },
  },
  async created () {
    this.loading = true;
    this.role = this.$store.getters["userState/getRole"];
    if (this.role !== "alex_admin") {
      this.brokerId = this.$store.getters["userState/getId"];
      this.$store.commit("mortgagesState/setMortgagesFilter", {
        key: "financnik",
        value: this.brokerId,
      });
    }
    await this.$store.dispatch("mortgagesState/initializeMortgages");
    this.loading = false;
    this.mortgagesInterval = setInterval(async () => {
      this.$store.dispatch("mortgagesState/fetchMortgages");
    }, 30000);
  },
  beforeDestroy () {
    // this.$store.commit("refreshMortgageDetailData");
    this.$store.commit("mortgagesState/resetMortgagesFilters");
    clearInterval(this.mortgagesInterval);
  },
  data: () => ({
    mortgagesInterval: null,
    loading: true,
    brokerId: null,
    role: null,
  }),
};
</script>

<style lang="scss" scoped>
.bcg-loading {
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
