<template>
  <div>
    <div class="d-flex justify-content-between">
      <span>O hypotéke</span>
      <img
        src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
        class="activate-button nav-link-icon filter-primary"
        :class="{'rotate': opened}"
        @click="opened = !opened"
        alt=""
      />
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'hlavný účel'" :value="mainPurpose"/>
        <text-value-holder :label="'vedľajší účel'" :value="secondaryPurpose"/>
        <text-value-holder :label="'výška úveru'" :value="totalAmount"/>
        <text-value-holder :label="'splatnosť'" :value="summaryData.splatnost + 'r'"/>
        <text-value-holder :label="'fix'" :value="summaryData.fix"/>
        <text-value-holder :label="'deň splátky'" :value="'nedorobené'"/>
        <text-value-holder :label="'čerpanie'" :value="'nedorobené'"/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import mortgageUtils from "@/mixins/mortgageUtils";

export default {
  name: "MortgageSummary",
  components: {TextValueHolder},
  props: {
    summaryData: Object,
    openedDefault: Boolean,
  },
  mixins: [
    mortgageUtils
  ],
  created() {
    this.opened = this.openedDefault;
    this.totalAmount = this.summaryData.suma_nadobudnutie + this.summaryData.suma_refinancovanie + this.summaryData.suma_bezucel;
    let amountsArray = [
      {
        type: "nadobudnutie",
        amount: this.summaryData.suma_nadobudnutie,
      },
      {
        type: "refinancovanie",
        amount: this.summaryData.suma_refinancovanie,
      },
      {
        type: "bezúčel",
        amount: this.summaryData.suma_bezucel,
      }
    ];
    const sorted = amountsArray.sort((a, b) => a - b);
    this.mainPurpose = sorted[0].type;
    this.secondaryPurpose = sorted[1].type;
  },
  data: () => ({
    opened: false,
    mainPurpose: null,
    secondaryPurpose: null,
    totalAmount: 0,
  }),
}
</script>

<style scoped>

</style>
