<template>
  <div>
        <!-- vytvorenie nového temp klienta -->
        <div
      v-if="(complex || formOnly) && !newTempClient"
      class="input-block pointer"
      @click="createNewClient()"
    >
      vytvorte nového
    </div>
    <!-- novo vytvorený templ klient  -->
    <div
      v-else-if="(complex || formOnly) && newTempClient"
    >
      <div class="result-client">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          class="image-10"
        >
        <div class="resultContent">
          <div class="light-text lightgrey">Klient</div>
          <div class="_900 big">
            <user-manager-handler
              :style-cst="'padding: 0;'"
              :client-info="newTempClient"
              client
            />
          </div>
          <div class="d-flex justify-content-start">
            <div
              class="bl-color pop-600 mr-2 pointer"
              style="font-size: 10px;"
              @click.self="saveClient(newTempClient)"
            >
              {{ clientPickedId === newTempClient.id ? 'ZRUŠIŤ' : !zalozit ? 'PRIDAŤ' : 'ZALOŽIŤ HYPOTÉKU' }}
            </div>
            <div
              class="bl-color pop-600 pointer"
              style="font-size: 10px;"
              @click.self="editTempClient()"
            >
              UPRAVIŤ
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- hľadanie existujúceho klienta -->
    <div v-if="complex || searchOnly">
      <div class="input-block">
        alebo vyberte z exitujúcich
        <div class="horizontal-diveder"></div>
        <div class="search-group">
          <label for="Vyh-ad-vanie" class="field-label"
          >zadajte</label
          >
          <input
            @change="searchForClient"
            v-model="clientKeySearch"
            type="text"
            class="text-input w-input"
            maxlength="256"
            name="Vyh-ad-vanie"
            data-name="Vyhľadávanie"
            placeholder="meno"
            id="Vyh-ad-vanie"
          /><img
          loading="lazy"
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f49_icons8-browse-folder.svg"
          alt=""
          class="nav-link-icon small filter-primary"
        />
        </div>
      </div>
      <div class="splitter clientError noselect" v-if="errorSearchFail">
        nebol nájdený žiadny klient
      </div>
      <div
        class="result-client"
        v-for="(client, key) in clientFound"
        v-if="!clientPickedId || clientPickedId === client.id"
        :key="key"
      >
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          class="image-10"
        >
        <div class="resultContent">
          <div class="light-text lightgrey">klient</div>
          <div class="_900 big">
            <user-manager-handler
              :style-cst="'padding: 0;'"
              :client-info="client"
              client
            />
          </div>
          <div
            class="bl-color pop-600 pointer"
            style="font-size: 10px;"
            @click.self="saveClient(client)"
          >
            {{ clientPickedId === client.id ? 'ZRUŠIŤ' : !zalozit ? 'PRIDAŤ' : 'ZALOŽIŤ' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createTempUser,
  getClientById,
  getClients,
} from "@/services/brokerService";
import UserManagerHandler from "../UserManager/UserManagerHandler";
import { mapGetters } from "vuex";

export default {
  name: "ClientPicker",
  components: {
    UserManagerHandler
  },
  computed: {
    ...mapGetters({
      pickerRefresh: "popupManagerState/getPickerRefresh"
    }),
  },
  watch: {
    async pickerRefresh (refresh) {
      if (!refresh) return;
      this.newTempClient = await getClientById(this.newTempClient.id, "client_profile");
      this.$store.commit("popupManagerState/setPickerLoad", false);
    },
  },
  props: {
    clientInit: [Number, null],
    formOnly: Boolean,
    searchOnly: Boolean,
    complex: Boolean,
    zalozit: Boolean,
  },
  data: () => ({
    clientPickedId: null,
    errorSearchFail: false,
    clientKeySearch: null,
    clientFound: [],
    newTempClient: null,
    emailMatchingClients: [],
  }),
  async created() {
    if (!this.clientInit) return;
    this.newTempClient = await getClientById(this.clientInit, "client_profile");
    this.clientPickedId = this.newTempClient.id;
  },
  methods: {
    editTempClient () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.newTempClient,
        component: "BasicClientInformationPopUp",
        rules: ['single'],
      });
    },
    async createNewClient () {
      const newTempClientId = (await createTempUser()).data.id;
      this.newTempClient = await getClientById(newTempClientId, "client_profile");
    },
    async searchForClient () {
      this.errorSearchFail = false;
      if (this.clientKeySearch === "") {
        this.clientFound = [];
        return;
      }
      const matchedClients = await getClients(
        {
          search: this.clientKeySearch,
          page_size: 5,
          expand: "client_profile"
        },
      );
      if (matchedClients.count === 0) this.errorSearchFail = true;
      this.clientFound = matchedClients.results;
    },
    saveClient (client) {
      if (this.clientPickedId === client.id) {
        this.clientPickedId = null;
        this.$emit("client-picked", null);
      } else {
        this.clientPickedId = client.id;
        this.$emit("client-picked", client);
      }
    },
  },
};
</script>


<style lang="scss" scoped>

.selected-client {
  border-style: none none none solid;
  border-color: #0a1953 #0a1953 #0a1953 #0019d9;
  border-left-width: 10px;
}

.fill-red {
  fill: red;
}

.selected-client-cancel {
  display: none;
}

.div-block-141:hover .selected-client-cancel {
  display: block;
}

</style>
