<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">{{ translateEventType(payload.event.type) }}</div>
        <button v-if="sliceZeros(payload.event.start.value) !== sliceZeros(eventData.start)" class="w-button" form="event-changes">Uložiť</button>
      </div>
      <div class="client-name-block">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
        <div class="div-block-28">
          <div class="light-text lightgrey">
            Klient
            <div class="blc-color">
              <user-manager-handler :rules="['single']" :client-info="payload.client" client/>
            </div>
          </div>
        </div>
      </div>
      <form id="event-changes" @submit.prevent="saveEvent">
        <div
          class="input-group mt-2"
        >
          <label for="podnikamOd" class="field-label"
          >Termín podpisu</label
          ><input
          type="datetime-local"
          v-model="eventData.start"
          :min="todayLong"
          class="text-input w-input date-input"
          maxlength="256"
          name="podnikamOd"
          data-name="podnikamOd"
          id="podnikamOd"
          required
        />
        </div>
      </form>
<!--        <div class="div-block-28">-->
<!--          <div class="light-text lightgrey">-->
<!--            Termín:-->
<!--          </div>-->
<!--          <div class="blc-color pop-700">-->
<!--            {{ transformDate() }}-->
<!--          </div>-->
<!--        </div>-->
      <div class="non-input-block">
        <div v-if="payload.event.type === 'online_konzultacia'">
          <div class="small-text light">
            Video call:
          </div>
          <a
            class="pop-700 blc-color font-size-16 overflow-hidden"
            :href="payload.event.daily_co_url"
            target="_blank"
          >
            {{ payload.event.daily_co_url }}
          </a>
        </div>
        <div v-else>
          <div class="small-text light">
            Iná adresa:
          </div>
          <a
            class="pop-900 blc-color font-size-16 pointer overflow-hidden"
            :href="'https://www.google.com/maps/place/?q=place_id:' + payload.event.meta?.placeId"
            target="_blank"
            v-if="payload.event.meta?.placeId"
          >
            Navigovať
          </a>
          <div
            v-if="payload.event.meta?.addressMeta"
            class="pop-700 blc-color font-size-14"
          >
            {{ getAddressModified(payload.event.meta.addressMeta) }}
          </div>
          <div v-else class="pop-700 blc-color font-size-16">
            Adresa {{ translateEventType(payload.event.type, 'u').toLowerCase() }} nebola špecifikovaná
          </div>
        </div>
      </div>
      <div class="section-title pointer noselect">
        <span @click="deleteEvent" class="bold bl-color">
          Vymazať udalosť
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import UserManagerHandler from "../UserManager/UserManagerHandler.vue";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "ExtendedViewPopUp",
  components: {UserManagerHandler},
  props: {
    payload: Object,
    type: Object,
  },
  mixins: [
    translateFromDBValue,
    propertyFilters,
  ],
  mounted() {
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-') + "T" + [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(':');
    this.eventData.start = this.payload.event.start.value;
  },
  watch: {
    'eventData.start'(newStart) {
      const date = new Date(newStart);
      date.setMinutes(date.getMinutes() + 30);
      date.setHours(date.getHours() + 1);
      this.eventData.end = date.toISOString().slice(0, 19);
    },
  },
  methods: {
    waitForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    showDialogToUser (event, messageText) {
      this.$store.commit("appState/setSuccessMessage", {
        text: messageText,
        type: "confirmation"
      });
    },
    transformDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: "numeric"
      });
    },
    async deleteEvent() {
      this.showDialogToUser();
      const userPermission = await this.waitForUserPermission();
      if (!userPermission) return;
      this.$root.$emit("event-delete", {event: this.payload.event, checkEventChange: false});
      this.closePopup();
    },
    isEventMovePossible(event) {
      if (this.eventData.start === this.payload.event.start.value) return false;
      const isFutureDate = event.start.value > this.todayFull;
      if (!isFutureDate) {
        this.$store.commit("appState/setSuccessMessage", {
          text: `Zadajte budúci dátum`,
          type: "error"
        });
        return false;
      }
      return true;
    },
    async saveEvent() {
      let newEventData = JSON.parse(JSON.stringify(this.payload.event));
      const messageText = `Naozaj chcete presunúť ${this.translateEventType(newEventData.type).toLowerCase()} na ${this.transformDate(this.eventData.start)}`
      this.showDialogToUser(newEventData, messageText);
      const userPermission = await this.waitForUserPermission();
      if (!userPermission) return;
      newEventData = {
        ...newEventData,
        start: this.eventData.start,
        end: this.eventData.end,
      };
      this.$store.dispatch("clientsEvents/moveEvent", newEventData);
      this.closePopup();
    },
    sliceZeros(start) {
      if (!start) return;
      return start.slice(0,16);
    },
    addZero (value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    todayLong: null,
    todayFull: null,
    eventData: {
      start: null,
      end: null,
    }
  }),
}
</script>

<style lang="scss" scoped>
.text-cta {
  cursor: default;
}

</style>