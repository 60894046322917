<template>
  <div class="popup-form">
    
    <div
      class="content-form-popup"
    >
      <div class="header-text">
          <div class="back-button-wrapper pointer" @click="closePopup">
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
          </div>
          <div class="blackcolor pop-800">Fixácia</div>
          <button
          class="w-button saveformBtn"
          @click="saveApplicationData"
          v-if="changed"
        >
          Uložiť
        </button>
      </div>

      <fixation-item-holder
        v-for="year in rok" :key="year"
        :title="'ROK'"
        :year="year"
        :preselected="fixationSelected"
        @fixation-toggle="handleFixationYearChange($event)"
      />
      <fixation-item-holder
        v-for="year in roky" :key="year"
        :title="'ROKY'"
        :year="year"
        :preselected="fixationSelected"
        @fixation-toggle="handleFixationYearChange($event)"/>
      <fixation-item-holder
        v-for="year in rokov" :key="year"
        :title="'ROKOV'"
        :year="year"
        :preselected="fixationSelected"
        @fixation-toggle="handleFixationYearChange($event)"/>
    </div>
  </div>
</template>

<script>
import FixationItemHolder from "./FixationItemHolder";
import RangeSlider from "../../../RangeSlider";

export default {
  name: "MaturityFixationChoosePopUp",
  components: {
    RangeSlider,
    FixationItemHolder
  },
  created () {
    const appData = this.$store.getters["mortgageDetailState/getOffersData"];
    this.fixationSelected = appData.fix;
    this.maturity = appData.splatnost_v_rokoch;
    this.max = Math.round(appData.maximalna_splatnost);
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
  },
  methods: {
    // setMaturity (maturity) {
    //   this.changed = true;
    //   this.maturity = maturity;
    // },
    createMortgageObjectHistory () {
      const currentHistory = this.mortgage.offers_filter_history;
      const applicationData = this.$store.getters["mortgageDetailState/getOffersData"];
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let newHistoryObject = {
        date: new Date().toISOString(),
        fix: applicationData.fix ? applicationData.fix : 3,
        suma_bezucel: amounts.suma_bezucel ? amounts.suma_bezucel : 0,
        suma_nadobudnutie: amounts.suma_nadobudnutie ? amounts.suma_nadobudnutie : 0,
        suma_refinancovanie: amounts.suma_refinancovanie ? amounts.suma_refinancovanie : 0
      };
      if (!currentHistory) {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              broker: {
                lastOffersSetup: null,
                history: [newHistoryObject],
              },
              sent: [],
              client: [],
            }
          },
        }
      } else {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              sent: currentHistory.sent,
              client: currentHistory.client,
              broker: {
                lastOffersSetup: currentHistory.broker?.lastOffersSetup ? currentHistory.broker.lastOffersSetup : null,
                history:
                  currentHistory.broker.history ? [newHistoryObject, ...currentHistory.broker.history] : Array.isArray(currentHistory.broker) && currentHistory.broker?.length !== 0 ? [newHistoryObject, ...currentHistory.broker] : [newHistoryObject],
              }
            }
          }
        }
      }
    },
    saveApplicationData () {
      this.$store.commit("mortgageDetailState/setOffersData", {
        maximalna_splatnost: this.max,
        fix: this.fixationSelected,
      });
      this.$store.dispatch("mortgageDetailState/fetchLimits", true);
      const mortgageObject = this.createMortgageObjectHistory();
      const historyObject = mortgageObject.object.offers_filter_history.broker;
      if (historyObject.length > 1) {
        const deepEqual = require("deep-equal");
        const newHistoryItem = JSON.parse(JSON.stringify(historyObject[0]));
        delete newHistoryItem.date;
        for (const [ind, object] of historyObject.entries()) {
          if (ind === 0) continue;
          const historyObjectCopy = JSON.parse(JSON.stringify(object));
          delete historyObjectCopy.date
          if (!deepEqual(historyObjectCopy, newHistoryItem, true)) continue;
          historyObject.splice(ind, 1);
        }
      }
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.closePopup();
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    handleFixationYearChange (fixationYear) {
      this.changed = true;
      this.fixationSelected = fixationYear;
    }
  },
  data: () => ({
    mortgage: null,
    changed: false,
    rok: [1],
    roky: [2, 3, 4],
    rokov: [5, 6, 7, 8, 10, 15, 20],
    fixationSelected: null,
    maturity: 1,
    max: null,
  }),
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute !important;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.prepo-et-slider {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
</style>
