<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      <div>
        Príjem <span class="pop-800">{{ summaryData.clientInfo.type.toLowerCase() }}a</span> zo <span class="pop-800">zamestnania</span>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'typ príjmu'" :value="summaryData.typ"/>
        <text-value-holder :label="'druh príjmu'" :value="'Zamestnanie'"/>
        <text-value-holder :label="'mesačný príjem'" :value="summaryData.dti.extra.prijem_mo"/>
        <text-value-holder :label="'diety mesačn. príjem'" :value="monthlyDiets"/>
        <text-value-holder :label="'zamestnávateľ'" :value="summaryData.zamestnavatel.name"/>
        <text-value-holder :label="'ičo'" :value="summaryData.zamestnavatel.ico"/>
        <text-value-holder :label="'obec'" :value="summaryData.zamestnavatel.address_city"/>
        <text-value-holder :label="'ulica'" :value="summaryData.zamestnavatel.address_street"/>
        <text-value-holder :label="'súpisné číslo'" :value="summaryData.zamestnavatel.address_popisne"/>
        <text-value-holder :label="'orientačné číslo'" :value="summaryData.zamestnavatel.address_number"/>
        <text-value-holder :label="'PSČ'" :value="summaryData.zamestnavatel.address_zip"/>
        <text-value-holder :label="'email. zamestn'" :value="summaryData.zamestnavatel.email"/>
        <text-value-holder :label="'telefon zamestn'" :value="summaryData.zamestnavatel.telefon"/>
        <text-value-holder :label="'prac. pomer na dobu'" :value="summaryData?.employment_type"/>
        <text-value-holder :label="'zamestnaný od'" :value="transformDate(summaryData.zamestnanie_od)"/>
        <text-value-holder :label="'zamestnaný do'" :value="transformDate(summaryData.zamestnanie_do)"/>
        <text-value-holder :label="'skúšobná/výpovedn. doba'" :value="summaryData.vypovedna_skusobna_doba"/>
        <text-value-holder :label="'profesia'" :value="$store.getters['formStore/getProfessionLabelById'](summaryData.profesia)"/>
        <text-value-holder :label="'zaradenie'" :value="summaryData.pracovne_zaradenie"/>
        <text-value-holder :label="'odvetvie'" :value="summaryData.zamestnavatel_odvetvie"/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import dateConverter from "@/mixins/dateConverter";

export default {
  name: "EmploymentSummary",
  components: {TextValueHolder},
  props: {
    summaryData: Object,
    openedDefault: Boolean,
  },
  mixins: [
    dateConverter,
  ],
  created() {
    this.opened = this.openedDefault;
    let diety = 0;
    let prijem = 0;
    for (const income of this.summaryData.mesacne_prijmy) {
      diety += income.diety;
      prijem += income.income;
    }
    const monthsCount = this.summaryData.mesacne_prijmy.length;
    this.monthlyDiets = diety / monthsCount;
    this.monthlyIncomes = prijem / monthsCount;
  },
  data: () => ({
    opened: false,
    monthlyIncomes: 0,
    monthlyDiets: 0,
  }),
}
</script>

<style scoped>

</style>
