import {
  deleteCalendarEvent,
  getCalendarEvents,
  getUserProfileByID,
  postCalendarEvents
} from "@/services/brokerService";
import translateFromDBValue from "../mixins/translateFromDBValue";

const deepEqual = require('deep-equal');

export default {
  namespaced: true,
  state: {
    firstTime: true,
    events: [],
    eventsUsers: [],
    otherUsersIds: [],
    calendarLoading: false,
    taskAcquired: false,
  },
  getters: {
    getFirstTime: (state) => {
      return state.firstTime;
    },
    getTaskAcquired: (state) => {
      return state.taskAcquired;
    },
    getEvents: (state) => {
      return state.events;
    },
    getEventsUsers: (state) => {
      return state.eventsUsers;
    },
    getCalendarLoading: (state) => {
      return state.calendarLoading;
    },
    otherUsersIdsIncludes: (state) => (id) => {
      return state.otherUsersIds.includes(id);
    },
    getEventUserById: (state) => (id) => {
      return state.eventsUsers.filter(e => e.id === id)[0];
    },
    eventUsersIncludesUserId: (state) => (id) => {
      const ids = state.eventsUsers.map(e => e.id);
      return ids.includes(id);
    },
    getEventsByUserIdAndOrType: (state) => (id, type) => {
      let events = state.events;
      if (type) {
        events = events.filter(e => e.type === type);
      }
      return events.filter(e => e.users.includes(id));
    }
  },
  mutations: {
    setFirstTime: (state, value) => {
      state.firstTime = value;
    },
    setTaskAcquired: (state, value) => {
      state.taskAcquired = value;
    },
    setEvents: (state, events) => {
      state.events = events;
    },
    setCalendarLoading: (state, loading) => {
      state.calendarLoading = loading;
    },
    setEventsUsers: (state, users) => {
      state.eventsUsers = [...state.eventsUsers, ...users];
    },
    setOtherUsersIds: (state, ids) => {
      state.otherUsersIds = [...state.otherUsersIds, ...ids];
    },
    removeEvent: (state, oldEventId) => {
      const updatedEvents = JSON.parse(JSON.stringify(state.events));
      const indexOfOldEvent = updatedEvents.map(e => e.id).indexOf(oldEventId);
      updatedEvents.splice(indexOfOldEvent, 1);
      state.events = updatedEvents;
    },
    addEvent: (state, eventsData) => {
      const updatedEvents = JSON.parse(JSON.stringify(state.events));
      updatedEvents.push(eventsData);
      state.events = updatedEvents;
    }
  },
  actions: {
    updateUserEvents: async ({commit, getters}) => {
      const eventsIdsBefore = getters["getEvents"].map(e => e.id);
      const events = await retrieveAllEvents(1);
      let usersIds = [];
      const usersObjects = [];
      const notClientIds = [];
      for (const event of events) {
        usersIds = [...usersIds, ...event.users];
      }
      usersIds = [...new Set(usersIds)];
      for (const userId of usersIds) {
        if (getters["otherUsersIdsIncludes"](userId)) continue;
        if (getters["eventUsersIncludesUserId"](userId)) continue;
        const client = await getUserProfileByID(userId)
        if (client.role !== "klient") {
          notClientIds.push(userId);
          continue;
        }
        usersObjects.push(client);
      }
      for (const event of events) {
        const ids = [];
        for (const id of event.users) {
          if (getters["otherUsersIdsIncludes"](id)) continue;
          ids.push(id);
        }
        event.users = ids;
      }
      const eventsIdsAfter = events.map(e => e.id);
      commit("setEvents", events);
      commit("setOtherUsersIds", notClientIds);
      commit("setEventsUsers", usersObjects);
      if (!getters["getFirstTime"] && eventsIdsBefore <= eventsIdsAfter) {
        commit("setTaskAcquired", !deepEqual(eventsIdsAfter, eventsIdsBefore, true));
      } else {
        commit("setFirstTime", false)
      }
    },
    createEvent: async ({commit}, newEventData) => {
      try {
        const event = await postCalendarEvents(newEventData.eventData);
        console.log(event);
        commit("setEventsUsers", [newEventData.client]);
        commit("addEvent", event);
        commit("appState/setSuccessMessage", {
          text: "Event bol úspešne vytvorený ste event",
          type: "success"
        }, {root: true});
      } catch(e) {
        commit("appState/setSuccessMessage", {
          text: "Pri vytváraní eventu nastala chyba",
          type: "error"
        }, {root: true});
      }
    },
    moveEvent: async ({commit}, movedEventData) => {
      try {
        const event = await postCalendarEvents(movedEventData);
        deleteCalendarEvent(movedEventData.id);
        event.users = event.users.filter(id => id !== movedEventData.users[movedEventData.length - 1]);
        const transformedUpdatedEvent = transformEvents([event])[0];
        commit("removeEvent", movedEventData.id);
        commit("addEvent", transformedUpdatedEvent);
        commit("appState/setSuccessMessage", {
          text: `${translateFromDBValue.methods.translateEventType(transformedUpdatedEvent.type)} je úspešne presunutý na ${transformDate(transformedUpdatedEvent.start)}`,
          type: "success"
        }, {root: true});
      } catch (e) {
        commit("appState/setSuccessMessage", {
          text: "Pri editácií času eventu nastala chyba",
          type: "error"
        }, {root: true});
      }
    },
    deleteEvent: async ({commit}, deletedId) => {
      try {
        await deleteCalendarEvent(deletedId);
        commit("removeEvent", deletedId);
        commit("appState/setSuccessMessage", {
          text: "Event bol úspešne odstránený",
          type: "success"
        }, {root: true});
      } catch(e) {
        commit("appState/setSuccessMessage", {
          text: "Event nebolo možné odstrániť",
          type: "error"
        }, {root: true});
      }
    }
  },
};
async function retrieveAllEvents(page) {
  const eventsObject = await getCalendarEvents(page)
  const eventCount = eventsObject.count;
  let events = eventsObject.results;
  if (eventCount > page * 10) {
    const nextPageEvents = await retrieveAllEvents(page + 1);
    events = [...events, ...nextPageEvents];
  }
  return events
}
function transformEvents(events) {
  const eventsArray = [];
  for (const event of events) {
    eventsArray.push({
      ...event,
      start: event.start.slice(0, 19),
      end: event.end.slice(0, 19),
      cssClass: "customcss",
      barColor: "#0019d9"
    })
  }
  return eventsArray;
}

function transformDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: "numeric"
  });
}