<template>
  <div class="popup-form">
      <div class="content-form-popup">
        <div class="header-text">
          <div class="back-button-wrapper pointer" @click="closePopup">
            <img
              class="nav-link-icon filter-primary"
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
              alt=""
            />
          </div>
          <div class="blackcolor pop-800">Úprava bonity</div>
          <button form="bonita-submit" class="w-button saveformBtn">Uložiť</button>
        </div>
        <div class="header-block inline">
          <div class="bold">{{ payload.type }}</div>
          <img :src="getIcon(payload.type)" loading="lazy" alt="" class="image-10"/>
        </div>
        <client-bonita-form
          @bonita-saved="saveBonita($event)"
          :bonita="payload.client"
          :form-template="form"
          :meta-data="metaData"
        />
      </div>
  </div>
</template>

<script>
import ClientBonitaForm from "./ClientBonitaForm";
import clientFilters from "../../../../../mixins/clientFilters";

export default {
  name: "ClientBonitaPopUp",
  components: {ClientBonitaForm},
  props: {
    payload: [Object, null],
    metaData: [Object, null],
  },
  mixins: [
    clientFilters
  ],
  data: () => ({
    activeComponent: null,
    form: null,
  }),
  created () {
    this.form = this.$store.getters["formStore/getFormByType"]("clientProfileForm");
  },
  methods: {
    async saveBonita (bonitaSaved) {
      const client = this.payload.client;
      const clientObject = this.getClientType(client.id);
      const bonitaObject = {
        id: bonitaSaved.id,
        object: {...client.client_profile, ...bonitaSaved},
        clientLabel: clientObject.type,
        clientRole: clientObject.type_code,
      }
      this.$store.dispatch("mortgageDetailState/patchClientProfileObject", bonitaObject);
      this.closePopup();
    },
    getIcon (type) {
      if (type === "Žiadateľ") {
        return require("@/images/avatar_applicant.png");
      } else if (type === "Spolužiadateľ") {
        return require("@/images/avatar_coapplicant.png");
      }
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
};
</script>

<style scoped lang="scss">

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
