<template>
  <div
    class="center"
    @click="openTestimonialOverviewPopup"
  >
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f5a_icons8-opened-folder.svg">
  </div>
</template>

<script>
export default {
  name: "TestimonialOverviewHandler",
  methods: {
    openTestimonialOverviewPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "TestimonialOverviewPopUp",
        rules: ['single'],
      });
    }
  },
}
</script>

<style scoped>

</style>
