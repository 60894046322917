<template>
  <div class="mortgage-result-broker">
    <div class="mortgage-parameters">
      <img
        style="object-fit: contain"
        :src="application.bank.logo_square"
        loading="lazy"
        alt=""
        class="bank-logo"
      />
      <div
        class="bold wrap100px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      >
        {{ getTotalAmountBasedOnCorrection() | dotTheAmount }} EUR
      </div>
      <div
        class="bold wrap50px"
      >
        {{ application.sadzba_pozadovana | toFloor(2) }}<span class="upper-index">%</span>
      </div>
      <div
        class="bold wrap100px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      > {{ application.splatka | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <div
        class="bold wrap50px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      > {{  application.poplatok_za_poskytnutie | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <div
        class="bold wrap50px"
      >
        {{ application.splatnost }} <span class="upper-index">R</span>
      </div>
      <div
        class="bold wrap100px"
      >
        {{ application.popl_za_vedenie_uctu }} EUR<span class="upper-index">/ m</span>
      </div>
      <div
        class="bold wrap50px"
      >
        {{ application.fix }}
      </div>
      <div
        class="bold wrap50px"
      >
      </div>
      <div @click="deleteApplication()" class="pointer noselect">
        <img
          v-if="!loading"
          class="nav-link-icon filter-primary"
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
          alt=""
        >
        <v-progress-circular
          v-else
          class="loading-spinner small"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteMortgageApplicationByID } from "@/services/brokerService";
import imagesConvert from "../../../../../mixins/imagesConvert";

export default {
  name: "MortgageApplicationItemPicked",
  props: {
    application: Object,
    prePicked: Boolean,
  },
  mixins: [
    imagesConvert,
  ],
  created () {
    this.picked = this.prePicked;
  },
  methods: {
    getTotalAmountBasedOnCorrection() {
      if (this.application.backEndLimitCorrected) {
        return this.application.correctedAmount;
      }
      return this.application.suma_nadobudnutie + this.application.suma_refinancovanie + this.application.suma_bezucel;
    },
    async deleteApplication () {
      this.loading = true;
      await deleteMortgageApplicationByID(this.application.id);
      this.$store.commit("mortgageDetailState/deleteApplication", this.application.id);
      this.loading = false;
    },
  },
  data: () => ({
    picked: false,
    loading: false,
  }),
}
</script>

<style scoped>

</style>
