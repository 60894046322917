<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      {{ label }}
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'druh nehnuteľnosti'" :value="convertType(getPropertySubType())"/>
        <text-value-holder :label="'hodnota'" :value="summaryData.hodnota"/>
        <text-value-holder :label="'obec'" :value="summaryData.address_text_city"/>
        <text-value-holder :label="'ulica'" :value="summaryData.address_text_street"/>
        <text-value-holder :label="'súpisné číslo'" :value="summaryData.address_text_popisne"/>
        <text-value-holder :label="'orientačné číslo'" :value="summaryData.address_text_number"/>
        <text-value-holder :label="'PSČ'" :value="summaryData.address_text_zip"/>
        <text-value-holder :label="'vlastník'" :value="getPropertyOwner()"/>
        <file-download-holder
          v-if="Array.isArray(lVdocument?.uploaded)"
          v-for="(file, index) in lVdocument?.uploaded"
          :key="index"
          :cloud-file="file"
          :label="file.name"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import FileDownloadHolder from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/FileDownloadHolder.vue";

export default {
  name: "PropertySummary",
  components: {FileDownloadHolder, TextValueHolder},
  props: {
    label: String,
    summaryData: Object,
    openedDefault: Boolean,
  },
  created() {
    this.lVdocument = this.$store.getters["mortgageDetailCloud/getCloudMissingFileByType"](this.summaryData.cloud, "vypis_z_listu_vlastnictva");
    this.opened = this.openedDefault;
  },
  methods: {
    convertType(type) {
      if (type === "house") return "rodinný dom";
      else if (type === "flatInFlat") return "byt v bytovom dome";
      else if (type === "flatInHouse") return "byt v rodinnom dome";
      else if (type === "land") return "pozemok";
      else if (!type) return "neznámy typ nehnuteľnosti";
    },
    getPropertyOwner() {
      const applicantId = this.$store.getters["mortgageDetailState/getApplicant"].id;
      const coApplicantId = this.$store.getters["mortgageDetailState/getCoApplicant"]?.id;
      if (this.summaryData.majitel === applicantId) return "Žiadateľ";
      if (this.summaryData.majitel === coApplicantId) return "Spolužiadateľ";
      return "Iný vlastník";
    },
    getPropertySubType() {
      if (this.summaryData.hasOwnProperty("typ_bytu")) {
        if (this.summaryData.typ_bytu === "v_bytovom_dome") {
          return "flatInFlat"
        } else if (this.summaryData.typ_bytu === "v_rodinnom_dome") {
          return "flatInHouse"
        }
      }
      return this.summaryData.child_type;
    },
  },
  data: () => ({
    lVdocument: null,
    opened: false,
  })
}
</script>

<style scoped>
</style>
