<template>
  <div :class="classCst">
    <div class="add-prijem-btn">
      <div class="text-small bl-color pointer" @click="showMortgageAdd">
        <div v-if="text">
          {{ title }}
        </div>
        <div
          class="white--text no-decoration"
          v-else-if="button && $store.getters['popupManagerState/getLengthOfPopupOrder'] === 0"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageAddHandler",
  props: {
    rules: [Array, null],
    classCst: String,
    button: Boolean,
    text: Boolean,
    title: String,
  },
  methods: {
    showMortgageAdd () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "MortgageAddPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-decoration {
  font-size: 24px;
}

</style>
