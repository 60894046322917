import store from "../store/index"

export default {
  methods: {
    translateRole (role) {
      if (role === "alex_admin") {
        return "admin";
      } else if (role === "financnik") {
        return "finančník";
      } else if (role === "znalec") {
        return "znalec";
      }
    },
    getClientType (id) {
      const applicantId = store.getters["mortgageDetailState/getApplicant"].id;
      const coApplicantId = store.getters["mortgageDetailState/getCoApplicant"]?.id;
      if (id === applicantId) {
        return {type: "Žiadateľ", type_code: "applicant", id: id};
      } else if (id === coApplicantId) {
        return {type: "Spolužiadateľ", type_code: "coApplicant", id: id};
      }
    },
  }
}
