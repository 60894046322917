export default {
  methods: {
    getAddressModified(property) {
      if (!property?.address_text_city && !property?.address_text_street && !property?.address_text_number) {
        return "Adresa neznáma"
      }
      return `${property.address_text_street ? property.address_text_street + ", " : ""} ${property.address_text_number ? property.address_text_number + ", " : ""} ${property.address_text_city ? property.address_text_city : ""}`
    },
    filterFinancedPropertiesById (properties) {
      const financedProperties = [];
      for (const property of properties.propertiesObjects) {
        if (!properties.financedPropertiesIds.includes(property.id)) continue;
        financedProperties.push(property);
      }
      return financedProperties;
    },
    filterFoundedPropertiesById (properties) {
      const foundedProperties = [];
      for (const property of properties.propertiesObjects) {
        if (!properties.foundedPropertiesIds.includes(property.id)) continue;
        foundedProperties.push(property);
      }
      return foundedProperties;
    },
    getLabelByType (type) {
      if (type === "zakladane_nehnutelnosti") {
        return "Zakladaná";
      } else if (type === "financovane_nehnutelnosti") {
        return "Financovaná";
      } else if (type === "zatazene_nehnutelnosti") {
        return "Zaťažená";
      }
    },
    getFullLabelType (type) {
      if (type === "zakladane_nehnutelnosti") {
        return "zakladaných nehnuteľností";
      } else if (type === "financovane_nehnutelnosti") {
        return "financovaných nehnuteľností";
      } else if (type === "zatazene_nehnutelnosti") {
        return "zaťažených nehnuteľností";
      }
    },
    getPropertyDataBySubType(type) {
      if (type === "house") return {type: "house", subtype: "house", label: "rodinného domu"};
      else if (type === "flatInFlat") return {
        type: "flat",
        subtype: "flatInFlat",
        label: "bytu v bytovom dome"
      };
      else if (type === "flatInHouse") return {
        type: "flat",
        subtype: "flatInHouse",
        label: "bytu v rodinnom dome"
      };
      else if (type === "land") return {type: "land", subtype: "land", label: "pozemku"};
      else if (!type) return {type: "not_specified", subtype: "not_specified", label: "neznámej nehnuteľnosti"};
    },
  }
}
