<template>
  <div class="content-panel">
    <div class="bcg-loading" v-if="loading">
      <v-progress-circular
        class="loading-spinner"
        indeterminate
      ></v-progress-circular>
    </div>
    <applications-wrapper v-else/>
  </div>
</template>

<script>
import ApplicationsWrapper from "../../../components/broker/KartaZiadosti/ApplicationsWrapper";

export default {
  name: "KartaZiadosti",
  components: {
    ApplicationsWrapper
  },
  async created () {
    const role = this.$store.getters["userState/getRole"];
    const userId = this.$store.getters["userState/getId"];
    if (role === "financnik") this.$store.commit("mortgageApplicationsState/setFilters", {mortgage__applicant__financnik: userId});
    this.loading = true;
    await this.$store.dispatch("mortgageApplicationsState/initializeApplications");
    this.loading = false;
  },
  data: () => ({
    loading: false,
  }),
}
</script>

<style scoped>

</style>
