<template>
  <div class="popup-form">
    <div class="content-form-popup">
      
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"/>
        </div>
        <div class="blackcolor pop-800">
          {{ typeLabel }}
        </div>
        <button form="income-submit" class="w-button saveformBtn">Uložiť</button>
      </div>

      <component
        @income-saved="saveIncome($event)"
        :is="activeComponent"
        :income="payload"
        :form-template="form"
        :meta-data="metaData">
      </component>
    </div>
  </div>
</template>

<script>
import zivnost from "./TradesmanForm";
import sro from "./SROForm";
import prenajom from "./RentalIncome";
import zamestnanie from "./EmploymentForm";
import dochodok from "./RetirementPension";
import rodicovskyprispevok from "./MaternityContribution";
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";

export default {
  name: "ClientIncomePopUp",
  components: {zivnost, sro, zamestnanie, prenajom, dochodok, rodicovskyprispevok},
  props: {
    type: [String, null],
    payload: [Object, null],
    metaData: [Object, null],
  },
  mixins: [
    translateFromDBValue,
  ],
  data: () => ({
    incomeOpened: {},
    typeLabel: null,
    activeComponent: null,
    form: null,
    clientInfo: null,
  }),
  async created () {
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
    this.typeLabel = this.translateIncomeType(this.type)
    this.activeComponent = this.typeLabel.replace(/\s/g, '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const incomeForm = this.$store.getters["formStore/getFormByType"]("incomeForm");
    const professions = this.$store.getters["formStore/getFormByType"]("professionForm");
    professions.forEach((p) => {
      p.display_name = p.label
      p.value = p.id
    })
    incomeForm.profesia["choices"] = professions;
    this.form = incomeForm;
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    saveIncome (incomeSaved) {
      if (this.payload) {
        const data = {
          id: incomeSaved.incomeInfo.id,
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          object: {
            clientInfo: this.clientInfo,
            ...incomeSaved.incomeInfo,
          }
        };
        this.$store.dispatch("mortgageDetailState/patchClientIncome", data);
      } else {
        const data = {
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          monthlyIncomes: incomeSaved.monthlyIncomes,
          object: {
            clientInfo: this.clientInfo,
            ...incomeSaved.incomeInfo,
            client: this.clientInfo.id,
          },
        };
        this.$store.dispatch("mortgageDetailState/createClientIncome", data);
      }
      this.closePopup();
    },
  },
};
</script>

<style scoped lang="scss">

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
