<template>
  <div class="cloud-holder-wrapper-main">
    <div class="holder-header-wrapper" v-if="!openOnLoad">
      <strong>
        {{ label }}
      </strong>
      <img
        src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
        class="activate-button nav-link-icon filter-primary"
        :class="{'rotate': opened}"
        @click="opened = !opened"
        alt=""
      />
    </div>
    <v-expand-transition class="summary-items-transition">
      <div v-if="opened">
        <document-cloud-item
          v-for="(cloud, key) in clouds"
          :type="type"
          :extra="extra"
          :open-on-load="clouds.length < 6"
          :key="key"
          :cloud="cloud"
          :meta-data="metaData"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>

import DocumentCloudItem from "./DocumentCloudItem";

export default {
  name: "DocumentCloudHolder",
  components: {DocumentCloudItem},
  props: {
    type: [Number, null],
    extra: Object,
    clouds: Array,
    label: String,
    openOnLoad: Boolean,
    metaData: [Object, null],
  },
  async created () {
    this.opened = this.openOnLoad;
  },
  data: () => ({
    opened: false,
  }),
}
</script>

<style lang="scss" scoped>

</style>
