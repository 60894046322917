<template>
  <div class="statistic-tabs" data-aos="fade-down">
    <div class="stat-block">
      <div>
        <v-scroll-y-reverse-transition leave-absolute v-if="!loading">
          <div>
            <div class="div-block-2">
              <div
                class="text-small"
                :class="[
                  this.newClientsLength >= this.latestTemporary
                    ? 'green--text'
                    : 'red--text',
                ]"
              >
                {{
                this.newClientsLength >= this.latestTemporary ? "+" : "-"
                }}{{ calculateTemp() }}
              </div>
            </div>
            <div class="blacktext">Noví klienti</div>
            <div class="large-text stat-number pop-800">{{ newClientsLength }}</div>
          </div>
        </v-scroll-y-reverse-transition>
        <v-scroll-y-transition leave-absolute v-if="loading">
          <div>
            <div class="blacktext">Noví klienti</div>
            <div class="loaderbg loading-spinner">
              <v-progress-circular
                class="loading-spinner extrasmall"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </v-scroll-y-transition>
      </div>
    </div>
    <div class="stat-block">
      <v-scroll-y-transition leave-absolute v-if="!loading">
        <div>
          <div class="div-block-2">
            <div
              class="text-small"
              :class="[
              this.registeredClientLength >= this.latestRegistered
                ? 'green--text'
                : 'red--text',
            ]"
            >
              {{
              this.registeredClientLength >= this.latestRegistered ? "+" : "-"
              }}{{ calculateNew() }}
            </div>
          </div>
          <div class="blacktext">Registrácia</div>
          <div class="large-text stat-number pop-800">
            {{ registeredClientLength }}
          </div>
        </div>
      </v-scroll-y-transition>
      <v-scroll-y-transition leave-absolute v-if="loading">
        <div>
          <div class="blacktext">Registrácia</div>
          <div class="loaderbg loading-spinner">
            <v-progress-circular
              class="loading-spinner extrasmall"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-scroll-y-transition>
    </div>
  </div>
</template>

<script>
import { getClients } from "../../../services/brokerService";

export default {
  name: "KlientiStats",
  props: {
    periods: [Object, null],
  },
  watch: {
    async periods (periods) {
      this.loading = true;
      this.getClientAmounts();
      this.changePeriodForStatistics(periods);
      await this.calculateStatistics();
      this.loading = false;
    },
  },
  methods: {
    calculateTemp () {
      let temp = 1;
      let latestTemp = 1;
      let percentile = null;
      if (this.newClientsLength !== 0) temp = this.newClientsLength;
      if (this.latestTemporary !== 0) latestTemp = this.latestTemporary;
      percentile = (temp / latestTemp) * 100;
      if (temp < latestTemp) {
        percentile = 100 - percentile;
        if (temp === 1) {
          percentile = 100;
        }
      }
      if (temp === latestTemp) percentile = 0;
      return percentile.toFixed(1) + " %";
    },
    calculateNew () {
      let reg = 1;
      let latestReg = 1;
      let percentile = null;
      if (this.registeredClientLength !== 0) reg = this.registeredClientLength;
      if (this.latestRegistered !== 0) latestReg = this.latestRegistered;
      percentile = (reg / latestReg) * 100;
      if (reg < latestReg) {
        percentile = 100 - percentile;
        if (reg === 1) {
          percentile = 100;
        }
      }
      if (reg === latestReg) percentile = 0;
      return percentile.toFixed(1) + " %";
    },
    getClientAmounts () {
      this.registeredClientLength = this.$store.getters["clientState/getLengthOfRegistered"];
      this.newClientsLength = this.$store.getters["clientState/getLengthOfNew"];
    },
    changePeriodForStatistics (periods) {
      if (periods.lt) {
        this.lt = periods.lt.replaceAll(" ", "");
      } else {
        this.lt = null;
      }
      if (periods.gte) {
        this.gte = periods.gte.replaceAll(" ", "");
      } else {
        this.gte = null;
      }
    },
    async calculateStatistics () {
      this.latestTemporary = (await getClients(
        {
          client_profile__ob_status: "novy",
          financnik: this.onlyForBroker,
          date_joined__gte: this.gte,
          date_joined__lt: this.lt,
          page_size: 1,
        }
      )).count;
      this.latestRegistered = (await getClients(
        {
          client_profile__ob_status: "rozpracovaný",
          financnik: this.onlyForBroker,
          date_joined__gte: this.gte,
          date_joined__lt: this.lt,
          page_size: 1,
        }
      )).count;
    },
    getDateOfDatePriorToToday (days) {
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - days));
      return priorDate.toLocaleDateString();
    },
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  async created () {
    this.loading = true;
    this.gte = this.getDateOfDatePriorToToday(60).replaceAll(" ", "");
    this.lt = this.getDateOfDatePriorToToday(30).replaceAll(" ", "");
    this.brokerId = this.$store.getters["userState/getId"];
    this.role = this.$store.getters["userState/getRole"];
    if (this.role !== "alex_admin") this.onlyForBroker = this.brokerId;
    await this.calculateStatistics();
    this.getClientAmounts();
    this.loading = false;
    this.interval = setInterval(this.getClientAmounts, 500);
  },
  data: () => ({
    loading: false,
    lt: null,
    gte: null,
    registeredClientLength: 0,
    newClientsLength: 0,
    latestRegistered: null,
    latestTemporary: null,
    brokerId: null,
    role: null,
    onlyForBroker: null,
    interval: null,
  }),
};
</script>

<style scoped lang="scss">
.loaderbg {
  width: 35px;
  height: 35px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistic-tabs {
  display: flex;
}

.stat-block {
  margin-bottom: 20px !important;
}
</style>
