<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      <div>
        Príjem <span class="pop-800">{{ summaryData.clientInfo.type.toLowerCase() }}a</span> z <span class="pop-800">sro</span>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'typ príjmu'" :value="summaryData.typ"/>
        <text-value-holder :label="'druh príjmu'" :value="'SRO'"/>
        <text-value-holder :label="'mesačný príjem'" :value="summaryData.dti.extra.prijem_mo"/>
        <text-value-holder :label="'obchodný názov'" :value="summaryData.zamestnavatel.name"/>
        <div class="text-small light fullwidth"> Viac údajov na
            <a
              :class="[summaryData.zamestnavatel.ico ? 'link pointer' : 'link pointer noselect']"
              target="_blank"
              @click.prevent="navigateToFinstat"
            >
              finstat.sk
            </a>
        </div> 
        <text-value-holder :label="'ičo'" :value="summaryData.zamestnavatel.ico"/>
        <text-value-holder :label="'obec'" :value="summaryData.zamestnavatel.address_city"/>
        <text-value-holder :label="'ulica'" :value="summaryData.zamestnavatel.address_street"/>
        <text-value-holder :label="'súpisné číslo'" :value="summaryData.zamestnavatel.address_popisne"/>
        <text-value-holder :label="'orientačné číslo'" :value="summaryData.zamestnavatel.address_number"/>
        <text-value-holder :label="'PSČ'" :value="summaryData.zamestnavatel.address_zip"/>
        <text-value-holder :label="'podiel v spoločnosti'" :value="summaryData.podiel_v_sro"/>
        <text-value-holder :label="'odvetvie'" :value="summaryData.zamestnavatel_odvetvie"/>
        <text-value-holder :label="'komora'" :value="summaryData.komora"/>
        <text-value-holder :label="'podnikám od'" :value="transformDate(summaryData.zamestnanie_od)"/>
        <text-value-holder :label="'zisk'" :value="summaryData.zisk_sro"/>
        <text-value-holder :label="'tržby'" :value="summaryData.minulorocny_obrat"/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import dateConverter from "@/mixins/dateConverter";

export default {
  name: "SroSummary",
  components: {TextValueHolder},
  props: {
    summaryData: Object,
    openedDefault: Boolean,
  },
  mixins: [
    dateConverter
  ],
  created() {
    this.opened = this.openedDefault;
  },
  data: () => ({
    opened: false,
  }),
  methods: {
    navigateToFinstat() {
      if (!this.summaryData.zamestnavatel.ico) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Pre zobrazenie SRO na finstate zadajte ičo",
          type: "error",
        });
        return;
      }
      window.open(`https://www.finstat.sk/${this.summaryData.zamestnavatel.ico}`, "_blank");
    },
  }
}
</script>

<style scoped>
  .fullwidth{
    width: 100%;
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
  }
</style>
