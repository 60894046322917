<template>
  <div class="buttons-wrapper">
    <component
      v-if="floatingHandlers"
      v-for="(floatingHandler, index) in floatingHandlers"
      :key="index"
      :is="floatingHandler.component"
    />
    <v-slide-y-reverse-transition>
      <div v-show="showHandlers">
        <component
          v-for="(handler, index) in handlers"
          :key="index"
          :is="handler.component"
          :class-cst="handler.classCst"
          :rules="handler.rules"
          :history="handler.history"
          :cloud-meta="handler.cloudMeta"
          :icon-button="handler.iconButton"
          :button="handler.button"
        />
      </div>
    </v-slide-y-reverse-transition>
    <div class="button-item-main" @click="showHandlers = !showHandlers">
      <img src="@/images/multi-handler.svg" width="18px" alt="">
    </div>
  </div>
</template>

<script>
import DocumentCloudHandler from "../Popups/DocumentCloud/DocumentCloudHandler";
import CalculationsHistoryHandler from "../Popups/CalculationsHistory/CalculationsHistoryHandler";
import MetodicTable from "../Popups/CalculationsHistory/MetodicTable";
import posudokOrderBtn from "../Popups/CalculationsHistory/posudokOrderBtn";
import ClientFinancingOfferHandler from "../Popups/ClientFinnancingOffer/ClientFinancingOfferHander.vue";
import MortgageAddHandler from "@/components/shared/PopupHandler/Popups/MortgageAdd/MortgageAddHandler.vue";
import ObjectsRemoveHandler from "@/components/shared/PopupHandler/Popups/ObjectsRemove/ObjectsRemoveHandler.vue";
import LimitsRefreshButton
  from "@/components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/LimitsRefreshButton.vue";

export default {
  name: "HandlersWrapperButton",
  components: {
    LimitsRefreshButton,
    ObjectsRemoveHandler,
    MortgageAddHandler,
    ClientFinancingOfferHandler,
    CalculationsHistoryHandler,
    DocumentCloudHandler,
    MetodicTable,
    posudokOrderBtn
  },
  props: {
    handlers: Array,
    floatingHandlers: [Array, null],
  },
  data: () => ({
    showHandlers: false,
  }),
}
</script>

<style lang="scss" scoped>
</style>
