<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Naozaj chcete čerpať túto banku?</div>
      </div>

      <div class="input-group">
        <label for="urok" class="field-label">Úroková sadzba<strong></strong></label
        ><input
        v-model="formData.sadzba_schvalena"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="urok"
        id="urok"
      />
      </div>
      <div class="input-group">
        <label for="fixacia" class="field-label">Fixácia<strong></strong></label
        ><input
        v-model="formData.fix"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="fixacia"
        id="fixacia"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru nadobudnutie<strong></strong></label
        ><input
        v-model.number="formData.suma_nadobudnutie"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru refinancovanie<strong></strong></label
        ><input
        v-model.number="formData.suma_refinancovanie"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru bezúčel<strong></strong></label
        ><input
        v-model.number="formData.suma_bezucel"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="splatnost" class="field-label">Splatnosť<strong></strong></label
        ><input
        v-model="formData.splatnost"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="splatnost"
        id="splatnost"
      />
      </div>
      <div class="image-wrapper">
        <img
          :src="payload.bank.logo_square"
          width="100"
          loading="lazy"
          alt=""
          class="image-bcg"
        />
        <div class="blc-color MGtop20px center"
        >
          Kliknutím na čerpať sa sprístupní krok 4 - čerpanie, pričom klient dostane emailovú notifikáciu, že sa pracuje na príprave úverovej dokumentácie.
        </div>
        <button class="w-button" @click="saveStatus">
          Áno, čerpať
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageApplicationDrawdownPopup",
  props: {
    payload: Object,
  },
  created() {
    this.formData.sadzba_schvalena = this.payload.sadzba_pozadovana;
    this.formData.fix = this.payload.fix;
    this.formData.suma_nadobudnutie = this.payload.suma_nadobudnutie;
    this.formData.suma_refinancovanie = this.payload.suma_refinancovanie;
    this.formData.suma_bezucel = this.payload.suma_bezucel;
    this.formData.splatnost = this.payload.splatnost;
  },
  methods: {
    saveStatus () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: true, form: this.formData, stage: "cerpana"});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    closePopup () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  beforeDestroy () {
    if (this.preverDestroy) return;
    this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
  },
  data: () => ({
    preverDestroy: false,
    formData: {
      sadzba_schvalena: null,
      fix: null,
      suma_nadobudnutie: null,
      suma_refinancovanie: null,
      suma_bezucel: null,
      splatnost: null
    }
  }),
}
</script>

<style lang="scss" scoped>
.image-text {
  text-align: center;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
