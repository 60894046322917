export default {
  namespaced: true,
  state: {
    entryUrl: null,
    loadingCredential: false,
    loadingPage: false,
    limitsLoader: false,
    offersLoading: false,
    userConfirmation: null,
    calendarVisible: false,
    successMessage: null,
  },
  getters: {
    getLimitsLoader: (state) => {
      return state.limitsLoader;
    },
    getOffersLoading: (state) => {
      return state.offersLoading;
    },
    getSuccessMessage: (state) => {
      return state.successMessage;
    },
    getEntryUrl: (state) => {
      return state.entryUrl;
    },
    getLoadingMainData: (state) => {
      return state.loadingCredential;
    },
    getCalendarVisible: (state) => {
      return state.calendarVisible;
    },
    getLoadingPage: (state) => {
      return state.loadingPage;
    },
    getUserConfirmation: (state) => {
      return state.userConfirmation;
    }
  },
  mutations: {
    setLimitsLoader: (state, value) => {
      state.limitsLoader = value;
    },
    setOffersLoading: (state, value) => {
      state.offersLoading = value;
    },
    setSuccessMessage: (state, successMessage) => {
      state.successMessage = successMessage;
    },
    setEntryUrl (state, url) {
      state.entryUrl = url;
    },
    setLoadingMainData (state, credentialStatus) {
      state.loadingCredential = credentialStatus;
    },
    setCalendarVisible: (state, visibility) => {
      state.calendarVisible = visibility;
    },
    setLoadingPage: (state, loading) => {
      state.loadingPage = loading;
    },
    setUserConfirmation: (state, value) => {
      state.userConfirmation = value;
    }
  },
};
