<template>
  <div class="popup-form">

    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Upraviť údaje</div>
        <button form="client-submit" class="w-button saveformBtn">Uložiť</button>
      </div>
      <form
        @keydown.enter.prevent
        @submit.prevent="saveClient"
        id="client-submit"
      >
        <div class="splitter clientError noselect" v-if="existingEmail">
          klient so zadanou emailovou adresou už existuje
        </div>
        <div class="input-group">
          <label for="meno" class="field-label">Meno<strong></strong></label
          ><input
          v-model="form.first_name"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="meno"
          data-name="meno"
          placeholder=""
          id="meno"
        />
        </div>
        <div class="input-group">
          <label for="priezvisko" class="field-label"
          >Priezvisko<strong></strong></label
          ><input
          v-model="form.last_name"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="priezvisko"
          data-name="priezvisko"
          placeholder=""
          id="priezvisko"
        />
        </div>
        <div class="splitter clientError noselect" v-if="wrongEmailEntered">
          vložte prosím platnú emailovú adresu
        </div>
        <div class="input-group">
          <label for="email" class="field-label">Email<strong></strong></label
          ><input
          @change="checkEmailAvailability"
          v-model="form.email"
          type="email"
          class="text-input w-input"
          maxlength="256"
          name="email"
          data-name="email"
          placeholder=""
          id="email"
        />
        </div>
        <div class="input-group">
          <label for="telefon" class="field-label">Telefón<strong></strong></label
          ><input
          v-model="form.client_profile.telephone"
          type="number"
          class="text-input w-input"
          maxlength="256"
          name="telefon"
          data-name="telefon"
          placeholder=""
          id="telefon"
        />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  getClients,
  patchClientProfile,
  patchUserProfileByID
} from "@/services/brokerService";

export default {
  name: "BasicClientInformationPopUp",
  props: {
    payload: Object,
  },
  created () {
    this.form.first_name = this.payload.first_name;
    this.form.last_name = this.payload.last_name;
    this.form.email = this.payload.email.includes("@nomail.bankari.sk") ? null : this.payload.email;
    this.form.client_profile.telephone = this.payload.client_profile.telephone;
  },
  methods: {
    async saveClient () {
      this.existingEmail = false;
      this.wrongEmailEntered = false;
      const clientData = this.getClientObject();
      try {
        await patchUserProfileByID(this.payload.id, clientData.clientObject);
        await patchClientProfile(clientData.clientProfileObject, this.payload.client_profile.id);
        this.$store.commit("popupManagerState/setPickerLoad", true);
        this.closePopup();
      } catch (e) {
        const err = e.response.data.email[0];
        if (err.includes("Užívateľ s týmto Email už existuje.")) {
          this.existingEmail = true;
        } else if (err.includes("Vložte správnu emailovú adresu.")) {
          this.wrongEmailEntered = true;
        }
      }
    },
    getClientObject () {
      const clientObject = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email ? this.form.email : this.payload.email,
      };
      const clientProfileObject = {
        telephone: this.form.client_profile.telephone,
      };
      return {clientObject, clientProfileObject};
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    async checkEmailAvailability () {
      this.existingEmail = false;
      this.wrongEmailEntered = false;
      const matchedClients = await getClients(
        {
          search: this.form.email,
          page_size: 1,
        },
      );
      if (matchedClients.count === 0) return
      this.existingEmail = true;
    },
  },
  data: () => ({
    existingEmail: false,
    wrongEmailEntered: false,
    form: {
      first_name: null,
      last_name: null,
      email: null,
      client_profile: {
        telephone: null,
      },
    },
  })
}
</script>

<style scoped>

</style>
