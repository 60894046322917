<template>
  <!-- <div class="badge-icon pointer" @click="openTaskManagerPopUp">
    <img
        src="@/images/TaskIcon.svg"
        loading="lazy"
        alt=""
        class="nav-link-icon"
    />
    <div class="icontext pop-800 noselect">Úlohy</div>
    <div class="div-block-85 font-size-16" v-if="unseensTasksPresent">
      <div>!</div>
    </div>
  </div> -->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UsersTasksHandler",
  computed: {
    ...mapGetters({
      newTaskGetter: "clientsEvents/getTaskAcquired",
    }),
  },
  watch: {
    newTaskGetter(newTask) {
      if (!newTask) return;
      this.unseensTasksPresent = newTask;
      const audio = document.getElementById("new-notification");
      audio.play();
      this.$store.commit("clientsEvents/setTaskAcquired", false);
    }
  },
  methods: {
    openTaskManagerPopUp() {
      // this.$store.commit("popupManagerState/pushToPopupOrder", {
      //   type: null,
      //   payload: null,
      //   component: "ApplicationHelpPopUp",
      //   rules: ['single'],
      // });
      this.unseensTasksPresent = false;
    },
  },
  data:() => ({
    unseensTasksPresent: false,
  }),
}
</script>

<style lang="scss" scoped>
.pop-800 {
  color: #000 !important;
}
</style>
