<template>
  <div class="popup-form" :style="[type === 'define' ? 'width: 100%' : '']">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
            v-if="type !== 'define'"
          />
        </div>
        <div class="pop-800 noselect">Najnovšie správy</div>
      </div>

      <div class="image-wrapper" v-if="unseenMessages.length === 0">
        <img
          src="@/images/notification-pannel-background.png"
          loading="lazy"
          alt=""
          class="image-bcg"
        />
        <div class="image-text">všetky správy máte vybavené,<br> doprajte si chvíľu pauzy</div>
      </div>

      <div v-else>
        <div
          class="popup-block chat-message"
          v-for="(message, key) in unseenMessages"
          :key="key"
          @click="openChat(message)"
        >
          <div class="div-block-86">
            <div class="dark-blue-cta-text bold">
              <span v-if="getClient(message.sender).is_temporary">ID {{ message.sender }}</span>
              <span v-else>{{ getClient(message.sender).first_name }} {{ getClient(message.sender).last_name }}</span>
            </div>
            <div v-if="!isNaN(getDate(message.created))">
              {{ getDate(message.created) }}.{{ getMonth(message.created) }}
              {{ getHours(message.created) }}:{{ getMinutes(message.created) }}
            </div>
          </div>
          <div class="div-block-80">
            <div class="badge" v-if="message.amount_unseen !== 0">
              <div class="div-block-85">{{ message.amount_unseen }}</div>
            </div>
            <div class="open-message w-inline-block" style="overflow: hidden; white-space: nowrap;">
              <p style="text-overflow: ellipsis;">
                {{ message.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getClientById, getUserProfileByID, setMessageSeen} from "../../../../../services/brokerService";
import { mapGetters } from "vuex";

export default {
  name: "NotificationPopUp",
  data: () => ({
    weekday: ["Ne", "Pon", "Ut", "Str", "Štv", "Pia", "So"],
    unseenMessages: [],
    clients: [],
    clientIds: [],
  }),
  props: {
    type: String,
    payload: Object,
  },
  computed: ({
    ...mapGetters({
      messagesGetter: "clientState/getUnseenMessages",
    }),
  }),
  watch: {
    messagesGetter (newMessages) {
      this.unseenMessages = newMessages;
      this.retrieveClients();
    },
  },
  created () {
    if (!this.payload) return;
    this.unseenMessages = this.payload.messages;
    this.clients = this.payload.clients;
    this.clientIds = this.payload.clientIds;
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    getClient (id) {
      const client = this.clients.find((client) => client.id === id);
      if (client) return client;
      return {is_temporary: true};
    },
    getDate (created) {
      const date = new Date(created);
      return date.getDate();
    },
    getMonth (created) {
      const date = new Date(created);
      return date.getMonth() + 1;
    },
    getHours (created) {
      const date = new Date(created);
      return date.getHours();
    },
    getMinutes (created) {
      const date = new Date(created);
      if (date.getMinutes() < 10) {
        return "0" + date.getMinutes();
      }
      return date.getMinutes();
    },
    async openChat(message) {
      let client = null
      if (!message.meta) {
        client = await getClientById(message.sender);
      } else if (message.meta.invitation) {
        client = await getClientById(message.meta.id);
      }
      setMessageSeen(message.id);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: client,
        component: "ClientChatPopUp",
        rules: ['single'],
      });
    },
    async retrieveClients () {
      for (const message of this.unseenMessages) {
        const clientId = message.sender;
        if (!this.clientIds.includes(clientId)) {
          const client = await getUserProfileByID(clientId);
          this.clients.push(client);
          this.clientIds.push(clientId);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image-bcg {
  width: 174px;
  height: 160px;
  margin: 0 auto;
}

.image-text {
  margin-top: 20px;
  text-align: center;
}

.image-wrapper {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
}

.popup {
  z-index: 999;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.7s;
}
</style>
