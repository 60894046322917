<template>
  <div class="popup-form">    
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Termín {{ getPopUpType() }}</div>
        <button class="w-button" form="event-form">
            Vytvoriť
        </button>
      </div>
      <div class="w-form">
        <span class="clientError" v-if="clientMissing">
          Zvolte klienta
        </span>
        <client-picker v-if="type.type === 'clientUnknown'" @client-picked="setClient($event)" search-only/>
        <component :is="type.component" @event-saved="saveEvent($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationSignEvent from "./ApplicationSignEvent";
import ContractSignEvent from "./ContractSignEvent"
import OnlineConsultationEvent from "./OnlineConsultationEvent";
import ClientPicker from "../ClientPicker/ClientPicker";

export default {
  name: "ClientEventPopUp",
  components: {
    ClientPicker,
    ApplicationSignEvent,
    ContractSignEvent,
    OnlineConsultationEvent
  },
  props: {
    _googlePlaceId: [String, null],
    type: Object,
    payload: [Number, Object, null],
  },
  created() {
    console.log(this.payload);
  },
  methods: {
    getPopUpType () {
      if (this.type.component === "ApplicationSignEvent") {
        return "podpisu žiadostí";
      } else if (this.type.component === "ContractSignEvent") {
        return "podpisu úverovej zmluvy";
      } else if (this.type.component === "OnlineConsultationEvent") {
        return "online konzultácie";
      }
    },
    async saveEvent (eventData) {
      if (this.submitted) return;
      if (this.type.type === 'clientUnknown' && !this.client) {
        this.clientMissing = true;
        return;
      }
      if (this.type.type === 'clientKnown') {
        this.client = this.payload;
      }
      this.clientMissing = false;
      this.submitted = true;
      const myId = this.$store.getters["userState/getId"];
      eventData.users = [this.client.id, myId];
      this.closePopup();
      this.$store.dispatch("clientsEvents/createEvent", {eventData, client: this.client});
      // if (this.client && this.client.mortgages.length > 1) {
      //   this.$store.commit("popupManagerState/pushToPopupOrder", {
      //     type: null,
      //     payload: {mortgages: this.client.mortgages, id: this.client.id, title: 'PICK'},
      //     component: "MortgagePickerPopUp",
      //     rules: this.rules,
      //   });
      // }
    },
    setClient (client) {
      this.client = client;
      // this.invitationForm.user = [client.id];
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    client: null,
    submitted: false,
    clientMissing: false,
  }),
}
</script>

<style lang="scss" scoped>
.information-wrapper {
  margin-top: 10px;
  display: flex;
  column-gap: 105px;
  flex-direction: column;
  align-items: center;
}

.information-wrapper * {
  margin-top: 10px;
}

.information-wrapper > span {
  text-align: center;
  font-size: 15px;
}
</style>
