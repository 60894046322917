<template>
  <div class="div-block-25">
    <div
      data-w-id="239b29db-b0c5-1e0a-548a-17a023868560"
      class="add-prijem-btn"
    >
      <div class="text-small bl-color pointer" @click="openClientVerificationPicker()">
        {{ verified ? 'znova' : '' }} overiť KLIENTA
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientVerificationHandler",
  props: {
    clientInfo: [Object, null],
    rules: Array,
    verified: Boolean,
  },
  methods: {
    openClientVerificationPicker () {
      this.$store.commit("popupManagerState/setClientInfo", {type: this.clientInfo.type, id: this.clientInfo.id});
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ClientVerificationPicker",
        rules: this.rules,
      });
    }
  }
}
</script>

<style scoped>

</style>
