<template>
    <img @click="openMissingInfoPopup()" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1e_icons8-upload-to-the-cloud.svg" alt="">
</template>

<script>
export default {
  name: "ApplicationMissingInfoHandler",
  props: {
    rules: Array,
  },
  methods: {
    openMissingInfoPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ApplicationMissingInfoPopUp",
        rules: this.rules,
      });
    },
  }
}
</script>

<style scoped>

</style>
