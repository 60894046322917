<template>
  <div class="slider-component-wrapper">
    <div class="light-text small pop-500 description-slider">
      {{ description }} <span class="pop-900">{{ mainDesc }}</span>
    </div>
    <div :class="[percentageShow ? 'd-flex justify-content-between flex-row align-items-center' : '']">
      <div>
        <span v-if="sliderOnly" class="pop-800" @click="$emit('value-click')">
          {{ value | dotTheAmount }}
        </span>
        <chip-input
          v-else
          :id="idSelected"
          :type="'number'"
          :factor="8"
          :min="min"
          :max="max"
          :default-value="defaultValue"
          :value-given="parseInt(value)"
          :value-correction="correctChipInput"
          @value-corrected="correctChipInput = null"
          @change-event="emitValue($event)"
        />
        <span class="pop-800 unit"> {{ unit }}</span>
      </div>
      <div v-if="percentageShow" class="d-flex align-items-center">
        <div class="bold big mr-2"> {{ percentage }} %</div>
        <div class="div-block-109 noselect">
          <img
            class="nav-link-icon small filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
            @click="$emit('index-value-up-click', percentage)"
          />
          <img
            class="nav-link-icon small filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f29_icons8-expand-arrow.svg"
            @click="$emit('index-value-down-click', percentage)"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="slide-container">
      <input
        @change="emitValue(null)"
        class="slider-input"
        type="range"
        id="slider"
        :min="min"
        :max="max"
        v-model.number="value"
      />
      <div class="noselect slider-progress-wrapper">
        <div class="noselect selected-part" :id="idSelected"></div>
        <div class="noselect nonselected-part" :id="idNotSelected"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipInput from "./ChipInput";

export default {
  name: "RangeSlider",
  components: {ChipInput},
  props: {
    idSelected: String,
    idNotSelected: String,
    average: Array,
    mainDesc: [String, null],
    min: Number,
    max: Number,
    defaultValue: Number,
    description: String,
    unit: String,
    valueCorrection: [Number, null],
    sliderOnly: Boolean,
    percentageShow: Boolean,
    percentageMax: [Number, null],
  },
  data: () => ({
    correctChipInput: null,
    progressBarSelectedElement: null,
    progressBarNotSelectedElement: null,
    value: null,
    values: [],
    percentage: 0,
  }),
  methods: {
    setPercentage () {
      if (!this.percentageMax || !this.value) {
        this.percentage = 0;
        return;
      }
      if (this.min === 0) {
        this.percentage = Math.round((this.value / this.percentageMax) * 100)
      } else {
        this.percentage = Math.round(((this.value - this.min) / (this.percentageMax - this.min)) * 100)
      }
    },
    emitValue (value = null) {
      if (value) this.value = value;
      this.$emit("change-event", parseInt(this.value));
    },
    getAverage () {
      if (this.max !== 0) {
        return Math.round((this.value / this.max) * 100);
      }
      this.value = 0;
      return 0;
    },
    resize (newValue) {
      if (newValue === 0) {
        this.progressBarSelectedElement.style.width = '0%';
        this.progressBarNotSelectedElement.style.width = '100%';
        return;
      }
      let percentageSelected = null;
      if (this.min === 0) {
        percentageSelected = Math.floor(((newValue / this.max) * 100));
      } else {
        const max = this.max - this.min;
        const value = newValue - this.min;
        percentageSelected = Math.floor(((value / max) * 100));
      }
      this.progressBarSelectedElement.style.width = percentageSelected + '%';
      this.progressBarNotSelectedElement.style.width = (100 - percentageSelected) + '%';
    },
  },
  created () {
    this.value = this.defaultValue;
    if (!this.valueCorrection) return;
    this.value = this.valueCorrection;
    this.$emit("value-corrected");
    this.$emit("change-event", parseInt(this.value));
  },
  mounted () {
    // console.log(this._props);
    this.progressBarSelectedElement =
      window.document.getElementById(this.idSelected);
    this.progressBarNotSelectedElement =
      window.document.getElementById(this.idNotSelected);
    this.resize(this.defaultValue);
  },
  watch: {
    percentageMax () {
      this.setPercentage();
    },
    average (newValue) {
      if (newValue[0] === 0) return;
      if (newValue.length === 1) {
        this.value = Math.round(newValue[0]);
      } else if (newValue.length === 2) {
        this.values = newValue;
      }
    },
    value (newValue) {
      this.setPercentage();
      this.resize(newValue);
    },
    defaultValue (newValue) {
      this.value = newValue;
    },
    max (newMaxValue) {
      this.setPercentage();
      this.resize(this.value);
      if (this.value <= newMaxValue) return;
      this.value = newMaxValue;
      this.emitValue();
    },
    min (newMinValue) {
      this.resize(this.value);
      if (this.value >= newMinValue) return;
      this.value = newMinValue;
      this.emitValue()
      this.setPercentage();
    },
    valueCorrection (newValue) {
      if (newValue === null) return;
      this.value = newValue;
      this.correctChipInput = newValue;
      this.$emit("value-corrected");
    }
  },
};
</script>

<style scoped lang="scss">

</style>
