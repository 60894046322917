<template>
  <div class="klient-item">
    <a class="sourceBadge" :href="{ origin }" target="_blank">{{ origin }}</a>
    <div class="klient-item-to-part">
      <user-manager-handler :rules="['priority']" :client-info="clientData" client clients/>
      <div class="stages">
        <div class="stage-line">
          <div class="stage-line-hypoteka" :style="'width: ' + checkPoints * 25 + '%;'"></div>
          <div class="stages-container _4steps">
            <div class="stage" v-for="(barItem, index) in progressBarData" :key="index">
              <div class="stage-icon" :class="[checkPoints > index ? 'true-hypoteka' : '']"></div>
              <div class="stage-text" :class="[checkPoints > index ? 'true' : '']">
                <div class="stage-title">{{ barItem.label }}</div>
                <div class="extra-small-text">{{ barItem.data }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="div-block-94"
        :class="{ 'broker-grid-buttons': role !== 'alex_admin' }"
      >
        <div v-if="role === 'alex_admin'">
          <broker-picker-handler
            :rules="['priority']"
            :broker-id="clientData.financnik"
            :client-info="clientData.client_profile"
            clients
            klient
          />
        </div>
        <div @click="archiveClient">
          <div class="klienticta w-inline-block pointer" v-if="!clientData.client_profile.is_archived">
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1b_icons8-trash.svg">vymazať
          </div>
          <div v-else class="klienticta w-inline-block pointer">
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1c_icons8-reset.svg">obnoviť
          </div>
        </div>
        <client-chat-handler :client="clientData" :rules="['single']"/>
        <mortgage-picker-handler  :client="clientData" :rules="['single']"/>
      </div>
    </div>
    <div class="lastmessage" v-if="clientData.sent_latest_message" @click="openClientChatPopUp">
      <div class="badge" v-if="clientData.sent_unread_messages">
        <div class="text-block-13">
          {{ clientData.sent_unread_messages }}
        </div>
      </div>
      <div>
        {{ clientData.sent_latest_message }}
      </div>
    </div>
  </div>
</template>

<script>
import BrokerPickerHandler from "../../shared/PopupHandler/Popups/BrokerPicker/BrokerPickerHandler";
import UserManagerHandler from "../../shared/PopupHandler/Popups/UserManager/UserManagerHandler";
import MortgagePickerHandler from "../../shared/PopupHandler/Popups/MortgagePicker/MortgagePickerHandler";
import ClientChatHandler from "../../shared/PopupHandler/Popups/ClientChat/ClientChatHandler";
import translateFromDBValue from "../../../mixins/translateFromDBValue";
import {mapGetters} from "vuex";
import {getClientById} from "../../../services/brokerService";

export default {
  name: "KlientItem",
  components: {
    ClientChatHandler,
    MortgagePickerHandler,
    UserManagerHandler,
    BrokerPickerHandler
  },
  props: {
    client: Object,
    search: Boolean,
  },
  computed: {
    ...mapGetters({
      refreshGetter: "popupManagerState/getUserManagerRefresh"
    }),
  },
  mixins: [
    translateFromDBValue,
  ],
  data: () => ({
    checkPoints: 0,
    progressBarData: [],
    clientData: null,
    role: null,
    notificationInfo: {},
    weekday: ["Ne", "Pon", "Ut", "Str", "Štv", "Pia", "So"],
    open: false,
    broker: null,
    origin: null,
  }),
  created () {
    this.clientData = this.client;
    this.role = this.$store.getters["userState/getRole"];
    this.checkProgressBar();
  },
  methods: {
    checkProgressBar() {
      if (this.clientData.mortgages.length === 0) return;
      this.checkPoints = 0;
      this.progressBarData = [];
      const mortgage = this.clientData.mortgages[0];
      const source = mortgage.poznamka_k_prvej_analyze;
      this.origin = source;
      console.log(source);
      if (mortgage.prva_analyza_ciel_dt) {
        const date = new Date(mortgage.prva_analyza_ciel_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: `${this.getMortgagePurpose().toUpperCase()}`,
          data:`${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "účel",
          data: null,
        });
      }
      if (mortgage.prva_analyza_pridany_prijem_dt) {
        const date = new Date(mortgage.prva_analyza_pridany_prijem_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: `${this.mapIncomeType(this.clientData.incomes).toUpperCase()}`,
          data:`${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "príjem",
          data: null
        });
      }
      if (mortgage.prva_analyza_vypocitane_dti_dt) {
        const mortgageSum = mortgage.suma_nadobudnutie + mortgage.suma_refinancovanie + mortgage.suma_bezucel;
        const date = new Date(mortgage.prva_analyza_vypocitane_dti_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: `SUMA ${mortgageSum ? mortgageSum : 0}€`,
          data:`${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "DTI",
          data: null
        });
      }
      if (mortgage.prva_analyza_registracia_dt) {
        const date = new Date(mortgage.prva_analyza_registracia_dt);
        this.checkPoints++;
        this.progressBarData.push({
          label: "registrácia",
          data:`${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`,
        });
      } else {
        this.progressBarData.push({
          label: "registrácia",
          data: null,
        });
      }
    },
    calculateDti() {
      return 158000;
    },
    mapIncomeType(incomes) {
      const incomesStored = incomes.sort((a, b) => new Date(a.created) - new Date(b.created));
      return this.translateIncomeType(incomesStored[0].druh);
    },
    getMortgagePurpose () {
      let maxAmount = Number.MIN_SAFE_INTEGER;
      const mortgage = this.clientData.mortgages[0];
      let purpose = null;
      const amounts = {
        suma_nadobudnutie: mortgage.suma_nadobudnutie,
        suma_refinancovanie: mortgage.suma_refinancovanie,
        suma_bezucel: mortgage.suma_bezucel,
      };
      for (const [key, amount] of Object.entries(amounts)) {
        if (amount < maxAmount) continue;
        maxAmount = amount;
        purpose = key;
      }
      if (maxAmount === 0) {
        purpose = mortgage.ciel_prva_analyza;
      }
      switch (purpose) {
        case "suma_bezucel":
        case "americka_hypo":
          purpose = "bezucel";
          break;
        case "suma_refinancovanie":
        case "refinancovanie":
        case "konsolidacia":
          purpose = "refinancovanie";
          break;
        case "suma_nadobudnutie":
        case "nadobudnutie":
        case "uverovy_certifikat":
          purpose = "nadobudnutie";
          break;
        default:
          purpose = "nadobudnutie";
          break;
      }
      return purpose;
    },
    openClientChatPopUp () {
      this.clientData.sent_unread_messages = 0;
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.client,
        component: "ClientChatPopUp",
        rules: null,
      });
    },
    async archiveClient () {
      let typeOfClient = null;
      let client = null;
      switch (this.client.client_profile.ob_status) {
        case "archivovany":
          typeOfClient = "archivedClients";
          client = {
            clientType: typeOfClient,
            clientId: this.client.id,
            clientProfileId: this.client.client_profile.id,
            temporary: this.client.is_temporary,
            clientProfile: {
              ob_status: "novy",
            },
          };
          break;
        case "novy":
          typeOfClient = "temporaryClients";
          client = {
            clientType: typeOfClient,
            clientId: this.client.id,
            clientProfileId: this.client.client_profile.id,
            temporary: this.client.is_temporary,
            clientProfile: {
              ob_status: "archivovany",
            },
          };
          break;
        case "rozpracovaný":
          typeOfClient = "registeredClients";
          client = {
            clientType: typeOfClient,
            clientId: this.client.id,
            clientProfileId: this.client.client_profile.id,
            temporary: this.client.is_temporary,
            clientProfile: {
              ob_status: "archivovany",
            },
          };
          break;
      }
      if (this.client.client_profile.ob_status !== "archivovany") {
        await this.$store.dispatch("clientState/archiveClient", client);
        this.$store.commit("appState/setSuccessMessage", {
          text: "archivácia klienta prebehla úspešne",
          type: "success"
        });
        this.$emit("client-archived", true);
      } else {
        await this.$store.dispatch("clientState/unarchiveClient", client);
        this.$store.commit("appState/setSuccessMessage", {
          text: "obnova klienta prebehla úspešne",
          type: "success"
        });
        this.$emit("client-archived", false);
      }
    },
    getArchiveImage () {
      if (this.client.client_profile.is_archived) {
        return require("@/images/recovery-icon.png");
      }
      return require("@/images/karta_klienti_trash-icon.svg");
    },
    getAnalyzeStep () {
      return 1;
    },
    getDate (created) {
      const date = new Date(created.sent_latest_message_dt);
      return date.getDate();
    },
    getHours (created) {
      const date = new Date(created.sent_latest_message_dt);
      return date.getHours();
    },
    getMinutes (created) {
      const date = new Date(created.sent_latest_message_dt);
      if (date.getMinutes() < 10) {
        return "0" + date.getMinutes();
      }
      return date.getMinutes();
    },
    getMonth (created) {
      const date = new Date(created.sent_latest_message_dt);
      return date.getMonth() + 1;
    },
    getYears (created) {
      const date = new Date(created.sent_latest_message_dt);
      return date.getFullYear();
    },
    getDay (created) {
      const date = new Date(created.sent_latest_message_dt);
      return date.getDay();
    },
  },
  watch: {
    async refreshGetter(refresh) {
      if (!this.search || !refresh) return;
      this.clientData = await getClientById(this.clientData.id);
    },
    client (newClient) {
      const deepEqual = require("deep-equal");
      if (deepEqual(this.clientData, newClient, true)) return;
      this.clientData = newClient;
      this.checkProgressBar();
    },
    open: function () {
      let routeData = this.$router.resolve({
        name: "ChatContent",
        params: {id: this.client.id},
      });
      window.open(
        routeData.href,
        "_blank",
        "height=818,width=515"
      );
    },
  },
};
</script>

<style scoped lang="scss">
</style>
