<template>
  <div class="filter-control">
    <filter-by-period
      :label="'Zobraziť klientov za'"
      @period-filter-change="$emit('period-filter-change', $event)"
      @period-statistic-change="$emit('period-statistic-change', $event)"
    />
    <filter-by-employee
      v-if="role === 'alex_admin'"
      :label="'pre brokera'"
      :employees="brokers"
      @employee-filter-change="$emit('broker-filter-change', $event)"
    />
    <filter-by-employee
      v-if="role === 'alex_admin'"
      :label="'alebo znalca'"
      :employees="experts"
      @employee-filter-change="$emit('broker-filter-change', $event)"
    />
    <search-by-key @filter-client-by-key="$emit('filter-client', $event)"/>
  </div>
</template>

<script>
import FilterByEmployee from "../../shared/FilterResults/FilterByEmployee";
import SearchByKey from "../../shared/FilterResults/SearchByKey";
import FilterByPeriod from "../../shared/FilterResults/FilterByPeriod.vue";

export default {
  name: "TestimonialsFilterBar",
  components: {
    FilterByPeriod,
    SearchByKey,
    FilterByEmployee
  },
  created () {
    this.role = this.$store.getters["userState/getRole"];
    if (this.role !== "alex_admin") return;
    this.brokers = this.$store.getters["clientState/getFinancnici"];
    this.experts = this.$store.getters["clientState/getExperts"];
  },
  data: () => ({
    brokers: [],
    experts: [],
    role: null,
  }),
}
</script>

<style scoped>

</style>
