<template>
  <div class="popup popup-form">
    <div
      data-w-id="cc289cfd-0be4-7987-a2cf-7e1c631923e0"
      class="content-form-popup"
      style="padding: 0px; height: 100%;"
    >
      <chat-content :client="payload" :quick-message="quickMessage" @quickMessageSet="quickMessage = null"/>
    </div>
  </div>
</template>

<script>
import ChatContent from "../../../../broker/ClientChat/ChatContent";

export default {
  name: "ClientChatPopUp",
  components: {
    ChatContent
  },
  props: {
    payload: Object,
    metaData: [Object, null],
  },
  watch: {
    metaData (newMeta) {
      if (!newMeta) return;
      this.quickMessage = newMeta.quickMessage;
    },
  },
  data: () => ({
    quickMessage: null,
  })
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute !important;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
