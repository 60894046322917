<template>
  <div class="popup-form">

    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Osobné údaje</div>
        <button form="client-profile" class="w-button saveformBtn">Uložiť</button>
      </div>
      <div class="header-block inline" v-if="clientInfo.type">
        <div class="bold">{{ clientInfo.type }}</div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
      </div>
      <div>
        <personal-info-form :client="payload" @client-saved="saveClient($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalInfoForm from "./PersonalInfoForm";

export default {
  name: "ClientPersonalInfoPopUp",
  components: {PersonalInfoForm},
  props: {
    payload: [Object, null],
  },
  created () {
    this.clientInfo = this.payload.clientInfo;
  },
  methods: {
    async saveClient (clientSavedObject) {
      clientSavedObject.clientInfo.type = this.clientInfo.type;
      let successMessageLabel = null;
      if (this.payload.clientInfo.type) {
        successMessageLabel = `${this.payload.clientInfo.type}ove údaje boli aktualizované`;
      } else {
        successMessageLabel = "Klientove údaje boli aktualizované";
      }
      clientSavedObject.successMessage = successMessageLabel;
      await this.$store.dispatch("mortgageDetailState/patchClientProfile", clientSavedObject);
      this.$store.commit("popupManagerState/setUserManagerRefresh", true);
      this.closePopup();
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    clientInfo: null,
  }),
};
</script>

<style scoped lang="scss">

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
