<template>
  <form @submit.prevent="saveDebt" id="outcome-submit">
    <div id="email-form" data-name="Email Form" class="form-2">
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'banka')"
          :id="'credit_card_banka'"
          :init-value="form.banka"
          :options="bankChoices"
          normal
          :name="'Spoločnosť'"
        />
      </div>
      <div class="input-group">
        <label for="Limit" class="field-label">Limit</label
        ><input
        v-model.number="form.limit"
        type="number"
        class="text-input w-input required"
        maxlength="256"
        name="Limit"
        data-name="Limit"
        placeholder=""
        id="Limit"
        required
      />
      </div>
      <div class="input-group">
        <label for="zostatok-3" class="field-label"
        >Zostatok<strong></strong></label
        ><input
        @keydown.tab.prevent
        v-model.number="form.zostatok"
        type="number"
        step="0.01"
        class="text-input w-input"
        maxlength="256"
        name="zostatok"
        data-name="zostatok"
        id="zostatok-3"
      />
      </div>
    </div>
  </form>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "CreaditCardOutcome",
  components: {DropdownSelect},
  props: {
    debt: [Object, null],
    banks: [Array, Object],
  },
  data: () => ({
    form: {
      limit: null,
      banka: null,
      zostatok: null,
    },
    bankChoices: [],
  }),
  created () {
    for (const bank of this.banks) {
      this.bankChoices.push({
        display_name: bank.nazov,
        value: bank.id,
      });
    }
    if (this.debt) {
      // console.log(this.debt);
      // console.log(this.bankChoices);
      this.form.banka = this.debt.banka;
      this.form.limit = this.debt.limit;
      this.form.zostatok = this.debt.zostatok;
    }
  },
  methods: {
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
    saveDebt () {
      const debtObject = {
        limit: this.form.limit,
        zostatok: this.form.zostatok,
        banka: this.form.banka,
        vyska_splatky: (this.form.limit * 0.05).toFixed(2),
      };
      if (this.debt) {
        debtObject.id = this.debt.id;
      }
      this.$emit("debt-saved", debtObject);
    },
  },
};
</script>

<style scoped></style>
