<template>
  <div
    class="center"
    @click="openTestimonialDeadlinePopup"
  >
    <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0e_icons8-calendar.svg">
  </div>
</template>

<script>
export default {
  name: "TestimonialDeadlineHandler",
  methods: {
    openTestimonialDeadlinePopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "TestimonialDeadlinePopUp",
        rules: ['single'],
      });
    }
  },
}
</script>

<style scoped>

</style>
