<template>
  <div class="content-panel">
    <!-- zapier uzatvorene zmluvy bankari -->
    <script type='module' src='https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js'></script>
    <zapier-interfaces-page-embed page-id='cm0zb3g4a000cw7gokwp6ocod' no-background='false' allow-query-params='true' query-params='' style='max-width: 100%; height: 100%; overflow: hidden;'></zapier-interfaces-page-embed>
  </div>
</template>

<script>

export default {
  name: "KartaNastavenia",
  components: {
    
  },
}
</script>

<style scoped>

.content-panel{
  padding: 20px; 
  height: 100%;
}

</style>