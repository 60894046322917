<template>
  <div class="popup-form">
    <div
      class="content-form-popup noselect"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Termín obhliadky</div>
        <div class="w-button saveformBtn">Uložiť</div>
      </div>
      <div
        class="input-group"
      >
        <label for="podnikamOd" class="field-label"
        >Termín obhliadky</label
        ><input
        type="datetime-local"
        class="text-input w-input date-input"
        :min="todayLong"
        maxlength="256"
        name="podnikamOd"
        data-name="podnikamOd"
        id="podnikamOd"
        required
      />
      </div>
      <div
        class="input-group"
      >
        <label for="sprava" class="field-label"
        >Správa pre klienta</label
        >
        <textarea
          class="text-input w-input date-input"
          id="sprava"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialDeadlinePopUp",
  created () {
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-') + "T" + [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(':');
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    addZero (value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
  },
  data: () => ({
    todayLong: null,
  }),
}
</script>

<style lang="scss" scoped>
.information-wrapper {
  margin-top: 10px;
  display: flex;
  column-gap: 105px;
  flex-direction: column;
  align-items: center;
}

.information-wrapper * {
  margin-top: 10px;
}

.information-wrapper > span {
  text-align: center;
  font-size: 15px;
}
</style>
