import Vue from 'vue';

Vue.filter('dotTheAmount', function (amount) {
  if (!amount) return 0;
  return Math.floor(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
});

Vue.filter('floor', function (amount) {
  return Math.floor(amount);
});

Vue.filter('ceil', function (amount) {
  if (!amount) return 0;
  return Math.ceil(amount);
});

Vue.filter('toFloor', function (number, amount) {
  if (!number) return 0;
  return number.toFixed(amount);
})
