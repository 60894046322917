<template>
  <div>
    <div class="input-group">
      <label for="meno" class="field-label">Meno:</label>
      <input
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="meno"
        data-name="meno"
        id="meno"
        required=""
      />
    </div>
    <div class="input-group">
      <label for="prezvisko" class="field-label">Priezvisko:</label>
      <input
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="prezvisko"
        data-name="prezvisko"
        id="prezvisko"
        required=""
      />
    </div>
    <div class="input-group">
      <label for="adresa" class="field-label">Adresa:</label>
      <input
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="adresa"
        data-name="adresa"
        id="adresa"
        required=""
      />
    </div>
    <div class="input-group">
      <label for="email" class="field-label">Email:</label>
      <input
        type="email"
        class="text-input w-input"
        maxlength="256"
        name="email"
        data-name="email"
        id="email"
        required=""
      />
    </div>
    <div class="input-group">
      <label for="Telefon" class="field-label">Telefón:</label>
      <input
        type="tel"
        class="text-input w-input"
        maxlength="256"
        name="Telefon"
        data-name="Telefon"
        id="Telefon"
        required=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPersonForm"
}
</script>

<style scoped>

</style>
