<template>
    <img @click="openQuickResponsePopup" class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2a_icons8-timer.svg" alt="">
</template>

<script>
export default {
  name: "QuickResponseChatHandler",
  methods: {
    openQuickResponsePopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "QuickResponseChatPopUp",
        rules: ['single'],
      });
    },
  },
}
</script>

<style scoped>

</style>
