<template>
  <div class="popup-form">
    <div class="header-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg" alt=""/>
        </div>
        <div id="Client-name" class="blackcolor pop-800">Faktúra & objednávateľ</div>
      </div>
      <button class="button-primary-expert w-button">
        Uložiť
      </button>
    </div>
    <div
      data-w-id="d3c42ea8-0e05-8060-b016-22f1a2f40311"
      class="content-form-popup noselect"
    >
      <div class="dropdown-input-custom">
        <input
          type="text"
          style="opacity: 0; position: absolute"
          required
        />
        <dropdown-select
          @select="setDropdownValue($event, 'orderer')"
          :id="'orderer'"
          :init-value="form.orderer"
          :options="personChoices"
          normal
          :name="'Profesia'"
        />
      </div>
      <contact-person-form/>
      <div class="dropdown-input-custom mt-4">
        <input
          type="text"
          style="opacity: 0; position: absolute"
          required
        />
        <dropdown-select
          @select="setDropdownValue($event, 'contactPerson')"
          :id="'contactPerson'"
          :init-value="form.contactPerson"
          :options="personChoices"
          normal
          :name="'Profesia'"
        />
      </div>
      <contact-person-form v-if="form.contactPerson"/>
    </div>
  </div>
</template>

<script>
import ContactPersonForm from "./ContactPersonForm";
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "TestimonialInvoicePopUp",
  components: {DropdownSelect, ContactPersonForm},
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    setDropdownValue (value, type) {
      this.form[type] = value;
    },
  },
  data: () => ({
    personChoices: [
      {
        display_name: "Žiadateľ",
        value: false,
      },
      {
        display_name: "Tretia strana",
        value: true,
      },
    ],
    form: {
      orderer: false,
      contactPerson: false,
    },
  }),
}
</script>

<style scoped>

</style>
