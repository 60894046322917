<template>
  <div class="popup-form">
    <div
      class="content-form-popup noselect"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Vyberte znalca</div>
        <button class="button-primary-expert w-button" @click="assignExpertToClient">Priradiť</button>
      </div>
      <div
        class="popup-block pointer"
        v-for="(expert, key) in experts"
        :key="key"
        @click="selectExpert(key)"
      >
        <div class="vertical-align-divs flex-row">
          <div class="user-relation">
            <div>
              <img v-if="expertSelected === key" 
                  class="nav-link-icon filter-primary"
                  src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
                  alt="">
              <img
                v-if="expert.profile_image"
                :src="expert.profile_image"
                loading="lazy"
                sizes="100vw"
                alt=""
                class="broker-image"
              />
              <img
                v-else
                src="@/images/Michal-Voronič.jpg"
                loading="lazy"
                sizes="100vw"
                alt=""
                class="broker-image"
              />
            </div>
            <div class="text-block">
              <div class="text--black">
                {{ expert.first_name }} {{ expert.last_name }}
              </div>
            </div>
          </div>
          <div class="cta-text">1</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesConvert from "../../../../../mixins/imagesConvert";

export default {
  name: "ExpertPickerPopUp",
  mixins: [
    imagesConvert
  ],
  created () {
    this.experts = this.$store.getters["clientState/getExperts"];
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    assignExpertToClient () {
    },
    selectExpert (key) {
      if (this.expertSelected === key) {
        this.expertSelected = null;
        return;
      }
      this.expertSelected = key;
      this.expertId = this.experts[key].id;
    },
  },
  data: () => ({
    experts: [],
    expertSelected: null,
    expertId: null
  }),
}
</script>

<style lang="scss" scoped>
.selected-expert {
  border-style: none none none solid;
  border-color: #0a1953 #0a1953 #0a1953 #0019d9;
  border-left-width: 10px;
}
</style>
