<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
      </div>
      
         <!-- objednávkový form OKposudok od zapier -->
         <div class="zapierIframe">
            <script type='module' src='https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js'></script>
            <zapier-interfaces-page-embed page-id='clrgssxod000a86puchoptnjc' no-background='true'  style='max-width: 100%; height: 100%;'></zapier-interfaces-page-embed>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "posudokOrderPopup",
  props: {
    type: String,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style lang="scss" scoped>
.header-popup-wrapper {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icontext {
  line-height: 12px;
  font-size: 17px;
}

.zapierIframe{
    height: 220vh;
    margin: -20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

</style>
