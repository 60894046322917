<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Osobné údaje</div>
      </div>
      <client-verification-local :user="payload"/>
    </div>
    
  </div>
</template>

<script>
import ClientVerificationLocal from "./ClientVerificationLocal";

export default {
  name: "ClientVerificationPopUp",
  components: {ClientVerificationLocal},
  props: {
    type: String,
    payload: Number,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style scoped lang="scss">
.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
