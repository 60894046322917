<template>
  <div>
    <div class="mortgage-result-broker" v-if="offer.success">
      <div class="pin-button pointer" @click="pinOffer()">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f26_icons8-star.svg" alt="" v-if="!offer.pinned">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/6491453bd08ddae77bee9864_star%20colored.svg" alt="" v-else>
      </div>
      <div class="mortgage-parameters">
        <img
          style="object-fit: contain"
          :src="offer.bank.logo"
          loading="lazy"
          alt=""
          class="bank-logo"
        />
        <div
          class="bold wrap100px"
        >
          {{ offer.pozicana_suma | dotTheAmount }} EUR
        </div>
        <div
          class="bold wrap50px"
          v-if="!offer.loadingSadzba"
        >
          {{ offer.sadzba | toFloor(2) }}<span class="upper-index">%</span>
        </div>
        <div
          v-else
          class="bold wrap50px"
        >
          <v-progress-circular
            class="loading-spinner small"
            indeterminate
          ></v-progress-circular>
        </div>
        <div
          class="bold wrap100px d-flex align-items-center"
          v-if="!offer.loadingSplatka"
        >
          {{ offer.mesacna_splatka | dotTheAmount }} <span class="upper-index">EUR</span>
        </div>
        <div
          v-else
          class="bold wrap50px"
        >
          <v-progress-circular
            class="loading-spinner small"
            indeterminate
          ></v-progress-circular>
        </div>
        <div
          class="bold d-flex align-items-center"
          :class="{'note-present': bankNotes.length !== 0}"
        >
          {{ offer.popl_sprac_hypo | dotTheAmount }} <span class="upper-index">EUR</span>
        </div>
        <div
        >
          <div class="d-flex flex-row align-items-center">
            <img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f29_icons8-expand-arrow.svg"
              class="nav-link-icon filter-primary"
              :class="{'disabled-maturity' : offer.splatnost_r === 0}"
              alt=""
              @click="decreaseMaturity"
            />
            <div class="bold wrap50px">
              {{ offer.splatnost_r }} <span class="upper-index">R</span>
            </div>
            <img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
              class="nav-link-icon filter-primary"
              :class="{'disabled-maturity' : offer.splatnost_r === maxMaturity}"
              alt=""
              @click="increaseMaturity"
            />
          </div>
        </div>
        <div
          class="bold d-flex align-items-center"
          :class="{'note-present': bankNotes.length !== 0}"
        >
          {{ offer.popl_za_vedenie_uctu }} EUR<span class="upper-index">/ m</span>
        </div>
        <div
          class="bold wrap50px"
        >
          {{ offer.fix }}<span class="upper-index">R</span>
        </div>
        <div
          class="bold wrap50px"
        >
        </div>
        <div @click="createApplication()" class="pointer noselect">
          <div
            class="text-small-link wrap40px broker"
            v-if="!loading"
          >
            VYBRAŤ
          </div>
          <v-progress-circular
            v-else
            class="loading-spinner small"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
      <div class="discount-list">
        <discount-item
          v-for="(discount, key) in offer.extra.mozne_zmeny_sadzieb"
          :key="key"
          :discount="discount"
          :mortgage-rate-changes="mortgageRateChanges"
          :mortgage-id="mortgageId"
          @discount-change="handleSadzbaChange"
        />
      </div>
      <div class="discount-list">
        <div
          class="discount-item"
          v-for="(noteObject, index) in ($store.getters['formStore/getBankByKey'](offer.bank.code)).notes"
          :key="index"
        >
          {{ noteObject.note }}
        </div>
      </div>
      <div class="d-flex MGtop20px">
        <div class="text-cta" @click="showCampaign(offer.bank.code)">
          AKTUÁLNE KAMPANE
        </div>
      </div>
    </div>
    <div class="mortgage-result-broker error-offer" v-else>
      <img
        :src="offer.bank.logo"
        width="35"
        height="35"
        loading="lazy"
        alt=""
        class="bank-logo"
      />
      <div>
        <div
          class="error-message blc-color"
          v-for="(error, key) in offer.details"
          :key="key"
        >
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiscountItem from "./DiscountItem";
import { createMortgageApplication, deleteMortgageApplicationByID } from "@/services/brokerService";
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import mortgageUtils from "../../../../../mixins/mortgageUtils";

export default {
  name: "MortgageApplicationChoiceItem",
  components: {
    DiscountItem
  },
  props: {
    offer: Object,
    bankKey: String,
  },
  mixins: [
    translateFromDBValue,
    mortgageUtils,
  ],
  computed: {
    ...mapGetters({
      maturityGetter: "mortgageDetailState/getMaturity",
      ratesGetter: "mortgageDetailState/getAppliedMortgageRateChanges",
    })
  },
  watch: {
    maturityGetter(newMaturity) {
      const purpose = this.getMortgagePurpose();
      const maturityObject = newMaturity[this.offer.bank.code][purpose];
      this.maxMaturity = maturityObject.success ? maturityObject.value : 0;
    },
    ratesGetter (newRates) {
      this.mortgageRateChanges = newRates;
    },
  },
  created () {
    // const notes = ;
    // for (const note of notes) {
    //   console.log(note)
    //   this.bankNotes.push(note.note);
    // }
    this.mortgageId = this.$store.getters["mortgageDetailState/getMortgage"].id;
    this.mortgageRateChanges = this.ratesGetter;
    const purpose = this.getMortgagePurpose();
    const maturityObject = (this.$store.getters["mortgageDetailState/getMaturity"])[this.offer.bank.code][purpose];
    this.maxMaturity = maturityObject.success ? maturityObject.value : 0;
  },
  methods: {
    showCampaign(bankKey) {
      switch(bankKey) {
        case "365": window.open('https://bankari.sk/hypoteka/365-bank', '_blank'); break;
        case "tatra": window.open('https://bankari.sk/hypoteka/tatra-banka', '_blank'); break;
        case "csob": window.open('https://bankari.sk/hypoteka/csob-banka', '_blank'); break;
        case "unicredit": window.open('https://bankari.sk/hypoteka/unicreditbank', '_blank'); break;
        case "slsp": window.open('https://bankari.sk/hypoteka/slovenska-sporitelna', '_blank'); break;
        case "vub": window.open('https://bankari.sk/hypoteka/vub-banka', '_blank'); break;
        case "prima": window.open('https://bankari.sk/hypoteka/prima', '_blank'); break;
        case "mbank": window.open('https://bankari.sk/hypoteka/mbank', '_blank'); break;
      }
    },
    decreaseMaturity() {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      this.$store.dispatch("mortgageDetailState/fetchOffer", {
        mortgageId: this.mortgageId,
        bankKey: this.offer.bank.code,
        loadingSplatka: true,
        loadingSadzba: false,
        pinned: this.offer.pinned,
        offerMeta: {
          bank: this.translateBankFromCodeNameToId(this.offer.extra.bank_code_name),
          fix: this.offer.fix,
          ...amounts,
          splatnost_m: (this.offer.splatnost_r - 1) * 12
        }
      });
    },
    increaseMaturity() {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      this.$store.dispatch("mortgageDetailState/fetchOffer", {
        mortgageId: this.mortgageId,
        bankKey: this.offer.bank.code,
        loadingSplatka: true,
        loadingSadzba: false,
        pinned: this.offer.pinned,
        offerMeta: {
          bank: this.translateBankFromCodeNameToId(this.offer.extra.bank_code_name),
          fix: this.offer.fix,
          ...amounts,
          splatnost_m: (this.offer.splatnost_r + 1) * 12
        }
      });
    },
    handleSadzbaChange() {
      this.$store.dispatch("mortgageDetailState/fetchOffersByBank", {
        mortgageId: this.mortgageId,
        bankKey: this.offer.bank.code,
        loadingSplatka: true,
        loadingSadzba: true,
      });
    },
    async registerNewApplication(offerObject) {
      let newApplication = await createMortgageApplication(offerObject, "bank");
      newApplication = await this.$store.dispatch("mortgageDetailState/fillMissingApplicationsData", [newApplication]);
      this.$store.commit("mortgageDetailState/registerNewApplication", newApplication[0]);
      await this.$store.dispatch("mortgageDetailCloud/addMissingCloudObject", {applications: newApplication});
    },
    async deleteExistingApplication() {
      const id = this.getIdOfExistingApplication();
      if (!id) return;
      await deleteMortgageApplicationByID(id);
      this.$store.commit("mortgageDetailState/deleteApplication", id)
    },
    async createApplication () {
      const isBankAlreadyCreated = this.$store.getters["mortgageDetailState/isBankApplicationCreated"](this.offer.bank.id);
      this.loading = true;
      const offerObject = this.getApplicationObject();
      for (const possibleRateChange of this.offer.extra?.mozne_zmeny_sadzieb) {
        if (!this.mortgageRateChanges.includes(possibleRateChange.id)) continue;
        offerObject.zmeny_sadzieb.push(possibleRateChange.id)
      }
      if (!isBankAlreadyCreated) {
        await this.registerNewApplication(offerObject);
        const mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
        const mortgageObject = {
          id: mortgage.id,
          object: {
            stage_old: mortgage.stage,
            stage: "podpis_ziadosti",
          },
        }
        this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      } else {
        this.showDialogToUser();
        const allowed = await this.waitForUserPermission();
        if (allowed) {
          await this.deleteExistingApplication()
          await this.registerNewApplication(offerObject);
        }
      }
      this.loading = false;
      await this.$store.dispatch("mortgageDetailState/fetchMortgage", {force: true});
    },
    getIdOfExistingApplication () {
      const mortgageApplications = this.$store.getters["mortgageDetailState/getMortgage"].applications;
      for (const offer of mortgageApplications) {
        if (offer.bank.code_name !== this.offer.bank.code) continue;
        return offer.id;
      }
      return null;
    },
    getApplicationObject () {
      return {
        mortgage: this.mortgageId,
        bank: this.offer.bank.id,
        splatnost: this.offer.splatnost_r,
        fix: this.offer.fix,
        sadzba_pozadovana: this.offer.sadzba.toFixed(2),
        splatka: this.offer.mesacna_splatka?.toFixed(2),
        poplatok_za_poskytnutie: this.offer.popl_sprac_hypo?.toFixed(2),
        suma_nadobudnutie: this.offer.ucel === "nadobudnutie" ? Math.floor(this.offer.pozicana_suma) : 0,
        suma_refinancovanie: this.offer.ucel === "refinancovanie" ? Math.floor(this.offer.pozicana_suma) : 0,
        suma_bezucel: this.offer.ucel === "bezucel" ? Math.floor(this.offer.pozicana_suma) : 0,
        zmeny_sadzieb: [],
      }
    },
    showDialogToUser () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Pre danú banku je už vytvorená žiadosť, želáte si ju nahradiť ?",
        type: "confirmation"
      });
    },
    waitForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    pinOffer () {
      let offersStored = JSON.parse(window.localStorage.getItem("offers-pinned"));
      if (this.offer.pinned) {
        offersStored = this.removeOffer(offersStored);
      } else {
        offersStored = this.addOffer(offersStored);
      }
      window.localStorage.setItem("offers-pinned", JSON.stringify(offersStored));
    },
    addOffer (offers) {
      let offersStored = offers;
      if (!offersStored) offersStored = [];
      let bankPinnedSum = 0;
      for (const offersStoredElement of offersStored) {
        if (offersStoredElement.bank.code !== this.offer.bank.code) continue;
        bankPinnedSum++;
      }
      if (bankPinnedSum === 3) {
        this.$store.commit("appState/setSuccessMessage", {
          text: `Maximálny možný počet pripnutých žiadostí pre ${this.offer.bank.name} bol dosiahnutý`,
          type: "error"
        });
        return offersStored;
      }
      this.$store.commit("mortgageDetailState/setPinnedApplication", this.offer);
      offersStored.push(this.offer);
      return offersStored;
    },
    removeOffer (offers) {
      let offersStored = offers;
      for (const [index, offer] of offersStored.entries()) {
        if (offer.bank.code === this.offer.bank.code && offer.fix === this.offer.fix && offer.splatnost_r === this.offer.splatnost_r && offer.value === this.offer.value) {
          offersStored.splice(index, 1);
        }
      }
      this.$store.commit("mortgageDetailState/setPinnedApplication", this.offer);
      return offersStored;
    }
  },
  data: () => ({
    bankNotes: [],
    maxMaturity: 0,
    loading: false,
    mortgageRateChanges: [],
    mortgageId: null,
    bank: null,
    sadzba: 0,
    sadzbaChange: 0,
  }),
};
</script>

<style lang="scss" scoped>

.disabled-maturity {
  filter: invert(94%) sepia(5%) saturate(6%) hue-rotate(332deg) brightness(87%) contrast(82%);
  cursor: not-allowed;
}

.mortgage-result-broker {
  position: relative;
}

.error-offer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-message {
  font-size: 13px;
  margin-left: 34px;
}

.error-message:not(:last-child) {
  margin-bottom: 5px;
}
</style>
