<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div id="Client-name" class="_900 big">
          Pridanie {{ getFullLabelType(type) }}
        </div>
      </div>
      <div class="w-form">
        <div id="email-form" class="form-2">
          <div class="splitter">
            1. Vyberte typ <strong>nehnuteľnosti</strong>, ktorú chcete pridať
          </div>
          <div
            data-w-id="8466810e-7557-a963-e1a4-1ff5d954c81e"
            class="option-block"
            @click="propertyTypePicker('house')"
          >
            <div class="bold">rodinný dom</div>
          </div>
          <div
            data-w-id="815ce0e9-0210-f8e5-3b0d-1a7cbddd7f06"
            class="option-block"
            @click="propertyTypePicker('flatInHouse')"
          >
            <div class="bold">byt v rodinnom dome</div>
          </div>
          <div
            data-w-id="7cf0ddfc-1840-5f61-2b30-9494c9becaa4"
            class="option-block"
            @click="propertyTypePicker('flatInFlat')"
          >
            <div class="bold">byt v bytovom dome</div>
          </div>
          <div
            data-w-id="0802c97d-bcad-27ac-d75b-f75f48e0a1e5"
            class="option-block"
            @click="propertyTypePicker('land')"
          >
            <div class="bold">pozemok</div>
          </div>
          <div class="splitter">2. alebo vyberte z už pridaných</div>
          <div
            class="input-block p-relative"
            v-for="(property, key) in properties.propertiesObjects"
            :key="key"
          >
            <div class="div-block-101">
              {{ getAddressModified(property) }}
              <v-progress-circular
                v-if="property.loading"
                class="loading-spinner extrasmall"
                indeterminate
              ></v-progress-circular>
              <div class="bold" v-else>{{ property.hodnota | dotTheAmount }} EUR</div>
            </div>
            <div class="div-block-112">
              <div class="text-cta" @click="edit(property)">EDIT</div>
              <div
                class="text-cta"
                v-if="!isUsed(property.id)"
                @click="pick(property.id)"
              >
                PICK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "ClientPropertyPicker",
  props: {
    type: String,
    rules: [Array, null],
    payload: [Array, null],
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "mortgageDetailState/getProperties",
    }),
  },
  watch: {
    propertiesGetter (newProperties) {
      this.properties = newProperties;
    },
  },
  mixins: [
    propertyFilters
  ],
  data: () => ({
    mortgageId: null,
    properties: [],
  }),
  created () {
    this.mortgageId = this.$store.getters["mortgageDetailState/getMortgage"].id;
    this.properties = this.$store.getters["mortgageDetailState/getProperties"];
  },
  methods: {
    pick (id) {
      if (this.type === "zatazene_nehnutelnosti") {
        this.$emit("set-meta", {id: id});
        this.closePopup();
      } else {
        const data = {
          propertyId: id,
          mortgageId: this.mortgageId,
          object: {},
          propertyLabel: this.getLabelByType(this.type),
        }
        if (this.type === "zakladane_nehnutelnosti") {
          data.object[this.type] = [...this.properties.foundedPropertiesIds, id];
          data.propertyType = "foundedPropertiesIds";
        } else if (this.type === "financovane_nehnutelnosti") {
          data.object[this.type] = [...this.properties.financedPropertiesIds, id];
          data.propertyType = "financedPropertiesIds";
        }
        this.$store.dispatch("mortgageDetailState/pickProperty", data);
      }
    },
    edit (pProperty) {
      let type = pProperty.child_type;
      if (type === "flat" && pProperty.typ_bytu === "v_rodinnom_dome") {
        type = "flatInHouse";
      } else if (type === "flat" && pProperty.typ_bytu === "v_bytovom_dome") {
        type = "flatInFlat";
      }
      this.propertyTypePicker(type, pProperty);
    },
    isUsed (id) {
      if (this.type === "zakladane_nehnutelnosti" && this.isFounded(id)) return true
      else if (this.type === "financovane_nehnutelnosti" && this.isFinanced(id)) return true;
      else if (!!this.payload && this.payload.includes(id)) return true;
      return false;
    },
    isFounded (id) {
      return this.properties.foundedPropertiesIds.includes(id);
    },
    isFinanced (id) {
      return this.properties.financedPropertiesIds.includes(id);
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    propertyTypePicker (type, propertyData = null) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {druh: this.type, child_type: type},
        payload: propertyData,
        component: "ClientPropertyPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.pop-up-active {
  transform: translateX(0);
}

.header-form-popup {
  border: none;
}
</style>
