<template>
  <div class="bold" :class="classCst" :style="styleCst" @click="showUserManager">
    <span class="noselect" v-if="!clientInfo.first_name">
      ID {{ clientInfo.id }}
    </span>
    <span class="noselect" v-else>
      {{ clientInfo.first_name }} {{ clientInfo.last_name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "UserManagerHandler",
  props: {
    clientInfo: Object,
    dti: Boolean,
    styleCst: [String, null],
    classCst: [String, null],
    rules: [Array, null],
    mortgageLayout: Boolean,
    client: Boolean,
    clients: Boolean,
    detail: Boolean,
    mortgages: [Object, Boolean, null],
  },
  // watch: {
  //   clientInfo (e) {
  //     console.log(e);
  //   },
  // },
  methods: {
    showUserManager () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {
          detail: this.detail,
          clients: this.clients,
          client: this.client,
          mortgage: this.mortgages,
          mortgageLayout: this.mortgageLayout
        },
        payload: this.clientInfo,
        component: "UserManagerPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped></style>
