<template>
  <div class="poppins" style="height: 100%">
    <nav-bar-broker/>
    <popup-handler/>
    <loading-page/>
    <karta-kalendar/>
    <router-view style="min-height: 100%" v-if="!mobileView"></router-view>
    <success-message-handler/>
  </div>
</template>

<script>
import NavBarBroker from "../components/broker/NavBarBroker";
import PopupHandler from "../components/shared/PopupHandler/PopupHandler";
import SuccessMessageHandler from "../components/shared/SuccessMessageHandler";
import LoadingPage from "../components/shared/LoadingPage";
import KartaKalendar from "../containers/broker/KartaKalendar/KartaKalendar";

export default {
  name: "Broker",
  components: {
    NavBarBroker,
    KartaKalendar,
    LoadingPage,
    SuccessMessageHandler,
    PopupHandler,
  },
  created() {
    this.$store.dispatch("mortgageDetailCloud/initializeFileTypes");
    this.$store.dispatch("clientsEvents/updateUserEvents");
    this.$store.dispatch("clientState/retrieveEmployees", ["financnik", "alex_admin"]);
    this.$store.dispatch("formStore/initializeForms");
    // const viewPortWidth = window.innerWidth;
    // if (viewPortWidth > 791) return;
    // this.mobileView = true;
    // this.$store.commit("popupManagerState/pushToPopupOrder", {
    //   type: 'define',
    //   payload: null,
    //   component: "NotificationPopUp",
    //   rules: ['priority'],
    // });
  },
  data: () => ({
    mobileView: false,
  })
};
</script>

<style scoped></style>
