<template>
  <div id="sumsub-websdk-container"></div>
</template>

<script>
import snsWebSdk from '@sumsub/websdk';
import { getSumSubToken } from "../../../../../services/brokerService";

export default {
  name: "ClientVerificationLocal",
  props: {
    user: Number,
  },
  async mounted () {
    const accessToken = await this.getNewAccessToken();
    // console.log(this.user);
    let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => this.getUpdatedAccessToken()
    )
      .withConf({
        lang: 'sk', //language of WebSDK texts and comments (ISO 639-1 format)
      })
      .withOptions({addViewportTag: false, adaptIframeHeight: true})
      // see below what kind of messages WebSDK generates
      .on('idCheck.stepCompleted', (payload) => {
        // console.log('stepCompleted', payload)
      })
      .on('moduleResultPresented', (e) => {
        // console.log(e);
      })
      .on('idCheck.onError', (error) => {
        // console.log('onError', error)
      })
      .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  },
  methods: {
    async getNewAccessToken () {
      return (await getSumSubToken({user_id: this.user})).access_token;
    },
    async getUpdatedAccessToken () {
      const token = (await getSumSubToken({user_id: this.user})).access_token;
      return Promise.resolve(token);
    }
  }
}
</script>

<style scoped>

</style>
