<template>
  <div class="master-filter">
    <div class="dropdown-title d-inline mr-4">
      podľa
    </div>
    <chip-input
      :factor="10"
      :type="'text'"
      :color="'white--text'"
      :placeholder="'mena'"
      @value-changed=""
      @change-event="filterClients($event)"
    />
    <label id="search-label" class="pt-1 pl-2">
      <svg width="22" height="20" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.375 0C7.272 0 5.37157 1.16321 4.40332 3.00146C1.96657 3.05246 0 5.0505 0 7.5C0 9.98175 2.01825 12 4.5 12H8.4624C8.32515 11.526 8.25 11.0235 8.25 10.5H4.5C2.8455 10.5 1.5 9.1545 1.5 7.5C1.5 5.8455 2.8455 4.5 4.5 4.5H5.37891L5.57227 4.04004C6.21652 2.49729 7.7085 1.5 9.375 1.5C11.523 1.5 13.2912 3.15222 13.481 5.25146C13.487 5.25146 13.4932 5.25 13.5 5.25C14.0205 5.25 14.5195 5.32594 14.9912 5.46094C14.9035 2.43544 12.4215 0 9.375 0ZM13.5 6.75C11.4 6.75 9.75 8.4 9.75 10.5C9.75 12.6 11.4 14.25 13.5 14.25C14.25 14.25 15.0006 14.0244 15.6006 13.6494L16.9497 15L18 13.9497L16.6494 12.6006C17.0244 12.0006 17.25 11.25 17.25 10.5C17.25 8.4 15.6 6.75 13.5 6.75ZM13.5 8.25C14.775 8.25 15.75 9.225 15.75 10.5C15.75 11.775 14.775 12.75 13.5 12.75C12.225 12.75 11.25 11.775 11.25 10.5C11.25 9.225 12.225 8.25 13.5 8.25Z"
          fill="white"/>
      </svg>
    </label>
  </div>
</template>

<script>
import ChipInput from "../ChipInput";

export default {
  name: "SearchByKey",
  components: {ChipInput},
  methods: {
    filterClients (key) {
      this.$emit("filter-client-by-key", key);
    },
  }
}
</script>

<style lang="scss" scoped>
#search-label:hover {
  cursor: pointer;
}
</style>
