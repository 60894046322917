export default {
  namespaced: true,
  state: {
    me: null,
    accessToken: null,
    refreshToken: null,
    logged: false,
    id: null,
    role: null,
    loading: true,
  },
  getters: {
    getMe: (state) => {
      return state.me;
    },
    getLogged: (state) => {
      return state.logged;
    },
    getId: (state) => {
      return state.me.id;
    },
    getRole: (state) => {
      return state.me.role;
    },
  },
  mutations: {
    setMe: (state, me) => {
      state.me = me;
    },
    setLogged (state, data) {
      state.logged = data.logged;
    },
  },
};
