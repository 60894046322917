<template>
  <div class="div-block-25">
    <div
      data-w-id="4c06adb2-2b97-ce79-91ef-5e3b3e797c81"
      class="add-prijem-btn"
    >
      <div class="text-small bl-color pointer" @click="openDebtTypePicker()">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientDebtHandler",
  props: {
    title: String,
    rules: [Array, null],
    clientInfo: [Object, null],
    type: [String, null],
  },
  methods: {
    openDebtTypePicker () {
      if (this.clientInfo) this.$store.commit("popupManagerState/setClientInfo", this.clientInfo);
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: this.type,
        payload: null,
        component: "ClientDebtPicker",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped></style>
