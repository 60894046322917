<template>
  <div class="main-view-stage-cerpanie">
    <document-cloud-handler
      :cloud-meta="getCloudMeta()"
      :rules="['single']"
      :class-cst="'cloud-button add-obhliadka white--text no-decoration'"
      icon-button
    />
    <contract-sign />
    <application-document-finish />
  </div>
</template>

<script>
import DocumentCloudHandler from "../../../../components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler";
import ContractSign from "@/components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationSummary/ContractSign.vue";
import ApplicationDocumentFinish
  from "@/components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationSummary/ApplicationDocumentFinish.vue";

export default {
  name: "ApplicationSummary",
  components: {ApplicationDocumentFinish, ContractSign, DocumentCloudHandler},
  props: {
    mortgage: [Object, null],
  },
  methods: {
    getCloudMeta () {
      return this.$store.getters["mortgageDetailCloud/getFullClouds"];
    },
  }
}
</script>

<style scoped>

</style>
