import { render, staticRenderFns } from "./KlientiItemWrapper.vue?vue&type=template&id=10c5263c&scoped=true&"
import script from "./KlientiItemWrapper.vue?vue&type=script&lang=js&"
export * from "./KlientiItemWrapper.vue?vue&type=script&lang=js&"
import style0 from "./KlientiItemWrapper.vue?vue&type=style&index=0&id=10c5263c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c5263c",
  null
  
)

export default component.exports