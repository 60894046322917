<template>
  <form
    @submit.prevent="login"
    id="login-form"
    name="Sign-In"
    data-name="Sign In"
    method="get"
    class="inner-form"
  >
    <div class="callout-box-wrapper margin-bottom-small">
      <a
        href="#"
        class="logo-footer margin-bottom-small w-inline-block"
      ><img
        loading="lazy"
        src="@/images/Bankari-logo-transparent-extrabold.svg"
        alt=""
      /></a>
      <p class="main-paragraph text-white-opacity-strong">
        Nečakaj. Podmienky nebudú nikdy perfektné.
      </p>
      <p v-if="isError" class="red--text">S danými prihlasovacími údajmi nebolo možné sa prihlásiť.</p>
    </div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4041"
      class="inner-input"
    >
      <input
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        type="email"
        class="text-field-3 dark w-input"
        maxlength="256"
        name="Email"
        v-model="email"
        data-name="Email"
        placeholder="Zadajte email"
        id="Email"
        required=""
      />
    </div>
    <div style="margin-top: 10px"></div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4045"
      class="inner-input margin-bottom-xsmall"
    >
      <div class="passw-input">
        <input
          v-on:keydown.enter.prevent="login"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-once="true"
          :type="[typePassword ? 'password' : 'text']"
          class="text-field-3 dark w-input"
          maxlength="256"
          name="Heslo"
          v-model="password"
          data-name="Heslo"
          placeholder="*********"
          id="Heslo"
          required=""
        />
        <div class="icon-wrapper" @click="typePassword = !typePassword">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-fill" viewBox="0 0 16 16" v-if="typePassword">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path
              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-slash-fill" viewBox="0 0 16 16" v-else>
            <path
              d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
            <path
              d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {loginUser} from "@/services/brokerService";
import adminRoutes from "@/router/adminRoutes";
import brokerRoutes from "@/router/brokerRoutes";
import expertRoutes from "@/router/expertRoutes";

export default {
  name: "LoginForm",
  methods: {
    async login () {
      this.$store.commit("appState/setLoadingPage", true);
      this.isError = false;
      try {
        const user = await loginUser(this.email, this.password);
        if (
          !["alex_admin", "financnik", "znalec"]
            .includes(user.user?.role)
        ) {
          this.$store.commit("appState/setLoadingPage", false);
          this.isError = true;
          return;
        }
        this.isError = false;
        this.$store.commit("userState/setMe", user.user);
        this.$store.commit("userState/setLogged", {
          logged: true,
        });
        const entryUrl = this.$store.getters["appState/getEntryUrl"];
        if (["alex_admin"].includes(user.user?.role)) {
          this.$router.addRoute(adminRoutes);
        } else if (["financnik"].includes(user.user?.role)) {
          this.$router.addRoute(brokerRoutes);
        } else if (["znalec"].includes(user.user?.role)) {
          this.$router.addRoute(expertRoutes);
        }
        if (!!entryUrl) {
          this.$router.push(entryUrl);
        } else {
          this.$router.push({name: "RoleRoutes"});
        }
        this.$store.commit("appState/setLoadingPage", false);
      } catch (e) {
        const error = e.toJSON();
        if (error.status === 400) {
          this.isError = true;
        }
        this.$store.commit("appState/setLoadingPage", false);
      }
    },
  },
  data: () => ({
    typePassword: true,
    password: null,
    email: null,
    wrongRole: false,
    isError: false,
  }),
}
</script>

<style scoped>

</style>