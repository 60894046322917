<template>
  <div class="popup-form">
  
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Vyberte brokera</div>
        <button
          class="w-button"
          @click="assignBrokerToClient()"
        >
          Priradiť
        </button>
      </div>

      <div
        class="popup-block pointer"
        v-for="(broker, key) in brokers"
        :key="key"
        @click="selectBroker(key)"
      >
        <div class="vertical-align-divs flex-row">
          <div class="user-relation">
            <div>
              <img v-if="brokerSelected === key" 
                class="nav-link-icon filter-primary"
                style="margin-right: 10px;"
                src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
                alt="">
              <img
                v-if="broker.profile_image"
                :src="broker.profile_image"
                loading="lazy"
                sizes="100vw"
                alt=""
                class="broker-image"
              />
              <img
                v-else
                src="@/images/Michal-Voronič.jpg"
                loading="lazy"
                sizes="100vw"
                alt=""
                class="broker-image"
              />
            </div>
            <div class="text-block">
              <div class="text--black">
                {{ broker.first_name }} {{ broker.last_name }}
              </div>
            </div>
          </div>
          <div class="cta-text">{{ broker.aktivne_hypoteky_financnika }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesConvert from "../../../../../mixins/imagesConvert";

export default {
  name: "BrokerPickerPopUp",
  props: {
    type: Object,
    payload: [Object, null],
  },
  mixins: [
    imagesConvert,
  ],
  created () {
    this.brokers = this.$store.getters["clientState/getFinancnici"];
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    async assignBrokerToClient () {
      this.$store.commit("appState/setLoadingPage", true);
      if (this.type.client) {
        await this.assignBrokerWhileClients();
      } else if (this.type.mortgage) {
        await this.assignBrokerWhileMortgage();
      } else if (this.type.detail) {
        const client = {
          clientType: null,
          clientId: this.payload.user,
          financnik: this.brokerId,
        };
        await this.$store.dispatch("clientState/patchClient", client);
      }
      this.$store.dispatch("clientState/updateEmployees", ["financnik"]);
      this.$store.commit("popupManagerState/setUserManagerRefresh", true);
      this.$store.commit("appState/setLoadingPage", false);
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
      this.$store.commit("appState/setSuccessMessage", {
        text: "úspešne ste priradili ku klientovi bankára",
        type: "success"
      });
    },
    async assignBrokerWhileClients () {
      let typeOfClient = null;
      if (this.payload.ob_status === "novy")
        typeOfClient = "temporaryClients";
      if (this.payload.ob_status === "rozpracovaný")
        typeOfClient = "registeredClients";
      if (this.payload.ob_status === "archivovany")
        typeOfClient = "archivedClients";
      const client = {
        clientType: typeOfClient,
        clientId: this.payload.user,
        financnik: this.brokerId,
      };
      await this.$store.dispatch("clientState/patchClient", client);
    },
    async assignBrokerWhileMortgage () {
      const client = {
        clientType: null,
        clientId: this.payload.user,
        financnik: this.brokerId,
      };
      await this.$store.dispatch("clientState/patchClient", client);
      await this.$store.dispatch("mortgagesState/fetchMortgage", {
        id: this.type.mortgageId,
        stage: this.type.mortgageStage
      });
    },
    selectBroker (key) {
      if (this.brokerSelected === key) {
        this.brokerSelected = null;
        return;
      }
      this.brokerSelected = key;
      this.brokerId = this.brokers[key].id;
    },
  },
  data: () => ({
    brokers: [],
    brokerSelected: null,
    brokerId: null
  }),
};
</script>

<style scoped lang="scss">
.selected-broker {
  border-style: none none none solid;
  border-color: #0a1953 #0a1953 #0a1953 #0019d9;
  border-left-width: 10px;
}

.popup-block:hover {
  cursor: pointer;
}

.popup {
  position: absolute !important;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
