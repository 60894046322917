<template>
  <div class="div-block-25">
    <v-progress-circular
      v-if="coApplicantLoadingGetter"
      class="loading-spinner small"
      indeterminate
    ></v-progress-circular>
    <div
      v-else
      data-w-id="4c06adb2-2b97-ce79-91ef-5e3b3e797c81"
      class="add-prijem-btn"
    >
      <div
        class="text-small bl-color pointer"
        @click="openPropertyTypePicker()"
      >
        + pridať SPOLUŽIADATEĽA
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientAddHandler",
  props: {
    clientInfo: Object,
    rules: [Array, null],
  },
  computed: {
    coApplicantLoadingGetter () {
      return this.$store.getters["mortgageDetailState/getCoApplicantLoading"];
    }
  },
  methods: {
    openPropertyTypePicker () {
      this.$store.commit("popupManagerState/setClientInfo", {type: this.clientInfo.type, id: this.clientInfo.id});
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ClientAddPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped></style>
