import {
  getApplicationMissingFilesById,
  getClientMissingFilesById,
  getCloudFilesByCloudId,
  getDebtMissingFilesById,
  getFileTypes,
  getIncomeMissingFilesById,
  getMortgageMissingFilesById,
  getPropertyMissingFilesById,
  patchCloudFileByID
} from "@/services/brokerService";
import cloudsHelpers from "@/mixins/cloudsHelpers";

export default {
  namespaced: true,
  state: {
    cloud: [],
    types: [],
  },
  getters: {
    getCloudByCloudId: (state) => (cloudId) => {
      const cloud = state.cloud.find((c) => {
        return c.cloudId === cloudId
      });
      return cloud ? cloud : null;
    },
    getCloudFilesByCloudId: (state, getters) => (cloudId) => {
      const cloud = getters["getCloudByCloudId"](cloudId);
      return cloud.cloudMeta._.clouds[0].files;
    },
    getPickedCloudFilesByCloudId: (state, getters) => (cloudId) => {
      const cloud = getters["getCloudByCloudId"](cloudId);
      let files = [];
      for (const missingFile of cloud.missingFiles) {
        if (missingFile.uploaded === 0) continue;
        files = [...files, ...missingFile.uploaded];
      }
      return files;
    },
    getAllFilesWithSpecificType: (state) => (type) => {
      const files = [];
      for (const cloud of state.cloud) {
        const cloudFiles = cloud.cloudMeta._.clouds[0].files;
        for (const cloudFile of cloudFiles) {
          if (cloudFile.type?.code !== type) continue;
          files.push(cloudFile);
        }
      }
      return files;
    },
    getCloudFileFromCloudByType: (state, getters) => (cloudId, type) => {
      const files = getters["getCloudFilesByCloudId"](cloudId);
      const searchedFile = files.find((f) => f.type?.code === type);
      return searchedFile ? searchedFile : null;
    },
    getAllCloudFilesFromCloudByType: (state, getters) => (cloudId, type) => {
      const files = getters["getCloudFilesByCloudId"](cloudId);
      const matchedFilesIndexes = [];
      for (const file of files) {
        if (file.type?.code !== type) continue;
        matchedFilesIndexes.push(file);
      }
      return matchedFilesIndexes;
    },
    getCloudMissingFileByType: (state, getters) => (cloudId, fileType) => {
      const cloudFiles = getters["getCloudFilesByCloudId"](cloudId);
      const cloudFileIndex = cloudFiles.map(m => m.code).indexOf(fileType);
      if (cloudFileIndex === -1) return null;
      return cloudFiles[cloudFileIndex];
    },
    getCloudFileById: (state, getters) => (cloudId, fileId) => {
      const cloudFiles = getters["getCloudFilesByCloudId"](cloudId);
      const cloudFileIndex = cloudFiles.map(f => f.id).indexOf(fileId);
      if (cloudFileIndex === -1) return null;
      return cloudFiles[cloudFileIndex];
    },
    getClouds: (state) => {
      return state.cloud;
    },
    getCloudFileTypeById: (state) => (id) => {
      const typeIndex = state.types.map((t) => t.id).indexOf(id);
      return typeIndex === -1 ? id : state.types[typeIndex];
    },
    getFullClouds: (state) => {
      const clouds = state.cloud.map(c => c.cloudMeta._.clouds[0]);
      return {
        _: {
          clouds
        }
      }
    },
  },
  mutations: {
    initCloud: (state) => {
      state.cloud = [];
      state.types = [];
    },
    setFileTypes: (state, types) => {
      state.types = types;
    },
    setCloud: (state, clouds) => {
      state.cloud = clouds
    },
    setCloudFile: (state, file) => {
      const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      for (const cloudsCopyElement of cloudsCopy) {
        const cloudObject = cloudsCopyElement.cloudMeta._.clouds[0];
        if (cloudObject.id !== file.cloud) continue;
        const index = cloudObject.files.map(f => f.id).indexOf(file.id);
        if (index !== -1) {
          cloudObject.files.splice(index, 1, file);
        }
        let found = false;
        for (const missingFile of cloudsCopyElement.missingFiles) {
          if (missingFile.id !== file.type.id) continue;
          missingFile.uploaded.push(file);
          found = true;
          break;
        }
        if (found) break;
        cloudsCopyElement.missingFiles = [...cloudsCopyElement.missingFiles, {
          ...file.type,
          uploaded: [file],
        }];
        break;
      }
      state.cloud = cloudsCopy;
    },
    setCloudFiles: (state, files) => {
      const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      for (const cloudsCopyElement of cloudsCopy) {
        const cloudObject = cloudsCopyElement.cloudMeta._.clouds[0];
        if (cloudObject?.id !== files[0]?.cloud) continue;
        for (const file of files) {
          const index = cloudObject.files.map(f => f.id).indexOf(file.id);
          if (index !== -1) {
            cloudObject.files.splice(index, 1, file);
          }
          for (const missingFile of cloudsCopyElement.missingFiles) {
            if (missingFile?.id !== file.type?.id) continue;
            missingFile.uploaded.push(file);
            break;
          }
          cloudsCopyElement.missingFiles = [...cloudsCopyElement.missingFiles, {
            ...file.type,
            uploaded: [file],
          }];
        }
        break;
      }
      state.cloud = cloudsCopy;
    },
    // removeOldTypeFromFile: (state, file) => {
    //   const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
    //   for (const cloudsCopyElement of cloudsCopy) {
    //     const cloudObject = cloudsCopyElement.cloudMeta._.clouds[0];
    //     if (cloudObject.id !== file.cloud) continue;
    //     for (const missingFile of cloudsCopyElement.missingFiles) {
    //       const uploadedFiles = missingFile.uploaded;
    //       const oldMissingFile = uploadedFiles.map((f) => f.id).indexOf(file.id);
    //       if (oldMissingFile === -1) continue;
    //       uploadedFiles.splice(oldMissingFile, 1);
    //     }
    //   }
    //   state.cloud = cloudsCopy;
    // },
    mergeToClouds: (state, clouds) => {
      let cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      cloudsCopy = [...cloudsCopy, ...clouds];
      state.cloud = cloudsCopy;
    },
    registerNewFiles: (state, files) => {
      const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      for (const cloudsCopyElement of cloudsCopy) {
        const cloudObject = cloudsCopyElement.cloudMeta._.clouds[0];
        if (cloudObject.id !== files[0].cloud) continue;
        cloudObject.files = [...cloudObject.files, ...files];
        break;
      }
      state.cloud = cloudsCopy;
    },
    removeCloudFile: (state, {cloudId, file}) => {
      const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      const cloudIndex = cloudsCopy.map((c) => c.cloudId).indexOf(cloudId);
      if (cloudIndex === -1) return;
      const cloud = cloudsCopy[cloudIndex];
      removeCouldFileFromPicked(cloud, file);
      const cloudFiles = cloud.cloudMeta._.clouds[0].files;
      for (const [index, cloudFile] of cloudFiles.entries()) {
        if (cloudFile.id !== file.id) continue;
        cloudFiles.splice(index, 1);
      }
      state.cloud = cloudsCopy;
    },
    removeOldFileType: (state, {cloudId, file}) => {
      const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
      const cloudIndex = cloudsCopy.map((c) => c.cloudId).indexOf(cloudId);
      if (cloudIndex === -1) return;
      const cloud = cloudsCopy[cloudIndex];
      removeCouldFileFromPicked(cloud, file);
      state.cloud = cloudsCopy;
    },
    // removeOldFile: (state, file) => {
    //   const cloudsCopy = (JSON.parse(JSON.stringify({cloud: state.cloud}))).cloud;
    //   for (const cloudsCopyElement of cloudsCopy) {
    //     const cloudObject = cloudsCopyElement.cloudMeta._.clouds[0];
    //     if (cloudObject.id !== file.cloud) continue;
    //     const index = (cloudObject.missingFiles.uploaded.map(f => f.id)).indexOf(file.id);
    //     cloudObject.missingFiles.uploaded.splice(index, 1);
    //   }
    //   state.cloud = cloudsCopy;
    // },
  },
  actions: {
    initializeFileTypes: async ({commit}) => {
      const types = (await getFileTypes()).results;
      commit("setFileTypes", types);
    },
    initializeClouds: async ({commit, rootGetters}) => {
      const incomes = rootGetters["mortgageDetailState/getAllIncomes"];
      const debts = rootGetters["mortgageDetailState/getAllDebts"];
      const properties = rootGetters["mortgageDetailState/getProperties"];
      const propertiesObjects = properties.propertiesObjects;
      const usedProperties = properties.usedProperties;
      const applicant = rootGetters["mortgageDetailState/getApplicant"];
      const coApplicant = rootGetters["mortgageDetailState/getCoApplicant"];
      const applications = rootGetters["mortgageDetailState/getApplications"];
      const mortgage = rootGetters["mortgageDetailState/getMortgage"];
      let clouds = await constructRelatedCloudsObject(incomes, debts, propertiesObjects, applicant, coApplicant, applications, mortgage, usedProperties);
      clouds = await fillFilesToClouds(clouds);
      commit("setCloud", clouds);
    },
    addMissingCloudObject: async ({commit}, {
      incomes = [],
      debts = [],
      properties = [],
      applicant = null,
      coApplicant = null,
      applications = [],
      mortgage = null
    }) => {
      let clouds = await constructRelatedCloudsObject(incomes, debts, properties, applicant, coApplicant, applications, mortgage);
      clouds = await fillFilesToClouds(clouds);
      commit("mergeToClouds", clouds);
    },
    nullSpecificTypesForSpecificCloud: async ({commit, getters}, {cloudId, type}) => {
      const files = getters["getAllCloudFilesFromCloudByType"](cloudId, type);
      const promises = [];
      for (const file of files) {
        promises.push({func: patchCloudFileByID, ID: file.id, args: {type: null}});
      }
      const nullTypeFiles = await Promise.all(promises.map((promise) => promise.func(promise.ID, promise.args)));
      commit("setCloudFiles", nullTypeFiles);
    },
    assignFileTypeToFile: async ({commit, rootGetters}, fileObject) => {
      const patchedFile = await patchCloudFileByID(fileObject.file.id, {type: fileObject.typeId}, "type");
      if (fileObject.file.type) {
        commit("removeOldFileType", {cloudId: fileObject.file.cloud, file: fileObject.file})
      }
      commit("setCloudFile", patchedFile);
      return patchedFile;
    },
  },
}
function removeCouldFileFromPicked(cloud, file) {
  for (const missingFile of cloud.missingFiles) {
    if (missingFile.code !== file.type.code) continue;
    for (const [index, uploadedFile] of missingFile.uploaded.entries()) {
      if (uploadedFile.id !== file.id) continue;
      missingFile.uploaded.splice(index, 1);
    }
  }
}
async function fillFilesToClouds(pClouds) {
  let clouds = pClouds;
  clouds = clouds.filter(val => val !== null && val.length !== 0);
  const cloudIds = clouds.map((c) => c.cloudMeta._.clouds[0].id);
  const promises = [];
  for (const cloudId of cloudIds) {
    promises.push({func: getCloudFilesByCloudId, args: cloudId});
  }
  const cloudFiles = await Promise.all(promises.map((promise) => promise.func(promise.args, "cloud_files.type")));
  for (const cloud of clouds) {
    const cloudObject = cloud.cloudMeta._.clouds[0];
    const index = cloudIds.indexOf(cloudObject.id);
    cloudObject.files = cloudFiles[index].cloud.cloud_files;
    for (const uploadedFile of cloudObject.files) {
      if (!uploadedFile.type) continue;
      let foundType = false;
      if (!cloud.missingFiles) continue;
      for (const missingFile of cloud.missingFiles) {
        if (missingFile.id !== uploadedFile.type.id) continue;
        foundType = true;
        missingFile.uploaded.push(uploadedFile);
        break;
      }
      if (foundType) continue;
      cloud.missingFiles = [...cloud.missingFiles, {
        ...uploadedFile.type,
        uploaded: [uploadedFile],
      }];
    }
  }
  return clouds;
}
async function constructRelatedCloudsObject (incomes, debts, properties, applicant, coApplicant, applications, mortgage, usedProperties) {
  // console.log(incomes, debts, properties, applicant, coApplicant, applications, mortgage);
  const cloudsObjects = {
    clientClouds: [cloudsHelpers.methods.constructClient(applicant, true)],
    incomesClouds: cloudsHelpers.methods.constructIncomes(incomes),
    debtsClouds: cloudsHelpers.methods.constructDebts(debts),
    propertiesClouds: cloudsHelpers.methods.constructProperties(properties,"_", usedProperties),
    applicationsClouds: cloudsHelpers.methods.constructApplications(applications),
    mortgageCloud: cloudsHelpers.methods.constructMortgage(mortgage),
  };
  if (coApplicant) {
    cloudsObjects.clientClouds.push(cloudsHelpers.methods.constructClient(coApplicant, false));
  }
  const promises = [];
  let applicationFounded = false;
  for (const [key, clouds] of Object.entries(cloudsObjects)) {
    if (key === "incomesClouds") {
      for (const cloud of clouds) {
        if (cloud.length === 0) continue;
        promises.push({func: getIncomeMissingFilesById, arg: cloud.objectId});
      }
    } else if (key === "debtsClouds") {
      for (const cloud of clouds) {
        if (cloud.length === 0) continue;
        promises.push({func: getDebtMissingFilesById, arg: cloud.objectId});
      }
    } else if (key === "propertiesClouds") {
      for (const cloud of clouds) {
        if (cloud.length === 0) continue;
        promises.push({func: getPropertyMissingFilesById, arg: cloud.objectId});
      }
    } else if (key === "clientClouds") {
      for (const cloud of clouds) {
        if (!cloud) continue;
        promises.push({func: getClientMissingFilesById, arg: cloud.objectId});
      }
    } else if (key === "mortgageCloud") {
      for (const cloud of clouds) {
        if (!cloud) continue;
        promises.push({func: getMortgageMissingFilesById, arg: cloud.objectId});
      }
    } else if (key === "applicationsClouds") {
      for (const cloud of clouds) {
        if (!cloud || !["cerpana", "nacerpana"].includes(cloud.objectData.stage)) continue;
        applicationFounded = true;
        promises.push({func: getApplicationMissingFilesById, arg: cloud.objectId});
      }
    }
  }
  let clouds = [
    ...cloudsObjects.mortgageCloud,
    ...cloudsObjects.clientClouds,
    ...cloudsObjects.incomesClouds,
    ...cloudsObjects.debtsClouds,
    ...cloudsObjects.propertiesClouds,
  ];
  if (applicationFounded) {
    clouds = [...clouds, ...cloudsObjects.applicationsClouds];
  }
  const missingFiles = await Promise.all(promises.map((promise) => promise.func(promise.arg)));
  const missingFilesIds = missingFiles.map(mf => mf.id);
  for (const cloud of clouds) {
    if (!cloud || cloud.length === 0) continue;
    const index = missingFilesIds.indexOf(cloud.objectId);
    cloud.missingFiles = missingFiles[index]?.missingFiles;
    cloud.missingFiles?.forEach((file) => file.uploaded = []);
  }
  clouds = [...clouds];
  if (!applicationFounded) {
    clouds = [...clouds, ...cloudsObjects.applicationsClouds];
  }
  return clouds;
}
