<template>
  <div>
    <div class="div-block-25">
      <div class="add-prijem-btn">
        <div
          class="text-small bl-color pointer"
          @click="openPropertyTypePicker()"
        >
          upraviť BONITU
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientBonitaHandler",
  props: {
    rules: [Array, null],
  },
  methods: {
    openPropertyTypePicker () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ClientBonitaPicker",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped></style>
