import { render, staticRenderFns } from "./DocumentCloudPopUp.vue?vue&type=template&id=569c0206&scoped=true&"
import script from "./DocumentCloudPopUp.vue?vue&type=script&lang=js&"
export * from "./DocumentCloudPopUp.vue?vue&type=script&lang=js&"
import style0 from "./DocumentCloudPopUp.vue?vue&type=style&index=0&id=569c0206&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569c0206",
  null
  
)

export default component.exports