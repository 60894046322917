<template>
  <div class="content-panel" id="klienti-window" @scroll="paginationCheck($event)">
    <div class="statistics">
      <div class="stat-bar-filter">
        <div class="filter-flex-custom">
          <klienti-filters-bar
            @filter-client="filterClient($event)"
            @broker-filter-change="filterForBroker($event)"
            @period-filter-change="filterPeriod($event)"
            @period-statistic-change="changePeriodStatistics($event)"
            @filter-by-conversion="filterByConversion($event)"
          />
          <div class="hide-statistic-item pointer" @click="toggleStats">
            <svg
              width="25"
              height="25"
              viewBox="0 2 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="statistic-icon"
            >
              <path
                d="M0.875 0.875V10.875C0.875 11.5582 1.44184 12.125 2.125 12.125H12.125V10.875H2.125V0.875H0.875ZM11.0752 2.94653L8.36768 5.62842L6.49634 3.83887L2.93921 7.302L3.81079 8.198L6.50366 5.57593L8.38232 7.37158L11.9553 3.83398L11.0752 2.94653Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <klienti-stats v-if="statsShown" :periods="periodStatistic"/>
      </div>
    </div>
    <klienti-item-wrapper
      @reset-pagination="loaded = true"
      @loaded="setLoaded($event)"
      :scroll="scroll"
      :search-filter="searchFilter"
      :broker-filter="brokerFilter"
      :period-filter="periodFilter"
      :conversion-filter="conversionFilter"
    />
  </div>
</template>

<script>
import KlientiStats from "../../../components/broker/KartaKlienti/KlientiStats";
import KlientiItemWrapper from "../../../components/broker/KartaKlienti/KlientiItemWrapper";
import KlientiFiltersBar from "../../../components/broker/KartaKlienti/KlientiFiltersBar";

export default {
  name: "KartaKlienti",
  components: {
    KlientiFiltersBar,
    KlientiItemWrapper,
    KlientiStats
  },
  data: () => ({
    conversionFilter: null,
    brokerId: null,
    loaded: true,
    statsShown: false,
    brokerFilter: null,
    periodFilter: null,
    searchFilter: null,
    periodStatistic: null,
    role: null,
    scroll: 0,
  }),
  created () {
    this.brokerId = this.$store.getters["userState/getId"];
    this.role = this.$store.getters["userState/getRole"];
    this.initializeAllClients();
  },
  mounted () {
    this.paginationCheck();
  },
  beforeDestroy() {
    this.$store.commit("clientState/nullState");
  },
  methods: {
    filterByConversion(conversion) {
      this.conversionFilter = conversion;
    },
    initializeAllClients () {
      let onlyForBroker = null;
      if (this.role !== "alex_admin") onlyForBroker = this.brokerId;
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          client_profile__ob_status: "novy",
          financnik: onlyForBroker,
          page_size: 10,
          ordering: "-date_joined",
          is_applicant: true
        },
        meta: {
          typeOfClients: "temporaryClients",
          pageNumbers: 1,
        }
      });
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          client_profile__ob_status: "rozpracovaný",
          financnik: onlyForBroker,
          page_size: 10,
          ordering: "-date_joined",
          is_applicant: true
        },
        meta: {
          typeOfClients: "registeredClients",
          pageNumbers: 1
        }
      });
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          page_size: 10,
          client_profile__ob_status: "archivovany",
          financnik: onlyForBroker,
          ordering: "-date_joined",
          is_applicant: true
        },
        meta: {
          typeOfClients: "archivedClients",
          pageNumbers: 1,
        }
      });
    },
    setLoaded (loaded) {
      this.loaded = loaded;
    },
    changePeriodStatistics (period) {
      this.periodStatistic = period;
    },
    filterPeriod (period) {
      this.periodFilter = period;
    },
    filterForBroker (brokerId) {
      this.brokerFilter = brokerId;
    },
    filterClient (key) {
      this.searchFilter = key;
    },
    paginationCheck () {
      const el = document.getElementById("klienti-window");
      const position = window.innerHeight + Math.ceil(el.scrollTop);
      if (el.scrollHeight <= position && this.loaded) {
        this.loaded = false;
        this.scroll += 1;
      }
    },
    toggleStats () {
      this.statsShown = !this.statsShown;
    },
  },
};
</script>

<style scoped lang="scss">
.content-panel {

}

.hide-statistic-item {
  padding-right: 25px;
}

.filter-flex-custom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  width: 700px;
  position: absolute;
  left: 750px;
  top: 29px;
}
</style>
