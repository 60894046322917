<template>
  <div class="div-block-102 grid">
    <div class="div-block-147">
      <div class="div-block-148 topalign">
        <div class="div-block-110 primary">1</div>
        <div>Nahrajte úverovú dokumentáciu</div>
      </div>
      <div class="div-block-153">
        <document-cloud-handler
          v-for="(missingFile, index) in cloudGetter.missingFiles"
          v-if="firstStepFiles.includes(missingFile.code)"
          :key="index + 'cldmis'"
          :title="missingFile.name"
          :type="missingFile.id"
          :uploaded="missingFile.uploaded"
          :class-cst="'upload-item-broker-custom'"
          :cloud-meta="cloudGetter.cloudMeta"
          :rules="['priority']"
          :show-files="false"
          text-button
          leading-text
        />
      </div>
      <div class="div-block-148 topalign MGtop20px">
        <div class="div-block-110 primary">2</div>
        <div>Vyberte model čerpania</div>
      </div>
      <div class="div-block-148">
        <div class="dropdown-input-custom contrast rest-input">
          <dropdown-select
            @select="setDropdownValue($event)"
            :id="'credit_card_banka'"
            :init-value="mortgage.cerpanie_na_zapis_zalozneho_prava"
            :options="secondStepChoices"
            normal
          />
        </div>
      </div>
    </div>
    <div class="div-block-147 MGtop20px">
        <div class="div-block-148 topalign">
          <div class="div-block-110 primary">3</div>
          <div>Nahrajte podklady pre čerpanie</div>
        </div>
        <div class="div-block-153">
          <document-cloud-handler
            v-for="(missingFile, index) in cloudGetter.missingFiles"
            v-if="thirdStepFiles.includes(missingFile.code)"
            :key="index + 'cldmis'"
            :title="missingFile.name"
            :type="missingFile.id"
            :uploaded="missingFile.uploaded"
            :class-cst="'upload-item-broker-custom'"
            :cloud-meta="cloudGetter.cloudMeta"
            :rules="['priority']"
            :show-files="false"
            text-button
            leading-text
          />
        </div>
        <div class="div-block-153">
            <div
              v-for="(file, index) in pickedFilesGetter"
              :key="index + 'cldfil'"
              class="link-block-7"
            >
              <img src="@/images/cloud_icon.svg" alt="">
              <div class="d-flex flex-row">
                <div class="small fileName">{{ file.name }}</div>
              </div>
            </div>
        </div>
        <div class="div-block-153 MGtop20px">
          <v-progress-circular
            v-if="zipDownloading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="link-icon-text" v-else @click="download">
            <img loading="lazy" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4c_icons8-downloads-folder.svg">
            stiahnuť všetky
          </div>
        </div>
        <div class="div-block-148 topalign MGtop20px">
          <div class="div-block-110 primary">4</div>
          <div>Hypotéka bola načerpaná dňa</div>
        </div>
        <div class="div-block-153">
          <div class="input-group">
            <input
              @change="saveDrawMortgage"
              v-model="form.nacerpana"
              name="dateOfBirth"
              type="date"
              class="text-input w-input date-input contrast"
              maxlength="256"
            />
          </div>
        </div>
    </div>
    <div class="text-small light MGtop20px">V prípade tohto kroku <b>nie je potrebné nahrať skeny</b> všetkych podkladov na čerpanie, táto funkcia slúži skôr ako manuál k čerpaniu s možnosťou uchovať si úverovú zmluvu a ostatné dokumenty v archíve pre prípad do budúcna.</div>
</div>
</template>

<script>
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";
import DropdownSelect from "@/components/shared/DropdownSelect.vue";
import {mapGetters} from "vuex";
import cloudsHelpers from "@/mixins/cloudsHelpers";

export default {
  name: "ApplicationDocumentFinish",
  components: {DropdownSelect, DocumentCloudHandler},
  mixins: [
    cloudsHelpers,
  ],
  created() {
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
    this.form.nacerpana = this.mortgage.nacerpana;
    let application = (this.$store.getters["mortgageDetailState/getApplicationByStage"]("cerpana"))[0];
    if (!application) application = (this.$store.getters["mortgageDetailState/getApplicationByStage"]("nacerpana"))[0];
    this.application = application;
  },
  computed: {
    ...mapGetters({
      mortgageGetter: "mortgageDetailState/getMortgage"
    }),
    cloudGetter() {
      if (!this.application) return [];
      return this.$store.getters["mortgageDetailCloud/getCloudByCloudId"](this.application.cloud);
    },
    pickedFilesGetter() {
      if (!this.application) return [];
      let files = this.$store.getters["mortgageDetailCloud/getPickedCloudFilesByCloudId"](this.application.cloud);
      this.pickedFiles = files.filter((file) => !this.firstStepFiles.includes(file.type.code) && file.type.code !== "vygenerovana_ziadost");
      return this.pickedFiles;
    }
  },
  watch: {
    mortgageGetter(newMortgage) {
      this.mortgage = newMortgage;
    },
  },
  methods: {
    async saveDrawMortgage(date) {
      this.showConfirmationDialog();
      const userConfirmation = await this.askForUserPermission();
      if (!userConfirmation) {
        this.form.nacerpana = this.mortgage.nacerpana;
        return;
      }
      this.patchMortgage(date.target.value);
    },
    patchMortgage(date) {
      const mortgageObject = {
        id: this.mortgage.id,
        object: {
          nacerpana: date
        }
      }
      if (!this.mortgage.nacerpana) {
        mortgageObject.object.stage = "servis";
        mortgageObject.object.stage_old = this.mortgage.stage;
        this.patchApplication();
      }
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
    },
    patchApplication() {
      const applicationObject = {
        id: this.application.id,
        newStage: "nacerpana",
      }
      this.$store.dispatch("mortgageDetailState/setMortgageApplicationStage", {...applicationObject});
    },
    showConfirmationDialog () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Potvrdením dátumu načerpania hypotéky označíte žiadosť ako načerpanú",
        type: "confirmation"
      });
    },
    askForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    async download() {
      this.zipDownloading = true;
      const files = [];
      for (const pickedFile of this.pickedFiles) {
        if (!this.thirdStepFiles.includes(pickedFile.type.code)) continue;
        files.push(pickedFile)
      }
      const applicantSurname = (this.$store.getters["mortgageDetailState/getApplicant"]).last_name
      await this.downloadFilesAsZip(files, `${applicantSurname}_cerpanie`);
      this.zipDownloading = false;
    },
    setDropdownValue(value) {
      const mortgageObject = {
        id: this.mortgage.id,
        object: {
          cerpanie_na_zapis_zalozneho_prava: value
        }
      }
      // console.log(mortgageObject);
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
    }
  },
  data: () => ({
    mortgage: null,
    application: null,
    zipDownloading: false,
    pickedFiles: [],
    form: {
      nacerpana: null,
    },
    todayLong: new Date().toISOString().substr(0, 10),
    firstStepFiles: ["uverova_zmluva", "zalozna_zmluva"],
    secondStepChoices: [
      {
        display_name: "štandardné na plombu",
        value: false,
      },
      {
        display_name: "na zápis záložného práva",
        value: true,
      },
    ],
    thirdStepFiles: ["podpisana_uverova_zmluva","podpisana_zalozna_zmluva","potvrdeny_navrh_na_vklad_zalozneho_prava","doklad_o_uhrade_rozdielu_kupnej_ceny_a_hypo","podpisana_kupna_zmluva","vycislenie_zostatku_hypo","vinkulacia_poistnej_sumy_v_prospech_novej_banky"],
  }),
}
</script>

<style scoped>
.div-block-147 {
  background-color: #fff;
}

.link-block-7 {
  justify-content: space-around;
  gap: 10px;
}
</style>