<template>
  <div
    class="pointer archive-button button-item-handler white--text no-decoration"
    @click="refreshLimits"
  >
    <img class="nav-link-icon refresh-icon" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4b_icons8-available-updates%20(1).svg" alt="">
  </div>
</template>

<script>
export default {
  name: "LimitsRefreshButton",
  methods: {
    refreshLimits() {
      this.$store.commit("mortgageDetailState/setLimitsChanged", false);
      this.$store.dispatch("mortgageDetailState/fetchLimits");
    },
  }
}
</script>

<style lang="scss" scoped>
</style>