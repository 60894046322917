<template>
  <div class="poppins" style="height: 100%">
    <popup-handler/>
    <nav-bar-expert/>
    <router-view style="min-height: 100%"></router-view>
    <success-message-handler/>
  </div>
</template>

<script>
import SuccessMessageHandler from "../components/shared/SuccessMessageHandler";
import NavBarExpert from "../components/expert/NavBarExpert";
import PopupHandler from "../components/shared/PopupHandler/PopupHandler";

export default {
  name: "Expert",
  components: {
    PopupHandler,
    NavBarExpert,
    SuccessMessageHandler
  },
  created () {
    this.$store.dispatch("clientsEvents/updateUserEvents");
    this.$store.dispatch("formStore/initializeForms");
  },
}
</script>

<style scoped>

</style>
