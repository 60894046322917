<template>
  <div class="main-view-stage-3-custom">
    <document-cloud-handler
      :cloud-meta="getCloudMeta()"
      :rules="['single']"
      :class-cst="'cloud-button add-obhliadka white--text no-decoration'"
      icon-button
    />
    <documents-zip-download/>
    <div class="stage-3-items-wrapper" v-if="loaded">
      <mortgage-item-cloud
        v-for="(cloud, key) in clouds"
        v-if="!cloud.invisible"
        :key="key"
        :object="cloud"
      />
    </div>
  </div>
</template>

<script>
import MortgageItemCloud
  from "@/components/broker/KartaHypoteky/KartaHypotekyDetail/DocumentCompletion/MortgageItemCloud.vue";
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";
import DocumentsZipDownload
  from "@/components/broker/KartaHypoteky/KartaHypotekyDetail/DocumentCompletion/DocumentsZipDownload.vue";
import {mapGetters} from "vuex";

export default {
  name: "DocumentCompletion",
  components: {
    DocumentsZipDownload,
    DocumentCloudHandler,
    MortgageItemCloud,
  },
  props: {
    mortgage: [Object, null],
    applicant: [Object, null],
    coApplicant: [Object, null],
  },
  computed: {
    ...mapGetters({
      cloudsGetter: "mortgageDetailCloud/getClouds",
    }),
  },
  watch: {
    cloudsGetter(clouds) {
      this.clouds = clouds;
    },
  },
  async created () {
    this.clouds = this.$store.getters["mortgageDetailCloud/getClouds"];
    this.loaded = true;
  },
  methods: {
    getCloudMeta () {
      return this.$store.getters["mortgageDetailCloud/getFullClouds"];
    },
  },
  data: () => ({
    clouds: [],
    loaded: false,
    foundedProperties: [],
    applicantInfo: {},
    coApplicantInfo: {},
  }),
}
</script>

<style lang="scss" scoped>
.stage-3-items-wrapper .div-block-102 {
  flex: 0 0 25%;
}

.stage-3-items-wrapper {
  flex: 1 1 auto;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
</style>
