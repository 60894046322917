<template>
  <div class="p-relative">
    <kanban-board :blocks="applications" :stages="stages" :config="config" @update-block="applicationMoved">
      <div v-for="(stage, index)  in stages" :slot="stage" :key="stage" class="light-text small">
        <h2 class="pop-800 blc-color text-uppercase header-custom">
          <h2 class="div-block-110 bordered">
            {{ index + 1 }}
          </h2>
          {{ getLabel(stage) }}
        </h2>
      </div>
      <div
        v-for="(application, index) in applications"
        :key="index"
        :slot="application.id"
        class="ziadost-item-custom pointer position-relative"
        :class="{'editing': applicationEdit}"
      >
        <div
          class="closebtn-broker-app pointer"
          v-if="show"
          @click="removeApplication(application)"
        >
        <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
        </div>
        <div
          class="ziadost-top w-inline-block"
        >
          <div class="ziadost-icon">
            <img
              src="@/images/Tatra-banka.png"
              loading="lazy"
              alt=""
              class="bank-logo"
            />
            <div class="text-part">
              <div class="bold">{{ application.suma }} EUR</div>
              <div
                class="text-cta pointer"
                v-if="application.status === 'schvalena'"
                @click="applicationMoved(application.id, 'cerpanie')"
              >
                ČERPAŤ
              </div>
            </div>
          </div>
          <div class="bold bl-color">
            1.9
            <span class="upper-index">%</span>
          </div>
        </div>
      </div>
    </kanban-board>
    <div class="pointer archive-button add-obhliadka white--text no-decoration" @click="show = true">
      <img src="@/images/archive-icon-white.svg" width="15px" alt="">
    </div>
    <objects-remove-pop-up
      :objects-component="'MortgageApplicationsObjects'"
      @restore="restoreApplication($event)"
      :show="show"
      :applications="getRemovedApplications()"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ObjectsRemovePopUp from "@/components/shared/PopupHandler/Popups/ObjectsRemove/ObjectsRemovePopUp.vue";

export default {
  name: "ApplicationsWrapper",
  components: {ObjectsRemovePopUp},
  computed: {
    ...mapGetters({
      popupSuccessGetter: "popupManagerState/getPopupSuccessResult",
      applicationsGetter: "mortgageApplicationsState/getApplications",
    }),
  },
  watch: {
    applicationsGetter (applications) {
      this.applications = this.mergeApplications(applications);
    },
    popupSuccessGetter (successInfo) {
      if (successInfo === null) return;
      if (successInfo === true) {
        this.changeStatusOfEdited();
      } else if (successInfo === false) {
        this.returnApplicationBack(this.applicationEditObject.id);
      }
      this.applicationEdit = false;
      this.applicationEditObject = null;
      this.$store.commit("popupManagerState/setPopupSuccessResult", null);
    },
  },
  created () {
    this.role = this.$store.getters["userState/getRole"];
    let applications = this.$store.getters["mortgageApplicationsState/getApplications"];
    applications = this.mergeApplications(applications);
    this.applications = applications;
  },
  methods: {
    mergeApplications(applicationsObjects) {
      let applicationsArray = [];
      for (const applications of Object.values(applicationsObjects)) {
        applicationsArray = [...applicationsArray, ...applications];
      }
      return applicationsArray;
    },
    restoreApplication (application) {
      const restoredApplication = {
        ...application,
        stage_old: null,
        stage: application.stage_old,
        status: application.stage_old,
      }
      this.$store.dispatch("mortgageApplicationsState/restoreApplication", {restoredApplication});
    },
    removeApplication (application) {
      const removedApplication = {
        ...application,
        stage_old: application.stage,
        stage: "zrusena",
        status: "zrusena",
      }
      this.$store.dispatch("mortgageApplicationsState/removeApplication", {removedApplication});
    },
    getRemovedApplications () {
      return this.applications.filter((application) => application.stage === "zrusena");
    },
    getLabel (type) {
      return this.stageLabel[type];
    },
    changeStatusOfEdited () {
      const mortgageMovedInGrid = this.applications.find((mortgage) => mortgage.id === parseInt(this.applicationEditObject.id));
      mortgageMovedInGrid.status = this.applicationEditObject.newStatus;
      // const indexOfMortgageMoved = this.applications.indexOf(mortgageMovedInGrid);
      // this.applications.splice(indexOfMortgageMoved, 1);
      // setTimeout(() => {
      //   this.applications.push(mortgageMovedInGrid);
      // }, 1);
    },
    returnApplicationBack (id) {
      const mortgageMovedInGrid = this.applications.find((mortgage) => mortgage.id === parseInt(id));
      const indexOfMortgageMoved = this.applications.indexOf(mortgageMovedInGrid);
      this.applications.splice(indexOfMortgageMoved, 1);
      setTimeout(() => {
        this.applications.push(mortgageMovedInGrid);
        this.applicationEdit = false;
        this.applicationEditObject = null;
      }, 1);
    },
    applicationMoved (id, newStatus) {
      const oldStatus = (this.applications.find((mortgage) => mortgage.id === parseInt(id))).status;
      this.applicationEdit = true;
      this.applicationEditObject = {
        id: id,
        newStatus: newStatus,
      }
      if (newStatus === "podana_v_banke" && oldStatus === "podpisana") {
        this.openSignedStatusPopup();
        return;
      } else if (newStatus === "prerusena" && oldStatus === "podana_v_banke") {
        this.openStoppedStatusPopup();
        return;
      } else if (newStatus === "schvalena" && (oldStatus === "podana_v_banke" || oldStatus === "prerusena")) {
        this.openApprovedStatusPopup();
        return;
      } else if (newStatus === "cerpanie" && oldStatus === "schvalena") {
        this.openDrawdownStatusPopup();
        return;
      }
      this.returnApplicationBack(id);
    },
    openSignedStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationUploadPopUp",
        rules: ['single'],
      });
    },
    openStoppedStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationStoppedPopup",
        rules: ['single'],
      });
    },
    openApprovedStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationApprovalPopup",
        rules: ['single'],
      });
    },
    openDrawdownStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationDrawdownPopup",
        rules: ['single'],
      });
    },
  },
  data: () => ({
    show: false,
    stageLabel: {
      podpisana: "Podpísaná",
      podana_v_banke: "Podaná v banke",
      prerusena: "Prerušená",
      schvalena: "Schválená",
    },
    stages: ["podpisana", "podana_v_banke", "prerusena", "schvalena"],
    applications: [],
    config: {
      moves (el) {
        return !el.children[0].className.includes("editing");
      }
    },
    applicationEdit: false,
    applicationEditObject: null,
    role: null,
  }),
}
</script>


<style lang="scss">
@import "node_modules/vue-kanban/src/assets/kanban.scss";

.drag-item {
  display: flex;
  justify-content: center;
  height: auto;
  background: none;
  margin: 10px 0;
  padding: 0;
}

.drag-container {
  max-width: none;
  margin: 0 30px;
}

.drag-column-header {
  display: block;
  padding: 0;
  text-align: center;
}

.drag-column {
  & h2 {
    text-transform: none;
  }

  background: none;
}
</style>