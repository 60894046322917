<template>
  <div
    class="hypot-ka pointer"
  >
    <!--    <div>{{ mortgage.id }}</div>-->
    <div
        class="closebtn-broker-app pointer"
        v-if="activeDeleteButton"
        @click="removeApplication()"
    >
    <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
    </div>
    <div class="hypoteka-top w-inline-block mortgage-header">
      <div v-if="mortgage.coapplicant" class="position-relative">
        <img
          class="image-10"
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f48_two%20applicants%20icon.png"
          loading="lazy"
          alt=""
        />
      </div>
      <div v-else>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
      </div>
      <div class="bold blc-color">
        <user-manager-handler
          v-if="client !== null"
          :class-cst="'blc-color'"
          :rules="['priority']"
          :client-info="client"
          :mortgages="{stage: this.stage, id: this.mortgage.id}"
          client
        />
        <div v-if="mortgage.coapplicant" class="co-applicant-name pop-500">
          &
          <span v-if="mortgage.coapplicant.first_name !== ''">
            {{ mortgage.coapplicant.first_name }}
            {{ mortgage.coapplicant.last_name }}
          </span>
          <span v-else>
            ID {{ mortgage.coapplicant.id }}
          </span>
        </div>
      </div>
    </div>
    <div class="last-milestone mortgage-body" @click="navigateToDetail">
        <div v-if="mortgage">
          <div class="light">
            {{ getChoicesFromFormByKey("hlavny_ucel", mortgage && mortgage.hlavny_ucel ? mortgage.hlavny_ucel : mortgage.ciel_prva_analyza) }}
          </div>
          <div class="bold primary">
            {{ getMortgageAmount(mortgage) }} EUR
          </div>
        </div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/649145e2f83116b98203f321_open%20mortgage.svg"
          loading="lazy"
          alt=""
          class="nav-link-icon small filter-primary"
        />
    </div>
    <div class="w-inline-block mortgage-footer d-flex align-items-center justify-content-between"
         v-if="role === 'alex_admin'">
      <broker-picker-handler
        v-if="client !== null"
        :rules="['priority']"
        :broker-id="client.financnik"
        :client-info="client.client_profile"
        :mortgage-id="mortgage.id"
        :mortgage-stage="stage"
        @click.self.prevent
        mortgage-layout
        hypoteka
      />
      <div
        v-if="client.financnik"
      >
<!--        <div-->
<!--          class="mortgage-application-other position-absolute d-flex align-items-center justify-content-center"-->
<!--          :style="getCustomStyle(0)"-->
<!--          v-if="mortgage.applications.length > 2"-->
<!--        >-->
<!--          +{{ mortgage.applications.length - 2 }}-->
<!--        </div>-->
<!--        <div-->
<!--          class="mortgage-application-logo position-absolute d-flex align-items-center"-->
<!--          v-for="(application, key) in mortgage.applications"-->
<!--          v-if="key < 2"-->
<!--          :key="key"-->
<!--          :style="getCustomStyle(key+1)"-->
<!--        >-->
<!--          <img-->
<!--            style="object-fit: fill; border-radius: 50px"-->
<!--            :src="application.bank.logo_square"-->
<!--            width="35"-->
<!--            alt=""-->
<!--          >-->
      </div>
    </div>
  </div>
</template>

<script>
import BrokerPickerHandler from "../../../shared/PopupHandler/Popups/BrokerPicker/BrokerPickerHandler";
import UserManagerHandler from "../../../shared/PopupHandler/Popups/UserManager/UserManagerHandler";
import translateFromDBValue from "../../../../mixins/translateFromDBValue";

export default {
  name: "HypotekaItemDetail",
  components: {UserManagerHandler, BrokerPickerHandler},
  props: {
    activeDeleteButton: Boolean,
    mortgage: Object,
    client: Object,
    stage: String,
  },
  mixins: [
    translateFromDBValue,
  ],
  data: () => ({
    role: null,
    form: null,
  }),
  async created () {
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        reject("Form not loaded in time.");
      }, 5000);

      const interval = setInterval(() => {
        if (this.$store.getters["formStore/getFormByType"]("mortgageForm")) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });

    this.form = this.$store.getters["formStore/getFormByType"]("mortgageForm");
    this.role = this.$store.getters["userState/getRole"];
  },
  methods: {
    getMortgageAmount(mortgage) {
      const historyAmount = this.getMortgageAmountFromHistory(mortgage.offers_filter_history);
      if (historyAmount !== 0) return historyAmount;
      return mortgage.suma_nadobudnutie + mortgage.suma_refinancovanie + mortgage.suma_bezucel;
    },
    getMortgageAmountFromHistory(historyObject) {
      if (!historyObject || Array.isArray(historyObject.broker) || historyObject.broker.history.length === 0) return 0;
      const lastHistoryObject = historyObject.broker.history[0];
      return lastHistoryObject.suma_bezucel + lastHistoryObject.suma_nadobudnutie + lastHistoryObject.suma_refinancovanie;
    },
    getChoicesFromFormByKey (key, value) {
      if(!this.form || !this.form[key]) return "Účel neznámy";

      for (const choice of this.form[key].choices) {
        if (choice.value === value) {
          return choice.display_name;
        }
      }
      return "Účel neznámy"
    },
    removeApplication() {
      this.$emit("remove-mortgage", this.mortgage)
    },
    getCustomStyle (key) {
      return `right: ${10 + (key * 20)}px; bottom: 15px; z-index: ${3 - key};`
    },
    navigateToDetail () {
      this.$router.push({
        name: "KartaHypotekyDetail",
        params: {hypoteka: this.mortgage.id},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
