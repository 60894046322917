<template>
  <div class="popup-form">

    <div
        class="content-form-popup noselect"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Pozvite do chatu</div>
        <button class="w-button" @click="shareChatToEmployee">Zdieľať</button>
      </div>
      <div
          class="popup-block pointer"
          v-for="(employee, key) in employees"
          :key="key"
          @click="selectEmployee(key)"
      >
        <div class="vertical-align-divs flex-row">
          <div class="user-relation">
            <div>
              <img v-if="employeeSelected === key" class="nav-link-icon filter-primary" style="margin-right: 10px" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f25_icons8-check.svg"
                   alt="">
              <img
                  v-if="employee.profile_image"
                  :src="employee.profile_image"
                  loading="lazy"
                  sizes="100vw"
                  alt=""
                  class="broker-image"
              />
              <img
                  v-else
                  src="@/images/Michal-Voronič.jpg"
                  loading="lazy"
                  sizes="100vw"
                  alt=""
                  class="broker-image"
              />
            </div>
            <div class="text-block">
              <div class="text--black">
                {{ employee.first_name }} {{ employee.last_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesConvert from "../../../../../mixins/imagesConvert";
import { sendMessage } from "../../../../../services/brokerService";

export default {
  name: "ClientChatSharePopUp",
  mixins: [
      imagesConvert,
  ],
  props: {
    payload: Object,
  },
  created() {
    this.employees = [];
    this.employees = [...this.$store.getters["clientState/getAdmini"], ...this.$store.getters["clientState/getFinancnici"]];
  },
  methods: {
    closePopup() {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    async shareChatToEmployee() {
      //TODO: Dorobiť error message
      await sendMessage(
          this.employees[this.employeeSelected].id,
          `Boli ste prizvaný do chatu s klientom ${this.payload.label}, kliknutím otvoríte.`,
          null,
          null,
          {id: this.payload.id, invitation: true},
      );
      this.$store.commit("appState/setSuccessMessage", {
        text: "úspešne ste poslali pozvánku do chatu",
        type: "success"
      });
    },
    selectEmployee(index) {
      this.employeeSelected = index;
    },
  },
  data: () => ({
    employeeSelected: null,
    employees: [],
  }),
}
</script>

<style scoped>

</style>