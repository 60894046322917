<template>
  <form
    @submit.prevent="register"
    id="register-form"
    name="Sign-In"
    data-name="Sign In"
    method="get"
    class="inner-form"
  >
    <div class="callout-box-wrapper margin-bottom-small">
      <a
        href="#"
        class="logo-footer margin-bottom-small w-inline-block"
      ><img
        loading="lazy"
        src="@/images/Bankari-logo-transparent-extrabold.svg"
        alt=""
      /></a>
      <div v-if="errorArray.length > 0">
        <p
          class="red--text"
          v-for="(error, index) in errorArray"
          :key="index"
        >
          {{ error }}
        </p>
      </div>
      <p v-else class="main-paragraph text-white-opacity-strong">
        Pre registráciu vypíšte údaje nižšie.
      </p>
    </div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4041"
      class="inner-input"
    >
      <input
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        class="text-field-3 dark w-input"
        maxlength="256"
        v-model="firstName"
        placeholder="Meno"
        id="Meno"
        required=""
      />
    </div>
    <div style="margin-top: 10px"></div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4041"
      class="inner-input"
    >
      <input
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        class="text-field-3 dark w-input"
        maxlength="256"
        v-model="lastName"
        placeholder="Priezvisko"
        id="Priezvisko"
        required=""
      />
    </div>
    <div style="margin-top: 10px"></div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4041"
      class="inner-input"
    >
      <input
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        type="email"
        class="text-field-3 dark w-input"
        maxlength="256"
        name="Email"
        v-model="email"
        data-name="Email"
        placeholder="Zadajte email"
        id="Email"
        required=""
      />
    </div>
    <div style="margin-top: 10px"></div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4045"
      class="inner-input margin-bottom-xsmall"
    >
      <div class="passw-input">
        <input
          v-on:keydown.enter.prevent="register"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-once="true"
          :type="[typePassword ? 'password' : 'text']"
          class="text-field-3 dark w-input"
          maxlength="256"
          name="Heslo1"
          v-model="password1"
          placeholder="Zadajte heslo"
          id="Heslo1"
          required=""
        />
        <div class="icon-wrapper" @click="typePassword = !typePassword">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-fill" viewBox="0 0 16 16" v-if="typePassword">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path
              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-slash-fill" viewBox="0 0 16 16" v-else>
            <path
              d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
            <path
              d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
        </div>
      </div>
    </div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4045"
      class="inner-input margin-bottom-xsmall"
    >
      <div class="passw-input">
        <input
          v-on:keydown.enter.prevent="register"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-once="true"
          :type="[typePassword ? 'password' : 'text']"
          class="text-field-3 dark w-input"
          maxlength="256"
          name="Heslo2"
          v-model="password2"
          placeholder="Zopakujte heslo"
          id="Heslo2"
          required=""
        />
        <div class="icon-wrapper" @click="typePassword = !typePassword">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-fill" viewBox="0 0 16 16" v-if="typePassword">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path
              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-eye-slash-fill" viewBox="0 0 16 16" v-else>
            <path
              d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
            <path
              d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
        </div>
      </div>
    </div>
    <div
      data-w-id="04ad70bc-eea8-0271-fb2c-c6b2beee4041"
      class="inner-input"
    >
      <input
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
        class="text-field-3 dark w-input"
        maxlength="256"
        v-model="token"
        placeholder="Token"
        id="Token"
        required=""
      />
    </div>
  </form>
</template>

<script>
import {registerBroker} from "@/services/brokerService";
import brokerRoutes from "@/router/brokerRoutes";

export default {
  name: "RegisterForm",
  methods: {
    async register() {
      if (this.password1 !== this.password2) {
        this.errorArray = ["Zadané heslá sa nezhodujú"];
        return;
      }
      const regex = /^(?=.*\d)(?=.*[\W_])[^\s]{10,}$/;
      if (!regex.test(this.password1.toString())) {
        this.errorArray = ["Zadané heslo nesplňuje jednu z podmienok, minimálna dĺžka 10 znakov, jedno číslo, jeden špeciálny znak a minimálne jedno číslo."];
        return;
      }
      try {
        this.$store.commit("appState/setLoadingPage", true);
        this.errorArray = [];
        const registerObject = {
          email: this.email,
          password1: this.password1,
          password2: this.password2,
          first_name: this.firstName,
          last_name: this.lastName,
          token: this.token,
        }
        const registerData = await registerBroker(registerObject);
        this.$store.commit("userState/setMe", registerData.data.user);
        this.$store.commit("userState/setLogged", {
          logged: true,
        });
        this.$store.commit("appState/setLoadingPage", false);
        this.$router.addRoute(brokerRoutes);
        this.$router.push({name: "RoleRoutes"});
      } catch (e) {
        for (const errors of Object.values(e.response.data)) {
          if (errors.length === 1 && (errors[0].includes('nie je platné UUID.') || errors[0] === "Invalid token")) {
            this.errorArray = [...this.errorArray, "Token pre registráciu nie je správny."];
          } else {
            this.errorArray = [...this.errorArray, ...errors];
          }
        }
        this.$store.commit("appState/setLoadingPage", false);
      }
    },
  },
  data: () => ({
    errorArray: [],
    sameEmail: false,
    typePassword: true,
    password1: null,
    password2: null,
    email: null,
    firstName: null,
    lastName: null,
    token: null,
  }),
}
</script>

<style scoped>
.red--text {
  color: red;
}
</style>