<template>
  <form id="event-form" @submit.prevent="saveEvent">
    <div
      class="input-group"
    >
      <label for="podnikamOd" class="field-label"
      >Termín podpisu</label
      ><input
      type="datetime-local"
      v-model="meetingDate"
      :min="todayLong"
      class="text-input w-input date-input"
      maxlength="256"
      name="podnikamOd"
      data-name="podnikamOd"
      id="podnikamOd"
      required
    />
    </div>
    <div
      class="input-group"
    >
      <label for="sprava" class="field-label"
      >Správa pre klienta</label
      >
      <textarea
        v-model="invitationForm.description"
        class="text-input w-input"
        id="sprava"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: "OnlineConsultationEvent",
  created () {
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-') + "T" + [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(':');
  },
  methods: {
    setStartEndDate() {
      this.invitationForm.start = `${this.meetingDate}:00`
      const date = new Date(this.invitationForm.start);
      date.setMinutes(date.getMinutes() + 30);
      date.setHours(date.getHours() + 2);
      this.invitationForm.end = date.toISOString().slice(0, 19);
    },
    saveEvent() {
      this.setStartEndDate();
      this.$emit("event-saved", this.invitationForm);
    },
    addZero (value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    }
  },
  data: () => ({
    todayLong: null,
    meetingDate: null,
    invitationForm: {
      type: "online_konzultacia",
      start: null,
      end: null,
      user: null,
      description: null,
      meta: {
        placeId: null,
        addressMeta: null,
      }
    },
  }),
}
</script>

<style scoped>

</style>
