<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Vyberte brokera</div>
        <button class="w-button saveformBtn" form="amounts-submit">Uložiť</button>
      </div>
      <form @submit.prevent="saveAmounts" id="amounts-submit">
        <div class="input-group">
          <label for="nadobudnutie" class="field-label"
          >Suma <strong>nadobudnutie</strong
          ></label>
          <input
            v-model.number="amounts.suma_nadobudnutie"
            :max="limits.suma_nadobudnutie"
            min="0"
            type="number"
            step="0.01"
            class="text-input w-input"
            name="nadobudnutie"
            data-name="nadobudnutie"
            required
            id="nadobudnutie"
          />
          <div class="max-button">
            <span
              class="pop-600 bl-color copy-text pointer noselect pointer"
              @click="amounts.suma_nadobudnutie = limits.suma_nadobudnutie"
            >
              MAX
            </span>
          </div>
        </div>
        <div class="input-group">
          <label for="refinancovanie" class="field-label"
          >Suma <strong>refinancovanie</strong
          ></label>
          <input
            v-model.number="amounts.suma_refinancovanie"
            :max="limits.suma_refinancovanie"
            min="0"
            type="number"
            step="0.01"
            class="text-input w-input"
            name="refinancovanie"
            data-name="refinancovanie"
            required
            id="refinancovanie"
          />
          <div class="max-button">
            <span
              class="pop-600 bl-color copy-text pointer noselect"
              @click="amounts.suma_refinancovanie = limits.suma_refinancovanie"
            >
              MAX
            </span>
          </div>
        </div>
        <div class="input-group">
          <label for="zakladDane" class="field-label"
          >Suma <strong>bezúčel</strong
          ></label>
          <input
            v-model.number="amounts.suma_bezucel"
            :max="limits.suma_bezucel"
            min="0"
            type="number"
            step="0.01"
            class="text-input w-input"
            name="zakladDane"
            data-name="zakladDane"
            required
            id="zakladDane"
          />
          <div class="max-button">
            <span
              class="pop-600 bl-color copy-text pointer noselect"
              @click="amounts.suma_bezucel = limits.suma_bezucel"
            >
              MAX
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageAmountsPopUp",
  created () {
    // this.limits = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getAmountsLimits"]));
    this.amounts = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getAmounts"]));
  },
  methods: {
    saveAmounts () {
      this.$store.commit("mortgageDetailState/setAmounts", this.amounts);
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    }
  },
  data: () => ({
    limits: null,
    amounts: {
      suma_nadobudnutie: null,
      suma_refinancovanie: null,
      suma_bezucel: null,
    },
  })
}
</script>

<style lang="scss" scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-group:hover {
  & .max-button {
    display: block;
  }
}

.max-button {
  position: absolute;
  display: none;
  top: 30px;
  right: 15px;
  font-size: 11px;
}
</style>
