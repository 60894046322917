var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistic-tabs",attrs:{"data-aos":"fade-down"}},[_c('div',{staticClass:"stat-block"},[_c('div',[(!_vm.loading)?_c('v-scroll-y-reverse-transition',{attrs:{"leave-absolute":""}},[_c('div',[_c('div',{staticClass:"div-block-2"},[_c('div',{staticClass:"text-small",class:[
                this.newClientsLength >= this.latestTemporary
                  ? 'green--text'
                  : 'red--text',
              ]},[_vm._v(" "+_vm._s(this.newClientsLength >= this.latestTemporary ? "+" : "-")+_vm._s(_vm.calculateTemp())+" ")])]),_c('div',{staticClass:"blacktext"},[_vm._v("Noví klienti")]),_c('div',{staticClass:"large-text stat-number pop-800"},[_vm._v(_vm._s(_vm.newClientsLength))])])]):_vm._e(),(_vm.loading)?_c('v-scroll-y-transition',{attrs:{"leave-absolute":""}},[_c('div',[_c('div',{staticClass:"blacktext"},[_vm._v("Noví klienti")]),_c('div',{staticClass:"loaderbg loading-spinner"},[_c('v-progress-circular',{staticClass:"loading-spinner extrasmall",attrs:{"indeterminate":""}})],1)])]):_vm._e()],1)]),_c('div',{staticClass:"stat-block"},[(!_vm.loading)?_c('v-scroll-y-transition',{attrs:{"leave-absolute":""}},[_c('div',[_c('div',{staticClass:"div-block-2"},[_c('div',{staticClass:"text-small",class:[
            this.registeredClientLength >= this.latestRegistered
              ? 'green--text'
              : 'red--text',
          ]},[_vm._v(" "+_vm._s(this.registeredClientLength >= this.latestRegistered ? "+" : "-")+_vm._s(_vm.calculateNew())+" ")])]),_c('div',{staticClass:"blacktext"},[_vm._v("Registrácia")]),_c('div',{staticClass:"large-text stat-number pop-800"},[_vm._v(" "+_vm._s(_vm.registeredClientLength)+" ")])])]):_vm._e(),(_vm.loading)?_c('v-scroll-y-transition',{attrs:{"leave-absolute":""}},[_c('div',[_c('div',{staticClass:"blacktext"},[_vm._v("Registrácia")]),_c('div',{staticClass:"loaderbg loading-spinner"},[_c('v-progress-circular',{staticClass:"loading-spinner extrasmall",attrs:{"indeterminate":""}})],1)])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }