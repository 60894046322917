<template>
  <div class="podpis-event-banner">
    <div class="div-block-142">
      <img
        class="image-10"
        src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f3b_document%20icon.png"
        loading="lazy"
        alt=""
      >
      <div class="div-block-143">
        <div class="bold blc-color">Vygenerovať .zip podkladov na schválenie</div>
      </div>
    </div>
    <div class="div-block-146">
      <v-progress-circular
        v-if="zipLoading"
        class="loading-spinner small"
        indeterminate
      ></v-progress-circular>
      <button
        v-else
        class="w-button"
        @click="download"
      >
        Stiahnuť podklady
      </button>
    </div>
  </div>
</template>

<script>
import cloudsHelpers from "@/mixins/cloudsHelpers";

export default {
  name: "DocumentsZipDownload",
  mixins: [
    cloudsHelpers
  ],
  methods: {
    async download() {
      this.zipLoading = true;
      const uploadedCloudFilesObjects = this.collectUploadedFilesFromMortgageCloud();
      const applicantSurname = (this.$store.getters["mortgageDetailState/getApplicant"]).last_name
      await this.downloadFilesAsZip(uploadedCloudFilesObjects, `${applicantSurname}_schvalovanie`);
      this.zipLoading = false;
    },
  },
  data: () => ({
    zipLoading: false,
  })
}
</script>

<style scoped>

</style>