<template>
  <div class="noselect">
    <handlers-wrapper-button :handlers="getHandlers()"/>
    <mortgage-applications-approval-wrapper :applications-objects="mortgage.applications"/>
  </div>
</template>

<script>
import MortgageApplicationsApprovalWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationAproval/MortgageApplicationsApprovalWrapper";
import MortgageApplicationsRemoveHandler
  from "../../../../components/shared/PopupHandler/Popups/ObjectsRemove/ObjectsRemoveHandler.vue";
import HandlersWrapperButton from "../../../../components/shared/PopupHandler/HandlerWrapper/HandlersWrapperButton";

export default {
  name: "ApplicationApproval",
  components: {
    HandlersWrapperButton,
    MortgageApplicationsApprovalWrapper
  },
  props: {
    mortgage: [Object, null],
  },
  methods: {
    getHandlers () {
      return [
        {
          component: "DocumentCloudHandler",
          classCst: "button-item-handler white&#45;&#45;text no-decoration",
          iconButton: true,
          rules: ['single'],
          cloudMeta: this.$store.getters["mortgageDetailCloud/getFullClouds"],
        },
        {
          component: "ObjectsRemoveHandler"
        }
      ]
    },
  }
}
</script>

<style scoped></style>
