<template>
  <div class="stage2content">
    <client-needs-record/>
    <div class="result-block-broker-app stage2">
      <div class="results-legend signed">
        <div
            class="break-40px"
        ></div>
        <div
            class="text-small wrap-100px"
        >
          ÚVER
        </div>
        <div
            class="text-small wrap-50px"
        >
          SADZBA
        </div>
        <div
            class="text-small wrap-100px"
        >
          SPLÁTKA
        </div>
        <div
            class="text-small wrap-50px"
        >
          POPLATOK
        </div>

        <div
            class="text-small wrap-50px"
        >
          SPLATNOST
        </div>
        <div
            class="text-small wrap-100px"
        >
          ÚČET
        </div>
        <div
            class="text-small wrap-100px"
        >
          FIXÁCIA
        </div>
      </div>
      <mortgage-application-sign-item
        v-for="(application, index) in applications"
        v-if="application.stage !== 'zrusena'"
        :key="index"
        :application="application"
      />
    </div>
  </div>
</template>

<script>
import MortgageApplicationSignItem from "../ApplicatioinSign/MortgageApplicationSignItem";
import ClientNeedsRecord from "./ClientNeedsRecord";

export default {
  name: "MortgageApplicationsSignWrapper",
  components: {
    ClientNeedsRecord,
    MortgageApplicationSignItem
  },
  props: {
    applications: Array,
  }
}
</script>

<style scoped>

</style>
