import {getApplications, patchMortgageApplicationByID} from "@/services/brokerService";

export default {
  namespaced: true,
  state: {
    filters: {
      mortgage__applicant__financnik: null,
    },
    pages: {
      zrusena: 1,
      podpisana: 1,
      podana_v_banke: 1,
      prerusena: 1,
      schvalena: 1,
    },
    applications: {
      zrusena: [],
      podpisana: [],
      podana_v_banke: [],
      prerusena: [],
      schvalena: [],
    },
  },
  getters: {
    getApplications: (state) => {
      return state.applications;
    },
  },
  mutations: {
    setApplications: (state, applications) => {
      const applicationsUpdated = JSON.parse(JSON.stringify(state.applications));
      applicationsUpdated[applications.stage] = applications.results;
      state.applications = applicationsUpdated;
    },
    setFilters: (state, filters) => {
      state.filters = {...state.filters, ...filters};
    },
    patchApplication: (state, application) => {
      const newApplications = JSON.parse(JSON.stringify(state.applications));
      const index = newApplications.map((e) => e.id).indexOf(application.id);
      newApplications[index] = application;
      state.applications = newApplications;
    },
  },
  actions: {
    initializeApplications: async ({commit, state}) => {
      for (const [stage, pages] of Object.entries(state.pages)) {
        const applicationsResponse = await getApplications({
          ...state.filters,
          page_size: pages * 10,
          stage,
          page: 1,
          expand: "coapplicant,applicant.client_profile"
        });
        let applications = applicationsResponse.results;
        for (const application of applications) {
          application.status = application.stage;
        }
        commit("setApplications", {stage, results: applications});
      }
    },
    removeApplication: async ({commit, state}, data) => {
      const applicationsBackup = JSON.parse(JSON.stringify(state.applications));
      try {
        commit("patchApplication", data.removedApplication);
        commit("appState/setSuccessMessage", {
          text: "Žiadosť bola zrušená",
          type: "success"
        }, {root: true});
        await patchMortgageApplicationByID(data.removedApplication.id, data.removedApplication);
        // await patchMortgageApplicationByID(546645, data.removedApplication);
      } catch (e) {
        commit("appState/setSuccessMessage", {
          text: "Pri rušení žiadosti nastala chyba",
          type: "error"
        }, {root: true});
        commit("setOffers", applicationsBackup);
      }
    },
    restoreApplication: async ({commit, state}, data) => {
      const applicationsBackup = JSON.parse(JSON.stringify(state.applications));
      try {
        commit("patchApplication", data.restoredApplication);
        commit("appState/setSuccessMessage", {
          text: "Žiadosť bola obnovená",
          type: "success"
        }, {root: true});
        await patchMortgageApplicationByID(data.restoredApplication.id, data.restoredApplication);
      } catch (e) {
        commit("appState/setSuccessMessage", {
          text: "Pri obnovovaní žiadosti nastala chyba",
          type: "error"
        }, {root: true});
        commit("setOffers", applicationsBackup);
      }
    },
  },
}
