import axios from "axios";

const baseURL = process.env.NODE_ENV !== "production" ?
  "https://api.bankari.sk/api" :
  "https://api.bankari.sk/api";

// const baseURL = "https://api.bankari.sk/api";

const axi = axios.create({
  baseURL,
  withCredentials: true,
})

export default axi;
