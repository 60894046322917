<template>
  <div
    @click.self="openDocumentCloudPopUp"
    :class="showFiles && uploaded?.length === 0 ? `${classCst} uploaded` : classCst"
    class="center"
  >
    <div @click="openDocumentCloudPopUp" class="link-icon-text">
      <img v-if="iconButton" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1e_icons8-upload-to-the-cloud.svg" >
      <img v-if="iconButtonSign" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1e_icons8-upload-to-the-cloud.svg" alt="">
      <img v-if="textIconButton" class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1e_icons8-upload-to-the-cloud.svg">
      <span v-if="leadingText">nahrať</span>
      <span v-if="textIconButton">cloud</span>
    </div>
    <div
      :class="{'missing-file-title': showFiles && uploaded?.length !== 0}"
      @click="openDocumentCloudPopUp"
      v-if="textButton"
    >
      <span>
        {{ title }}
      </span>
    </div>
    <div
      class="link-block-7 w-inline-block justify-content-between"
      v-for="(file, index) in uploaded"
      :key="index + 'cldfil'"
      v-if="showFiles && uploaded"
    >
      <div class="d-flex flex-row">
        <div class="small fileName">{{ file.name }}</div>
      </div>
      <a
        v-if="isFilePdf(file.name)"
        :href="file.file"
        target="_blank"
        class="text-cta pointer"
        style="text-decoration: none"
      >
        STIAHNUŤ
      </a>
      <div
        v-else
        @click="download(file)"
        class="text-cta pointer"
        style="text-decoration: none"
      >
        STIAHNUŤ
      </div>
    </div>
  </div>
</template>

<script>
import {getFile} from "@/services/brokerService";

export default {
  name: "DocumentCloudHandler",
  props: {
    type: [Number, null],
    iconButton: Boolean,
    forceFileReplace: Boolean,
    uploaded: Array,
    showFiles: {
      type: Boolean,
      default: true,
    },
    textIconButton: Boolean,
    iconButtonSign: Boolean,
    textButton: Boolean,
    title: [String, null],
    rules: Array,
    cloudMeta: Object,
    classCst: [String, null],
    metaData: [Number, null],
    accept: [String, null],
    leadingText: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async download(fileObject) {
      let file = (await getFile(fileObject.file)).data;
      console.log(file)
      const url = URL.createObjectURL(new Blob([file], {
        type: this.getCloudFileTypeByExtension(fileObject.name)
      }))
      const a = document.createElement('a')
      a.href = url
      a.download = fileObject.name;
      a.target = "_blank";
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    getCloudFileTypeByExtension(fileName) {
      const fileExtension = fileName.match(/\.[0-9a-z]+$/i)[0];
      if (fileExtension === ".doc") return "application/msword";
      else if (fileExtension === ".docx") return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      else if (fileExtension === ".zip") return "application/zip";
      return "application/pdf";
    },
    isFilePdf(fileName) {
      const fileExtension = fileName.match(/\.[0-9a-z]+$/i)[0];
      return ![".doc", ".docx", ".zip"].includes(fileExtension);
    },
    openDocumentCloudPopUp () {
      if (this.type < 0 && this.uploaded.length > 0) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Nie je možné priradiť viac ako jeden súbor tomuto typu súboru",
          type: "error"
        });
        return;
      }
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        extra: {force: this.forceFileReplace, accept: this.accept},
        type: this.type,
        payload: this.cloudMeta,
        component: "DocumentCloudPopUp",
        rules: this.rules,
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
