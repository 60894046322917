<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Priemerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <div class="dropdown-input-custom rest-input">
        <input
          type="text"
          v-model="form.suma"
          style="opacity: 0; position: absolute"
          required
        />
        <dropdown-select
          @select="setDropdownValue($event, 'suma')"
          :id="'maternity_recieve'"
          :init-value="form.suma"
          :options="druhChoices"
          class="required"
          normal
          :name="'Poberáte materský príspevok'"
        />
      </div>
      <div class="input-group">
        <label for="Zamestnan-od" class="field-label"
        >Poberanie materskej <strong>od</strong></label
        ><input
        v-model="form.zamestnanyOd"
        type="date"
        :max="getTodayDate()"
        class="text-input w-input date-input"
        maxlength="256"
      />
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import { getRestIncomeDTI } from "@/services/brokerService";

export default {
  name: "MaternityContribution",
  components: {DropdownSelect},
  async created () {
    const bankVars = this.$store.getters["formStore/getBankVars"];
    this.druhChoices[0].value = bankVars.rodicovsky_prispevok_ak_materska;
    this.druhChoices[1].value = bankVars.rodicovsky_prispevok_ak_nie_materska;
    if (!this.income) return;
    this.form.suma = this.income.suma;
    this.form.zamestnanyOd = this.income.zamestnanie_od;
    this.showEstimatedDtiToUser();
  },
  props: {
    income: [Object, null],
  },
  data: () => ({
    dtiLoading: false,
    form: {
      suma: 0,
      zamestnanyOd: null,
    },
    estimatedDti: 0,
    druhChoices: [
      {
        display_name: "ano",
        value: null,
      },
      {
        display_name: "nie",
        value: null,
      },
    ],
  }),
  methods: {
    getTodayDate () {
      const today = new Date();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm;
    },
    async showEstimatedDtiToUser () {
      this.dtiLoading = true;
      const incomeData = {
        suma: this.form.suma,
      };
      this.estimatedDti = null;
      if (!incomeData.suma) {
        this.dtiLoading = false;
        return;
      }
      const dtiInfo = await getRestIncomeDTI(incomeData);
      this.pickHighestDti(dtiInfo);
      this.dtiLoading = false;
    },
    checkValue(value) {
      return !(value === undefined || value === null || value === "");
    },
    pickHighestDti (dtiInfo) {
      let dtiValue = 0;
      for (const value of Object.entries(dtiInfo)) {
        for (const valueElement of value) {
          if (this.estimatedDti < valueElement.value) {
            dtiValue = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
    },
    saveIncome () {
      const incomeObject = {
        typ: "vedlajsi",
        druh: "materska_rodicovsky_prispevok",
        suma: this.form.suma,
        zamestnanie_od: this.form.zamestnanyOd,
      };
      if (this.income) {
        incomeObject.id = this.income.id;
      }
      // console.log(incomeObject);
      this.$emit("income-saved", {incomeInfo: incomeObject});
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
      this.showEstimatedDtiToUser();
    },
  },
};
</script>

<style scoped lang="scss">
.error-text {
  font-size: 11px;
}
</style>
