<template>
  <div class="section full-page bg-dark-gray wf-section">
    <loading-page/>
    <div class="w-layout-grid main-grid full-page">
      <div class="box-wrapper inner-padding-medium justify login-wrapper">
        <div class="form-container">
          <div class="form-wrapper left">
            <div class="full-form w-form">
              <component :is="activeForm"></component>
              <div class="loginBtnwrapper">
                <button
                  class="w-button border"
                  @click.prevent="handleFormSubmit"
                >
                  {{ activeForm === 'LoginForm' ? 'Prihlásiť' : 'Registrovať' }}
                </button>
                <button
                  class="main-paragraph secondary-button"
                  style="text-align: left;"
                  @click.prevent="handleFormChange"
                >
                  {{ activeForm === 'LoginForm' ? 'Ak ešte nemáte u nás konto a dostali ste od nás registračný token, pokračujte do registrácie ' : 'Pre prihlásenie kliknite ' }} <span class="text-primary bold">TU</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-wrapper signup image-wrapper">
        <img
          src="../../images/login-image.jpg"
          loading="lazy"
          alt=""
          class="full-image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "../../components/shared/LoadingPage";
import LoginForm from "@/containers/login/LoginForm.vue";
import RegisterForm from "@/containers/login/RegisterForm.vue";

export default {
  name: "LandingPage",
  components: {RegisterForm, LoginForm, LoadingPage},
  created () {
    const entryUrl = this.$store.getters["appState/getEntryUrl"];
    if (entryUrl) window.localStorage.setItem("initUrl", JSON.stringify(entryUrl));
    const reload = window.localStorage.getItem("firstReload");
    if (!reload) {
      window.localStorage.setItem("firstReload", "false");
      window.location.reload();
      return;
    } else if (reload === "false") {
      window.localStorage.removeItem("firstReload");
    }
    const initUrl = JSON.parse(window.localStorage.getItem("initUrl"));
    if (initUrl) {
      this.$store.commit("appState/setEntryUrl", initUrl);
      window.localStorage.removeItem("initUrl");
    }
  },
  methods: {
    handleFormChange() {
      if (this.activeForm === "RegisterForm") {
        this.activeForm = "LoginForm";
      } else if (this.activeForm === "LoginForm") {
        this.activeForm = "RegisterForm";
      }
    },
    handleFormSubmit() {
      if (this.activeForm === "RegisterForm") {
        this.submitForm("register-form");
      } else if (this.activeForm === "LoginForm") {
        this.submitForm("login-form");
      }
    },
    submitForm(formId) {
      const button = document.createElement("button");
      button.style.display = "none";
      document.body.appendChild(button);
      button.setAttribute("form", formId);
      button.click();
      document.body.removeChild(button);
    },
  },
  data: () => ({
    activeForm: "LoginForm",
  }),
};
</script>

<style scoped lang="scss">
.secondary-button {
  color: #F8F9FA;
  font-size: 12px;
}
</style>
