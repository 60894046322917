<template>
  <div class="master-filter hideSmartphone">
    <div class="dropdown-title">{{ label }}</div>
    <div data-hover="false" data-delay="0" class="dropdown w-dropdown">
      <b-dropdown
        ref="dropdown-filters"
        size="sm"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="w-dropdown-toggle">
            <div class="icon w-icon-dropdown-toggle"></div>
            <div class="dropdown-placeholder">
              {{ forConversion.active.name }}
            </div>
          </div>
        </template>
        <b-dropdown-item
          class="drop"
          :class="[
              forConversion.active === item ? 'active-filter' : '',
            ]"
          href="#"
          v-for="(item, key) in forConversion.inDropdown"
          :key="key"
          @click="filterClient(item)"
        >
          <div class="text-block-4 fnt-13" id="forEmployee">
            {{ item.name }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterByConversion",
  props: {
    label: String,
  },
  methods: {
    filterClient(conversion) {
      this.forConversion.active = conversion;
      this.$emit("filter-by-conversion", conversion.meta);
    },
  },
  data: () => ({
    forConversion: {
      active: {name: "Všetko", meta: {type: "all"}},
      inDropdown: [
        {name: "Všetko", meta: {type: "all"}},
        {name: "Registrovaný", meta: {value: false, type: "registerDt"}},
        {name: "Neregistrovaný", meta: {value: true, type: "registerDt"}}
      ],
    },
  }),
}
</script>

<style lang="scss" scoped>
.active-filter {
  background-color: rgba(232, 232, 232, 0.8);
}
</style>