import { helpers } from 'vuelidate/lib/validators'

export const maxDigits = (maxDigits) => helpers.withParams(
  { upperLimit: maxDigits },
  (value) => !helpers.req(value) || value.toString().split(".").join("").length <= maxDigits
)

export const maxDecimal = (maxDecimal) => helpers.withParams(
  { maxDecimal: maxDecimal },
  (value) => {
    if (!!value && !value.toString().includes(".")) return true;
    return !helpers.req(value) || value.toString().split(".")[1].length <= maxDecimal
  }
)

export const maxInteger = (maxInteger) => helpers.withParams(
  { maxInteger: maxInteger },
  (value) => {
    if (!!value && value.toString().includes(".") && value.toString().split(".")[0].length <= maxInteger)
      return true;
    return !helpers.req(value) || value.toString().length <= maxInteger
  }
)
