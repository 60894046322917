<template>
  <div class="cloud-holder-wrapper" :class="{'pad-bot-15': opened}">
    <div class="header-wrapper">
      <div class="add-file-wrapper">
        <button
          class="sub-ctass image-upload-btn add-file-button pointer blc-color pop-500"
          @click="openFileUploader()"
        >
          + pridať
        </button>
      </div>
      <div class="cloudLabel">
        <div id="Client-name">{{ cloud.type_label }}<br><span class="_900 big">{{ !cloud.label?.includes("nomail.bankari.sk") ? cloud.label : "" }}</span></div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="filesList" v-if="opened && files.length === 0">
        Tento cloud je zatiaľ prázdny,
      </div>
      <div class="filesList" v-else-if="opened && files.length !== 0">
        <div
          data-v-09f30b34=""
          class="discount-item field-names"
          v-for="(cloudFile, index) in files"
          :key="index"
          v-if="cloudFile.type?.code !== 'vygenerovana_ziadost'"
        >
          <v-progress-circular
            v-if="cloudFile.loading"
            class="loading-spinner small"
            indeterminate
          ></v-progress-circular>
          <div
            v-else-if="type && cloudFile?.type?.id !== type"
            class="text-cta pointer"
            @click="assignFileType(cloudFile, index)"
          >
            VYBRAŤ
          </div>
          <div class="text-cta pointer" v-else></div>
          <div class="fileName">{{ cloudFile.name }}</div>
          <div class="fileTimestamp">{{ getDay(cloudFile.created) }}.{{ getMonth(cloudFile.created) }}.{{ getYears(cloudFile.created) }}</div>
          <a
            v-if="isFilePdf(cloudFile.name)"
            :href="cloudFile.file"
            target="_blank"
            class="text-cta pointer"
            style="text-decoration: none"
          >
            STIAHNUŤ
          </a>
          <div
            v-else
            @click="download(cloudFile)"
            class="text-cta pointer"
            style="text-decoration: none"
          >
            STIAHNUŤ
          </div>
<!--          TODO: pridať v-if="!cloudFile.type"-->
          <div
            class="text-cta pointer"
            @click="deleteCloudFile(cloudFile, index)"
          >
            VYMAZAŤ
          </div>
        </div>
        <div
          data-v-09f30b34=""
          class="discount-item field-names"
          v-for="(_, key) in loadingFiles"
          :key="key+999"
        >
          <v-progress-circular
            class="loading-spinner small"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>
<script>
import {
  getCloudFilesByCloudId, getFile,
  postFileToCloud,
  removeCloudFileByID
} from "@/services/brokerService";

export default {
  name: "DocumentCloudItem",
  props: {
    type: [Number, null],
    cloud: Object,
    openOnLoad: Boolean,
    extra: Object,
    metaData: [Object, null],
  },
  async created() {
    if (this.cloud.files) {
      this.files = this.cloud.files;
      if (!this.type) this.pickAble = false;
    } else {
      this.files = (await getCloudFilesByCloudId(this.cloud.id)).cloud.cloud_files;
      this.pickAble = false;
    }
    this.opened = this.openOnLoad;
    const dirtyLabel = this.cloud.type_label.toLowerCase();
    this.cleanLabel = dirtyLabel.substring(0, 5) + "u" + dirtyLabel.substring(5, dirtyLabel.length);
  },
  watch: {
    metaData(metaData) {
      if (metaData.cloudId !== this.cloud.id) return;
      const file = new File([metaData.blob], metaData.fileName, {type: "application/pdf"});
      this.handleFiles([file]);
    }
  },
  methods: {
    async download(fileObject) {
      let file = (await getFile(fileObject.file)).data;
      const url = URL.createObjectURL(new Blob([file], {
        type: this.getCloudFileTypeByExtension(fileObject.name)
      }))
      const a = document.createElement('a')
      a.href = url
      a.download = fileObject.name;
      a.target = "_blank";
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    getCloudFileTypeByExtension(fileName) {
      const fileExtension = fileName.match(/\.[0-9a-z]+$/i)[0];
      if (fileExtension === ".doc") return "application/msword";
      else if (fileExtension === ".docx") return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      else if (fileExtension === ".zip") return "application/zip";
      return "application/pdf";
    },
    isFilePdf(fileName) {
      const fileExtension = fileName.match(/\.[0-9a-z]+$/i)[0];
      return ![".doc", ".docx", ".zip"].includes(fileExtension);
    },
    async assignFileType(file) {
      if (this.extra.forceFileType) {
        await this.cleanOldTypes();
        this.files = this.$store.getters["mortgageDetailCloud/getCloudFilesByCloudId"](this.cloud.id);
      }
      this.setFileType(file);
      if (this.type === 2) {
        this.$store.dispatch("mortgageDetailState/setMortgageApplicationStage", {id: this.cloud.applicationId, newStage: "podpisana"});
      }
      if (this.type === 6) {
        const mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
        const mortgageObject = {
          id: mortgage.id,
          object: {
            stage_old: mortgage.stage,
            stage: "kompletizacia_podkladov",
          },
        }
        this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      }
    },
    async cleanOldTypes() {
      const newFileType = (this.$store.getters["mortgageDetailCloud/getCloudFileTypeById"](this.type)).code;
      await this.$store.dispatch("mortgageDetailCloud/nullSpecificTypesForSpecificCloud", {
        cloudId: this.cloud.id,
        type: newFileType
      });
    },
    async setFileType(file) {
      const index = this.files.map(f => f.id).indexOf(file.id);
      const fileLoading = {...file, loading: true};
      this.files.splice(index, 1, fileLoading);
      const transformedFile = await this.$store.dispatch("mortgageDetailCloud/assignFileTypeToFile", {file, typeId: this.type});
      this.files.splice(index, 1, transformedFile);
    },
    openFileUploader() {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        extra: this.extra?.accept,
        type: this.cloud.id,
        payload: null,
        component: "DocumentCloudFileUpload",
        rules: ["single"],
      });
    },
    async deleteCloudFile(file, index) {
      this.files.splice(index, 1);
      try {
        this.$store.commit("appState/setSuccessMessage", {
          text: `Úspešne ste odstránili súbor z ${this.cleanLabel}`,
          type: "success"
        });
        await removeCloudFileByID(file.id);
        if (this.cloud.files && file.type) {
          this.$store.commit("mortgageDetailCloud/removeCloudFile", {cloudId: file.cloud, file: file});
        }
      } catch (e) {
        this.$store.commit("appState/setSuccessMessage", {
          text: `Pri odstraňovaní súboru z ${this.cleanLabel} sa niečo pokazilo`,
          type: "error"
        });
        this.files = (await getCloudFilesByCloudId(this.cloud.id)).cloud.cloud_files;
      }
    },
    getMonth(created) {
      const date = new Date(created);
      return date.getMonth() + 1;
    },
    getYears(created) {
      const date = new Date(created);
      return date.getFullYear();
    },
    getDay(created) {
      const date = new Date(created);
      return date.getDate();
    },
    async handleFiles(files) {
      if (files.length === 0) return;
      const newFiles = await this.prepareFiles(files);
      this.files = [...this.files, ...newFiles];
      this.loadingFiles = [];
    },
    removeExtension(filename) {
      return filename.substring(0, filename.lastIndexOf('.')) || filename;
    },
    removeFile() {
      this.files = [];
    },
    async prepareFiles(files) {
      const newFiles = [];
      this.loadingFiles = files;
      for (const file of files) {
        const formData = new FormData();
        console.log(file.size)
        formData.append("file", file);
        formData.append("name", file.name);
        formData.append("cloud", this.cloud.id);
        newFiles.push(await postFileToCloud(formData));
      }
      if (this.cloud.files) {
        this.$store.commit("mortgageDetailCloud/registerNewFiles", newFiles);
      }
      this.$store.commit("appState/setSuccessMessage", {
        text: `Úspešne ste pridali ${files.length === 1 ? 'súbor' : 'súbory'} do ${this.cleanLabel}`,
        type: "success"
      });
      return newFiles;
    },
  },
  data: () => ({
    url: null,
    pickAble: false,
    opened: false,
    cleanLabel: null,
    files: [],
    loadingFiles: [],
  }),
};

</script>

<style lang="scss" scoped>

</style>
