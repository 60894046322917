<template>
  <div class="d-flex flex-row justify-content-between pointer" @click="logout">
    <div class="p-relative mr-2">
      <div class="settings-icon">
        <img width="12" height="12" src="@/images/settings-icon.png" alt="">
      </div>
      <img class="broker-image" :src="getProfileImage()" width="50" alt="">
    </div>
  </div>
</template>

<script>
import clientFilters from "../../mixins/clientFilters";
import { logoutUser } from "../../services/brokerService";
import imagesConvert from "../../mixins/imagesConvert";

export default {
  name: "UserProfile",
  mixins: [
    clientFilters,
    imagesConvert
  ],
  methods: {
    logout () {
      this.$router.push({name: "KartaNastavenia"});
    },
    // logout () {
    //   this.$store.commit("userState/setMe", null);
    //   this.$store.commit("appState/setEntryUrl", null);
    //   logoutUser();
    //   this.$router.push({name: "Landing"});
    // },
    getProfileImage () {
      if (this.me.profile_image) {
        return this.me.profile_image;
      } else {
        return require("@/images/logo-avatar-not-set.png");
      }
    },
  },
  created () {
    this.me = this.$store.getters["userState/getMe"];
  },
  data: () => ({
    me: null
  }),
}
</script>

<style lang="scss" scoped>
.settings-icon {
  position: absolute;
  top: 60%;
  right: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  width: 20px;
  height: 20px;
}
</style>
