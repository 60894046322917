<template>
  <v-scale-transition>
    <div class="pdf-wrapper" v-if="show">
      <client-needs-generator
        :applicant="applicant"
        :coApplicant="coApplicant"
        :properties="properties"
        :mortgage="mortgage"
        :broker="broker"
        />
    </div>
  </v-scale-transition>
</template>

<script>
import ClientNeedsGenerator from "@/components/shared/PopupHandler/Popups/ClientNeeds/ClientNeedsGenerator.vue";

export default {
  name: "ClientNeedsPopUp",
  components: {
    ClientNeedsGenerator
  },
  props: {
    show: Boolean,
  },
  created() {
    this.applicant = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getFullApplicant"]));
    this.coApplicant = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getFullCoApplicant"]));
    this.properties = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getProperties"]));
    this.mortgage = JSON.parse(JSON.stringify(this.$store.getters["mortgageDetailState/getMortgage"]));
    this.broker = JSON.parse(JSON.stringify(this.$store.getters["userState/getMe"]));
  },
  data: () => ({
    applicant: null,
    coApplicant: null,
    properties: null,
    mortgage: null,
    broker: null,
  })
}
</script>

<style lang="scss" scoped>
.pdf-wrapper {
  padding: 25px;
  height: 90vh;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  overflow-y:scroll;
  z-index: 9999;
  box-shadow: 0 0 40px rgba(0,0,0,0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>