<template>
    <div class="podpis-event-banner" v-if="event.type !== 'notCreated'">
      <div class="div-block-142">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          width="40"
          alt=""
          class="image-10"
        >
        <div class="div-block-143">
          <div class="bold">{{ translateEventType(event.type) }} <span class="place">{{ getAddressModified(event.meta.addressMeta) }}</span></div>
          <div class="term">{{ transformDate(event.start) }}</div>
        </div>
      </div>
      <div>
        <button
          class="w-button"
          @click="eventExtendedView"
        >
          Navrhnúť nový
        </button>
      </div>
    </div>
    <div class="podpis-event-banner" v-else>
      <div class="div-block-142">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f39_calendar%20icon.png"
          loading="lazy"
          width="40"
          alt=""
          class="image-10"
        >
        <div class="div-block-143">
          <div class="bold">
            Zatiaľ nie je dohodnutý podpis žiadostí
          </div>
        </div>
      </div>
      <button class="w-button" @click="openCreateEventDialog">Vytvoriť</button>
    </div>
</template>

<script>
import ClientEventHandler from "../../../../shared/PopupHandler/Popups/ClientMortgageEvents/ClientEventHandler.vue";
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "ClientSignEventHolder",
  props: {
    event: Object,
  },
  components: {
    ClientEventHandler
  },
  mixins: [
    translateFromDBValue,
    propertyFilters,
  ],
  methods: {
    getUserIdFromEventUsers(event) {
      let clientId = null;
      const eventUserIds = this.eventUsers.map(e => e.id);
      for (const eventUser of event.users) {
        if (!eventUserIds.includes(eventUser)) continue;
        clientId = eventUser;
      }
      return clientId;
    },
    eventExtendedView() {
      let client = null;
      for (const userId of this.event.users) {
        const isClientEventUser = this.$store.getters["clientsEvents/eventUsersIncludesUserId"](userId);
        if (!isClientEventUser) continue;
        client = this.$store.getters["clientsEvents/getEventUserById"](userId);
        break;
      }
      const event = {...this.event, start: {value: this.event.start.slice(0, 19)}, end: {value: this.event.end.slice(0, 19)}}
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {delete: false},
        payload: {event, client},
        component: "ExtendedViewPopUp",
        rules: ["single"],
      });
    },
    openCreateEventDialog() {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {type: "clientKnown", component: "ApplicationSignEvent"},
        payload: this.event.applicantId ? this.event.applicantId : null,
        component: "ClientEventPopUp",
        rules: ["single"],
      });
    },
    transformDate(dateString) {
      let date = new Date(dateString);
      date = date.toLocaleDateString('en-GB', {
        weekday:"long", day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: "numeric"
      });
      date = date.toString().split(",");
      const day = this.translateDayToSlovak(date[0]);
      date.splice(0, 1, day);
      date = date.join(",")
      return date;
    }
  }
}
</script>

<style scoped>

</style>