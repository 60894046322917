import Vue from "vue";
import Vuex from "vuex";

import userState from "./userState";
import appState from "./appState";
import formStore from "./formStore";
import popupManagerState from "./popupManagerState";

import clientState from "./brokerRelated/clientState";
import mortgageDetailState from "./brokerRelated/mortgageDetailState";
import mortgageApplicationsState from "./brokerRelated/mortgageApplicationsState";
import mortgagesState from "./brokerRelated/mortgagesState";
import clientsEvents from "./clientsEvents";
import mortgageDetailCloud from "./brokerRelated/mortgageDetailCloud";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    clientsEvents: clientsEvents,
    clientState: clientState,
    userState: userState,
    mortgagesState: mortgagesState,
    appState: appState,
    popupManagerState: popupManagerState,
    mortgageDetailState: mortgageDetailState,
    mortgageApplicationsState: mortgageApplicationsState,
    mortgageDetailCloud: mortgageDetailCloud,
    formStore: formStore,
  },
});
