<template>
  <div class="popup-form">
      <div class="content-form-popup">

        <div class="header-text">
            <div class="back-button-wrapper pointer" @click="closePopup">
              <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt="">
            </div>
            <div class="blackcolor pop-800">
              {{ debtLabel?.toLowerCase() }}
            </div>
            <button form="outcome-submit" class="w-button saveformBtn">Uložiť</button>
        </div>

        <div class="header-block inline" v-if="clientInfo">
          <div class="bold">{{ clientInfo.type }}</div>
          <img
            :src="getIcon(clientInfo.type)"
            loading="lazy"
            alt=""
            class="image-10"
          />
        </div>
        <div class="header-block-error" v-if="payload?.error">Nutné opraviť:
          <div
            v-for="(error, key) in payload.error"
            :key="key"
            class="error-text bl-color font-size-12"
          >
           {{ error }}
          </div>
        </div>
        <div>
          <div
            class="dropdown-input-custom rest-input"
            v-if="type.debtPurpose === 'refinancovane' && this.$store.getters['mortgageDetailState/getCoApplicant']"
          >
            <dropdown-select
              @select="refinancedClient = $event"
              :id="'clientDebt'"
              :init-value="refinancedClient"
              :options="debtClient"
              normal
              :name="'Dlh klienta'"
            />
          </div>
          <component :is="activeComponent" @debt-saved="saveDebt($event)" :debt="payload" :banks="banks"/>
          <div class="modalBottomWrapper">
            
            <div v-if="cloud && cloud.missingFiles.length > 0">
              <document-cloud-handler
                v-for="(missingFile, index) in cloud.missingFiles"
                :key="index"
                :title="missingFile.name"
                :type="missingFile.id"
                :uploaded="missingFile.uploaded"
                :class-cst="'upload-item-broker-custom'"
                :cloud-meta="cloud.cloudMeta"
                :rules="['single']"
                text-button
              />
            </div>
            <div class="sub-popup-wrapper" v-if="type.debtType === 'hypoteka'">
              <client-property-handler
                :rules="['single']"
                :type="'zatazene_nehnutelnosti'"
                :client-type="clientInfo"
                :title="'priradiť zaťaženú NEHNUTEĽNOSŤ'"
                :properties-selected="foundedPropertiesIds"
              />
            </div>
            <div class="input-block contrast p-relative" v-for="(property, key) in foundedProperties" :key="key">
              <div class="closebtn-broker-app pointer" @click="removeProperty(key)">
                <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
              </div>
              <div class="div-block-101">
                {{ getAddressModified(property) }}
                <div class="bold">{{ property.hodnota }} EUR</div>
              </div>
  <!--            <img-->
  <!--              src="../../../../../../../../../../../var/folders/y4/7qp9664s4x13l20kjhnwxyjh0000gn/T/TemporaryItems/NSIRD_screencaptureui_nXg2Ql/Snímka obrazovky 2023-04-27 o 10.49.34.png"-->
  <!--              height="236" width="703"/>-->
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import hypoteka from "./MortgageDebt";
import kreditnakarta from "./CreditCardDebt";
import ClientPropertyHandler from "../ClientProperty/ClientPropertyHandler";
import { getPropertyById } from "@/services/brokerService";
import DropdownSelect from "../../../DropdownSelect";
import clientFilters from "../../../../../mixins/clientFilters";
import propertyFilters from "@/mixins/propertyFilters";
import {mapGetters} from "vuex";
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";

export default {
  name: "ClientDebtPopUp",
  components: {DocumentCloudHandler, DropdownSelect, ClientPropertyHandler, hypoteka, kreditnakarta},
  props: {
    payload: [Object, null],
    type: [String, Object, null],
    metaData: [Object, null],
    rules: [Array, null],
  },
  mixins: [
    clientFilters,
    propertyFilters,
  ],
  computed: {
    ...mapGetters({
      cloudsGetter: "mortgageDetailCloud/getClouds",
    }),
  },
  watch: {
    cloudsGetter(clouds) {
      if (!this.payload.cloud) return;
      this.cloud = clouds.find((cloud) => cloud.cloudId === this.payload.cloud);
    },
    metaData (newMeta) {
      if (newMeta) {
        this.setFoundedProperty(newMeta.id);
      }
    },
  },
  data: () => ({
    debtForm: [],
    debtLabel: null,
    cloud: null,
    clientInfo: null,
    activeComponent: null,
    banks: null,
    foundedProperties: [],
    foundedPropertiesIds: [],
    debtClient: [],
    refinancedClient: null,
  }),
  created () {
    this.debtForm = this.$store.getters["formStore/getFormByType"]("debtForm");
    const debtLabelIndex = this.debtForm.typ.choices.map((choice) => choice.value).indexOf(this.type.debtType);
    this.debtLabel = this.debtForm.typ.choices[debtLabelIndex].display_name;
    if (this.payload?.cloud) {
      const clouds = this.$store.getters["mortgageDetailCloud/getClouds"];
      this.cloud = clouds.find((cloud) => cloud.cloudId === this.payload.cloud);
    }
    this.activeComponent = this.setActive(this.type.debtType);
    this.banks = this.$store.getters["formStore/getBanks"];
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
    this.refinancedClient = this.$store.getters["mortgageDetailState/getApplicant"].id;
    if (this.type.debtPurpose === "refinancovane" && this.$store.getters["mortgageDetailState/getCoApplicant"]) {
      this.debtClient.push({
        display_name: "Žiadateľ",
        value: this.$store.getters["mortgageDetailState/getApplicant"].id
      });
      this.debtClient.push({
        display_name: "Spolužiadateľ",
        value: this.$store.getters["mortgageDetailState/getCoApplicant"]?.id
      });
    }
    if (this.payload && this.payload.zalozene_nehnutelnosti.length !== 0) {
      for (const id of this.payload.zalozene_nehnutelnosti) {
        this.setFoundedProperty(id);
      }
    }
  },
  methods: {
    removeProperty (index) {
      this.foundedProperties.splice(index, 1);
      this.foundedPropertiesIds.splice(index, 1);
    },
    async setFoundedProperty (id) {
      const property = await getPropertyById(id);
      this.foundedProperties.push(property);
      this.foundedPropertiesIds.push(property.id);
    },
    getIcon (type) {
      if (type === "Žiadateľ") {
        return require("@/images/avatar_applicant.png");
      } else if (type === "Spolužiadateľ") {
        return require("@/images/avatar_coapplicant.png");
      }
    },
    setActive (type) {
      switch (type) {
        case "hypoteka":
        case "spotrebny_uver":
        case "leasing":
          return "hypoteka";
        case "kreditna_karta":
        case "povolene_precerpanie":
          return "kreditnakarta";
      }
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    checkIfHasBudget (leftover) {
      const limits = this.$store.getters["mortgageDetailState/getLimits"];
      const limit = this.getUpperLimitLoanInfo(limits);
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      const amount = this.getTotalAmount(amounts);
      const amountLeft = limit - amount;
      return leftover < amountLeft;
    },
    getTotalAmount (amounts) {
      let totalAmount = 0;
      for (const amount of Object.values(amounts)) {
        totalAmount += amount;
      }
      return totalAmount;
    },
    getLoanPurpose () {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let maxAmount = Number.MIN_SAFE_INTEGER;
      let amountKey = {};
      for (const [key, amount] of Object.entries(amounts)) {
        if (amount < maxAmount) continue;
        maxAmount = amount;
        amountKey = key;
      }
      if (maxAmount === 0) {
        amountKey = this.$store.getters["mortgageDetailState/getMortgage"].ciel_prva_analyza;
      }
      switch (amountKey) {
        case "suma_bezucel":
        case "americka_hypo":
          return "bezucel";
        case "suma_refinancovanie":
        case "refinancovanie":
        case "konsolidacia":
          return "refinancovanie";
        case "suma_nadobudnutie":
        case "nadobudnutie":
        case "uverovy_certifikat":
          return "nadobudnutie";
      }
    },
    getUpperLimitLoanInfo (limits) {
      const loanPurpose = this.getLoanPurpose();
      let maxLoan = 0;
      for (const [key, limit] of Object.entries(limits)) {
        if (key === "max_pozicka_podla_ltv") continue;
        const loanObject = limit[loanPurpose];
        if (loanObject.extra.podla_dti.success && maxLoan < loanObject.extra.podla_dti.value) {
          maxLoan = loanObject.extra.podla_dti.value;
        }
      }
      return maxLoan;
    },
    async saveDebt (debtSaved) {
      if (this.type.debtPurpose === "refinancovane" && !this.checkIfHasBudget(debtSaved.zostatok)) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Nie je možné refinancovať dlh s aktuálnym limitom hypotéky",
          type: "error"
        });
        return;
      }
      if (this.payload) {
        const data = {
          id: debtSaved.id,
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          object: {
            clientInfo: this.clientInfo,
            ...debtSaved,
            zalozene_nehnutelnosti: this.foundedPropertiesIds,
          },
        };
        this.$store.dispatch("mortgageDetailState/patchClientDebt", data);
      } else {
        if (this.type.debtPurpose === "refinancovane") this.clientInfo = this.getClientType(this.refinancedClient);
        const data = {
          clientLabel: this.clientInfo.type,
          clientRole: this.clientInfo.type_code,
          object: {
            clientInfo: this.clientInfo,
            ...debtSaved,
            klient: this.clientInfo.id,
            typ: this.type.debtType,
            zalozene_nehnutelnosti: this.foundedPropertiesIds,
          }
        }
        this.$store.dispatch("mortgageDetailState/createClientDebt", data);
      }
      this.closePopup();
    },
  },
};
</script>

<style scoped lang="scss">
.header-block-error {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  margin: 10px 0px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.8);
  flex-direction: column;
  align-items: normal;
}

.v-select__selection--comma div {
  padding: 8px 0 8px 10px !important;
}

.v-text-field--outlined fieldset {
  border-radius: 10px !important;
  border-width: 2px !important;
  border-color: hsla(0, 0%, 80.8%, 0.5) !important;
}

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.pop-up-active {
  transform: translateX(0);
}

.modalBottomWrapper{
  margin-top: 10px;
  background-color: #fff;
  margin-right: -20px;
  margin-left: -20px;
  width: auto;
  padding: 40px 20px;
}

</style>
