<template>
  <div
    class="pop-600 text-small wrap-50px bl-color noselect pointer"
    :style="styleCustom"
    @click="openMaturityFixationPopUp"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "MaturityFixationChooseHandler",
  props: {
    title: String,
    rules: [Array, null],
    styleCustom: String,
  },
  methods: {
    openMaturityFixationPopUp () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "MaturityFixationChoosePopUp",
        rules: this.rules,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
