<template>
  <div class="add-prijem-btn">
    <div class="text-small bl-color noselect pointer" @click="openRestPopup">
      + pridať
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientEmploymentRestHandler",
  props: {
    rules: [Array, null],
    ostatne: Array,
  },
  methods: {
    openRestPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: this.ostatne,
        component: "ClientEmploymentRestPopUp",
        rules: this.rules,
      });
    },
  }
}
</script>

<style scoped>

</style>
