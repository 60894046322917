<template>
  <div
    class="div-block-102"
  >
    <div class="div-block-104">
      <img
        src="@/images/avatar_applicants.png"
        loading="lazy"
        alt=""
        class="avatar"
      />
      <div class="div-block-103">
        <div class="bold">Bonita žiadateľ{{ coApplicantPresent ? "ov" : "a" }}</div>
      </div>
    </div>
    <div class="input-block contrast" v-if="!missingInfo">
      <div class="div-block-101 apart">
        <img
          src="@/images/verified_icon.png"
          loading="lazy"
          width="30"
          alt="">
        <div class="bold">
          Trieda A
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BonitaOfApplicants",
  props: {
    missingInfo: Boolean,
    coApplicantPresent: Boolean,
  }
};
</script>

<style scoped></style>
