<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closeDialog">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div id="Client-name" class="_900 big">
          Overenie {{ clientInfo.type.toLowerCase() }}a
        </div>
      </div>
      <div class="w-form">
        <div class="splitter">Vyberte ako chceme {{ clientInfo.type.toLowerCase() }}a overiť</div>
        <div
          data-w-id="8466810e-7557-a963-e1a4-1ff5d954c81e"
          class="option-block"
        >
          <div class="bold">na diaľku</div>
        </div>
        <div
          data-w-id="8466810e-7557-a963-e1a4-1ff5d954c81e"
          class="option-block"
          @click="clientVerificationPicker('local')"
        >
          <div class="bold">na tomto zariadení</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientVerificationPicker",
  created () {
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
  },
  methods: {
    clientVerificationPicker (device) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: device,
        payload: this.clientInfo.id,
        component: "ClientVerificationPopUp",
        rules: null,
      });
    },
    closeDialog () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    clientInfo: null,
  }),
}
</script>

<style scoped>

</style>
