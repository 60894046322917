<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">
          Vytvorenie hypotéky
        </div>
      </div>
      <div class="input-block">
          <dropdown-select
          @select="pickedMortgagePurpose = $event"
          :id="'property_pravny_stav'"
          :init-value="mortgagePurposes[0].value"
          :options="mortgagePurposes"
          normal
          :name="'Účel hypotéky'"
        />
      </div>
      <client-picker @client-picked="createMortgageToClient($event)" complex zalozit/>
    </div>
  </div>
</template>

<script>
import ClientPicker from "../ClientPicker/ClientPicker";
import DropdownSelect from "../../../DropdownSelect";
import {patchClient} from "../../../../../services/brokerService";

export default {
  name: "MortgageAddPopUp",
  components: {
    DropdownSelect,
    ClientPicker
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    async createMortgageToClient (clientInfo) {
      const role = this.$store.getters["userState/getRole"];
      if (role === "financnik") {
        const broker = this.$store.getters["userState/getId"];
        await patchClient(
          clientInfo.id,
          {financnik: broker}
        );
      }
      const mortgageInfo = {
        applicant: clientInfo.id,
        ciel_prva_analyza: this.pickedMortgagePurpose,
        stage: "vyber_riesenia",
      };
      await this.$store.dispatch("mortgagesState/postMortgage", mortgageInfo);
      this.closePopup();
    },
  },
  data: () => ({
    pickedMortgagePurpose: null,
    mortgagePurposes: [
      {
        display_name: "Nadobudnutie",
        value: "nadobudnutie",
      },
      {
        display_name: "Refinancovanie",
        value: "refinancovanie",
      },
      {
        display_name: "Konsolidácia",
        value: "konsolidacia",
      },
      {
        display_name: "Americká hypo.",
        value: "americka_hypo",
      },
      {
        display_name: "Úverový cert.",
        value: "uverovy_certifikat",
      },
    ]
  }),
};
</script>

<style lang="scss" scoped>
.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
