<template>
  <div
    class="pointer button-item-handler white--text no-decoration" @click="openHelperPopup"
  >
    <img
      class="nav-link-icon filter-primary"
      src="https://bankari.sk/images/appiconpack/order.svg"
      alt="" 
    >
  </div>
</template>

<script>
export default {
  name: "posudokOrderBtn",
  props: {
    rules: [Array, null],
  },
  methods: {
    openHelperPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: "",
        payload: null,
        component: "posudokOrderPopup",
        rules: ['single'],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
