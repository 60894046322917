<template>
  <div class="popup-form">
    <div class="content-form-popup" v-if="clientData">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"/>
        </div>
      </div>
      <div class="payload-name-block">
        <div class="client-name-block">
          <img
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
            loading="lazy"
            alt=""
            class="image-10"
          />
          <div class="div-block-28">
            <div class="light-text lightgrey">
              {{ clientData.email.includes("@nomail.bankari.sk") ? 'Neregistrovaný klient' : 'Klient' }}
            </div>
            <div class="dark-blue-cta-text">
              <div class="blc-color">
                {{ constructClientLabel() }}
              </div>
            </div>
            <div v-if="clientData.is_temporary">
              <v-progress-circular
                v-if="loading"
                class="loading-spinner extrasmall"
                indeterminate
              ></v-progress-circular>
              <div
                class="text-small bl-color pointer invite-button"
                v-else
                @click="inviteClient(clientData.id)"
              >
                + pozvať do aplikácie
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="email-cta">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f21_icons8-mail.svg" loading="lazy" alt=""/>
        <div class="cta-email">
          <div class="light-text lightgrey">Email</div>
            <a
              class="blc-color pointer"
              :href="`mailto:${clientData.email.includes('@nomail.bankari.sk') ? '' : clientData.email}`"
              target="_blank"
            >
              {{ clientData.email.includes("@nomail.bankari.sk") ? "" : clientData.email }}
            </a>
        </div>
      </div>

      <div class="email-cta">
        <img class="nav-link-icon small filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f20_icons8-shake-phone.svg" loading="lazy" alt=""/>
        <div class="cta-email">
          <div class="light-text lightgrey">Telefón</div>
          <div class="blc-color">
            <a
              class="blc-color pointer"
              :href="`tel:${ clientData?.client_profile?.telephone }`"
              target="_blank"
            >
            {{ clientData?.client_profile?.telephone }}
          </a>
          </div>
        </div>
      </div>
      <div class="popup-block" v-if="clientLoaded">
        <div class="icons-wrapper" :class="{'single-row': define}">
          <div v-if="role === 'alex_admin'">
            <broker-picker-handler
              :rules="['single']"
              :client-info="clientData.client_profile"
              :broker-id="clientData.financnik"
              :klient="type.client"
              :clients="type.clients"
              :detail="type.detail"
              :hypoteka="!!type.mortgage"
              :mortgage-id="type.mortgage?.id"
              :mortgage-stage="type.mortgage?.stage"
              :mortgageLayout="type.mortgageLayout"
            />
          </div>
          <client-personal-info-handler
            :client-info="getClientInfo()"
            :client-profile="clientData.client_profile"
            :rules="['single']" icon-button
          />
          <client-chat-handler v-if="!define" :client="clientData" :rules="['single']"/>
          <div class="pointer" @click="archiveClient">
            <div
              class="d-flex flex-column align-items-center bl-color w-inline-block no-decoration"
              v-if="!clientData.client_profile.is_archived"
            >
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1b_icons8-trash.svg">vymazať
            </div>
            <div class="d-flex flex-column align-items-center bl-color w-inline-block no-decoration" v-else>
              <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1c_icons8-reset.svg">obnoviť
            </div>
          </div>
          <mortgage-picker-handler v-if="!define" :client="clientData" :rules="['single']"/>
          <div v-if="!define" class="d-flex justify-content-center">
            <document-cloud-handler
              v-if="cloudsLoaded"
              :cloud-meta="cloudsMeta"
              :rules="['single']"
              text-icon-button
            />
            <v-progress-circular
              v-else
              class="loading-spinner extrasmall"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <!-- pridavanie a evidencia poznamok  -->
      <div class="popup-block">
        <textarea v-model="clientNote" placeholder="Napíšte si poznámku ku klientovi" rows="20" name="comment[text]" id="comment_text" cols="40" class="ui-autocomplete-input" autocomplete="off" role="textbox" aria-autocomplete="list" aria-haspopup="true"></textarea>
      </div>
      <button
          class="text-small bl-color pointer invite-button MGtop20px"
          v-if="clientNote !== (clientData.client_profile.poznamka_ku_klientovi || '')"
          @click="saveClientNote"
      >
        Uložiť poznámku
      </button>
    </div>
  </div>
</template>

<script>
import BrokerPickerHandler from "../BrokerPicker/BrokerPickerHandler";
import MortgagePickerHandler from "../MortgagePicker/MortgagePickerHandler";
import {getClientById, getDebtDTI, getIncomeDTI, getPropertiesByIds, inviteClientById} from "@/services/brokerService";
import ClientChatHandler from "../ClientChat/ClientChatHandler";
import ClientPersonalInfoHandler from "../ClientPersonalInfo/ClientPersonalInfoHandler";
import DocumentCloudHandler from "../DocumentCloud/DocumentCloudHandler";
import {mapGetters} from "vuex";
import cloudsHelpers from "@/mixins/cloudsHelpers";

export default {
  name: "UserManagerPopUp",
  components: {
    DocumentCloudHandler,
    ClientPersonalInfoHandler, ClientChatHandler, BrokerPickerHandler, MortgagePickerHandler
  },
  props: {
    define: Boolean,
    chat: Boolean,
    payload: [Object, null],
    type: [Object],
  },
  computed: {
    ...mapGetters({
      refreshGetter: "popupManagerState/getUserManagerRefresh"
    }),
  },
  mixins: [
    cloudsHelpers,
  ],
  data: () => ({
    clientNote: "",
    cloudsLoaded: false,
    loading: false,
    form: null,
    role: null,
    clientData: null,
    brokerImage: null,
    open: false,
    clientType: null,
    clientLoaded: false,
    cloudsMeta: null,
  }),
  async created () {
    this.form = this.$store.getters["formStore/getFormByType"]("mortgageForm");
    this.clientData = JSON.parse(JSON.stringify(this.payload));
    this.clientNote = this.clientData.client_profile.poznamka_ku_klientovi || "";
    this.role = this.$store.getters["userState/getRole"];
    if (
      !this.clientData.hasOwnProperty("client_profile,mortgages") ||
      !this.clientData.hasOwnProperty("mortgages")
    ) this.clientData = await getClientById(this.clientData.id, "incomes,debts,client_profile,mortgages.coapplicant.incomes,mortgages.coapplicant.debts");
    const mortgageId = (this.$store.getters["mortgageDetailState/getMortgage"])?.id
    if (mortgageId) {
      this.clientType = "Spolužiadateľ";
      for (const mortgage of this.clientData.mortgages) {
        if (typeof mortgage === "number" || mortgage.id !== mortgageId) continue;
        this.clientType = this.clientData.id === mortgage.applicant ? "Žiadateľ" : null;
      }
    }
    this.clientLoaded = true;
    this.cloudsMeta = await this.getCloudMeta();
    this.cloudsLoaded = true;
  },
  watch: {
    async refreshGetter (shouldRefresh) {
      if (!shouldRefresh) return;
      this.clientData = await getClientById(this.clientData.id, "client_profile,mortgages");
      this.$store.commit("popupManagerState/setUserManagerRefresh", false);
    },
  },
  methods: {
    async getCloudMeta () {
      let cloudMeta = {};
      const currentComponent = this.$route.name;
      let mortgageId = null;
      if (currentComponent === "KartaHypotekyDetail") {
        const mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
        mortgageId = mortgage.id;
        const currentMortgageCloud = this.$store.getters["mortgageDetailCloud/getFullClouds"];
        cloudMeta = {
          [mortgageId]: {
            clouds: currentMortgageCloud._.clouds,
            type_label: `Cloud hypotéky ${mortgage.id}, ${this.getMortgagePurpose(mortgage) ? this.getMortgagePurpose(mortgage) + "," : ""} ${this.getMortgageAmount(mortgage)}€`
          }
        };
        cloudMeta[mortgageId].clouds = cloudMeta[mortgageId].clouds.filter((cloud) => !cloud.invisible);
      }
      for (const mortgage of this.clientData.mortgages) {
        if (mortgageId === mortgage.id) continue;
        const clouds = await this.constructAllRelatedCloudsToMortgage(mortgage);
        cloudMeta = {
          ...cloudMeta,
          [mortgage.id]: {
            clouds,
            type_label: `Cloud hypotéky ${mortgage.id}, ${this.getMortgagePurpose(mortgage) ? this.getMortgagePurpose(mortgage) + "," : ""} ${this.getMortgageAmount(mortgage)}€`
          }
        };
      }
      return cloudMeta;
    },
    async constructAllRelatedCloudsToMortgage(mortgage) {
      let incomesArray = [...this.mergeClientInfoToObjects(mortgage.coapplicant?.incomes, "spolužiadateľ"), ...this.mergeClientInfoToObjects(this.clientData.incomes, "žiadateľ")];
      incomesArray = await this.calculateDtiToIncomes(incomesArray);
      let debtsArray = [...this.mergeClientInfoToObjects(mortgage.coapplicant?.debts, "spolužiadateľ"), ...this.mergeClientInfoToObjects(this.clientData.debts, "žiadateľ")];
      debtsArray = await this.calculateDtiToDebts(debtsArray);
      const propertiesArray = (await getPropertiesByIds(mortgage.suvisiace_nehnutelnosti)).results;
      const incomesClouds = this.constructIncomes(incomesArray, mortgage.id);
      const debtsClouds = this.constructDebts(debtsArray, mortgage.id);
      const propertiesClouds = this.constructProperties(propertiesArray, mortgage.id);
      const applicantCloud = [this.constructClient(this.clientData, true, mortgage.id)];
      let coApplicantCloud = [];
      if (mortgage.coapplicant) {
        coApplicantCloud = [this.constructClient(mortgage.coapplicant, false, mortgage.id)];
      }
      return this.getCloudsObjectsFromCloud([...incomesClouds, ...debtsClouds, ...propertiesClouds, ...applicantCloud, ...coApplicantCloud], mortgage.id);
    },
    async calculateDtiToIncomes(incomes) {
      const promises = [];
      for (const income of incomes) {
        promises.push({func: getIncomeDTI, args: income.id});
      }
      const incomesDtiInfo = await Promise.all(promises.map((promise) => promise.func(promise.args, true)));
      return incomes.map((income) => {
        const dtiObject = incomesDtiInfo.find((dtiObject) => dtiObject.id === income.id);
        return {
          ...income,
          dti: this.pickHighestDti(dtiObject).dtiMaxObject
        };
      });
    },
    async calculateDtiToDebts(debts) {
      const promises = [];
      for (const debt of debts) {
        promises.push({func: getDebtDTI, args: debt.id});
      }
      const debtsDtiInfo = await Promise.all(promises.map((promise) => promise.func(promise.args, true)));
      return debts.map((debt) => {
        const dtiObject = debtsDtiInfo.find((dtiObject) => dtiObject.id === debt.id);
        return {
          ...debt,
          dti: this.pickHighestDti(dtiObject).dtiMaxObject
        };
      });
    },
    pickHighestDti (dtiInfo) {
      if (!dtiInfo) return 0;
      let dtiMax = Number.MIN_SAFE_INTEGER;
      let dtiMaxObject = {};
      const incomeDtiData = Object.values(dtiInfo.data);
      for (const dtiItem of incomeDtiData) {
        if (dtiItem.success && dtiMax < dtiItem.value) {
          dtiMax = dtiItem.value;
          dtiMaxObject = dtiItem;
        }
      }
      if (dtiMax === Number.MIN_SAFE_INTEGER) dtiMax = null;
      let error = null;
      if (!dtiMax) {
        error = incomeDtiData[1].details;
        dtiMaxObject.value = 0;
      }
      return {dtiMaxObject, error};
    },
    getCloudsObjectsFromCloud(pClouds, mortgageId) {
      let clouds = [];
      for (const cloudFullObject of pClouds) {
        clouds = [...cloudFullObject.cloudMeta[mortgageId].clouds, ...clouds];
      }
      return clouds
    },
    mergeClientInfoToObjects(objects, clientType) {
      if (!objects) return [];
      for (const object of objects) {
        object.clientInfo = {type: clientType};
      }
      return objects;
    },
    getMortgagePurpose(mortgage) {
      let historyPurpose = this.maxKey(this.getMortgageAmountsFromHistory(mortgage.offers_filter_history));
      if (!historyPurpose) {
        historyPurpose = mortgage.hlavny_ucel;
      }
      if (!historyPurpose) {
        historyPurpose = "suma_nadobudnutie"
      }
      historyPurpose = historyPurpose.split("_")[1];
      return this.getChoicesFromFormByKey("hlavny_ucel", historyPurpose);
    },
    getChoicesFromFormByKey (key, value) {
      for (const choice of this.form[key].choices) {
        if (choice.value === value) {
          return choice.display_name;
        }
      }
    },
    getMortgageAmount(mortgage) {
      const historyAmount = this.sum(this.getMortgageAmountsFromHistory(mortgage.offers_filter_history));
      if (historyAmount !== 0) return historyAmount;
      const clientAmount = mortgage.suma_nadobudnutie + mortgage.suma_refinancovanie + mortgage.suma_bezucel
      return clientAmount ? clientAmount : 0;
    },
    getMortgageAmountsFromHistory(historyObject) {
      if (!historyObject || Array.isArray(historyObject.broker) || historyObject.broker.history.length === 0) return 0;
      const lastHistoryObject = historyObject.broker.history[0];
      return {
        suma_bezucel: lastHistoryObject.suma_bezucel,
        suma_nadobudnutie: lastHistoryObject.suma_nadobudnutie,
        suma_refinancovanie: lastHistoryObject.suma_refinancovanie
      };
    },
    maxKey(object) {
      let maxValue = 0;
      let maxKey = null;
      for (const [key, value] of Object.entries(object)) {
        if (value <= maxValue) continue;
        maxKey = key;
      }
      return maxKey;
    },
    sum(object) {
      let sum = 0;
      for (const objectElement of Object.values(object)) {
        sum += objectElement;
      }
      return sum;
    },
    constructClientLabel () {
      if (this.clientData.first_name === "") {
        return `ID ${this.clientData.id}`;
      } else if (this.clientData.first_name !== "") {
        return `${this.clientData.first_name} ${this.clientData.last_name}`;
      }
      return '';
    },
    getClientInfo () {
      return {
        type: this.clientType,
        id: this.clientData.id,
        email: this.clientData.email,
        firstName: this.clientData.first_name,
        lastName: this.clientData.last_name
      };
    },
    async inviteClient(id) {
      this.loading = true;
      try {
        await inviteClientById(id);
        this.$store.commit("appState/setSuccessMessage", {
          text: "Klient bol úspešne pozvaný do aplikácie",
          type: "success"
        })
      } catch (e) {
        if (e.response.data.detail.includes("User has no email or email ends with @nomail.bankari.sk")) {
          this.$store.commit("appState/setSuccessMessage", {
            text: "Pridajte klientovi email",
            type: "error"
          });
        }
      }
      this.loading = false;
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    archiveClient () {
      if (this.type.client) this.archiveClientWhileClient()
      else this.archiveClientWhileOther()
    },
    getClientType() {
      switch (this.clientData.client_profile.ob_status) {
        case "archivovany":
          return "archivedClients";
        case "novy":
          return "temporaryClients";
          break;
        case "rozpracovaný":
          return "registeredClients";
      }
    },
    getClientArchiveData () {
      let typeOfClient = null;
      let client = null;
      switch (this.clientData.client_profile.ob_status) {
        case "archivovany":
          typeOfClient = "archivedClients";
          client = {
            clientType: typeOfClient,
            clientId: this.clientData.id,
            clientProfileId: this.clientData.client_profile.id,
            temporary: this.clientData.is_temporary,
            clientProfile: {
              ob_status: "novy",
            },
          };
          break;
        case "novy":
          typeOfClient = "temporaryClients";
          client = {
            clientType: typeOfClient,
            clientId: this.clientData.id,
            clientProfileId: this.clientData.client_profile.id,
            temporary: this.clientData.is_temporary,
            clientProfile: {
              ob_status: "archivovany",
            },
          };
          break;
        case "rozpracovaný":
          typeOfClient = "registeredClients";
          client = {
            clientType: typeOfClient,
            clientId: this.clientData.id,
            clientProfileId: this.clientData.client_profile.id,
            temporary: this.clientData.is_temporary,
            clientProfile: {
              ob_status: "archivovany",
            },
          };
          break;
      }
      return client;
    },
    async archiveClientWhileOther () {
      const client = this.getClientArchiveData();
      if (this.clientData.client_profile.ob_status !== "archivovany") {
        this.$store.commit("appState/setLoadingPage", true);
        await this.$store.dispatch("clientState/patchClientProfile", client);
        this.$store.commit("appState/setLoadingPage", false);
        this.$store.commit("appState/setSuccessMessage", {
          text: "archivácia klienta prebehla úspešne",
          type: "success"
        });
      } else {
        this.$store.commit("appState/setLoadingPage", true);
        await this.$store.dispatch("clientState/patchClientProfile", client);
        this.$store.commit("appState/setLoadingPage", false);
        this.$store.commit("appState/setSuccessMessage", {
          text: "obnova klienta prebehla úspešne",
          type: "success"
        });
      }
      this.clientData = await getClientById(this.payload.id, "client_profile,mortgages");
    },
    async archiveClientWhileClient () {
      const client = this.getClientArchiveData();
      if (this.clientData.client_profile.ob_status !== "archivovany") {
        await this.$store.dispatch("clientState/archiveClient", client);
        this.$store.commit("appState/setSuccessMessage", {
          text: "archivácia klienta prebehla úspešne",
          type: "success"
        });
      } else {
        await this.$store.dispatch("clientState/unarchiveClient", client);
        this.$store.commit("appState/setSuccessMessage", {
          text: "obnova klienta prebehla úspešne",
          type: "success"
        });
      }
      this.clientData = await getClientById(this.payload.id, "client_profile,mortgages");
      // console.log("ASDASD", this.clientData);
    },

    async saveClientNote() {
      await this.$store.dispatch("clientState/patchClientProfile", {
        clientId: this.clientData.id,
        clientProfileId: this.clientData.client_profile.id,
        clientProfile: {
          poznamka_ku_klientovi: this.clientNote
        },
        clientType: this.getClientType()
      });
      this.$store.commit("appState/setSuccessMessage", {
        text: "Poznámka bola úspešne uložená",
        type: "success"
      });
    },
  },
};
</script>

<style scoped lang="scss">
.single-row {
  grid-template-rows: 1fr !important;
}

.invite-button {
  font-size: 13px;
}

.dark-blue-cta-text {
  padding: 0;
}

.div-block-28 {
  text-align: left;
  margin-left: 10px;
}

.client-name-block {
  justify-content: left;
}

.payload-name-block {
  position: relative;
}


.broker-grid-buttons {
  grid-template-columns: repeat(3, minmax(50px, 1fr));
}

.div-block-85 {
  right: 0;
}

.popup-block {
  display: inline-block;
  width: 100%;
  font-weight: 500;
}

.popupz {
  z-index: 5555;
}

.popup-block-chat {
  padding: 40px;
}

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.icon-btn {
  padding: 0;
  font-weight: 400 !important;

  img {
    margin-right: 4px;
  }
}
</style>
