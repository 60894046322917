<template>
  <div
    data-current="Tab 1"
    data-easing="ease"
    data-duration-in="300"
    data-duration-out="100"
    class="klienti-tabs w-tabs"
  >
    <div class="klienti-tabs w-tab-menu">
      <a
        class="tab-link-broker w-inline-block w-tab-link no-decoration"
        :class="[filterType === 'new' ? 'w--current' : '']"
        data-w-tab="Tab 1"
        @click="changeClients('new')"
      >
        <div class="noselect">Noví klienti ({{ newLength }})</div>
      </a>
      <a
        class="tab-link-broker w-inline-block w-tab-link no-decoration"
        :class="[filterType === 'inProgress' ? 'w--current' : '']"
        data-w-tab="Tab 2"
        @click="changeClients('inProgress')"
      >
        <div class="noselect">
          Rozpracovaní klienti ({{ registeredLength }})
        </div>
      </a>
      <a
        class="tab-link-broker w-inline-block w-tab-link no-decoration"
        :class="[filterType === 'archived' ? 'w--current' : '']"
        data-w-tab="Tab 3"
        @click="changeClients('archived')"
      >
        <div class="noselect">Archív ({{ archivedLength }})</div>
      </a>
      <a
        v-if="searching"
        class="tab-link-broker w-inline-block w-tab-link no-decoration"
        :class="[filterType === 'search' ? 'w--current' : '']"
        data-w-tab="Tab 3"
      >
        <div class="noselect">Výsledky hľadania ({{ filteredLength }})</div>
      </a>
    </div>
    <div class="tabs-content-3 w-tab-content">
      <div
        data-w-tab="Tab 1"
        data-w-id="155033eb-2d0e-aa7b-9a05-cefed74ed377"
        class="tab-pane-klienti w-tab-pane w--tab-active"
      >
        <div class="klienti-list">
          <klient-item
            @show-client-detail="showDetailOfClient($event)"
            v-for="(client, index) in filteredClients"
            :key="index"
            :search="searching"
            :client="client"
            @client-archived="archiveClient($event, index)"
          />
          <div class="text-center mb-2" v-if="loadingPaginaion">
            <v-progress-circular
            class="loading-spinner extrasmall"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KlientItem from "./KlientItem";
import { getClients } from "@/services/brokerService";

export default {
  name: "KlientiItemWrapper",
  components: {
    KlientItem
  },
  // created () {
  //   const copm = this;
  //   window.onstorage = () => {
  //     const metaData = window.localStorage.getItem("refresh-clients");
  //     if (metaData) {
  //       copm.retrieveActiveTabClients();
  //       window.localStorage.removeItem("refresh-clients");
  //     }
  //   };
  // },
  mounted () {
    this.gteFilter = null;
    this.role = this.$store.getters["userState/getRole"];
    if (this.role !== "alex_admin")
      this.filterForBroker = this.$store.getters["userState/getId"];
    this.clientLookup = setInterval(() => {
      if (this.searchingLoop) {
        this.searchingLoop = false;
        getClients({
            search: this.searchingClient,
            financnik: this.filterForBroker,
            page_size: 10,
            ordering: "-date_joined",
            expand: "client_profile,mortgages",
          })
          .then((resp) => {
          this.filteredClients = resp.results;
          this.filteredLength = resp.count;
          this.loadingPaginaion = false;
          if (resp.next) {
            this.searchPage++;
            this.hasNextSearch = true;
          } else {
            this.hasNextSearch = false;
          }
        });
      }
    }, 500);
    this.patchAllClients();
    this.getClientsFromStore();
  },
  watch: {
    periodFilter (period) {
      this.filterPeriod(period);
    },
    searchFilter (key) {
      this.searching = true;
      this.searchingLoop = true;
      this.filterType = "search";
      this.searchingClient = key;
      if (key === "") {
        this.changeClients("new");
        this.searchPage = 1;
      }
    },
    scroll (e) {
      if (e === 1) {
        this.$emit("loaded", true);
        return;
      }
      const previousLength = this.filteredClients.length;
      const totalLengthOfCurrent = this.getTotalLength();
      if (
        previousLength <= 10 * (e - 1) &&
        previousLength !== totalLengthOfCurrent &&
        previousLength % 10 === 0 &&
        this.filterType !== "search"
      ) {
        this.loadingPaginaion = true;
        const loaderInterval = setInterval(() => {
          if (this.filteredClients.length > previousLength) {
            this.loadingPaginaion = false;
            this.$emit("loaded", true);
            clearInterval(loaderInterval);
          }
        }, 50);
        this.handlePagination();
      } else if (
        previousLength <= 10 * (e - 1) &&
        previousLength !== totalLengthOfCurrent &&
        previousLength % 10 === 0 &&
        this.hasNextSearch
      ) {
        this.loadingPaginaion = true;
        const loaderInterval = setInterval(() => {
          if (this.filteredClients.length > previousLength) {
            this.loadingPaginaion = false;
            this.$emit("loaded", true);
            clearInterval(loaderInterval);
          }
        }, 50);
        this.handlePagination();
      }
    },
    brokerFilter (brokerId) {
      this.filterClients(brokerId);
    },
    conversionFilter (conversion) {
      switch(conversion.type) {
        case "all": this.nullConversionFields(); break;
        case "registerDt": this.setConversionFilters(conversion); break;
      }
    },
  },
  props: {
    searchFilter: [String, null],
    scroll: Number,
    brokerFilter: [Number, null],
    periodFilter: [Object, null],
    conversionFilter: [Object, null],
  },
  data: () => ({
    registerDt: null,
    hasNextSearch: false,
    searchPage: 1,
    searchingLoop: false,
    loadingPaginaion: false,
    clientArchived: false,
    archived: null,
    brokerSaved: false,
    brokerPickerOpened: false,
    clientDetailOpened: false,
    role: null,
    clientsInterval: 0,
    setInterval: null,
    isBrokerPickerOpen: false,
    isDetailOpen: false,
    showBrokerPicker: false,
    showClientDetail: false,
    selectedClient: null,
    clientToSelectBroker: null,
    filterType: "new",
    filteredClients: [],
    registeredLength: 0,
    newClientPages: 1,
    registeredClientPages: 1,
    archivedClientPages: 1,
    newLength: 0,
    searching: false,
    searchingClient: null,
    clientLookup: null,
    archivedLength: 0,
    filteredLength: 0,
    filterForBroker: null,
    ltFilter: null,
    gteFilter: null,
    onlyForBroker: null,
  }),
  beforeDestroy () {
    clearInterval(this.setInterval);
    clearInterval(this.clientLookup);
    clearInterval(this.clientsInterval);
  },
  methods: {
    setConversionFilters(conversion) {
      this[conversion.type] = conversion.value;
      this.retrieveAllClients();
    },
    nullConversionFields() {
      this.registerDt = null;
      this.retrieveAllClients();
    },
    filterPeriod (period) {
      if (period.lt) {
        this.ltFilter = period.lt.replaceAll(" ", "");
      } else {
        this.ltFilter = null;
      }
      if (period.gte) {
        this.gteFilter = period.gte.replaceAll(" ", "");
      } else {
        this.gteFilter = null;
      }
      this.retrieveAllClients();
    },
    filterClients (brokerId) {
      this.filterForBroker = brokerId;
      this.retrieveAllClients();
    },
    retrieveAllClients() {
      this.retrieveRegistered();
      this.retrieveTemporary();
      this.retrieveArchived();
    },
    retrieveActiveTabClients () {
      if (this.filterType === "inProgress") {
        this.retrieveRegistered();
      } else if (this.filterType === "new") {
        this.retrieveTemporary();
      } else if (this.filterType === "archived") {
        this.retrieveArchived();
      }
    },
    getTotalLength () {
      if (this.filterType === "inProgress") {
        return this.registeredLength;
      } else if (this.filterType === "new") {
        return this.newLength;
      } else if (this.filterType === "archived") {
        return this.archivedLength;
      }
    },
    handlePagination () {
      if (this.filterType === "inProgress") {
        this.registeredClientPages += 1;
        this.retrieveRegistered();
      } else if (this.filterType === "new") {
        this.newClientPages += 1;
        this.retrieveTemporary();
      } else if (this.filterType === "archived") {
        this.archivedClientPages += 1;
        this.retrieveArchived();
      } else if (this.filterType === "search" && this.hasNextSearch) {
        this.searchingLoop = true;
      }
    },
    retrieveArchived () {
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          client_profile__ob_status: "archivovany",
          financnik: this.filterForBroker,
          date_joined__gte: this.gteFilter,
          date_joined__lt: this.ltFilter,
          is_applicant: true,
          page_size: 10,
          ordering: "-date_joined",
          mortgages__prva_analyza_registracia_dt__isnull: this.registerDt,
        },
        meta: {
          typeOfClients: "archivedClients",
          pageNumbers: this.archivedClientPages,
        }
      });
    },
    retrieveTemporary () {
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          client_profile__ob_status: "novy",
          financnik: this.filterForBroker,
          date_joined__gte: this.gteFilter,
          date_joined__lt: this.ltFilter,
          is_applicant: true,
          page_size: 10,
          ordering: "-date_joined",
          mortgages__prva_analyza_registracia_dt__isnull: this.registerDt,
        },
        meta: {
          typeOfClients: "temporaryClients",
          pageNumbers: this.newClientPages,
        }
      });
    },
    retrieveRegistered () {
      this.$store.dispatch("clientState/retrieveClients", {
        params: {
          expand: "client_profile,mortgages,incomes",
          client_profile__ob_status: "rozpracovaný",
          financnik: this.filterForBroker,
          date_joined__gte: this.gteFilter,
          date_joined__lt: this.ltFilter,
          is_applicant: true,
          page_size: 10,
          ordering: "-date_joined",
          mortgages__prva_analyza_registracia_dt__isnull: this.registerDt,
        },
        meta: {
          typeOfClients: "registeredClients",
          pageNumbers: this.registeredClientPages,
        },
      });
    },
    archiveClient (isArchived, index = null) {
      if (this.filterType === "search") {
        this.filteredClients[index].client_profile.is_archived = isArchived;
      } else {
        this.retrieveActiveTabClients();
        this.archived = isArchived;
        this.clientArchived = true;
        setTimeout(() => {
          this.clientArchived = false;
        }, 2000);
      }
    },
    showDetailOfClient (client) {
      if (!this.clientDetailOpened) {
        this.showBrokerPicker = false;
        this.clientDetailOpened = true;
        setTimeout(() => {
          this.isDetailOpen = true;
          this.showClientDetail = true;
          this.selectedClient = client;
        }, 100);
      }
    },
    changeClients (filter) {
      this.$emit("reset-pagination");
      this.searching = false;
      this.filterType = filter;
      this.getClients(this.filterType);
    },
    getClients (filter) {
      if (this.searching) return;
      this.filteredClients = [];
      if (filter === "inProgress") {
        this.filteredClients = this.$store.getters["clientState/getRegisteredClients"];
      } else if (filter === "new") {
        this.filteredClients = this.$store.getters["clientState/getNewClients"];
      } else if (filter === "archived") {
        this.filteredClients = this.$store.getters["clientState/getArchivedClients"];
      }
    },
    getCounts () {
      this.registeredLength = 0;
      this.newLength = 0;
      this.archivedLength = 0;
      this.registeredLength = this.$store.getters["clientState/getLengthOfRegistered"];
      this.newLength = this.$store.getters["clientState/getLengthOfNew"];
      this.archivedLength = this.$store.getters["clientState/getLengthOfArchived"];
    },
    setClients () {
      this.getCounts();
      this.getClients(this.filterType);
    },
    patchAllClients () {
      this.clientsInterval = setInterval(() => {
        this.retrieveActiveTabClients();
      }, 5000);
    },
    getClientsFromStore () {
      this.setClients();
      this.setInterval = setInterval(() => {
        this.setClients();
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.success-message {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-bottom: 10px;
  padding: 10px 40px 10px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#2a8bf2),
      to(#0019d9)
  );
  background-image: linear-gradient(90deg, #2a8bf2, #0019d9);
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.1);
  color: #f8f9fa;
  font-weight: 700;
}

.image-4 {
  margin-top: -15px;
  margin-right: 10px;
}

.text-center {
  padding-top: 30px;
}
</style>
