<template>
  <div class="podpis-event-banner">
    <div class="div-block-142"><img src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f39_calendar%20icon.png" loading="lazy" width="40" alt="" class="image-10">
      <div class="div-block-143">
        <div class="bold">Podpis úverových zmlúv</div>
      </div>
    </div>
    <div>
      <client-event-handler
        :component="'ContractSignEvent'"
        :rules="['single']"
        :title="'VYTVORIŤ'"
        :type="'clientKnown'"
        :client="client"
        :class-cst="'text-cta bl-color'"
      />
    </div>
  </div>
</template>

<script>
import ClientEventHandler from "@/components/shared/PopupHandler/Popups/ClientMortgageEvents/ClientEventHandler.vue";

export default {
  name: "ContractSign",
  components: {ClientEventHandler},
  created() {
    this.client = this.$store.getters["mortgageDetailState/getApplicant"];
  },
  data: () => ({
    client: null,
  }),
}
</script>

<style scoped>

</style>