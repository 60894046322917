<template>
  <div>
    <div class="amounts-title text-small light blc-color">
      <p>Sumy</p>
      <p>Diety</p>
    </div>
    <div class="date-picker-wrapper">
      <div class="diets-amounts-month-wrapper" v-for="(month, index) in monthlyData" :key="index">
        <div class="input-group">
          <label :for="'Month-payment' + index" class="field-label">
            {{ months[month.mesiac] }} {{ month.rok }}
          </label>
          <div class="month-wrapper">
            <input
              @change="showEstimatedDtiToUser"
              v-model.number="month.suma"
              type="number"
              step="0.01"
              min="0"
              class="text-input w-input"
              :name="'Month-payment' + index"
              :id="'Month-payment' + index"
              data-name="'Month-payment' + index"
              required
            />
            <div
              v-if="index !== 0"
              class="copy-button"
              @click="copy(index - 1, index, 'suma')"
            >
            <span class="pop-600 bl-color copy-text pointer noselect">
              COPY
            </span>
            </div>
          </div>
          <div
            class="closebtn-broker-app pointer"
            @click="removeMonthlyIncome(index)"
            v-if="index > 2"
          >
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
          </div>
        </div>
        <div class="input-group">
          <label :for="'Month-payment' + index" class="field-label">
            {{ months[month.mesiac] }} {{ month.rok }}
          </label>
          <div class="month-wrapper">
            <input
              @change="showEstimatedDtiToUser"
              v-model.number="month.diety"
              type="number"
              step="0.01"
              min="0"
              class="text-input w-input"
              :name="'Month-payment' + index"
              :id="'Month-payment' + index"
              data-name="'Month-payment' + index"
              required
            />
            <div
              v-if="index !== 0"
              class="copy-button"
              @click="copy(index - 1, index, 'diety')"
            >
            <span class="pop-600 bl-color copy-text pointer noselect">
              COPY
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-title">
      <div class="text-primary" @click="addMonth">
        + pridať
        {{ monthlyData.length !== 0 ? "ďalší" : "" }} mesiac
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthlyAmountInput",
  props: {
    initMonths: Array,
    // updateValue: String,
    averages: [Object, null],
  },
  created() {
    this.monthlyData = this.initMonths;
  },
  watch: {
    averages(newAverages) {
      const lengthOfCreated = this.monthlyData.length;
      if (lengthOfCreated > newAverages.months) {
        this.monthlyData.splice(newAverages.months, lengthOfCreated-newAverages.months);
      } else {
        const missingMonths = newAverages.months - lengthOfCreated;
        for (let i = 0; i < missingMonths; i++) {
          this.addMonth();
        }
      }
      for (const monthlyData of this.monthlyData) {
        monthlyData.suma = newAverages.amount;
      }
      this.showEstimatedDtiToUser();
    }
  },
  methods: {
    addMonth() {
      const lastEntry = this.monthlyData[this.monthlyData.length - 1];
      if (lastEntry.mesiac === 1) {
        this.monthlyData.push({
          income: lastEntry.income,
          mesiac: 12,
          rok: lastEntry.rok - 1,
          diety: null,
          suma: null,
        })
      } else {
        this.monthlyData.push({
          income: lastEntry.income,
          mesiac: lastEntry.mesiac - 1,
          rok: lastEntry.rok,
          diety: null,
          suma: null,
        })
      }
    },
    showEstimatedDtiToUser() {
      this.$emit("refresh-dti");
    },
    copy (from, to, which) {
      this.monthlyData[to][which] = this.monthlyData[from][which];
      this.showEstimatedDtiToUser();
    },
    removeMonthlyIncome (index) {
      if (this.monthlyData.length - 1 < 3) return;
      this.monthlyData.splice(index, 1);
      this.showEstimatedDtiToUser();
    },
  },
  data: () => ({
    monthlyData: [],
    months: {
      1: "Január",
      2: "Február",
      3: "Marec",
      4: "Apríl",
      5: "Máj",
      6: "Jún",
      7: "Júl",
      8: "August",
      9: "September",
      10: "Október",
      11: "November",
      12: "December",
    },
  }),
}
</script>

<style lang="scss" scoped>
.amounts-title {
  display: flex;
  justify-content: space-around;
}

.diets-amounts-month-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.copy-text {
  font-size: 12px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.closebtn-broker-app {
  top: 5px;
}

.date-picker-wrapper {
  margin-top: 6px;
  display: grid;
  grid-column-gap: 10px;
}

.icon-add-date {
  top: 28px;
  right: 15px;
  position: absolute;
  width: 27px;
  opacity: 0.6;
}

.copy-button {
  position: absolute;
  display: none;
  top: 30px;
  right: 15px;
}

.month-wrapper {
  width: 100%;
  height: auto;
}

.month-wrapper:hover {
  & .copy-button {
    display: block;
  }
}
</style>