<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Odovzdanie žiadosti</div>
        <button class="w-button saveformBtn" @click="saveStatus">Uložiť</button>
      </div>
      <div class="input-group">
        <label class="field-label"
        >Kedy ste žiadosť odovzdali<strong></strong
        ></label>
        <input
          name="dateOfUpload"
          type="date"
          :max="todayLong"
          class="text-input w-input date-input"
          maxlength="256"
        />
      </div>
      <!-- <div class="splitter noselect">Vyberte schvaľovateľa</div>
      <div class="input-block">
        <div class="div-block-101">
          <div>VÚB Detvianska 22</div>
          <div class="bold">N. Hlavovičová</div>
        </div>
        <div class="div-block-112">
          <div class="text-cta">PICK</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageApplicationUploadPopUp",
  // props: {
  //   payload: Object,
  // },
  methods: {
    saveStatus () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", true);
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    closePopup () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", false);
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  beforeDestroy () {
    if (this.preverDestroy) return;
    this.$store.commit("popupManagerState/setPopupSuccessResult", false);
  },
  data: () => ({
    preverDestroy: false,
    todayLong: new Date().toISOString().substr(0, 10),
  }),
}
</script>

<style lang="scss" scoped>
.div-block-112 {
  justify-content: center;
}
</style>
