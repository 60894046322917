import {
  getClients,
  getMessagesTo,
  getUserProfile,
  patchClient,
  patchClientProfile,
} from "../../services/brokerService";

const deepEqual = require('deep-equal');

export default {
  namespaced: true,
  state: {
    employees: {
      count: 0,
      results: [],
    },
    temporaryClients: {
      count: 0,
      results: [],
    },
    registeredClients: {
      count: 0,
      results: [],
    },
    archivedClients: {
      count: 0,
      results: [],
    },
    clientUnseenMessages: [],
    clientUnseenMessagesAmount: 0,
  },
  getters: {
    // getFinancnikByID: (state) => (id) => {
    //   return state.financnik.results.find((financnik) => financnik.id === id);
    // },
    getClientById: (state) => (client) => {
      const clientFounded = state[client.type].results.find(
        (result) => result.id === client.id
      );
      if (typeof clientFounded === 'undefined') return null;
      return clientFounded;
    },
    getLengthOfRegistered: (state) => {
      return state.registeredClients.count;
    },
    getLengthOfNew: (state) => {
      return state.temporaryClients.count;
    },
    getLengthOfArchived: (state) => {
      return state.archivedClients.count;
    },
    getNewClients: (state) => {
      return state.temporaryClients.results;
    },
    getRegisteredClients: (state) => {
      return state.registeredClients.results;
    },
    getArchivedClients: (state) => {
      return state.archivedClients.results;
    },
    getEmployeeById: (state) => (id) => {
      const index = state.employees.results.map(e => e.id).indexOf(id);
      if (index === -1) return null;
      return state.employees.results[index];
    },
    getFinancnici: (state) => {
      return state.employees.results.filter((e) => e.role === "financnik");
    },
    getAdmini: (state) => {
      return state.employees.results.filter((e) => e.role === "alex_admin");
    },
    getExperts: (state) => {
      return state.employees.results.filter((e) => e.role === "znalec");
    },
    totalAmountOfUnseenMessages: (state) => {
      let totalAmount = 0;
      state.clientUnseenMessages.forEach(
        (item) => (totalAmount += item.amount_unseen)
      );
      return totalAmount;
    },
    getUnseenMessages: (state) => {
      return state.clientUnseenMessages.sort((a, b) => {
        return (
          new Date(b.created) - new Date(a.created)
        );
      });
    },
  },
  mutations: {
    nullState (state) {
      state.temporaryClients = {
        count: 0,
        results: [],
      }
      state.registeredClients = {
        count: 0,
        results: [],
      }
      state.archivedClients = {
        count: 0,
        results: [],
      }
      state.clientUnseenMessages = []
      state.clientUnseenMessagesAmount = 0
    },
    registerClients (state, data) {
      state[data.typeOfArray] = {
        results: data.retrievedClients,
        count: data.count,
      };
    },
    updateEmployees: (state, data) => {
      let filteredEmployees = state.employees.results.filter((e) => !data.roles.includes(e.role));
      filteredEmployees = [...filteredEmployees, ...data.employees];
      state.employees = {
        results: filteredEmployees,
        count: filteredEmployees.length,
      };
    },
    moveToArchive (state, data) {
      const client = state[data.clientType].results.find(
        (client) => client.id === data.clientId
      );
      const index = state[data.clientType].results.indexOf(client);
      state[data.clientType].results.splice(index, 1);
      state.archivedClients.results.push(client);
    },
    // moveFromArchive (state, data) {
    //   const client = state.archivedClients.results.find(
    //     (client) => client.id === data.clientId
    //   );
    //   const index = state.archivedClients.results.indexOf(client);
    //   state.archivedClients.results.splice(index, 1);
    //   state[data.moveTo].results.push(client);
    // },
    updateClientProfile (state, data) {
      const clientProfile = data.clientProfile;
      let client = state[data.clientType].results.find(
        (client) => client.id === data.clientId
      );
      if (typeof client === "undefined") return;
      client.client_profile = {...client.client_profile, ...clientProfile};
    },
    updateClient (state, data) {
      const client = state[data.clientType].results.find(
        (client) => client.id === data.clientId
      );
      const index = state[data.clientType].results.indexOf(client);
      if (index === -1) return;
      state[data.clientType].results.splice(index, 1, data.clientUpdated);
    },
    updateChatOfExistingClient (state, messages) {
      state.clientUnseenMessages = messages;
    },
  },
  actions: {
    updateUnseenMessages: async ({commit, state}, clientId) => {
      const messages = await getMessagesTo(clientId);
      let clientUnseenMessages = [];
      messages.results.forEach((message) => {
        if (message.sender !== null) {
          const lastMessageFromClient = clientUnseenMessages.find(
            (lastMessage) => lastMessage.sender === message.sender
          );
          const indexOfClient = clientUnseenMessages.indexOf(lastMessageFromClient);
          const messageObj = clientUnseenMessages[indexOfClient];
          if (indexOfClient === -1) {
            clientUnseenMessages[clientUnseenMessages.length] = {
              ...message,
              amount_unseen: message.seen_at ? 0 : 1
            }
          } else {
            if (!message.seen_at && messageObj.id < message.id) {
              messageObj.message = message;
              messageObj.amount_unseen = messageObj.amount_unseen + 1;
            } else if (!message.seen_at && messageObj.id > message.id) {
              messageObj.amount_unseen = messageObj.amount_unseen + 1;
            }
          }
        }
      });
      if (deepEqual(state.clientUnseenMessages, clientUnseenMessages)) return;
      commit("updateChatOfExistingClient", clientUnseenMessages);
    },
    async patchClient ({commit}, data) {
      const clientData = JSON.parse(JSON.stringify(data));
      clientData.clientUpdated = await patchClient(
        data.clientId,
        {financnik: clientData.financnik},
        "client_profile"
      );
      if (!data.clientType) return;
      commit("updateClient", clientData);
    },
    async patchClientProfile ({commit}, data) {
      const clientToUpdate = {
        clientType: data.clientType,
        clientId: data.clientId,
      };
      clientToUpdate.clientProfile = await patchClientProfile(
        data.clientProfile,
        data.clientProfileId
      );
      commit("updateClientProfile", clientToUpdate);
    },
    async archiveClient ({commit}, data) {
      commit("appState/setLoadingPage", true, {root: true});
      const clientToUpdate = {
        clientType: data.clientType,
        clientId: data.clientId,
      };
      clientToUpdate.clientProfile = await patchClientProfile(
        data.clientProfile,
        data.clientProfileId
      );
      commit("updateClientProfile", clientToUpdate);
      commit("moveToArchive", {
        clientId: clientToUpdate.clientId,
        clientType: clientToUpdate.clientType,
      });
      commit("appState/setLoadingPage", false, {root: true});
    },
    async unarchiveClient ({commit}, data) {
      commit("appState/setLoadingPage", true, {root: true});
      const clientToUpdate = {
        clientType: data.clientType,
        clientId: data.clientId,
      };
      await patchClientProfile(data.clientProfile, data.clientProfileId).then(
        (resp) => {
          clientToUpdate.clientProfile = resp;
        }
      );
      if (data.temporary) {
        clientToUpdate.moveTo = "temporaryClients";
      } else {
        clientToUpdate.moveTo = "registeredClients";
      }
      // commit("updateClientProfile", clientToUpdate);
      // commit("moveFromArchive", clientToUpdate);
      commit("appState/setLoadingPage", false, {root: true});
    },
    retrieveEmployees: async({commit}, employeesToGet) => {
      const employees = await getUserProfile({role__in: employeesToGet.toString(), page_size: 999});
      const employeesObject = {
        typeOfArray: "employees",
        retrievedClients: employees.results,
        count: employees.count,
      };
      commit("registerClients", employeesObject);
    },
    updateEmployees: async({commit}, employeesToGet) => {
      const employees = await getUserProfile({role__in: employeesToGet.toString(), page_size: 999});
      const employeesObject = {
        roles: employeesToGet,
        employees: employees.results,
      };
      commit("updateEmployees", employeesObject);
    },
    retrieveClients: async ({commit}, data) => {
      const clientObject = {
        typeOfArray: data.meta.typeOfClients,
        retrievedClients: [],
        count: 0,
      };
      for (let i = 0; i < data.meta.pageNumbers; i++) {
        if (clientObject.count / 10 < i) break;
        const clients = await getClients({...data.params, page: i+1});
        clientObject.retrievedClients.push(...Object.values(clients.results));
        clientObject.count = clients.count;
      }
      commit("registerClients", clientObject);
    },
  },
};
