<template>
  <div class="d-flex justify-content-between">
    <div class="closebtn-broker-app pointer" @click="restoreApplication()">
      <img class="nav-link-icon extrasmall white filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1c_icons8-reset.svg" loading="lazy"/>
    </div>
    <div class="hypoteka-top grey w-inline-block">
      <div v-if="object.coapplicant" class="position-relative">
        <img
          class="image-10"
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f48_two%20applicants%20icon.png"
          loading="lazy"
          alt=""
        />
      </div>
      <div v-else>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
      </div>
      <div class="MGleft10px bold blc-color">
        <user-manager-handler
            v-if="object.applicant"
            :class-cst="'blc-color'"
            :rules="['priority']"
            :client-info="object.applicant"
            client
        />
        <div v-if="object.coapplicant" class="co-applicant-name pop-500">
          &
          <span v-if="object.coapplicant.first_name">
            {{ object.coapplicant.first_name }}
            {{ object.coapplicant.last_name }}
          </span>
          <span v-else>
            ID {{ object.coapplicant.id }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesConvert from "@/mixins/imagesConvert";
import UserManagerHandler from "@/components/shared/PopupHandler/Popups/UserManager/UserManagerHandler.vue";

export default {
  name: "MortgageApplicationsObjects",
  components: {UserManagerHandler},
  props: {
    object: Object,
  },
  mixins: [
    imagesConvert,
  ],
  methods: {
    restoreApplication() {
      this.$emit("restore", this.object);
    },
  },
}
</script>

<style lang="scss" scoped>

</style>