import api from "./apiConfig";
import axi from "./axiConfig";

export async function loginUser (name, psw) {
  const response = await axi.post(`/auth/token/`, {
    email: name,
    password: psw,
  });

  return response.data;
}

export async function logoutUser () {
  const response = await axi.post(`/auth/logout/`);

  return response.data;
}

export async function createTempUser () {
  return await axi.post(`/auth/temp-client/`);
}

export async function registerBroker (userData) {
  return await axi.post(`/auth/client-signup/`,{
    ...userData,
    vek_v_case_vyplnania: 0,
    role: "financnik",
  });
}

export async function setMessageSeen (id) {
  const response = await api.post(`/chat-message/${id}/set-seen/`);

  return response.data;
}

export async function sendMessage (
  recipient,
  content,
  file = null,
  fileName = null,
  meta = null,
) {
  const response = await api.post(
    `/chat-message/`,
    {
      recipient: recipient,
      content: content,
      attachment: file,
      attachment_filename: fileName,
      meta: meta,
    }
  );

  return response.data;
}

export async function getMessagesTo (recipient, seenAtNull=null) {
  const response = await api.get(`/chat-message/`, {
    params: {
      seen_at__isnull: seenAtNull,
      recipient: recipient,
      page_size: 9999,
    },
  });
  return response.data;
}

export async function getMessages (ID) {
  const response = await api.get(`/chat-message/`, {
    params: {
      sender_or_recipient: ID,
    },
  });

  return response.data;
}

// export async function getClients (
//   page,
//   ob_status,
//   search,
//   searchForFinancnikId,
//   onlyForBroker,
//   gte,
//   lt,
//   page_size = null
// ) {
//   const response = await api.get(`/client/`, {
//     params: {
//       search: search,
//       // is_temporary: temporary,
//       // is_archived: archived,
//       financnik: onlyForBroker,
//       financnik_or_none: searchForFinancnikId,
//       ordering: "-date_joined",
//       client_profile__ob_status: ob_status,
//       page_size: page_size,
//       page: page,
//       date_joined__gte: gte,
//       date_joined__lt: lt,
//       is_applicant: true,
//     },
//   });
//
//   return response.data;
// }

export async function getClients (params) {
  const response = await api.get(`/client/`, {params});

  return response.data;
}

export async function patchClient (ID, data, expand=null) {
  const response = await api.patch(`/client/${ID}/`, data, {
    params: {
      expand
    }
  });

  return response.data;
}

export async function getClientMissingFilesById (ID) {
  const response = await api.get(`/client/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}

export async function getMortgageMissingFilesById (ID) {
  const response = await api.get(`/mortgage/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}
export async function getApplicationMissingFilesById (ID) {
  const response = await api.get(`/application/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}
export async function inviteClientById (ID) {
  return await api.post(`/client/${ID}/invite/`);
}

export async function getClientById (ID, expand=null) {
  const response = await api.get(`/client/${ID}/`, {
    params: {
      expand
    }
  });

  return response.data;
}

export async function getAccess () {
  const response = await api.post(
    `/auth/token/refresh/`,
  );

  return response.data;
}

export async function getCalendarEvents(page= null) {
  const response = await api.get(`/calendar-event/`, {
    params: {
      page
    }
  });

  return response.data;
}

export async function postCalendarEvents(data) {
  const response = await api.post(`/calendar-event/`, data);

  return response.data;
}

export async function deleteCalendarEvent(ID) {
  const response = await api.delete(`/calendar-event/${ID}/`);

  return response.data;
}

export async function patchCalendarEvent(ID, data) {
  const response = await api.patch(`/calendar-event/${ID}/`, data);

  return response.data;
}

export async function getMortgages (params) {
  const response = await api.get(`/mortgage/`, {
    params,
  });

  return response.data;
}

export async function patchMortgage (mortgage, ID) {
  const response = await api.patch(`/mortgage/${ID}/`, mortgage);

  return response.data;
}

export async function getMortgageById (ID, expand= null) {
  const response = await api.get(`/mortgage/${ID}/`, {
    params: {
      expand
    }
  });

  return response.data;
}

export async function postMortgage (mortgage) {
  const response = await api.post(`/mortgage/`, mortgage);

  return response.data;
}
export async function getStaffProfile (ID) {
  const response = await api.get(`/staff/${ID}/`);

  return response.data;
}
export async function getSfa (ID) {
  const response = await api.get(`/sfa/${ID}/`);

  return response.data;
}
export async function patchUserProfileByID (ID, data) {
  const response = await api.patch(`/user/${ID}/`, data);

  return response;
}

export async function getUserProfileByID (ID) {
  const response = await api.get(`/user/${ID}/`);

  return response.data;
}

export async function getUserProfile (params = null) {
  const response = await api.get(`/user/`, {params});

  return response.data;
}

export async function patchClientProfile (profileToPatch, ID) {
  const response = await api.patch(`/client-profile/${ID}/`, profileToPatch);

  return response.data;
}

export async function getApplications (params) {
  const response = await api.get(`/application/`, {
    params
  });

  return response.data;
}
export async function getApplicationById (ID, expand= null, requested= false) {
  const response = await api.get(`/application/${ID}/`, {
    params: {
      expand
    }
  });

  if (requested) {
    return {id: ID, application: response.data};
  }

  return response.data;
}

export async function deleteMortgageApplicationByID (ID) {
  const response = await api.delete(`/application/${ID}/`);

  return response.data;
}

export async function patchMortgageApplicationByID (ID, data) {
  const response = await api.patch(`/application/${ID}/`, data);

  return response.data;
}

export async function createMortgageApplication (data, expand = null) {
  const response = await api.post(`/application/create-from-mortgage/`, data, {
    params: {
      expand,
    }
  });

  return response.data;
}

export async function patchClientIncome (income, ID) {
  const response = await api.patch(`/client-income/${ID}/`, income);

  return response.data;
}

export async function getIncomeMissingFilesById (ID) {
  const response = await api.get(`/client-income/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}
export async function postClientIncome (income) {
  const response = await api.post(`/client-income/`, income);

  return response.data;
}

export async function removeClientIncome (ID) {
  const response = await api.delete(`/client-income/${ID}/`);

  return response.data;
}

export async function getFlatById (ID) {
  const response = await api.get(`/flat/${ID}/`);

  return response.data;
}

export async function patchClientProperty (property, ID) {
  const response = await api.patch(`/property/${ID}/`, property);

  return response.data;
}

export async function patchClientFlat (property, ID) {
  const response = await api.patch(`/flat/${ID}/`, property);

  return response.data;
}


export async function removeClientProperty (ID) {
  const response = await api.delete(`/property/${ID}/`);

  return response.data;
}

export async function getPropertyMissingFilesById (ID) {
  const response = await api.get(`/property/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}

export async function postChildTypeToPropertyById (type, ID) {
  const response = await api.post(`/property/${ID}/create-child/`, type);

  return response.data;
}

export async function patchClientDebt (debt, ID) {
  const response = await api.patch(`/client-debt/${ID}/`, debt);

  return response.data;
}

export async function postClientProperty (property) {
  const response = await api.post(`/property/`, property);

  return response.data;
}

export async function postClientDebt (debt) {
  const response = await api.post(`/client-debt/`, debt);

  return response.data;
}

export async function removeClientDebt (ID) {
  const response = await api.delete(`/client-debt/${ID}/`);

  return response.data;
}

export async function getDebtMissingFilesById (ID) {
  const response = await api.get(`/client-debt/${ID}/missing-files/`);

  return {missingFiles: response.data, id: ID};
}

export async function userMe () {
  const response = await api.get(`/user/me/`);

  return response.data;
}

export async function getPropertyById (ID) {
  const response = await api.get(`/property/${ID}/`);

  return response.data;
}

export async function getPropertiesByIds (ids) {
  const response = await api.get(`/property/`, {
    params: {
      id__in: ids.toString(),
      page_size: ids.length,
    }
  });

  return response.data;
}

export async function getCloudFilesByCloudId (ID, expand= null) {
  const response = await api.get(`/cloud/${ID}/`, {
    params: {
      expand,
    }
  });

  return {cloud: response.data, id: ID};
}

export async function removeCloudFileByID (ID) {
  const response = await api.delete(`/cloud-file/${ID}/`);

  return response.data;
}

export async function patchCloudFileByID (ID, data, expand=null) {
  const response = await api.patch(`/cloud-file/${ID}/`, data, {
    params: {
      expand
    }
  });

  return response.data;
}

export async function getFile(URL, raw=false) {
  const response = await api.get(URL, {
    withCredentials: false,
    responseType: "blob"
  });
  if (raw) {
    return {
      fileData: response.data,
      url: URL,
    }
  }
  return response;
}

export async function postFileToCloud (file) {
  const response = await api.post(`/cloud-file/`, file);

  return response.data;
}

export async function getFileTypes () {
  const response = await api.get(`/cloud-file-type/`, {
    params: {
      page_size: 100,
    }
  });

  return response.data;
}

export async function getLocation (location) {
  const response = await api.get(
    `https://maps.googleapis.com/maps/api/place/autocomplete/`,
    {
      params: {
        location: location,
        language: "sk",
        key: "AIzaSyCa1iMnqDm7rO5JGxZL_dHbGl0BN-9ZJiI",
      },
    }
  );

  return response.data;
}

export async function getIncomeForm () {
  const response = await api.options(`/client-income/`);

  return response.data;
}

export async function getProfessions () {
  const response = await api.get(`/profesia/`);

  return response.data;
}

export async function getDebtForm () {
  const response = await api.options(`/client-debt/`);

  return response.data;
}

export async function getPropertyForm () {
  const response = await api.options(`/property/`);

  return response.data;
}

export async function getBonitaForm () {
  const response = await api.options(`/client-income/`);

  return response.data;
}

export async function getMortgageForm () {
  const response = await api.options(`/mortgage/`);

  return response.data;
}

export async function getClientProfileForm () {
  const response = await api.options(`/client-profile/`);

  return response.data;
}

export async function getAllBanks (expand) {
  const response = await api.get(`/bank/`, {
    params: {
      expand
    }
  });

  return response.data;
}

export async function getClientDTI (ID) {
  const response = await api.post(`/bank/calculations/client-dti/${ID}/`);

  return response.data;
}

export async function getEmploymentDTI (data) {
  const response = await api.post(`/bank/calculations/dti-zamestnanie/`, data);

  return response.data;
}

export async function getSzcoDTI (data) {
  const response = await api.post(`/bank/calculations/dti-szco/`, data);

  return response.data;
}

export async function getSroDTI (data) {
  const response = await api.post(`/bank/calculations/dti-sro/`, data);

  return response.data;
}

export async function getRentDTI (data) {
  const response = await api.post(`/bank/calculations/dti-prenajom/`, data);

  return response.data;
}

export async function getRestIncomeDTI (data) {
  const response = await api.post(
    `/bank/calculations/dti-ostatne-prijmy/`,
    data
  );

  return response.data;
}

export async function getDebtDTI (id, raw=false) {
  const response = await api.post(
    `/bank/calculations/debt-partial-dti/`,
    {debt_id: id}
  );
  if (raw) return {id, data: response.data};
  return response.data;
}

export async function getIncomeDTI (id, raw=false) {
  const response = await api.post(
    `/bank/calculations/income-partial-dti/`,
    {income_id: id}
  );
  if (raw) return {id, data: response.data};
  return response.data;
}

export async function createEmployer (employerData) {
  const response = await api.post(`/employer/`, employerData);

  return response.data;
}

export async function getEmployerByIco (ico) {
  const response = await api.post(`/employer/by-ico/`, ico);

  return response.data;
}

export async function getEmployerByID (ID) {
  const response = await api.get(`/employer/${ID}/`);

  return response.data;
}

export async function postMonthlyIncome (income) {
  const response = await api.post(`/client-monthly-income/`, income);

  return response.data;
}

export async function patchMonthlyIncome (income, ID) {
  const response = await api.patch(`/client-monthly-income/${ID}/`, income);

  return response.data;
}

export async function deleteMonthlyIncome (ID) {
  const response = await api.delete(`/client-monthly-income/${ID}/`);

  return response.data;
}

export async function getLoanData (data) {
  const response = await api.post(`/bank/calculations/loan/`, data);

  return response.data;
}

export async function getSumSubToken (data) {
  const response = await api.post(`/sumsub/get-access-token/`, data);

  return response.data;
}

export async function getBankVariables () {
  const response = await api.get(`/bank/vars/`);

  return response.data;
}

export async function getMortgageOffers (ID, data, expand= null, requested=false) {
  const response = await api.post(`/mortgage/${ID}/get-offers/`, data, {
    params: {
      expand,
    }
  });
  if (requested) return {offers: response.data, requested: data};
  return response.data;
}

export async function getMortgageApplicationForSpecificBank (ID, data, expand= null, requested=false) {
  const response = await api.post(`/mortgage/${ID}/get-bank-offer/`, data, {
    params: {
      expand,
    }
  });
  if (requested) return {offers: response.data, requested: data};
  return response.data;
}

export async function getMortgageLimits (ID, fix,) {
  const response = await api.post(`/mortgage/${ID}/limits/`, {
    fix: fix,
  });

  return response.data;
}

export async function getMortgageLoanLimit (ID, fix) {
  const response = await api.post(`/mortgage/${ID}/limits-uver/`, {
    fix: fix,
  });

  return response.data;
}

export async function getMortgageMaturityLimit (ID, fix) {
  const response = await api.post(`/mortgage/${ID}/limits-splatnost/`, {
    fix: fix,
  });

  return response.data;
}
