<template>
  <div>
      <div class="calendarCTAs">
        <div class="input-block contrast">
          <div class="div-block-101">
            <div class="text-nowrap">podpis úveru</div>
            <client-event-handler
              :component="'ContractSignEvent'"
              :rules="['single']"
              :title="'VYTVORIŤ'"
              :type="'clientUnknown'"
              :class-cst="'text-cta bl-color'"
            />
          </div>
        </div>
        <div class="input-block contrast">
          <div class="div-block-101">
            <div class="text-nowrap">podpis žiadosti</div>
            <client-event-handler
              :component="'ApplicationSignEvent'"
              :rules="['single']"
              :title="'VYTVORIŤ'"
              :type="'clientUnknown'"
              :class-cst="'text-cta bl-color'"
            />
          </div>
        </div>
        <div class="input-block contrast">
          <div class="div-block-101">
            <div class="text-nowrap">online konzultácia</div>
            <client-event-handler
              :component="'OnlineConsultationEvent'"
              :rules="['single']"
              :title="'VYTVORIŤ'"
              :type="'clientUnknown'"
              :class-cst="'text-cta bl-color'"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between pl-2 pr-2">
      <div class="calendar-navigation">
        <div class="pointer" @click="previousWeek()">
          <div class="circle-button d-flex justify-content-center align-items-center">
            <img class="nav-link-icon small filter-primary" style="transform:rotate(-90deg)" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg" alt=""/>
          </div>
        </div>
        <div
          class="blackcolor pop-500 ml-2 pointer blc-color"
          @click="previousWeek()"
        >
          predošlí {{ vh > 600 ? "týždeň" : "deň" }}
        </div>
      </div>
      <div class="calendar-navigation">
        <div
          class="blackcolor pop-500 mr-2 pointer blc-color"
          @click="nextWeek()"
        >
          nasledujúci {{ vh > 600 ? "týždeň" : "deň" }}
        </div>
        <div class="circle-button d-flex justify-content-center align-items-center pointer" @click="nextWeek()">
          <img class="nav-link-icon small filter-primary" style="transform:rotate(90deg)" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg" alt=""/>
        </div>
      </div>
    </div>
    <div class="overflow-auto" id="calendar"></div>
  </div>
</template>

<script>
import { DayPilot } from '@daypilot/daypilot-lite-javascript/daypilot-javascript.min'
import ClientEventHandler from "./PopupHandler/Popups/ClientMortgageEvents/ClientEventHandler";
import translateFromDBValue from "../../mixins/translateFromDBValue";
import {mapGetters} from "vuex";

export default {
  name: "CalendarCustom",
  components: {
    ClientEventHandler
  },
  mixins: [
    translateFromDBValue
  ],
  watch: {
    eventUsersGetter(users) {
      this.eventUsers = users;
    },
    calendarEventsGetter(events) {
      this.events = this.transformEvents(events);
      this.calendar.update({events: this.events});
    },
  },
  computed: {
    ...mapGetters({
      eventUsersGetter: "clientsEvents/getEventsUsers",
      calendarEventsGetter: "clientsEvents/getEvents",
    })
  },
  beforeDestroy() {
    this.$root.$off("event-delete");
  },
  created () {
    this.eventUsers = this.$store.getters["clientsEvents/getEventsUsers"];
    this.events = this.$store.getters["clientsEvents/getEvents"];
  },
  mounted () {
    this.$root.$on("event-delete", this.deleteEvent);
    this.setTodayFullDate();

    this.currentMonday = this.todayLong;

    this.vh = window.innerWidth;

    this.calendar = new DayPilot.Calendar("calendar", {
      viewType: this.vh > 600 ? "WorkWeek" : "Day",
      startDate: this.todayLong,
      headerDateFormat: "d.M.yyyy ddd",
      eventMoveHandling: "disabled",
      eventDeleteHandling: "CallBack",
      onEventClicked: args => {
        if (this.deleting) return;
        this.eventExtendedView(args)
      },
      onEventMoved: args => {
        this.setTodayFullDate();
        this.moveEvent({event: {...args.e.data, start: {value: args.newStart.value}, end: {value: args.newEnd.value}}, checkEventChange: true});
      },
      onEventDeleted: async args => {
        this.deleting = true;
        await this.deleteEvent({event: args, checkEventChange: true});
        this.deleting = false;
      }
    });

    this.calendar.locale = "sk-sk";
    this.calendar.init();

    this.events = this.transformEvents(this.events);
    this.calendar.update({events: this.events});
  },
  methods: {
    setTodayFullDate() {
      const date = new Date();
      date.setHours(date.getHours() + 1);
      this.todayFull = date.toISOString().substr(0, 19);
    },
    transformDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: "numeric"
      });
    },
    getStartAndEndTime(start, end) {
      return `${start.slice(11, 16)} - ${end.slice(11, 16)}`;
    },
    getUserLastnameById(id) {
      if (typeof id === "undefined") return "xxxxx";
      const userIndex = this.eventUsers.map(e => e.id).indexOf(id);
      return this.eventUsers[userIndex].last_name;
    },
    getUserIdFromEventUsers(event) {
      let clientId = null;
      const eventUserIds = this.eventUsers.map(e => e.id);
      for (const eventUser of event.users) {
        if (!eventUserIds.includes(eventUser)) continue;
        clientId = eventUser;
      }
      return clientId;
    },
    transformEventText(event) {
      const clientId = this.getUserIdFromEventUsers(event);
      return `${this.translateEventType(event.type)}\n${this.getUserLastnameById(clientId)}\n${this.getStartAndEndTime(event.start, event.end)}`;
    },
    transformEvents(events) {
      const eventsArray = [];
      for (const event of events) {
        eventsArray.push({
          ...event,
          start: event.start.slice(0, 19),
          end: event.end.slice(0, 19),
          text: this.transformEventText(event),
          id: event.id,
          cssClass: "customcss",
          barColor: "#0019d9"
        })
      }
      return eventsArray;
    },
    async deleteEvent(eventData) {
      if (eventData.checkEventChange) {
        this.showDialogToUser(eventData.event, 'Potvrdením vymažete túto udalosť');
        const userPermission = await this.waitForUserPermission();
        if (!userPermission) return;
      }
      await this.$store.dispatch("clientsEvents/deleteEvent", eventData.event.e.data.id);
    },
    eventExtendedView(event) {
      const myId = this.$store.getters["userState/getId"];
      const clientId = this.getUserIdFromEventUsers(event.e.data);
      const userIndex = this.eventUsers.map(e => e.id).indexOf(clientId);
      const client = this.eventUsers[userIndex];
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {delete: true},
        payload: {event: {...event.e.data, users: [...event.e.data.users, myId]}, client: client},
        component: "ExtendedViewPopUp",
        rules: ["single"],
      });
    },
    isEventMovePossible(event) {
      const oldEventIndex = this.events.map(e => e.id).indexOf(event.id);
      const oldEvent = this.events[oldEventIndex];
      if (event.start.value === oldEvent.start.value) return false;
      const isFutureDate = event.start.value > this.todayFull;
      if (!isFutureDate) {
        this.$store.commit("appState/setSuccessMessage", {
          text: `Zadajte budúci dátum`,
          type: "error"
        });
        return false;
      }
      return true;
    },
    async moveEvent(eventData) {
      if (!this.isEventMovePossible(eventData.event)) return;
      const messageText = `Naozaj chcete presunúť ${this.translateEventType(eventData.event.type).toLowerCase()} na ${this.transformDate(eventData.event.start.value)}`
      this.showDialogToUser(eventData.event, messageText);
      const userPermission = await this.waitForUserPermission();
      if (!userPermission) return;
      const myId = this.$store.getters["userState/getId"];
      const newEventData = {
        ...eventData.event,
        start: eventData.event.start.value,
        end: eventData.event.end.value,
        users: [...eventData.event.users, myId]
      }
      this.$store.dispatch("clientsEvents/moveEvent", newEventData);
    },
    showDialogToUser (event, messageText) {
      this.$store.commit("appState/setSuccessMessage", {
        text: messageText,
        type: "confirmation"
      });
    },
    waitForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    nextWeek () {
      const step = this.vh > 600 ? 7 : 1;
      this.calendar.startDate = this.calendar.startDate.addDays(step);
      this.calendar.update();
    },
    previousWeek () {
      const step = this.vh > 600 ? 7 : 1;
      this.calendar.startDate = this.calendar.startDate.addDays(-step);
      this.calendar.update();
    }
  },
  data: () => ({
    eventUsers: [],
    calendarEvents: [],
    deleting: false,
    vh: null,
    calendar: null,
    events: [],
    todayLong: new Date().toISOString().substr(0, 10),
    todayFull: null,
    currentMonday: null
  }),
}
</script>

<style lang="scss">
.pop-500{
  font-size: 12px !important;
}

.customcss {
  height: 65px !important;
}

#calendar {

  ::-webkit-scrollbar {
    width: 0;
  }

  //::-webkit-scrollbar-track {
  //  border-radius: 0 0 10px 0;
  //  background: #f1f1f1;
  //
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  border-radius: 5px;
  //  background: #2A12D4;
  //
  //
  //  &:hover {
  //    background: #1E3179;
  //  }
  //}
}

.calendar_default_event_bar {
  background: transparent;
  width: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.calendar_default_event_inner {
  border-radius: 10px !important;
}


.signature-d-flex {
  display: flex;

  @media(max-width: 600px) {
    flex-direction: column;
  }
}

.plan-signature {
  display: block !important;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.places {
  margin: 1rem 0;
  font-size: 1.2rem;

  & .place-item {
    margin: 1rem 0;
    padding: 2rem;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;


    @media (max-width: 479px) {
      &, & .fold {
        flex-direction: column;
      }

      & .fold .align-self-center {
        align-self: start;
      }
    }
  }
}

.address-select {
  margin-right: 10px;
}

#autocomplete-input {
  margin: 0 1rem;
  border: none !important;
  outline: none !important;
  width: 100%;

  @media (max-width: 479px) {
    margin: 0;
  }
}

.calendar-control {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  & img {
    transition: all ease-in-out .3s;
    width: 30px;
    height: 30px;

    &:hover {
      &.mirrored {
        transform: scaleX(-1.2) scaleY(1.2) !important;
      }

      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.mirrored {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.calendar-icon {
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.calendar-navigation{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
}

</style>

