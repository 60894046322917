import Expert from "../views/Expert";

import KartaPosudky from "../containers/expert/KartaPosudky/KartaPosudky";

const expertRoutes = {
  name: "RoleRoutes",
  component: Expert,
  path: "",
  redirect: KartaPosudky,
  children: [
    {
      name: "KartaPosudky",
      component: KartaPosudky,
      path: "/karta-posudky/",
    },
  ],
}

export default expertRoutes;
