<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Objednať posudok</div>
      </div>
      <order-property-report-form :property-info="payload"/>
      <div class="information-wrapper">
        <img src="@/images/existujuce-uvery-icon.png" width="100" alt="">
        <span class="blc-color">objednávka je pre klienta <br>záväzná, pričom cena posudku bude <br> 79 EUR splatných po dodaní</span>
        <div>
          <button class="w-button saveformBtn">
            Uložiť a odoslať
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import OrderPropertyReportForm from "./OrderPropertyReportForm";

export default {
  name: "OrderPropertyReportPopUp",
  components: {OrderPropertyReportForm, DropdownSelect},
  props: {
    payload: Object,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute !important;
  top: 0;
  z-index: 999;
  overflow-y: auto;
  transition-duration: 0.5s;
}

.information-wrapper {
  margin-top: 10px;
  display: flex;
  column-gap: 105px;
  flex-direction: column;
  align-items: center;
}

.information-wrapper * {
  margin-top: 10px;
}

.information-wrapper > span {
  text-align: center;
  font-size: 15px;
}
</style>
