<template>
  <div class="w-form">
    <div class="dropdown-input-custom rest-input">
      <dropdown-select
        @select="contactPersonHelper = $event"
        :id="'contactPerson'"
        :options="getOwnerChoices()"
        :init-value="propertyInfo.contactPerson.majitel"
        normal
        :name="'Objednávateľ'"
      />
    </div>
    <div v-if="contactPersonHelper === 'other'">
      <div class="input-group" style="margin-top: 10px">
        <label for="objednavatelMeno" class="field-label">Meno<strong></strong></label
        ><input
        v-model="form.objednavatel_meno"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="objednavatelMeno"
        placeholder=""
        id="objednavatelMeno"
        required=""
      />
      </div>
      <div class="input-group">
        <label for="objednavatelEmail" class="field-label">Email<strong></strong></label
        ><input
        v-model="form.objednavatel_email"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="objednavatelEmail"
        placeholder=""
        id="objednavatelEmail"
        required=""
      />
      </div>
      <div class="input-group">
        <label for="objednavatelTelefon" class="field-label"
        >Telefón<strong></strong></label
        ><input
        v-model="form.objednavatel_telefon"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="objednavatelTelefon"
        placeholder=""
        id="objednavatelTelefon"
        required=""
      />
      </div>
    </div>
    <div class="dropdown-input-custom rest-input">
      <dropdown-select
        @select="ownerHelper = $event"
        :id="'contactPerson'"
        :options="getOwnerChoices()"
        :init-value="propertyInfo.contactPerson.majitel"
        normal
        :name="'Kontaktná osoba'"
      />
    </div>
    <div v-if="ownerHelper === 'other'">
      <div class="input-group">
        <label for="majitelMeno" class="field-label">Meno<strong></strong></label
        ><input
        v-model="form.majitel_meno"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="majitelMeno"
        placeholder=""
        id="majitelMeno"
        required=""
      />
      </div>
      <div class="input-group">
        <label for="majitelEmail" class="field-label">Email<strong></strong></label
        ><input
        v-model="form.majitel_email"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="majitelEmail"
        placeholder=""
        id="majitelEmail"
        required=""
      />
      </div>
      <div class="input-group">
        <label for="majitelTelefon" class="field-label"
        >Telefón<strong></strong></label
        ><input
        v-model="form.majitel_telefon"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="majitelTelefon"
        placeholder=""
        id="majitelTelefon"
        required=""
      />
      </div>
    </div>
    <div
      class="input-group"
    >
      <label for="sprava" class="field-label"
      >Správa pre znalca</label
      >
      <textarea
        class="text-input w-input date-input"
        id="sprava"
        required
      />
    </div>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";

export default {
  name: "OrderPropertyReportForm",
  components: {DropdownSelect},
  props: {
    propertyInfo: Object,
  },
  created () {
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
    this.form.majitel_meno = this.propertyInfo.contactPerson.majitel_meno;
    this.form.majitel_email = this.propertyInfo.contactPerson.majitel_email;
    this.form.majitel_telefon = this.propertyInfo.contactPerson.majitel_telefon;
  },
  methods: {
    getOwnerChoices () {
      if (this.mortgage.coapplicant)
        return [
          {
            display_name: "žiadateľ",
            value: "applicant",
          },
          {
            display_name: "spolužiadateľ",
            value: "coapplicant",
          },
          {
            display_name: "tretia strana",
            value: "other",
          },
        ]
      return [
        {
          display_name: "žiadateľ",
          value: "applicant",
        },
        {
          display_name: "tretia strana",
          value: "other",
        },
      ]
    },
    setDropdownValue (value, key) {
      this.form[key] = value;
    },
  },
  data: () => ({
    form: {
      message: null,
      majitel_meno: null,
      majitel_telefon: null,
      majitel_email: null,
      objednavatel_meno: null,
      objednavatel_telefon: null,
      objednavatel_email: null,
    },
    ownerHelper: null,
    contactPersonHelper: null,
    mortgage: null,
  }),
}
</script>

<style scoped>

</style>
