<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Pridanie príjmu Diét</div>
        <button class="w-button saveformBtn" @click="saveDiety">Uložiť</button>
      </div>
      <form @submit.prevent>
        <div
          class="input-group"
          v-for="(income, index) in diety"
          :key="index"
        >
          <div class="div-block-141">
            <div style="width: 75%;">
              <label for="prijem" class="blc-color">{{ months[income.mesiac] }} {{ income.rok }}</label>
            </div>
            <div class="d-flex justify-content-end">
              <input
                @keydown.tab.prevent
                v-model.number="income.diety"
                type="number"
                style="text-align: right; width: 100%;"
                step="0.01"
                min="0"
                maxlength="256"
                name="diety"
                data-name="diety"
                id="diety"
                required
              />
              <span class="bl-color">
              EUR
            </span>
            </div>
          </div>
          <div
            v-if="index > 11"
            class="closebtn-broker-app pointer noselect"
            @click="diety.splice(index, 1)"
          >
          <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
          </div>
        </div>
      </form>
      <div class="section-title">
        <div class="sub-popup-wrapper noselect pointer" @click="addMonth">
          + pridať {{ diety.length !== 0 ? "ďalší" : "" }} mesiac
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientEmploymentDietyPopUp",
  props: {
    payload: [Array, null],
  },
  created () {
    for (let i = 0; i < 12; i++) {
      this.addMonth();
    }
    if (!this.payload || this.payload.length === 0) return;
    for (const dieta of this.diety) {
      for (const monthlyIncome of this.payload) {
        if (dieta.mesiac === monthlyIncome.mesiac && dieta.rok === monthlyIncome.rok) {
          dieta.diety = monthlyIncome.diety;
          break;
        }
      }
    }
  },
  methods: {
    convertDiety () {
      const diety = [];
      for (const dieta of this.diety) {
        if (dieta.diety === 0) continue;
        diety.push(dieta);
      }
      if (diety.length === 0) {
        return null;
      }
      return diety;
    },
    saveDiety () {
      const diety = this.convertDiety();
      if (!!diety) {
        this.$emit("set-meta", {diety});
      }
      this.closePopup();
    },
    convertDate (year, month) {
      if (month >= 10) {
        return year + "-" + month;
      }
      return year + "-0" + month;
    },
    addMonth () {
      const date = new Date();
      const incomeDate = this.getNextIncomeDate(date);
      const incomeMonth = {
        income: null,
        id: null,
        mesiac: incomeDate.mesiac,
        rok: incomeDate.rok,
        suma: 0,
        diety: 0,
      };
      this.diety.push(incomeMonth);
    },
    getNextIncomeDate (date) {
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      if (this.diety.length === 0) {
        return {
          mesiac: currentMonth - 1,
          rok: currentYear,
        };
      }
      const lastMonthInArray =
        this.diety[this.diety.length - 1].mesiac;
      const lastYearInArray =
        this.diety[this.diety.length - 1].rok;
      if (lastMonthInArray === 1) {
        return {
          mesiac: 12,
          rok: lastYearInArray - 1,
        };
      } else {
        return {
          mesiac: lastMonthInArray - 1,
          rok: lastYearInArray,
        };
      }
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  data: () => ({
    diety: [],
    months: {
      1: "Január",
      2: "Február",
      3: "Marec",
      4: "Apríl",
      5: "Máj",
      6: "Jún",
      7: "Júl",
      8: "August",
      9: "September",
      10: "Október",
      11: "November",
      12: "December",
    },
  }),
}
</script>

<style lang="scss" scoped>
.closebtn-broker-app {
  right: 15px;
}

.field-label {
  margin-top: 0;
}

.text-input {
  margin-top: 0;
}

.input-wrapper {
  position: relative;
}

.sub-popup-wrapper {
  text-align: center;
  color: #0019d9;
  font-size: 12px;
}

input {
  color: #0019d9;
}

.div-block-141 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  width: 90%;
}
</style>
