export default {
  methods: {
    transformDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('de-DE', {
        day: 'numeric', month: 'numeric', year: 'numeric',
      });
    }
  }
}
