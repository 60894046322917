<template>
  <div
    class="discount-item"
  >
    <span class="bold bl-color font-size-16 mr-2">{{ discount.zmena }}%</span>
    <div>
      {{ discount.label }}
    </div>
    <div v-if="!loading">
      <span
        v-if="!picked"
        class="ml-2 text-cta noselect"
        @click="applySadzbaChange(discount.id, discount.zmena)"
      >
        UPLATNIŤ
      </span>
      <span
        v-else
        class="ml-2 text-cta noselect"
        @click="removeSadzbaChange(discount.id, discount.zmena)"
      >
        ZRUŠIŤ
      </span>
    </div>
    <div class="ml-3" v-else>
      <v-progress-circular
        class="loading-spinner extrasmall"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscountItem",
  props: {
    discount: Object,
    mortgageRateChanges: Array,
    mortgageId: Number,
  },
  created () {
    this.picked = this.mortgageRateChanges.includes(this.discount.id);
  },
  methods: {
    async applySadzbaChange (id, change) {
      this.loading = true;
      const sadzby = [...this.mortgageRateChanges, id];
      this.$store.commit("mortgageDetailState/setAppliedMortgageRateChanges", sadzby);
      const mortgageObject = {
        id: this.mortgageId,
        object: {
          uplatnene_zmeny_sadzieb: sadzby,
        },
      }
      await this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.$emit("discount-change", change);
      this.loading = false;
      this.picked = true;
    },
    async removeSadzbaChange (id, change) {
      this.loading = true;
      const sadzby = [...this.mortgageRateChanges];
      const index = sadzby.indexOf(id);
      sadzby.splice(index, 1);
      this.$store.commit("mortgageDetailState/setAppliedMortgageRateChanges", sadzby);
      const mortgageObject = {
        id: this.mortgageId,
        object: {
          uplatnene_zmeny_sadzieb: sadzby,
        },
      }
      await this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.$emit("discount-change", change);
      this.loading = false;
      this.picked = false;
    }
  },
  data: () => ({
    loading: false,
    picked: false,
  }),
}
</script>

<style lang="scss" scoped>
</style>
