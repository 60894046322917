import Admin from "../views/Admin";

import KartaKlienti from "../containers/broker/KartaKlienti/KartaKlienti";
import KartaHypoteky from "../containers/broker/KartaHypoteky/KartaHypoteky";
import KartaHypotekyDetail from "../containers/broker/KartaHypoteky/KartaHypotekyDetail";
import KartaZiadosti from "../containers/broker/KartaZiadosti/KartaZiadosti";
import KartaPosudky from "../containers/expert/KartaPosudky/KartaPosudky";
import KartaNastavenia from "../containers/broker/KartaNastavenia/KartaNastavenia.vue";

const adminRoutes = {
  name: "RoleRoutes",
  component: Admin,
  path: "",
  redirect: KartaKlienti,
  children: [
    {
      name: "KartaHypoteky",
      component: KartaHypoteky,
      path: "/karta-hypoteky/",
    },
    {
      name: "KartaHypotekyDetail",
      component: KartaHypotekyDetail,
      path: "/karta-hypoteky/:hypoteka/detail",
    },
    {
      name: "KartaKlienti",
      component: KartaKlienti,
      path: "/karta-klienti/",
    },
    {
      name: "KartaZiadosti",
      component: KartaZiadosti,
      path: "/karta-ziadosti/",
    },
    {
      name: "KartaPosudky",
      component: KartaPosudky,
      path: "/karta-posudky/",
    },
    {
      name: "KartaNastavenia",
      component: KartaNastavenia,
      path: "/karta-nastavenia/",
    },
  ],
}

export default adminRoutes;
