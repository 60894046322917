<template>
  <input
    :id="id + 'xd'"
    :type="type"
    class="border-custom"
    :class="color"
    v-model="value"
    :value="valueGiven"
    :min="min"
    :max="max"
    :placeholder="placeholder"
    autocomplete="off"
    @change="emitChangeEvent"
  />
</template>

<script>
export default {
  name: "ChipInput",
  props: {
    id: String,
    min: [Number, null],
    max: [Number, null],
    placeholder: [String, null],
    color: String,
    factor: Number,
    type: String,
    valueGiven: Number,
    defaultValue: [String, Number],
    valueCorrection: [Number, null],
  },
  mounted () {
    this.el = document.getElementById(this.id + 'xd');
    this.el.style.width = (this.el.placeholder.length + 1) * this.factor + "px";
    this.changeWidth(this.el, this.factor);
  },
  methods: {
    emitChangeEvent () {
      if (this.max && this.value > this.max) {
        this.value = this.max;
      } else if (this.min !== null && parseInt(this.value) < this.min) {
        this.value = 1;
      }
      this.$emit("change-event", this.value);
      this.changeWidth(this.el, this.factor);
    },
    changeWidth (el, factor) {
      const int = Number(factor) || 7.7;
      // const e = "keyup,keypress,focus,blur,change".split(",");
      // for (let i of e) el.addEventListener(i, this.resize(el, int), false);
      this.resize(el, int);
    },
    getNumberLength(number) {
      return number.toString().length;
    },
    resize (el, int) {
      if (!this.value) {
        el.style.width = (el.placeholder.length + 1) * int + "px";
      } else {
        el.style.width = (this.getNumberLength(this.value) + 1) * int + "px";
      }
    },
    emitNewValue () {
      this.$emit("value-changed", this.value);
    },
  },
  watch: {
    valueCorrection (newValue) {
      if (newValue === null) return;
      this.value = newValue;
      this.changeWidth(this.el, this.factor);
      this.$emit("value-corrected");
    },
    valueGiven () {
      this.value = this.valueGiven;
      this.changeWidth(this.el, this.factor);
    },
    value (newValue) {
      if (newValue > this.max) this.value = this.max;
      this.changeWidth(this.el, this.factor);
    },
  },
  data () {
    return {
      el: null,
      value: this.defaultValue,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
