<template>
  <v-scale-transition>
    <div class="pdf-wrapper" v-if="show">
      <client-financing-offer-generator :banks-offers="mortgageApplications" :mortgage="mortgage"/>
    </div>
  </v-scale-transition>
</template>

<script>
import ClientFinancingOfferGenerator from "./ClientFinancingOfferGenerator.vue";

export default {
  name: "clientFinancingOfferPopUp",
  components: {
    ClientFinancingOfferGenerator
  },
  props: {
    mortgage: Object,
    mortgageApplications: Array,
    show: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.pdf-wrapper {
  padding: 25px;
  height: 90vh;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  overflow-y:scroll;
  z-index: 9999;
  box-shadow: 0 0 40px rgba(0,0,0,0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>