<template>
  <div class="popup-form">
    <div class="content-form-popup">
      
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blc-color pop-800">Rýchle odpovede</div>
      </div>
     
      <div class="mt-3">
        <div class="quick-response-block pointer" @click="setQuickResponse('Dobrý deň, poradím Vám s výberom riešenia? Ak Vám fixácia končí neskôr, už dnes vieme podchytiť úrokové sadzby (napr. UniCreditBank 3.89%) s čerpaním do 1 roka tak, aby sme sa vyhli pokute.')
">
          Dobrý deň, poradím Vám s výberom riešenia? Ak Vám fixácia končí neskôr, už dnes vieme podchytiť úrokové sadzby (napr. UniCreditBank 3.89%) s čerpaním do 1 roka tak, aby sme sa vyhli pokute.
        </div>
        <div class="quick-response-block pointer" @click="setQuickResponse('Dobrý deň, vieme Vám pomôcť s výberom správnej banky? V prípade ak už máte vybranú nehnuteľnosť, tak Vám náš advokát skontroluje zadarmo zmluvy. Len dajte vedieť, Petrovič.')">
          Dobrý deň, vieme Vám pomôcť s výberom správnej banky? V prípade ak už máte vybranú nehnuteľnosť, tak Vám náš advokát skontroluje zadarmo zmluvy. Len dajte vedieť, Petrovič.
        </div>
        <div class="quick-response-block pointer" @click="setQuickResponse('Schválenie hypotéky trvá štandardne 2 až 3 pracovné dní od skompletizivania žiadosti.')">
          Schválenie hypotéky trvá štandardne 2 až 3 pracovné dní od skompletizivania žiadosti.
        </div>
        <div class="quick-response-block pointer" @click="setQuickResponse('Dobrý deň, vieme Vám pomôcť s výberom správnej banky?')">
          Dobrý deň, vieme Vám pomôcť s výberom správnej banky?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickResponseChatPopUp",
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    setQuickResponse (quickMessage) {
      this.$emit("set-meta", {quickMessage});
      this.closePopup();
    },
  }
}
</script>


<style lang="scss" scoped>
.content-form-popup {
  justify-content: center;
}

.quick-response-block {
  padding: 20px;
  margin: 0 auto;
  width: 95%;
  background: #F8F9FA;
  border-radius: 10px;
  margin-bottom: 10px;
}

.logo {
  color: #0A1953;
}

.header-popup-wrapper {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icontext {
  line-height: 12px;
  font-size: 17px;
}
</style>

