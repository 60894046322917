<template>
  <div class="popup-form popup-cloud">
    <div class="content-form-popup noselect">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
      </div>
      <document-cloud-holder
        v-for="(cloudOfMortgage, key) in payload"
        :type="type"
        :extra="extra"
        :open-on-load="Object.keys(payload).length === 1"
        :clouds="cloudOfMortgage.clouds"
        :label="cloudOfMortgage.type_label"
        :key="key"
        :meta-data="metaData"
      />
    </div>
  </div>
</template>

<script>

import DocumentCloudHolder from "./DocumentCloudHolder";

export default {
  name: "DocumentCloudPopUp",
  props: {
    type: [Number, null],
    extra: Object,
    payload: Object,
    metaData: [Object, null],
  },
  components: {DocumentCloudHolder},
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style scoped lang="scss">
#Client-name {
  font-size: 15px;
}

.popup-cloud {
  width: 50% !important;
}

@media screen and (max-width: 992px) {
  .popup-cloud {
    width: 90% !important;
  }
}

</style>
