<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopupWithInfo">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
        <div class="blackcolor pop-800">Pridanie spolužiadateľa</div>
      </div>
      <div class="client-name-block">
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f37_avatar%20cropped.png"
          loading="lazy"
          alt=""
          class="image-10"
        />
        <div class="div-block-28">
          <div class="bold">{{ clientInfo.type }}</div>
        </div>
      </div>
      <client-picker @client-picked="assignCoApplicant($event)" complex/>
    </div>
  </div>
</template>

<script>
import ClientPicker from "../ClientPicker/ClientPicker";

export default {
  name: "ClientAddPopUp",
  components: {ClientPicker},
  props: {
    payload: [Object, null],
  },
  data: () => ({
    clientInfo: null,
    existingEmail: false,
  }),
  created () {
    this.clientInfo = this.$store.getters["popupManagerState/getClientInfo"];
  },
  methods: {
    async assignCoApplicant (clientInfo) {
      const mortgageId = (this.$store.getters["mortgageDetailState/getMortgage"]).id;
      const mortgageToPatch = {
        id: mortgageId,
        object: {
          coapplicant: clientInfo.id,
        }
      };
      this.closePopup();
      this.$emit("set-meta", {type: "coApplicantAdd", status: "success", ...this.payload});
      await this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageToPatch);
      this.$store.dispatch("mortgageDetailState/fetchLimits");
      await this.$store.dispatch("mortgageDetailState/addCoApplicantToMortgage", clientInfo.id);
      this.$store.commit("appState/setSuccessMessage", {
        text: "k hypotéke ste pridali spolužiadateľa",
        type: "success"
      });
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    closePopupWithInfo() {
      this.$emit("set-meta", {type: "coApplicantAdd", status: "aborted", ...this.payload});
      this.closePopup();
    }
  },
};
</script>

<style lang="scss" scoped>

.popup {
  width: 34%;
  position: absolute !important;
  top: 0;
  overflow-y: auto;
  transition-duration: 0.5s;
}
</style>
