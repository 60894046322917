<template>
  <div class="statistic-tabs mt-5">
    <div class="stat-block">
      <div class="div-block-2">
        <img
          alt=""
          src="@/images/Vector-12.png"
          loading="lazy"
          class="tendency-icon"
        />
        <div class="text-small green--text">+14.6%</div>
      </div>
      <div class="blacktext">Nové hypotéky</div>
      <div class="large-text stat-number pop-800">8</div>
    </div>
    <div class="stat-block">
      <div class="div-block-2">
        <img
          alt=""
          src="@/images/Vector-12-red.png"
          loading="lazy"
          class="tendency-icon"
        />
        <div class="text-small red--text">-25%</div>
      </div>
      <div class="black--text">Načerpané</div>
      <div class="large-text stat-number pop-800">589.000€</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HypotekyStats",
};
</script>

<style scoped></style>
