<template>
  <div>
    <div
      class="div-block-102 noselect"
    >
      <div class="div-block-106">
        <range-slider
          :id-not-selected="'not-selected-refinancovanie'"
          :id-selected="'selected-refinancovanie'"
          :min="0"
          :max="limit"
          :main-desc="'refinancovanie'"
          :default-value="amounts.suma_refinancovanie"
          :twoSideValues="false"
          :description="'Výška úveru na'"
          :unit="'EUR'"
          :value-correction="valueCorrection"
          @value-corrected="valueCorrection = null"
          @change-event="setRefinancovanieAmount($event)"
        />
      </div>
      <div class="input-block contrast p-relative" v-for="(debt, key) in refinancedDebtsGetter" :key="key">
        <div class="closebtn-broker-app pointer" @click="remove(debt)">
          <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
        </div>
        <div class="div-block-101">
          <div>
            {{ translateDebtType(debt.typ) }} <strong>{{ translateBank(debt.banka) }}</strong>
          </div>
          <div class="bold">{{ debt.dti.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <client-debt-handler :type="'refinancovane'" :rules="['priority']" :title="'+ pridať splatenie VÝDAVKU'"/>
    </div>
  </div>
</template>

<script>
import RangeSlider from "../../../../shared/RangeSlider";
import ClientDebtHandler from "../../../../shared/PopupHandler/Popups/ClientDebt/ClientDebtHandler";
import { mapGetters } from "vuex";
import translateFromDBValue from "../../../../../mixins/translateFromDBValue";
import clientFilters from "../../../../../mixins/clientFilters";

export default {
  name: "RefinancovanieWrapper",
  components: {RangeSlider, ClientDebtHandler},
  props: {
    mortgage: Object,
    totalLimit: Number,
    loanPurpose: [String, null],
  },
  mixins: [
    translateFromDBValue,
    clientFilters
  ],
  computed: {
    refinancedDebtsGetter (comp) {
      const debts = this.$store.getters["mortgageDetailState/getRefinancedDebts"](this.mortgageId);
      // console.trace()
      comp.handleMinBasedOnRefinanDebt(debts);
      return debts;
    },
    ...mapGetters({
      amountsGetter: "mortgageDetailState/getAmounts",
      mortgageGetter: "mortgageDetailState/getMortgage",
    }),
  },
  data: () => ({
    amounts: null,
    limit: 0,
    valueCorrection: null,
    refinancovanieDebtsAmount: 0,
    mortgageId: null,
  }),
  created () {
    this.mortgageId = parseInt(this.$route.params.hypoteka);
    this.amounts = this.$store.getters["mortgageDetailState/getAmounts"];
    const debts = this.$store.getters["mortgageDetailState/getRefinancedDebts"](this.mortgageId);
    for (const debt of debts) {
      this.refinancovanieDebtsAmount -= debt.dti.value;
    }
    if (!this.totalLimit) return;
    this.setMaximumAmount();
  },
  watch: {
    // limit (newLimit) {
    //   this.$store.commit("mortgageDetailState/setAmountsLimits", {type: "suma_refinancovanie", value: newLimit});
    // },
    amountsGetter (newAmounts) {
      this.amounts = newAmounts;
      this.setMaximumAmount();
    },
    totalLimit () {
      this.setMaximumAmount();
    },
  },
  methods: {
    handleMinBasedOnRefinanDebt (debts) {
      let valueCorrection = this.amounts.suma_refinancovanie - this.refinancovanieDebtsAmount;
      this.refinancovanieDebtsAmount = 0;
      for (const debt of debts) {
        this.refinancovanieDebtsAmount -= debt.dti.value;
      }
      valueCorrection += this.refinancovanieDebtsAmount;
      this.valueCorrection = valueCorrection;
      this.setRefinancovanieAmount(valueCorrection);
    },
    remove (debt) {
      const clientObject = this.getClientType(debt.klient);
      const debtObject = {
        id: debt.id,
        object: {
          is_active: false,
          refinancovany_hypotekou: null,
        },
        clientRole: clientObject.type_code,
        clientLabel: clientObject.type,
      };
      this.$store.dispatch("mortgageDetailState/toggleClientRefinanced", debtObject);
    },
    translateTypeFromDBValue (type) {
      switch (type) {
        case "hypoteka":
          return "Hypotéka";
        case "povolene_precerpanie":
          return "Povolené prečerpanie";
        case "kreditna_karta":
          return "Kreditná karta";
        case "spotrebny_uver":
          return "Spotrebný úver";
        case "leasing":
          return "Leasing";
      }
    },
    showConfirmationDialog () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Touto zmenou zmeníte účel hypotéky",
        type: "confirmation"
      });
    },
    checkForPurposeChange (value) {
      const currentMaxKey = Object.keys(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const currentMaxValue = Object.values(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const newAmounts = {...this.amounts, suma_refinancovanie: value};
      const newMaxKey = Object.keys(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      const newMaxValues = Object.values(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      if (currentMaxValue === 0 || newMaxValues === 0) return false;
      return currentMaxKey !== newMaxKey
    },
    askForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    setMaximumAmount () {
      let limit = this.totalLimit;
      for (const [key, amount] of Object.entries(this.amounts)) {
        if (key === "suma_refinancovanie") continue;
        limit = limit - amount;
      }
      if (limit < 0) {
        this.limit = this.totalLimit;
        if (this.loanPurpose === "refinancovanie") {
          this.$store.commit("mortgageDetailState/setAmounts", {
            suma_nadobudnutie: 0,
            suma_refinancovanie: this.limit,
            suma_bezucel: 0,
          });
        }
      } else {
        this.limit = limit;
      }
    },
    async setRefinancovanieAmount (value) {
      const latestValue = this.amounts.suma_refinancovanie;
      if (value < this.refinancovanieDebtsAmount) {
        this.valueCorrection = latestValue;
        this.$store.commit("appState/setSuccessMessage", {
          text: "Nie je možné ponížiť sumu refinancovania pod sumu refinancovaného dlhu",
          type: "error"
        });
        return;
      }
      const willChangePurpose = this.checkForPurposeChange(value);
      let userPermission = null;
      if (willChangePurpose) {
        this.showConfirmationDialog();
        userPermission = await this.askForUserPermission();
      }
      if (userPermission === false) {
        this.valueCorrection = latestValue;
        return;
      }
      if (latestValue === value) return;
      this.$store.commit("mortgageDetailState/setAmount", {
        type: 'suma_refinancovanie',
        value: value,
      });
      const mortgageObject = this.createObjectToHistory();
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
    },
    createObjectToHistory () {
      const currentHistory = this.mortgage.offers_filter_history;
      const applicationData = this.$store.getters["mortgageDetailState/getOffersData"];
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let newHistoryObject = {
        date: new Date().toISOString(),
        fix: applicationData.fix ? applicationData.fix : 3,
        suma_bezucel: amounts.suma_bezucel ? amounts.suma_bezucel : 0,
        suma_nadobudnutie: amounts.suma_nadobudnutie ? amounts.suma_nadobudnutie : 0,
        suma_refinancovanie: amounts.suma_refinancovanie ? amounts.suma_refinancovanie : 0
      };
      if (!currentHistory) {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              broker: {
                lastOffersSetup: null,
                history: [newHistoryObject],
              },
              sent: [],
              client: [],
            }
          },
        }
      } else {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              sent: currentHistory.sent,
              client: currentHistory.client,
              broker: {
                lastOffersSetup: currentHistory.broker?.lastOffersSetup ? currentHistory.broker.lastOffersSetup : null,
                history:
                  currentHistory.broker.history ? [newHistoryObject, ...currentHistory.broker.history] : Array.isArray(currentHistory.broker) && currentHistory.broker?.length !== 0 ? [newHistoryObject, ...currentHistory.broker] : [newHistoryObject],
              }
            }
          }
        }
      }
    },
  }
};
</script>

<style scoped></style>
