<template>

<div class="nav-menu">
      
      <div class="left-top-bar">
        <div class="nav-link-block">
          <user-profile/>
        </div>
        <div class="nav-link-block">
          <div
            class="nav-link noselect link-icon-text"
            :class="[this.current === 'klienti' ? 'current' : '']"
            @click="navigateTo('klienti')"
          >
            <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0b_icons8-people.svg"></img>
            klienti
          </div>
        </div>
        <div class="nav-link-block">
          <div
            class="nav-link noselect link-icon-text"
            :class="[this.current === 'hypoteky' ? 'current' : '']"
            @click="navigateTo('hypoteky')"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0c_icons8-business.svg"></img>
          hypotéky
          </div>
        </div>
        <!-- <div class="nav-link-block">
          <div
            class="nav-link noselect"
            @click="navigateTo('ziadosti')"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0d_icons8-agreement.svg"></img>
          </div>
        </div> -->
        <div class="nav-link-block">
          <div
            class="nav-link noselect link-icon-text"
            :class="[this.current === 'ziadosti' ? 'bl-color' : '']"
            @click="showCalendar()"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f0e_icons8-calendar.svg"></img>
          kalendár
          </div>
        </div>
        <div class="nav-link-block">
          <div
            class="nav-link noselect link-icon-text"
            :class="[this.current === 'posudky' ? 'bl-color' : '']"
            @click="navigateTo('posudky')"
          >
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4d_icons8-ereader.svg"></img>
          metodika
          </div>
        </div>
        <!-- <div class="nav-link-block">
                   <div class="nav-link noselect">ZMLUVY</div>
        </div> -->
        <!-- <div class="nav-link-block">
          <div
            class="nav-link noselect"
            :class="[this.current === 'posudky' ? 'bl-color' : '']"
            @click="navigateTo('posudky')"
          >
            POSUDKY
          </div>
        </div> -->
      </div>
      
      <div class="right-top-bar">
        <!-- <users-tasks-handler/> -->
        <notification-handler :rules="['single']"/>
      </div>

      <audio id="new-notification" style="display: none" muted="muted">
        <source :src="require('@/sounds/new_message.mp3')" type="audio/mpeg"/>
      </audio>
    
    </div>

</template>

<script>
import router from "@/router";
import NotificationHandler from "../shared/PopupHandler/Popups/NotificationsManager/NotificationHandler";
import UserProfile from "../shared/UserProfile";
import UsersTasksHandler from "@/components/shared/PopupHandler/Popups/UsersTasks/UsersTasksHandler.vue";

export default {
  name: "NavBarBroker",
  components: {UsersTasksHandler, UserProfile, NotificationHandler},
  watch: {
    $route (to) {
      this.current = to.path.split("/")[1].split("-")[1];
    },
  },
  mounted () {
    this.current = this.$route.fullPath.split("/")[1].split("-")[1];
  },
  created () {
    this.me = this.$store.getters["userState/getMe"];
    this.role = this.$store.getters["userState/getRole"];
  },
  beforeDestroy () {
    clearInterval(this.interval);
    clearInterval(this.mortgagesInterval);
  },
  methods: {
    showCalendar () {
      this.$store.commit("appState/setCalendarVisible", true);
    },
    showNotificationPanel () {
      this.notificationOpen = true;
      setTimeout(() => {
        this.isClicked = true;
        this.isOpen = true;
      }, 100);
    },
    navigateTo(clicked) {
      this.mobileDropdownMenu = false;
      let finalLocation = "/karta-" + clicked;
      router.push(finalLocation);
    },
  },
  data: () => ({
    me: null,
    mobileDropdownMenu: false,
    messageCounter: 0,
    interval: 0,
    mortgagesInterval: 0,
    isOpen: false,
    isClicked: false,
    notificationOpen: false,
    allUnseenMessages: 0,
    current: "",
    brokerId: null,
    clientId: null,
  }),
};
</script>

<style scoped lang="scss">
</style>
