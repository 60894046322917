<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      <span class="pop-800">{{ role }}</span>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'titul'" :value="summaryData.degree"/>
        <text-value-holder :label="'meno'" :value="summaryData.first_name"/>
        <text-value-holder :label="'priezvisko'" :value="summaryData.last_name"/>
        <text-value-holder :label="'obec'" :value="summaryData.client_profile.permanent_address_city"/>
        <text-value-holder :label="'ulica'" :value="summaryData.client_profile.permanent_address_street"/>
        <text-value-holder :label="'súpisné č.'" :value="summaryData.client_profile.permanent_address_popisne"/>
        <text-value-holder :label="'orientačné č.'" :value="summaryData.client_profile.permanent_address_number"/>
        <text-value-holder :label="'PSČ'" :value="summaryData.client_profile.permanent_address_zip"/>
        <text-value-holder :label="'narodený'" :value="transformDate(summaryData.client_profile.datum_narodenia)"/>
        <text-value-holder :label="'rodné č.'" :value="summaryData.client_profile.rodne_cislo"/>
        <text-value-holder :label="'príslušnosť'" :value="summaryData.client_profile.statna_prislusnost"/>
        <text-value-holder :label="'číslo OP'" :value="summaryData.client_profile.op_cislo"/>
        <text-value-holder :label="'OP do'" :value="transformDate(summaryData.client_profile.op_platnost_do)"/>
        <text-value-holder :label="'OP vydaný'" :value="transformDate(summaryData.client_profile.op_vytvoreny)"/>
        <text-value-holder :label="'rodinný stav'" :value="summaryData.client_profile.rodinny_stav"/>
        <text-value-holder :label="'vzdelanie'" :value="summaryData.client_profile.vzdelanie"/>
        <text-value-holder :label="'členovia v dom.'" :value="summaryData.client_profile.pocet_vyzivovanych_osob"/>
        <text-value-holder :label="'počet detí'" :value="summaryData.client_profile.pocet_nezaopatrenych_deti"/>
        <text-value-holder :label="'bývam'" :value="summaryData.client_profile.aktualne_byvate"/>
        <text-value-holder :label="'telefón'" :value="summaryData.client_profile.telephone"/>
        <text-value-holder :label="'email'" :value="summaryData.email"/>
        <file-download-holder v-if="prednaStranaOP" :cloud-file="prednaStranaOP" :label="'Predná strana OP'"/>
        <file-download-holder v-if="zadnaStranaOP" :cloud-file="zadnaStranaOP" :label="'Zadná strana OP'"/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import ClipboardButton from "./ClipboardButton";
import TextValueHolder from "./TextValueHolder";
import FileDownloadHolder from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/FileDownloadHolder.vue";
import dateConverter from "@/mixins/dateConverter";

export default {
  name: "ClientSummary",
  components: {FileDownloadHolder, TextValueHolder, ClipboardButton},
  props: {
    role: String,
    summaryData: [Object, null],
    openedDefault: Boolean,
  },
  mixins: [
    dateConverter
  ],
  created() {
    this.prednaStranaOP = this.$store.getters["mortgageDetailCloud/getCloudFileById"](this.summaryData.cloud, this.summaryData.client_profile.doklad_totoznosti_1);
    this.zadnaStranaOP = this.$store.getters["mortgageDetailCloud/getCloudFileById"](this.summaryData.cloud, this.summaryData.client_profile.doklad_totoznosti_2);
    this.opened = this.openedDefault;
  },
  data: () => ({
    opened: false,
    prednaStranaOP: null,
    zadnaStranaOP: null,
  })
}
</script>

<style scoped>
</style>
