<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Odoslať do banky</div>
      </div>
      <div class="splitter noselect">1. Vyberte schvaľovateľa, ktorému chcete podklady odoslať</div>
      <div
        class="input-block p-relative"
      >
        <div class="div-block-101">
          <div>VÚB Detvianska 22</div>
          <div class="bold">N. Havlovičová</div>
          <div class="text-cta">
            PICK
          </div>
        </div>
      </div>
      <div class="splitter noselect">2. Teraz vyberte jednotlivé súbory ktoré chcete poslať <br> alebo kliknite na
        <span class="bl-color pop-800">VŠETKO</span>
      </div>
      <div class="link-block-7 w-inline-block d-flex justify-content-between pl-5 pr-5">
        <div class="d-flex flex-row">
          <img
            src="@/images/cloud_icon.svg"
            loading="lazy" alt=""
            class="margin-right"
          >
          <div class="small">pôdorys bytu.JPG</div>
        </div>
        <div class="text-cta">
          PICK
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageDocumentsSendPopUp",
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    }
  }
}
</script>

<style scoped>

</style>
